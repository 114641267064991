/* eslint-disable max-len */
import { apiClient } from 'src/repositories/apiClient';
import { config } from 'src/lib/config';

export const getSubscriptionMetaData = async (params: undefined) => {
  const apiUrl = config.API_SVC_PATH_IFIRST_SUBSCRIPTION + config.SUBSCRIPTION_META_DATA;
  const res = await apiClient({
    method: 'get',
    url: apiUrl,
    params,
  });
  return res;
};
export const getSubscriptionStatus = async (params: any) => {
  const apiUrl = config.API_SVC_PATH_IFIRST_SUBSCRIPTION + config.SUBSCRIPTION_STATUS;
  const res = await apiClient({
    method: 'get',
    url: apiUrl,
    params,
  });
  return res;
};

export const getSubscription = async (params: any) => {
  const apiUrl = config.API_SVC_PATH_IFIRST_SUBSCRIPTION + config.SUBSCRIPTION;
  const res = await apiClient({
    method: 'get',
    url: apiUrl,
    params,
  });
  return res;
};
export const postSubscriptionData = async (payload: any) => {
  const apiUrl = config.API_SVC_PATH_IFIRST_SUBSCRIPTION + config.SUBSCRIPTION_CREATE;
  const res = await apiClient({
    method: 'post',
    url: apiUrl,
    data: { ...payload },
  });
  return res;
};
