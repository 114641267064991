import React, { useEffect, useState, useRef } from 'react';
import Loader from 'src/components/loadingAnimation/loadingAnimation.component';
import { useCachedMarketInfo } from 'src/hooks/marketInfo';
import ScreenerSelection from 'src/components/screenerComponents/selector';
import ScreenerTable from 'src/components/Table/ScreenerTable.component';
import { fetchScreenerFlaggedColumn, setScreenerMarket } from './Screener.reducer';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { selectScreenerMarket } from './Screener.selector';
import { PrimaryHeader } from 'src/components';
import Tableloader from 'src/components/loadingAnimation/tableloader';
import { selectHeatmapState } from 'src/pages/Heatmap/heatmap.selector';
import { useCachedScreenerMetaData } from 'src/hooks/cachedScreenerMetadata';
export default function Screener() {
  const tableRef = useRef();

  const dispatch = useDispatch();
  const [advancedFilterActive, setAdvancedFilteredActive] = useState(false);

  const screenerData = useSelector(selectHeatmapState);
  const { isLoading } = useCachedScreenerMetaData();
  const { data: marketInfo } = useCachedMarketInfo();
  const market = useSelector(selectScreenerMarket);
  const [headers, setHeaders] = useState([]);
  const [headersLabelMap, setHeadersLabelMap] = useState([]);
  const [headersKeyMap, setHeadersKeyMap] = useState([]);
  const [dataKey, setDataKey] = useState('');
  useEffect(() => {
    dispatch(fetchScreenerFlaggedColumn());
  }, []);

  const getScreenerNavClass = (currentMarket, market) =>
    `screener-nav-item ${currentMarket === market ? 'screener-nav-item-active' : ''}`;
  return (
    <>
      <section className='screener navbar-fix'>
        <PrimaryHeader text='Screener' className='color-primary' />
        <div className='screener-container'>
          {isLoading ? (
            <Loader />
          ) : (
            <>
              <ul className='screener-nav'>
                {marketInfo?.markets?.map((i) => (
                  <li
                    key={i}
                    onClick={() => {
                      dispatch(setScreenerMarket({ index: '', marketType: marketInfo?.market_supported_map[i] }));
                      setAdvancedFilteredActive(false);
                    }}
                    className={getScreenerNavClass(market, marketInfo?.market_supported_map[i])}>
                    {i} <span className='hidden lg:inline'>Screener</span>
                  </li>
                ))}
              </ul>

              <div className='w-full'>
                <ScreenerSelection
                  tableRef={tableRef}
                  setDataKey={setDataKey}
                  setHeadersLabelMap={setHeadersLabelMap}
                  setHeadersKeyMap={setHeadersKeyMap}
                  setHeaders={setHeaders}
                  setAdvancedFilteredActive={setAdvancedFilteredActive}
                />
                {screenerData?.isLoading ? (
                  <table className='w-full table table-pin-rows'>
                    <Tableloader count={8} />
                  </table>
                ) : screenerData?.error ? (
                  <table className='w-full table table-pin-rows'>
                    <tbody className='td-error'>
                      <div className='td-error-div'>Internal Server Error</div>
                    </tbody>
                  </table>
                ) : (
                  <ScreenerTable
                    ref={tableRef}
                    dataKey={dataKey}
                    headers={headers}
                    headersLabelMap={headersLabelMap}
                    headersKeyMap={headersKeyMap}
                    advancedFilterActive={advancedFilterActive}
                  />
                )}
              </div>
            </>
          )}
        </div>
      </section>
    </>
  );
}
