import * as echarts from 'echarts';
import React, { useEffect, useRef, useMemo, useCallback, memo } from 'react';
import { convertNumberToString } from 'src/lib/utils/utils';

export const ProjectionGraph = memo(({ data, color, height = '40px', targets }) => {
  const chartRef = useRef(null);

  // Calculate the index to start the last 50% of the data
  const startIndex = useMemo(() => Math.floor((data?.length * 3) / 4), [data]);

  const xAxisData = useMemo(() => {
    // Get the actual data from the datetime
    const actualData = data?.map((i) => i.datetime);

    if (!actualData.length) return [];

    // Parse the last date in the current data
    const lastDate = new Date(actualData[actualData.length - 1]);

    // Add 3 months worth of dates to the xAxis
    const futureDates = [];
    const futureDateCount = 90; // approximately 3 months worth of dates

    for (let i = 1; i <= futureDateCount; i++) {
      const futureDate = new Date(lastDate);
      futureDate.setDate(lastDate.getDate() + i); // Add i days to the last date
      futureDates.push(futureDate.toISOString()); // Format date to match 'YYYY-MM-DDTHH:MM:SSZ' format
    }

    // Combine actual data and future dates
    return [...actualData, ...futureDates];
  }, [data]);

  const mainSeriesData = useMemo(() => {
    const actualData = data.map((item) => item.close);

    return [...actualData];
  }, [data, startIndex]);

  const createConnectingLineData = (targetValue) => {
    const connection = Array(xAxisData.length).fill(null);
    const visibleData = data;
    if (visibleData.length > 1) {
      const midpointIndex = Math.floor(visibleData.length);

      connection[midpointIndex] = visibleData[midpointIndex - 1]?.close || 260;
      connection[connection.length - 1] = targetValue;
    }

    return connection;
  };

  const connectingLineDataConsensus = useMemo(
    () => createConnectingLineData(targets.targetConsensus),
    [data, startIndex, xAxisData.length, targets.targetConsensus],
  );
  const connectingLineDataHigh = useMemo(
    () => createConnectingLineData(targets.targetHigh),
    [data, startIndex, xAxisData.length, targets.targetHigh],
  );
  const connectingLineDataLow = useMemo(
    () => createConnectingLineData(targets.targetLow),
    [data, startIndex, xAxisData.length, targets.targetLow],
  );
  const connectingLineDataMedian = useMemo(
    () => createConnectingLineData(targets.targetMedian),
    [data, startIndex, xAxisData.length, targets.targetMedian],
  );
  const createChart = useCallback(() => {
    const chart = echarts.init(chartRef.current);
    const option = {
      title: {
        text: 'Sample Line Chart',
        show: false, // Hide title
      },

      grid: {
        left: 40,
        right: 80,
        top: 60,
        bottom: 40,
        containLabel: false,
      },
      xAxis: {
        type: 'category',
        data: xAxisData,
        show: true,
        axisLine: {
          show: true,
          lineStyle: {
            color: '#ffffff', // Change x-axis line color to white
          },
        },
        axisTick: { show: false },
        splitLine: { show: false },
        axisLabel: {
          color: '#ffffff', // Change x-axis label color to white
          formatter: (value) => {
            return new Date(value).toLocaleDateString(); // Format the date as needed
          },
        },
      },
      yAxis: {
        type: 'value',
        min: 'dataMin',
        max: 'dataMax',
        show: true,
        axisLine: { show: true },
        axisTick: { show: false },
        splitLine: { show: false },
        axisLabel: {
          color: '#ffffff', // Change x-axis label color to white
        },
      },
      tooltip: {
        trigger: 'axis',
        axisPointer: {
          type: 'shadow',
        },
        backgroundColor: 'rgba(0, 0, 0, 0.8)',
        borderColor: 'rgba(0, 0, 0, 0.8)',
        textStyle: {
          color: '#ffffff',
        },
        formatter: (params) => {
          const dataPoint = params[0].data;
          const date = xAxisData[params[0].dataIndex];

          // Only show tooltip for data points after startIndex
          if (params[0].dataIndex > data?.length) {
            return ''; // Return empty tooltip for earlier data points
          }

          const formattedDate = new Date(date).toLocaleDateString();
          const formattedValue = convertNumberToString(dataPoint); // Format value like axis

          return `Date: ${formattedDate}<br/><span style="color: ${color}">Price: ${formattedValue}</span>`;
        },
      },
      series: [
        {
          name: 'One year Data',
          type: 'line',
          showSymbol: false,
          color: color,
          data: mainSeriesData,
          connectNulls: true, // Connect through null values
          areaStyle: {
            // color: '#007BFF',
            color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
              { offset: 0, color: '#007BFF' },
              { offset: 1, color: 'transparent' },
            ]),
          },
          markLine: {
            symbol: ['none', 'none'], // No start and end symbols
            lineStyle: {
              type: 'dashed',
              color: 'grey',
              width: 2,
            },
            data: [
              {
                xAxis: xAxisData[data?.length - 1], // Position the line at the last data point
              },
            ],
            label: {
              show: false,
            },
          },
        },
        {
          name: 'Consensus',
          type: 'line',
          data: connectingLineDataConsensus,
          symbol: 'circle',
          symbolSize: 0,
          itemStyle: {
            color: 'grey',
          },
          markPoint: {
            data: [
              {
                xAxis: data.length - 1,
                yAxis: targets.targetConsensus,
              },
            ],
            symbol: 'circle',
            symbolSize: 0,
            label: {
              show: true,
              formatter: `Current: ${targets.targetConsensus.toFixed(2)}`,
              position: 'top',
              color: '#ffff',
              borderWidth: 1,
              fontSize: 15,
              backgroundColor: 'grey',
              borderColor: 'grey',
              padding: [5, 8],
              borderRadius: 3,
            },
          },
        },
        {
          name: 'High',
          type: 'line',
          data: connectingLineDataHigh,
          symbol: 'circle',
          symbolSize: 0,
          connectNulls: true,
          itemStyle: {
            color: '#20c997',
          },
          markPoint: {
            data: [
              {
                xAxis: xAxisData.length - 1,
                yAxis: targets.targetHigh,
              },
            ],
            symbol: 'circle',
            symbolSize: 0,
            label: {
              show: true,
              formatter: `Max: ${targets.targetHigh.toFixed(2)}`,
              position: 'top',
              color: '#ffff',
              borderWidth: 1,
              fontSize: 15,
              backgroundColor: '#20c997',
              borderColor: '#20c997',
              padding: [5, 8],
              borderRadius: 3,
            },
          },
        },
        {
          name: 'Low',
          type: 'line',
          data: connectingLineDataLow,
          symbol: 'circle',
          symbolSize: 0,
          connectNulls: true,
          itemStyle: {
            color: '#FF3540',
          },
          markPoint: {
            data: [
              {
                xAxis: xAxisData.length - 1,
                yAxis: targets.targetLow,
              },
            ],
            symbol: 'circle',
            symbolSize: 0,
            label: {
              show: true,
              formatter: `Low: ${targets.targetLow.toFixed(2)}`,
              position: 'top',
              color: '#ffff',
              borderWidth: 1,
              fontSize: 15,
              backgroundColor: '#FF3540',
              borderColor: '#FF3540',
              padding: [5, 8],
              borderRadius: 3,
            },
          },
        },
        {
          name: 'Median',
          type: 'line',
          data: connectingLineDataMedian,
          symbol: 'circle',
          symbolSize: 0,
          connectNulls: true,
          itemStyle: {
            color: '#007aff',
          },
          markPoint: {
            data: [
              {
                xAxis: xAxisData.length - 1,
                yAxis: targets.targetMedian,
              },
            ],
            symbol: 'circle',
            symbolSize: 0,
            label: {
              show: true,
              formatter: `Avg: ${targets.targetMedian.toFixed(2)}`,
              position: 'top',
              color: '#ffff',
              borderWidth: 1,
              fontSize: 15,
              backgroundColor: '#007aff',
              borderColor: '#007aff',
              padding: [5, 8],
              borderRadius: 3,
            },
          },
        },
      ],
      graphic: [
        {
          type: 'text',
          left: `${((data?.length - 1) / xAxisData.length) * 100 * 0.5}%`, // Left of midpoint
          top: '50%',
          style: {
            text: 'Past One Year',
            fontSize: 16,
            fill: '#007BFF',
          },
        },
        {
          type: 'text',
          left: `${((data?.length - 1) / xAxisData.length) * 100 + 10}%`, // Right of midpoint
          top: '50%',
          style: {
            text: 'Next One Year',
            fontSize: 16,
            fill: '#007BFF',
          },
        },
      ],
    };
    chart.setOption(option);
    return () => {
      chart.dispose();
    };
  }, [
    xAxisData,
    mainSeriesData,
    connectingLineDataConsensus,
    connectingLineDataHigh,
    connectingLineDataLow,
    connectingLineDataMedian,
    color,
    targets?.targetConsensus,
    targets?.targetHigh,
    targets?.targetLow,
    targets?.targetMedian,
  ]);

  useEffect(() => {
    const cleanup = createChart();

    return () => {
      cleanup();
    };
  }, [createChart]);

  return <div ref={chartRef} style={{ width: '100%', height }} />;
});
