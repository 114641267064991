import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Select } from 'src/components';
import HeatMapAdvancefilter from './advancefilter';
import { fetchHeatmap } from 'src/pages/Heatmap/heatmap.reducer';
import { Dialog } from 'src/components/Modal/dialogbox';
import { useCachedMarketInfo } from 'src/hooks/marketInfo';

export function HeatmapFilterControls({
  market,
  setMarket,
  marketIndex,
  setMarketIndex,
  setTimeframe,
  setGroupBy,
  sectors,
  setAdvancedFilteredActive,
}) {
  const [categories] = useState([
    'One Day',
    'FIve Day',
    'One Month',
    'Three Month',
    'Six Month',
    'One Year',
    'Three Year',
    'Year To Date',
    'Five Year',
    'Ten Year',
    'Max',
  ]);

  const [group1] = useState(['No Group', 'Sector']);
  const [group2] = useState(['No Group']);
  const dispatch = useDispatch();
  const { data: marketInfo } = useCachedMarketInfo();
  return (
    <div className='heatmap-selection-wrapper'>
      <div className='heatmap-selection'>
        <div className='heatmap-select'>
          <h4>Markets</h4>
          <Select
            options={marketInfo?.markets}
            onChange={(e) => {
              setMarket(marketInfo?.market_supported_map[e]);
              getDefaultData(dispatch, marketInfo?.market_supported_map[e]);
            }}
          />
        </div>
        {market === 'UsStocks' && (
          <Select
            onChange={(e) => {
              setMarketIndex(marketInfo?.index_map[e]);
              getDefaultData(dispatch, market, marketInfo?.index_map[e]);
            }}
            options={marketInfo?.index_list}
          />
        )}
        <div className='heatmap-select'>
          <h4>Categories</h4>
          <Select onChange={(e) => setTimeframe(e)} options={categories} />
        </div>
        <div className='heatmap-select'>
          <h4>Group by</h4>
          <Select onChange={(e) => setGroupBy(e)} options={market === 'UsStocks' ? group1 : group2} />
        </div>
        {market === 'UsStocks' && (
          <Dialog>
            <HeatMapAdvancefilter
              sectors={sectors}
              marketIndex={marketIndex}
              market={market}
              setAdvancedFilteredActive={setAdvancedFilteredActive}
            />
          </Dialog>
        )}
      </div>
    </div>
  );
}
function getDefaultData(dispatch, marketType, marketIndex = 'Sp500') {
  dispatch(
    fetchHeatmap({
      market_type: marketType,
      market_index: marketIndex,
      default_category: 'Performance',
      time_frame: 'Daily',
    }),
  );
}
