import { createSlice } from '@reduxjs/toolkit';
const INITIAL_STATE = {
  isLoading: false,
  data: [],
  error: null,
};

const searchSlice = createSlice({
  name: 'screach',
  initialState: INITIAL_STATE,
  reducers: {
    fetchSearch(state, action) {
      state.isLoading = true;
      state.data = null;
    },
    fetchSearchSuccess(state, action) {
      state.isLoading = false;
      state.data = action.payload;
      state.error = null;
    },
    fetchSearchFailure(state, action) {
      state.isLoading = false;
      state.data = [];
      state.error = action.payload;
    },
  },
});
export const { fetchSearch, fetchSearchSuccess, fetchSearchFailure } = searchSlice.actions;
export const searchReducer = searchSlice.reducer;

const supportSearchSlice = createSlice({
  name: 'supportScreach',
  initialState: INITIAL_STATE,
  reducers: {
    fetchSupportSearch(state, action) {
      state.isLoading = true;
      state.data = null;
    },
    fetchSupportSearchSuccess(state, action) {
      state.isLoading = false;
      state.data = action.payload;
      state.error = null;
    },
    fetchSupportSearchFailure(state, action) {
      state.isLoading = false;
      state.data = [];
      state.error = action.payload;
    },
  },
});
export const { fetchSupportSearch, fetchSupportSearchSuccess, fetchSupportSearchFailure } = supportSearchSlice.actions;
export const supportSearchReducer = supportSearchSlice.reducer;
