import React, { useState, useEffect, useRef } from 'react';
import { useParams, Link } from 'react-router-dom';
import { ShareIcon, TrashIcon, HandThumbUpIcon } from '@heroicons/react/20/solid';
import {
  FacebookShareButton,
  FacebookIcon,
  WhatsappShareButton,
  WhatsappIcon,
  EmailShareButton,
  EmailIcon,
  TelegramShareButton,
  TelegramIcon,
} from 'react-share';
import { Button, ModalWrapper, Para } from 'src/components';
import { useDispatch, useSelector } from 'react-redux';
import { fetchClubDetail, postClubComment, postClubCommentDelete, postClubLike } from '../club.reducer';
import { selectClubDetails } from '../club.selector';
import { IClubContent } from 'src/lib/types/club';
import { cardDummyImage, dummyUser, notificationWarning } from 'src/assets/Images';
import { loggedIn } from 'src/lib/auth';
import { convertNumberToString } from 'src/lib/utils/utils';
import Skeleton from 'react-loading-skeleton';
import { selectCurrentUser } from 'src/store/user/user.selector';
export default function ClubDetails() {
  const dispatch = useDispatch();
  const details = useSelector(selectClubDetails);
  const [commentId, setCommentId] = useState('');
  const user = useSelector(selectCurrentUser);
  const [confirmModal, setConfirmModal] = useState(false);
  const [shareModal, setShareModal] = useState(false);
  const [like, setLike] = useState(0);
  const [comments, setComments] = useState<{ comment: string; created_at: string; user_name: string; id: string }[]>(
    [],
  );
  const [hasLiked, setHasLiked] = useState(false);
  const { id } = useParams<{ id: string }>();
  const deleteStatus = useSelector((state: any) => state.clubCommentDelete);
  const commentStatus = useSelector((state: any) => state.clubComment);
  const isSignedIn = loggedIn();
  useEffect(() => {
    if (details?.data) {
      setHasLiked(details?.data?.has_liked);
      setLike(details?.data?.likes);
      setComments(details?.data?.comments_list);
    }
  }, [details?.data]);

  const commentRef = useRef<HTMLInputElement>(null);
  useEffect(() => {
    if (commentStatus?.data) {
      setComments((prev) => [
        {
          comment: commentStatus?.data.comment,
          created_at: commentStatus?.data.created_at,
          user_name: commentStatus?.data.user_name,
          id: commentStatus?.data.id,
        },
        ...prev,
      ]);
    }
  }, [commentStatus]);
  const commentHandler = () => {
    if (commentRef.current && commentRef.current.value !== '') {
      dispatch(postClubComment({ content_id: id, comment: commentRef.current.value }));
      commentRef.current.value = '';
    }
  };
  const formattedDate = (date) =>
    date
      ? new Date(date).toLocaleDateString('en-US', {
          month: 'long',
          day: 'numeric',
          year: 'numeric',
        })
      : ' ';
  useEffect(() => {
    dispatch(fetchClubDetail({ id }));
  }, [id, dispatch]);

  return (
    <>
      <section className='club-body-container '>
        <div className='club-body-container-main'>
          {details?.isLoading ? (
            <div className='academy-details-body'>
              <Skeleton className='img' />
              <Skeleton height='2.1rem' width='50%' />
              <Skeleton height='3.1rem' />
              <Skeleton height='2.1rem' />
            </div>
          ) : (
            <div className='academy-details-body'>
              <img src={details?.data?.image_long || cardDummyImage} alt='' />
              <h3 className='club-details-category'>{details?.data?.category}</h3>
              <h1 className='academy-details-header'>{details?.data?.headline}</h1>
              <h2 className='club-content-author'>
                By <span>{details?.data?.user_name}</span>
              </h2>
              <div className='club-details-info'>
                <div className='club-details-info-dt'>
                  <span>
                    {`${new Date(details?.data?.created_at).toLocaleString('en-US', {
                      month: 'long',
                    })} ${new Date(details?.data?.created_at).getDate()},${new Date(
                      details?.data?.created_at,
                    ).getFullYear()}` || ' '}
                  </span>
                  <div />
                  <span>{details?.data?.time_to_read} min read</span>
                </div>
                <div className='club-details-info-lk'>
                  <div
                    className='club-details-info-lk'
                    onClick={() => {
                      if (isSignedIn) {
                        dispatch(postClubLike({ content_id: details?.data?.id, like: !hasLiked }));
                        if (hasLiked) setLike((prevCount) => prevCount - 1);
                        else setLike((prevCount) => prevCount + 1);
                        setHasLiked(!hasLiked);
                      }
                    }}>
                    <HandThumbUpIcon className={`size-8  ${hasLiked ? 'club-liked' : ''}`} />
                    Like
                    <span>{convertNumberToString(like.toString())}</span>
                  </div>
                  <div
                    className='club-details-info-lk '
                    onClick={() => {
                      setShareModal(true);
                    }}>
                    <p>Share</p>
                    <ShareIcon className='size-8' />
                  </div>
                </div>
              </div>

              <Para url={details?.data?.details} />
            </div>
          )}

          <div className='club-comment'>
            {isSignedIn ? (
              <>
                <h1>Leave a comment</h1>
                <div className='club-add-comment'>
                  <input type='text' placeholder='Write your comment.....' ref={commentRef} />
                  <Button type='button' className='btn-primary btn-lg' label='Submit' onClick={commentHandler} />
                </div>
              </>
            ) : null}
            <h1 className='clubDetails__other'>Other comments</h1>
            {details?.data &&
              comments.map((item, i) => (
                <div className='clubDetails__userComment' key={i}>
                  <div className='clubDetails__userCommentTop'>
                    <div className='clubDetails__timing'>
                      <img src={dummyUser} alt='' />
                      <h1>{item?.user_name}</h1>
                      <span>{formattedDate(item?.created_at)}</span>

                      {isSignedIn && item.user_name === user.userName && (
                        <TrashIcon
                          className='size-7 cursor-pointer'
                          onClick={() => {
                            setConfirmModal(true);
                            setCommentId(item.id as string);
                          }}
                        />
                      )}
                    </div>
                  </div>
                  <div className='clubDetails__userCommentBottom'>
                    <p>{item?.comment}</p>
                  </div>
                </div>
              ))}
          </div>
        </div>
        <div className='club-container-sub'>
          {details?.data &&
            details?.data?.similar_contents.map((item: IClubContent, i: number) => (
              <Link to={`${item.id}`} key={i}>
                {item.headline}
              </Link>
            ))}
        </div>
      </section>

      <ModalWrapper open={confirmModal} onClose={() => setConfirmModal(false)}>
        <div className='clubConfirmModal'>
          <img src={notificationWarning} alt='warning-icon' />
          <h1>Do you want to delete this comment?</h1>
          <div className='clubConfirmModal--Btn'>
            <Button className='btn-primary btn-lg' label='Cancel' onClick={() => setConfirmModal(!confirmModal)} />
            <Button
              className='btn-primary btn-lg'
              label='Confirm'
              onClick={() => {
                dispatch(postClubCommentDelete({ id: commentId }));

                setComments((prev) => prev.filter((comment) => comment.id !== commentId));
                setConfirmModal(!confirmModal);
              }}
            />
          </div>
        </div>
      </ModalWrapper>
      {/* Share Post Modal */}
      <ModalWrapper open={shareModal} onClose={() => setShareModal(false)}>
        <div className='clubShareModal'>
          <div className='clubShareModal__Main'>
            <FacebookShareButton url={window.location.href}>
              <FacebookIcon iconFillColor='white' round size={52} />
            </FacebookShareButton>

            <WhatsappShareButton url={window.location.href}>
              <WhatsappIcon iconFillColor='white' round size={52} />
            </WhatsappShareButton>

            <EmailShareButton url={window.location.href}>
              <EmailIcon iconFillColor='white' round size={52} />
            </EmailShareButton>

            <TelegramShareButton url={window.location.href}>
              <TelegramIcon iconFillColor='white' round size={52} />
            </TelegramShareButton>
          </div>
          <div className='clubShareModal--btn'>
            <Button
              className='btn-primary btn-rounded btn-lg'
              label='Cancel'
              onClick={() => setShareModal(!shareModal)}
            />
          </div>
        </div>
      </ModalWrapper>
    </>
  );
}
