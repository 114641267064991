import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import TVChartContainer from 'src/components/TvContainer/tradingviewChart';
import { useSelector } from 'react-redux';
import { selectWatchlistOverview } from '../Watchlist.selector';
import { watchlistOverview1, watchlistOverview2, watchlistOverview3 } from 'src/assets/Images';
import { useDispatch } from 'react-redux';
import { fetchWatchlistNews } from '../Watchlist.reducer';
import { convertNumberToString } from 'src/lib/utils/utils';
import { FinancialGraphComponent } from 'src/components/WatchlistComponents/incomeGraph';
import { AnalystEstimateGraphComponent } from 'src/components/WatchlistComponents/estimateGraph';
import { FinancialTredGraphComponent } from 'src/components/WatchlistComponents/financialTredGraph';
import { Stockgrades } from 'src/components/WatchlistComponents/stockgrades';
import Stockpeers from 'src/components/WatchlistComponents/stockpeer';
import OverviewPressrelease from 'src/components/WatchlistComponents/overviewPressrelease';
import OverviewNews from 'src/components/WatchlistComponents/overviewNews';
import Keymatrics from 'src/components/WatchlistComponents/keymatrics';
import { useCachedMarketInfo } from 'src/hooks/marketInfo';
export default function OverView() {
  const dispatch = useDispatch();
  const overview = useSelector(selectWatchlistOverview);
  const { data: marketInfo } = useCachedMarketInfo();
  const { ticker, market } = useParams();

  useEffect(() => {
    const mkt = market[0].toUpperCase() + market.slice(1, market.length);
    dispatch(
      fetchWatchlistNews({
        market_type: marketInfo?.market_supported_map?.[mkt],
        ticker: ticker?.toUpperCase(),
      }),
    );
  }, [ticker, market]);

  return (
    <>
      <main className='overview'>
        <div className='overview__chart'>
          <TVChartContainer
            className='h-[80vh]  w-full'
            tick={ticker}
            featuresetDisableList={[
              'left_toolbar',
              'header_symbol_search',
              'scales_context_menu',
              'go_to_date',
              'header_settings',
              'header_indicators',
              'chart_property_page_scales',
              'main_series_scale_menu',
              'header_undo_redo',
              'pricescale_unit',
              'control_bar',
              'main_series_scale_menu',
              'context_menus',
              'timezone_menu',
            ]}
          />
        </div>

        <>
          {overview?.data && (
            <section className='overview__detailsCard'>
              <article className='overview__Info'>
                <img src={watchlistOverview1} alt='' />
                <div className='overview__Info--Data'>
                  <p>{overview?.data?.profile?.sector}</p>
                  <h2>{overview?.data?.profile?.industry}</h2>
                </div>
              </article>
              <article className='overview__Info'>
                <img src={watchlistOverview2} alt='' />
                <div className='overview__Info--Data'>
                  <h2>{`With a market cap of ${convertNumberToString(
                    overview?.data?.profile?.mkt_cap?.toFixed(2),
                  )}B`}</h2>
                </div>
              </article>
              <article className='overview__Info'>
                <img src={watchlistOverview3} alt='' />
                <div className='overview__Info--Data'>
                  <h2>Beta: {overview?.data?.profile?.beta}</h2>
                </div>
              </article>
            </section>
          )}
          {market === 'stocks' && (
            <>
              <section className='rating-matrics'>
                <Keymatrics />
                <Stockgrades data={overview?.data} isLoading={overview?.isLoading} />
              </section>
              <Stockpeers data={overview?.data} isLoading={overview?.isLoading} />
              <AnalystEstimateGraphComponent
                data={overview?.data?.analyst_estimate}
                isLoading={overview?.isLoading}
                activeReported
                activeEstimated
                activeSurprise
              />

              <FinancialTredGraphComponent data={overview?.data} isLoading={overview?.isLoading} />
              <FinancialGraphComponent data={overview?.data?.financials} isLoading={overview?.isLoading} />
            </>
          )}
        </>

        <section className='overview-news-section'>
          {overview?.data ? (
            <OverviewPressrelease
              data={overview?.data}
              isLoading={overview?.isLoading}
              ticker={overview?.data?.ticker}
            />
          ) : null}
          <OverviewNews />
        </section>
      </main>
    </>
  );
}
