import React, { useEffect, useState } from 'react';
import { FinancialDataType, StatementTf } from 'src/lib/enum/enum';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { selectWatchlistAnalystEstimate } from '../../Watchlist.selector';
import { fetchWatchlistAnalystEstimate } from '../../Watchlist.reducer';
import { useParams } from 'react-router-dom';
import { AnalystEstimateChart } from 'src/components';
import { activeElement } from 'src/lib/utils/utils';
import Skeleton from 'react-loading-skeleton';
function AnalystEstimate() {
  const { ticker, market } = useParams();
  const dispatch = useDispatch();
  const estimate = useSelector(selectWatchlistAnalystEstimate);

  useEffect(() => {
    dispatch(fetchWatchlistAnalystEstimate({ ticker }));
  }, [ticker]);
  const [activeReported, setActiveReported] = useState(true);
  const [activeEstimated, setActiveEstimated] = useState(true);
  const [activeSurprise, setActiveSurprise] = useState(true);
  const [tf, setTf] = useState(StatementTf.Annual);
  const [statementType, setStatementType] = useState(FinancialDataType.Revenue);
  return (
    <main className='analystEstimate'>
      <section>
        <h1 className='financials-section-header'>Analyst Estimates</h1>
        <div className='financials-graphcontianer'>
          <div className='financials-navbar'>
            <ul className=' menu menu-horizontal '>
              <li
                className={activeElement(FinancialDataType.Revenue === statementType)}
                onClick={() => setStatementType(FinancialDataType.Revenue)}>
                Revenue
              </li>
              <li
                className={activeElement(FinancialDataType.EIBTDA === statementType)}
                onClick={() => setStatementType(FinancialDataType.EIBTDA)}>
                EBITDA
              </li>
              <li
                className={activeElement(FinancialDataType.EBIT === statementType)}
                onClick={() => setStatementType(FinancialDataType.EBIT)}>
                EBIT
              </li>
              <li
                className={activeElement(FinancialDataType.NetIncome === statementType)}
                onClick={() => setStatementType(FinancialDataType.NetIncome)}>
                Net Income
              </li>
              <li
                className={activeElement(FinancialDataType.SGExpense === statementType)}
                onClick={() => setStatementType(FinancialDataType.SGExpense)}>
                SG&A Expense
              </li>
              <li
                className={activeElement(FinancialDataType.EPS === statementType)}
                onClick={() => setStatementType(FinancialDataType.EPS)}>
                EPS
              </li>
            </ul>
            <ul className=' menu menu-horizontal '>
              <li className={activeElement(StatementTf.Annual === tf)} onClick={() => setTf(StatementTf.Annual)}>
                Annual
              </li>
              <li className={activeElement(StatementTf.Quarter === tf)} onClick={() => setTf(StatementTf.Quarter)}>
                Quarter
              </li>
            </ul>
          </div>
          {estimate?.isLoading ? (
            <Skeleton height='40rem' width='100%' />
          ) : estimate?.data?.[tf]?.[statementType]?.length > 0 ? (
            <AnalystEstimateChart
              data={estimate?.data?.[tf]?.[statementType]}
              tf={tf}
              activeReported={activeReported}
              activeEstimated={activeEstimated}
              activeSurprise={activeSurprise}
            />
          ) : null}
        </div>
      </section>
      {estimate?.data?.[tf]?.[statementType]?.length > 0 ? (
        <div className='analystEstimate__table'>
          <table>
            <thead>
              <tr>
                <th>
                  Currency:
                  {estimate?.isLoading
                    ? Array.from({ length: 10 }).map((_, colIndex) => (
                        <td key={colIndex}>
                          <Skeleton />
                        </td>
                      ))
                    : estimate?.data?.[tf]?.[statementType][0].currency}
                </th>
                {estimate?.data?.[tf]?.[statementType].map((item, i) => (
                  <th key={i}>{item.period ? item.period : item.Date}</th>
                ))}
              </tr>
            </thead>
            <tbody>
              <tr
                onClick={() => setActiveReported(!activeReported)}
                className={`${activeReported && 'estimateActive reported'}`}>
                <td>Reported</td>
                {estimate?.isLoading
                  ? Array.from({ length: 10 }).map((_, colIndex) => (
                      <td key={colIndex}>
                        <Skeleton />
                      </td>
                    ))
                  : estimate?.data?.[tf]?.[statementType].map((item, i) => (
                      <td key={i}>{item.reported.toFixed(2) !== 0 ? item.reported.toFixed(2) : '-'}</td>
                    ))}
              </tr>
              <tr
                onClick={() => setActiveEstimated(!activeEstimated)}
                className={`${activeEstimated && 'estimateActive estimated'}`}>
                <td>Estimated</td>
                {estimate?.isLoading
                  ? Array.from({ length: 10 }).map((_, colIndex) => (
                      <td key={colIndex}>
                        <Skeleton />
                      </td>
                    ))
                  : estimate?.data?.[tf]?.[statementType].map((item, i) => (
                      <td key={i}>{item.estimated.toFixed(2)}</td>
                    ))}
              </tr>
              <tr
                onClick={() => setActiveSurprise(!activeSurprise)}
                className={`${activeSurprise && 'estimateActive surprise'}`}>
                <td>Surprise</td>
                {estimate?.isLoading
                  ? Array.from({ length: 10 }).map((_, colIndex) => (
                      <td key={colIndex}>
                        <Skeleton />
                      </td>
                    ))
                  : estimate?.data?.[tf]?.[statementType].map((item, i) => (
                      <td
                        key={i}
                        style={{
                          color: +item.surprise >= 0 ? 'rgba(32, 201, 151, 1)' : '#C72B2E',
                        }}>
                        {`${+item.surprise !== 0 ? +item.surprise : '-'}`}
                      </td>
                    ))}
              </tr>
            </tbody>
          </table>
        </div>
      ) : null}
    </main>
  );
}

export default AnalystEstimate;
