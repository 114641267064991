import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { selectWatchlistOverview, selectWatchlistTickerImagesState } from 'src/pages/Watchlist/Watchlist.selector';

export function useWatchlistData(ticker: string) {
  const overview = useSelector(selectWatchlistOverview)?.data?.profile || {};
  const tickerImage = useSelector(selectWatchlistTickerImagesState);
  const liveQuote = useSelector((state: any) => state.liveQuote?.data?.[0] || null);
  const websocketData = useSelector((state: any) => state.websocket);
  const [lastNonNullDataSource, setLastNonNullDataSource] = useState<any | null>(null);

  const imageSrc = overview.image || (tickerImage?.data && tickerImage.data[0]?.image) || null;
  useEffect(() => {
    let dataSource: null | any = null;
    if (ticker) {
      if (websocketData?.data) {
        if (typeof websocketData.data[0] != 'string' && websocketData?.data[0]?.symbol === ticker) {
          dataSource = websocketData?.data[0];
        }
        if (websocketData[ticker]) {
          dataSource = websocketData[ticker];
        } else {
          dataSource = liveQuote || lastNonNullDataSource;
        }
      } else {
        dataSource = liveQuote || lastNonNullDataSource;
      }
    }

    if (dataSource && (dataSource.price || dataSource.lp || dataSource.marketCap)) {
      let newDataSource = {};
      if (dataSource.lp) {
        newDataSource = {
          ...lastNonNullDataSource,
          price: dataSource.lp,
        };
      } else {
        newDataSource = dataSource;
      }

      if (JSON.stringify(lastNonNullDataSource) !== JSON.stringify(newDataSource)) {
        setLastNonNullDataSource(newDataSource);
      }
    }
  }, [ticker, liveQuote, websocketData, lastNonNullDataSource]);

  return {
    lastNonNullDataSource,
    imageSrc,
  };
}
