import { useState, useEffect } from 'react';
import { setScreenerIndex } from 'src/pages/Screener/Screener.reducer';
import { useDispatch, useSelector } from 'react-redux';
import Select from 'src/components/InputElements/select.component';
import { ArrowDownTrayIcon } from '@heroicons/react/20/solid';
import { useCachedScreenerMetaData } from 'src/hooks/cachedScreenerMetadata';
import { createColumnHelper } from '@tanstack/react-table';
import { selectScreenerMarket, selectScreenerIndex } from 'src/pages/Screener/Screener.selector';
import { fetchHeatmap } from 'src/pages/Heatmap/heatmap.reducer';
import ScreenerAdvancefilter from 'src/components/screenerComponents/advancefilter';
import { Dialog } from '../Modal/dialogbox';
import { downloadFilteredData } from 'src/lib/utils/tableUtils';

const includesString = (str, searchTerm) => str.toLowerCase().includes(searchTerm.toLowerCase());
const isInArray = (value, array) => array.includes(value);
const columnHelper = createColumnHelper();
const processeHeaders = (data, key) => {
  const headers = [];
  const headerMaplabel = {};
  const headerMapKey = {};
  data?.columns?.forEach((h) => {
    if (h.key.toLowerCase() === 'ticker') {
      headers.push(
        columnHelper.accessor(h.key, {
          header: h.short_name,
          filterFn: (row, columnId, filterValue) => {
            if (typeof filterValue != 'string') {
              return isInArray(row.original[columnId], filterValue);
            }

            return includesString(row.original[columnId], filterValue);
          },
          enablePinning: true,
        }),
      );

      headerMaplabel[h.short_name] = { dataType: h.data_type, label: h.label };
      headerMapKey[h.key] = { dataType: h.data_type, label: h.label };
      return;
    }
    if (h.key.toLowerCase() === 'image') {
      headers.push(columnHelper.accessor(h.key, { header: '', enableSorting: false }));
      headerMaplabel[h.short_name] = { dataType: h.data_type, label: h.label };
      headerMapKey[h.key] = { dataType: h.data_type, label: h.label };
      return;
    }
    if (
      (h.key.toLowerCase().includes('url') ||
        h.key.toLowerCase().includes('date') ||
        h.key.toLowerCase().includes('year')) &&
      !h.key.toLowerCase().includes('year_to_date')
    ) {
      headers.push(columnHelper.accessor(h.key, { header: h.short_name, enableSorting: false }));
      headerMaplabel[h.short_name] = { dataType: h.data_type, label: h.label };
      headerMapKey[h.key] = { dataType: h.data_type, label: h.label };
      return;
    }
    if (h.data_type === 'string') {
      headers.push(columnHelper.accessor(h.key, { header: h.short_name }));
    } else {
      headers.push(
        columnHelper.accessor(h.key, {
          header: h.short_name,

          meta: {
            filterVariant: 'range',
          },
        }),
      );
    }
    headerMaplabel[h.short_name] = { dataType: h.data_type, label: h.label };
    headerMapKey[h.key] = { dataType: h.data_type, label: h.label };
  });
  return { headers, headerMaplabel, headerMapKey };
};
export default function ScreenerSelection({
  tableRef,
  setDataKey,
  setHeaders,
  setHeadersLabelMap,
  setHeadersKeyMap,
  setAdvancedFilteredActive,
}) {
  const dispatch = useDispatch();
  const { metadata } = useCachedScreenerMetaData();

  const [tree, setTree] = useState(null);
  const [subTree, setSubTree] = useState(null);
  const market = useSelector(selectScreenerMarket);
  const screnerIndex = useSelector(selectScreenerIndex);
  const [category, setCategory] = useState('');
  const [indices, setIndices] = useState([]);
  const [options, setOptions] = useState([]);
  const [options2, setOptions2] = useState([]);
  const [options3, setOptions3] = useState([]);
  const [tf, setTf] = useState('Daily');

  const [ternaryTree, setTernaryTree] = useState(null);

  useEffect(() => {
    if (metadata) {
      setAdvancedFilteredActive(false);
      const q = metadata?.metadata?.asset_classes?.find((ac) => ac.key === market);
      if (q?.indices) {
        setIndices(q.indices.map((e) => e.label));
      } else {
        setIndices([]);
        if (q?.options) {
          const primaryOptions = q?.options?.map((e) => e.label) || [];
          setOptions(primaryOptions);
          setTree(q);
        }
      }
    }
  }, [metadata, market]);
  function handleSelect1Change(option) {
    dispatch(setScreenerIndex(option));
    setAdvancedFilteredActive(false);

    const assetClass = metadata?.metadata?.asset_classes?.find((ac) => ac.key === market);
    if (assetClass) {
      const indices = assetClass.indices || [assetClass];
      const index = indices.find((i) => i.label === option);
      dispatch(setScreenerIndex({ index: index?.key }));
      const target = index || assetClass;
      setTree(target);
      if (target?.options) {
        const primaryOptions = target?.options?.map((e) => e.label) || [];
        setOptions(primaryOptions);
      }
    }
  }
  function handlePrimaryOptionChange(option) {
    if (!tree || !option) return;
    setAdvancedFilteredActive(false);
    const secondaryOptionsMap = tree.options.find((e) => e.label === option);
    if (secondaryOptionsMap) {
      setCategory(secondaryOptionsMap.key);
      dispatch(
        fetchHeatmap({
          market_type: market,
          market_index: screnerIndex,
          default_category: secondaryOptionsMap.key,
          time_frame: 'Daily',
        }),
      );
      if (secondaryOptionsMap?.columns) {
        const { headers, headerMaplabel, headerMapKey } = processeHeaders(secondaryOptionsMap);
        setHeaders(headers);
        setHeadersLabelMap(headerMaplabel);
        setHeadersKeyMap(headerMapKey);
      }
      setDataKey('');
    }
    if (!secondaryOptionsMap?.options) {
      setOptions2([]);
      setOptions3([]);
      setSubTree(null);
      return;
    }
    setSubTree(secondaryOptionsMap);
    const secondaryOptions = secondaryOptionsMap.options?.map((e) => e.label) || [];
    setOptions2(secondaryOptions);
  }
  function handleSecondayOptionChange(option) {
    if (!subTree || !option) return;
    setAdvancedFilteredActive(false);
    const optionsMap = subTree.options.find((e) => e.label === option);
    if (optionsMap?.columns) {
      const { headers, headerMaplabel, headerMapKey } = processeHeaders(optionsMap, optionsMap.key);
      setHeaders(headers);
      setHeadersLabelMap(headerMaplabel);
      setHeadersKeyMap(headerMapKey);
      setDataKey(optionsMap.key);
      setOptions3([]);
      setTernaryTree(null);
    } else {
      setDataKey(optionsMap?.key);

      setTernaryTree(optionsMap);
      const tertaryOptions = optionsMap.options?.map((e) => e.label) || [];
      setOptions3(tertaryOptions);
    }
  }
  function handleTertiaryOptionChange(option) {
    if (ternaryTree) {
      const r = ternaryTree?.options?.find((i) => i.label === option);
      const { headers, headerMaplabel, headerMapKey } = processeHeaders(r);
      setHeaders(headers);
      setHeadersLabelMap(headerMaplabel);
      setHeadersKeyMap(headerMapKey);
      if (r.key !== tf) {
        dispatch(
          fetchHeatmap({
            market_type: market,
            market_index: screnerIndex,
            default_category: category,
            time_frame: r.key,
          }),
        );
      }
      setTf(r.key);
    }
  }
  return (
    <>
      <div className='screener-select-container '>
        <div className='screener-selects '>
          {indices?.length > 0 && (
            <Select
              onChange={(e) => {
                handleSelect1Change(e);
              }}
              options={indices}
            />
          )}
          <Select
            onChange={(e) => {
              handlePrimaryOptionChange(e);
            }}
            options={options}
          />

          {options2?.length > 0 && (
            <Select
              onChange={(e) => {
                handleSecondayOptionChange(e);
              }}
              options={options2}
            />
          )}
          {options3?.length > 0 && (
            <Select
              onChange={(e) => {
                handleTertiaryOptionChange(e);
              }}
              options={options3}
            />
          )}
        </div>
        <div className='screener-options'>
          <div className='reports-download-btn' onClick={() => downloadFilteredData(tableRef, category)}>
            <ArrowDownTrayIcon />
          </div>
          <Dialog>
            <ScreenerAdvancefilter setAdvancedFilteredActive={setAdvancedFilteredActive} />
          </Dialog>
        </div>
      </div>
    </>
  );
}
