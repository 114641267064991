/* eslint-disable max-len */
/* eslint-disable indent */
import { apiClient } from '../apiClient';
import { config } from 'src/lib/config';

export const getClubDetails = async (params: any) => {
  const apiUrl = config.API_SVC_PATH_IFIRST_CLUB + config.API_CLUB_URI_CONTENT_DETAILS;
  const res = await apiClient({
    method: 'get',
    url: apiUrl,
    params,
  });
  return res;
};
export const getClubContentCards = async (params: undefined) => {
  const apiUrl = config.API_SVC_PATH_IFIRST_CLUB + config.API_CLUB_URI_CONTENT_CARDS;
  const res = await apiClient({
    method: 'get',
    url: apiUrl,
    params,
  });
  return res;
};
export const getClubMetaData = async (params: undefined) => {
  const apiUrl = config.API_SVC_PATH_IFIRST_CLUB + config.API_CLUB_URI_COMPONENT;
  const res = await apiClient({
    method: 'get',
    url: apiUrl,
    params,
  });
  return res;
};

export const postClubLikes = async (payload: any) => {
  const apiUrl = config.API_SVC_PATH_IFIRST_CLUB + config.API_CLUB_URI_CONTENT_LIKE;
  const res = await apiClient({
    method: 'post',
    url: apiUrl,
    data: { ...payload },
  });
  return res;
};
export const postClubComments = async (payload: any) => {
  const apiUrl = config.API_SVC_PATH_IFIRST_CLUB + config.API_CLUB_URI_CONTENT_COMMENT;
  const res = await apiClient({
    method: 'post',
    url: apiUrl,
    data: { ...payload },
  });
  return res;
};
export const postClubDeleteComment = async (params: any) => {
  const apiUrl = config.API_SVC_PATH_IFIRST_CLUB + config.API_CLUB_URI_CONTENT_COMMENT;
  const res = await apiClient({
    method: 'delete',
    url: apiUrl,
    params: { ...params },
  });
  return res;
};

export const postClubPosts = async (payload: any) => {
  const apiUrl = config.API_SVC_PATH_IFIRST_CLUB + config.API_CLUB_URI_CONTENT;
  const res = await apiClient({
    headers: { 'Content-Type': 'multipart/form-data' },
    method: 'post',
    url: apiUrl,
    data: payload,
  });
  return res;
};
