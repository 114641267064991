import React, { createRef, useEffect, useMemo, useRef } from 'react';
import { widget } from '../../charting_library';
import { useCachedTvConfig } from 'src/hooks/cachedTvConfig';
import { createDatafeed } from './datafeed.js';

function TVChartContainer({ className, featuresetDisableList, tick }) {
  const tvref = createRef();
  const tvWidgetRef = useRef(null);
  const { tvConfig, tvSymbol } = useCachedTvConfig();
  const datafeed = useMemo(() => createDatafeed(tvConfig, tvSymbol, tick), [tvConfig, tvSymbol]);
  useEffect(() => {
    const tvWidget = new widget({
      symbol: tick,
      interval: '1D',
      autosize: true,
      container: tvref.current,
      disabled_features: featuresetDisableList,
      custom_css_url: `${window.location.origin}/style.css`,
      datafeed,
      library_path: '/charting_library/',
      overrides: {
        'paneProperties.backgroundType': 'solid',
        'paneProperties.background': '#041432',
        'scalesProperties.textColor': '#ffff',
      },
    });
    tvWidgetRef.current = tvWidget;
    return () => {
      if (tvWidgetRef.current) {
        tvWidgetRef.current.remove();
        tvWidgetRef.current = null;
      }
    };
  });

  return <div ref={tvref} className={className} />;
}

export default TVChartContainer;
