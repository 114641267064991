import React, { useEffect, useMemo, useRef } from 'react';
import ReactEcharts from 'echarts-for-react';
import * as echarts from 'echarts';
import { convertNumberToString } from 'src/lib/utils/utils';

interface IPriceGraphProps {
  data: { datetime: string; close: number }[];
  color: string;
  height?: string;
  showTooltip?: boolean;
}

export const PriceGraph: React.FC<IPriceGraphProps> = ({ data, color, height = '40px', showTooltip = true }) => {
  const chartRef = useRef<ReactEcharts>(null);

  // Memoized x-axis data
  const xAxisData = useMemo(() => data.map((item) => item.datetime), [data]);

  // Memoized chart options
  const chartOptions = useMemo(
    () => ({
      xAxis: {
        type: 'category',
        data: xAxisData,
        show: false, // No visible x-axis labels or line
      },
      yAxis: {
        type: 'value',
        show: false, // No visible y-axis
        min: 'dataMin',
        max: 'dataMax',
      },
      grid: {
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
      },
      tooltip: showTooltip
        ? {
            trigger: 'axis',
            axisPointer: {
              type: 'shadow',
            },
            backgroundColor: 'rgba(0, 0, 0, 0.8)',
            borderColor: 'rgba(0, 0, 0, 0.8)',
            textStyle: {
              color: '#ffffff',
            },
            formatter: (params: any) => {
              const dataPoint = params[0].data;
              const date = xAxisData[params[0].dataIndex];
              const formattedDate = new Date(date).toLocaleDateString();
              const formattedValue = convertNumberToString(dataPoint); // Format value like axis

              return `Date: ${formattedDate}<br/><span style="color: ${color}">Value: ${formattedValue}</span>`;
            },
          }
        : { show: false }, // Tooltip hidden if 'showTooltip' is false
      series: [
        {
          type: 'line',
          name: 'Price',
          showSymbol: false, // No symbols (dots)
          color: color,
          areaStyle: {
            color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
              { offset: 0, color: color },
              { offset: 1, color: 'transparent' },
            ]),
          },
          data: data.map((item) => item.close),
        },
      ],
    }),
    [xAxisData, data, color, showTooltip],
  );

  useEffect(() => {
    if (chartRef.current) {
      chartRef.current.getEchartsInstance().setOption(chartOptions);
    }
  }, [chartOptions]);

  return <ReactEcharts ref={chartRef} option={chartOptions} style={{ width: '100%', height: height }} />;
};
