import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchscreenerMetaData } from 'src/pages/Screener/Screener.reducer';
import { selectScreenerMetaData, selectScreenerMetaDataLoading } from 'src/pages/Screener/Screener.selector';

export const useCachedScreenerMetaData = () => {
  const dispatch = useDispatch();

  const metadata = useSelector(selectScreenerMetaData);
  const isLoading = useSelector(selectScreenerMetaDataLoading);
  useEffect(() => {
    if (!metadata) {
      dispatch(fetchscreenerMetaData());
    }
  }, [metadata, dispatch]);

  return { metadata, isLoading: isLoading };
};
