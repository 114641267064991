import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { WS_EVENT } from 'src/constants/ws.constant';
import { IWebsocket } from 'src/lib/types/websocket';

const initialState: IWebsocket = {
  event: '',
  data: null,
  connected: false,
};

const message = createSlice({
  name: 'message',
  initialState: initialState,
  reducers: {
    putMessage: (state, action: PayloadAction<IWebsocket>) => {
      state.event = action.payload.event || state.event;
      state.data = action.payload.data || state.data;
      if (state.event === WS_EVENT.WS_EVENT_SERVER_LOGIN_SUCCESS || state.event === WS_EVENT.WS_EVENT_SERVER_CONNECTED)
        state.connected = true;
      if (state.event === WS_EVENT.WS_EVENT_SERVER_DICONNECT) state.connected = false;
    },
  },
});

export const { putMessage } = message.actions;
export const messageReducer = message.reducer;

const messageCarousel = createSlice({
  name: 'messageCarousel',
  initialState: initialState,
  reducers: {
    putMessageCarousel: (state, action: PayloadAction<IWebsocket>) => {
      state.event = action.payload.event;
      state.data = action.payload.data || state.data;
    },
  },
});

export const { putMessageCarousel } = messageCarousel.actions;
export const messageCarouselReducer = messageCarousel.reducer;
