import { put, takeLatest } from 'redux-saga/effects';
import {
  getSupportFaq,
  getSupportFaqDetails,
  getSupportSections,
  getSupportTickets,
  getSupportVideo,
  postSupportTicket,
  putSupportTickets,
} from 'src/repositories/support/support';
import {
  fetchSupportDetail,
  fetchSupportDetailFailure,
  fetchSupportDetailSuccess,
  fetchSupportTicket,
  fetchSupportTicketFailure,
  fetchSupportTicketSuccess,
  fetchSupportFaq,
  fetchSupportFaqFailure,
  fetchSupportFaqSuccess,
  fetchSupportSection,
  fetchSupportSectionFailure,
  fetchSupportSectionSuccess,
  fetchSupportVideo,
  fetchSupportVideoFailure,
  fetchSupportVideoSuccess,
  supportCreateTicket,
  supportCreateTicketSuccess,
  supportCreateTicketFailure,
  supportCancelTicket,
  supportCancelTicketSuccess,
  supportCancelTicketFailure,
} from './support.reducer';
import { IError } from 'src/lib/types/error';
function* fetchSupportDetailSaga(action) {
  try {
    const data = yield getSupportFaqDetails(action.payload).then((response) => response);
    yield put(fetchSupportDetailSuccess(data.data.data));
  } catch (error) {
    yield put(fetchSupportDetailFailure(error as IError));
  }
}

export function* watchSupportDetailsSaga() {
  yield takeLatest(fetchSupportDetail.type, fetchSupportDetailSaga);
}

function* fetchSupportTicketSaga(action) {
  try {
    const data = yield getSupportTickets(action.payload).then((response) => response);
    yield put(fetchSupportTicketSuccess(data.data.data));
  } catch (error) {
    yield put(fetchSupportTicketFailure(error as IError));
  }
}

export function* watchSupportTicketsSaga() {
  yield takeLatest(fetchSupportTicket.type, fetchSupportTicketSaga);
}

function* fetchSupportFaqSaga(action) {
  try {
    const data = yield getSupportFaq(action.payload).then((response) => response);
    yield put(fetchSupportFaqSuccess(data.data.data));
  } catch (error) {
    yield put(fetchSupportFaqFailure(error as IError));
  }
}

export function* watchSupportFaqsSaga() {
  yield takeLatest(fetchSupportFaq.type, fetchSupportFaqSaga);
}
function* fetchSupportVideoSaga(action) {
  try {
    const data = yield getSupportVideo(action.payload).then((response) => response);
    yield put(fetchSupportVideoSuccess(data.data.data));
  } catch (error) {
    yield put(fetchSupportVideoFailure(error as IError));
  }
}

export function* watchSupportVideosSaga() {
  yield takeLatest(fetchSupportVideo.type, fetchSupportVideoSaga);
}
function* fetchSupportSectionSaga(action) {
  try {
    const data = yield getSupportSections(action.payload).then((response) => response);
    yield put(fetchSupportSectionSuccess(data.data.data));
  } catch (error) {
    yield put(fetchSupportSectionFailure(error as IError));
  }
}

export function* watchSupportSectionsSaga() {
  yield takeLatest(fetchSupportSection.type, fetchSupportSectionSaga);
}

function* postSupportTicketSaga(action) {
  try {
    const data = yield postSupportTicket(action.payload).then((response) => response);
    yield put(supportCreateTicketSuccess(data.data.data));
  } catch (error) {
    yield put(supportCreateTicketFailure(error as IError));
  }
}

export function* watchSupportCreateTicketSaga() {
  yield takeLatest(supportCreateTicket.type, postSupportTicketSaga);
}

function* cancelSupportTicketSaga(action) {
  try {
    const data = yield putSupportTickets(action.payload).then((response) => response);
    yield put(supportCancelTicketSuccess(data.data.data));
  } catch (error) {
    yield put(supportCancelTicketFailure(error as IError));
  }
}

export function* watchSupportCancelTicketSaga() {
  yield takeLatest(supportCancelTicket.type, cancelSupportTicketSaga);
}
