import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectSupportTickets } from './support.selector';
import { fetchSupportTicket, supportCancelTicket } from './support.reducer';
import { Button, ModalWrapper } from 'src/components';
import { notificationWarning } from 'src/assets/Images';
import { ChevronLeftIcon } from '@heroicons/react/20/solid';
import { useNavigate } from 'react-router-dom';
import { formattedDate } from 'src/lib/utils/utils';
import DatePicker from 'react-date-picker';
import { Dialog, DialogClose, DialogContent, DialogTrigger } from 'src/components/Modal/dialogbox';

export default function SupportTickets() {
  const dispatch = useDispatch();
  // const navigate = useNavigate();
  const [fromDate, setFromDate] = useState<Date | null>(null);
  const [toDate, setToDate] = useState<Date | null>(null);
  const tickets = useSelector(selectSupportTickets);
  const [statusData, setStatusData] = useState('Open');
  const [successModal, setSuccessModal] = useState(false);
  const [ticketId, setTicketId] = useState(false);
  const ticketCancel = useSelector((state: any) => state.supportCancelTicket);
  useEffect(() => {
    const today = new Date();
    today.setDate(today.getDate() + 1); // Increment by 1 day
    const oneMonthAgo = new Date();
    oneMonthAgo.setMonth(today.getMonth() - 1);

    let year = oneMonthAgo.getFullYear();
    let month = String(oneMonthAgo.getMonth() + 1).padStart(2, '0');
    let day = String(oneMonthAgo.getDate()).padStart(2, '0');
    const oneMonthAgoFmt = `${year}-${month}-${day}`;

    year = today.getFullYear();
    month = String(today.getMonth() + 1).padStart(2, '0');
    day = String(today.getDate()).padStart(2, '0');
    const todayFmt = `${year}-${month}-${day}`;

    dispatch(fetchSupportTicket({ from: oneMonthAgoFmt, to: todayFmt }));
  }, []);

  useEffect(() => {
    if (ticketCancel?.data) {
      const today = new Date();
      today.setDate(today.getDate() + 1); // Increment by 1 day
      const oneMonthAgo = new Date();
      oneMonthAgo.setMonth(today.getMonth() - 1);

      let year = oneMonthAgo.getFullYear();
      let month = String(oneMonthAgo.getMonth() + 1).padStart(2, '0');
      let day = String(oneMonthAgo.getDate()).padStart(2, '0');
      const oneMonthAgoFmt = `${year}-${month}-${day}`;

      year = today.getFullYear();
      month = String(today.getMonth() + 1).padStart(2, '0');
      day = String(today.getDate()).padStart(2, '0');
      const todayFmt = `${year}-${month}-${day}`;

      dispatch(fetchSupportTicket({ from: oneMonthAgoFmt, to: todayFmt }));
    }
  }, [ticketCancel]);

  const getActiveClass = (val) => (val ? 'active-switch' : '');

  const filteredTickets = tickets?.data?.filter((item) =>
    'Close' === statusData
      ? item.status === 'CLOSED' || item.status === 'CANCELLED'
      : item.status !== 'CLOSED' && item.status !== 'CANCELLED',
  );
  const handleFilterApply = () => {
    // Format the fromDate and toDate
    if (fromDate && toDate) {
      const from = `${fromDate.getFullYear()}-${String(fromDate.getMonth() + 1).padStart(2, '0')}-${String(
        fromDate.getDate(),
      ).padStart(2, '0')}`;
      const to = `${toDate.getFullYear()}-${String(toDate.getMonth() + 1).padStart(2, '0')}-${String(
        toDate.getDate(),
      ).padStart(2, '0')}`;

      // Dispatch the fetchSupportTicket with the new date range
      dispatch(fetchSupportTicket({ from, to }));
    }
  };
  const handleResetFilter = () => {
    // Reset the date pickers to null
    setFromDate(null);
    setToDate(null);

    // Re-fetch tickets with default date range (one month ago to today)
    const today = new Date();
    today.setDate(today.getDate() + 1);
    const oneMonthAgo = new Date();
    oneMonthAgo.setMonth(today.getMonth() - 1);

    const from = `${oneMonthAgo.getFullYear()}-${String(oneMonthAgo.getMonth() + 1).padStart(2, '0')}-${String(
      oneMonthAgo.getDate(),
    ).padStart(2, '0')}`;
    const to = `${today.getFullYear()}-${String(today.getMonth() + 1).padStart(2, '0')}-${String(
      today.getDate(),
    ).padStart(2, '0')}`;

    dispatch(fetchSupportTicket({ from, to }));
  };
  return (
    <>
      <div className='support-requests navbar-fix '>
        <h1>
          My <span>Request Items</span>
        </h1>
        <div className='support-requests-table'>
          <div className='support-requests-nav'>
            <div className='support-requests-nav-left'>
              <span onClick={() => setStatusData('Open')} className={getActiveClass(statusData === 'Open')}>
                Active
              </span>
              <span onClick={() => setStatusData('Close')} className={getActiveClass(statusData === 'Close')}>
                Close
              </span>
            </div>
            <div className='markets-economic-calender-filter'>
              <label htmlFor='fromDate'>
                From:
                <DatePicker
                  value={fromDate}
                  onChange={(value) => setFromDate(value as Date)}
                  className='if-date-picker'
                />
              </label>

              <label htmlFor='toDate'>
                To:
                <DatePicker value={toDate} onChange={(value) => setToDate(value as Date)} className='if-date-picker' />
              </label>
              <div className='flex gap-2'>
                <button onClick={handleFilterApply} className='bg-[#20c997] btn btn-lg'>
                  Apply
                </button>
                <button onClick={handleResetFilter} className='bg-[#dc3545] btn btn-lg'>
                  Reset
                </button>
              </div>
            </div>
          </div>
          <table className='support-requests-table-container'>
            <thead className='support-requests-table-head'>
              <tr>
                <th>Id</th>
                <th>Status</th>
                <th>Update Date</th>
                <th>Query</th>
                <th>Comment</th>
                <th>&nbsp;</th>
                <th>&nbsp;</th>
              </tr>
            </thead>
            <tbody className='support-tickets-table-body'>
              {filteredTickets?.map((item) => (
                <tr key={item.id} className='support-requests-table-item'>
                  <td>{item.id.slice(-6, -1)}</td>
                  <td>{item.status}</td>
                  <td>{formattedDate(item.updated_at)}</td>
                  <td>{item.query}</td>
                  <td>{item.comment}</td>
                  {statusData === 'Open' && (
                    <td>
                      <button
                        className='btn btn-primary btn-lg'
                        onClick={() => {
                          setSuccessModal(true);
                          setTicketId(item.id);
                        }}>
                        Close
                      </button>
                    </td>
                  )}
                  <td>
                    <Dialog>
                      <DialogTrigger className='btn btn-primary btn-lg'>View</DialogTrigger>
                      <DialogContent className='tooltip'>
                        <div className='support-ticket-dialog'>
                          <h1>Ticket Details</h1>
                          <div className='support-ticket-dialog-details'>
                            <p>
                              <strong>ID:</strong> {item.id.slice(-6, -1)}
                            </p>
                            <p>
                              <strong>Status:</strong> {item.status}
                            </p>
                            <p>
                              <strong>Section:</strong> {item.section}
                            </p>
                            <p>
                              <strong>Query:</strong> {item.query}
                            </p>
                            <p>
                              <strong>Description:</strong> {item.description}
                            </p>

                            <p>
                              <strong>Created At:</strong> {formattedDate(item.created_at)}
                            </p>
                            <p>
                              <strong>Updated At:</strong> {formattedDate(item.updated_at)}
                            </p>
                            <p>
                              <strong>Comments:</strong> {item.comment}
                            </p>
                          </div>
                          <DialogClose className='btn btn-primary btn-lg btn-rounded'>Close</DialogClose>
                        </div>
                      </DialogContent>
                    </Dialog>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>

      <ModalWrapper open={successModal} onClose={() => setSuccessModal(false)}>
        <div className='clubConfirmModal'>
          <img src={notificationWarning} alt='warning-icon' />
          <h1>Do you want to close this ticket?</h1>
          <div className='clubConfirmModal--Btn'>
            <Button className='btn-primary btn-lg' label='Cancel' onClick={() => setSuccessModal(!successModal)} />
            <Button
              className='btn-primary btn-lg'
              label='Confirm'
              onClick={() => {
                dispatch(supportCancelTicket({ id: ticketId, status: 'CANCELLED' }));
                setSuccessModal(!successModal);
              }}
            />
          </div>
        </div>
      </ModalWrapper>
    </>
  );
}
