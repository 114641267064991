export default function StockpeerCard({ item }) {
  return (
    <div className='stockpeer'>
      <div className='stockpeer-header'>
        <img src={item?.image} alt='' className='size-8' />
        <div>
          <h1>{item?.company_name}</h1>
          <h2>{item.ticker}</h2>
        </div>
      </div>
      <div className='stockpeer-info'>
        <div className='stockpeer-info-item'>
          <h4>PE Ratio</h4>
          <span>{item.pe_ratio.toFixed(3)}</span>
        </div>
        <div className='stockpeer-info-item'>
          <h4>1Y Return</h4>
          <span>{item.one_year_return ? item.one_year_return.toFixed(3) : 'NA'}</span>
        </div>
        <div className='stockpeer-info-item'>
          <h4>EPS</h4>
          <span>{item.eps}</span>
        </div>
      </div>
    </div>
  );
}
