import React, { useEffect, useState, useRef } from 'react';
import { ReportsHeader } from 'src/components';
import Select from 'src/components/InputElements/select.component';
import ReportsTable from 'src/components/Table/ReportsTable.component';
import { createColumnHelper } from '@tanstack/react-table';
import { useDispatch } from 'react-redux';
import { fetchReportMetaData, fetchReports } from './Reports.reducer';
import { useSelector } from 'react-redux';
import { selectReportFilterState, selectReportMetaDataState } from './Reports.selector';
import { ArrowDownTrayIcon } from '@heroicons/react/20/solid';

import { convertToNumber } from 'src/lib/utils/utils';
import { downloadFilteredData } from 'src/lib/utils/tableUtils';

import Loader from 'src/components/loadingAnimation/loadingAnimation.component';

function compareValues(a, b, colId) {
  let numA = convertToNumber(a.original[colId]);
  let numB = convertToNumber(b.original[colId]);
  if (numA < numB) {
    return -1;
  } else if (numA > numB) {
    return 1;
  } else {
    return 0;
  }
}

const filterValue = (row, col, filtervalue) => {
  let rowValue = convertToNumber(row.original[col]);
  let filterMin = filtervalue[0] !== '' ? parseFloat(filtervalue[0]) : undefined;
  let filterMax = filtervalue[1] !== '' ? parseFloat(filtervalue[1]) : undefined;

  if (filterMin !== undefined && rowValue < filterMin) {
    return false;
  }
  if (filterMax !== undefined && rowValue > filterMax) {
    return false;
  }
  return true;
};
const columnHelper = createColumnHelper();
const processeHeaders = (data) => {
  const headers = [];
  const headerMaplabel = {};
  const headerMapKey = {};
  data?.columns?.forEach((h) => {
    if (h.key.toLowerCase() === 'image') {
      headers.push(columnHelper.accessor(h.key, { header: '', enableSorting: false }));
      headerMaplabel[h.short_name] = { dataType: h.data_type, label: h.label };
      headerMapKey[h.key] = { dataType: h.data_type, label: h.label };
      return;
    }
    if (h.key.toLowerCase().includes('url')) {
      headers.push(columnHelper.accessor(h.key, { header: h.short_name, enableSorting: false }));
      headerMaplabel[h.short_name] = { dataType: h.data_type, label: h.label };
      headerMapKey[h.key] = { dataType: h.data_type, label: h.label };
      return;
    }
    if (h.data_type === 'string') {
      headers.push(columnHelper.accessor(h.key, { header: h.short_name }));
    } else {
      headers.push(
        columnHelper.accessor(h.key, {
          header: h.short_name,
          sortingFn: compareValues,
          filterFn: filterValue,
          meta: {
            filterVariant: 'range',
          },
        }),
      );
    }
    headerMaplabel[h.short_name] = { dataType: h.data_type, label: h.label };
    headerMapKey[h.key] = { dataType: h.data_type, label: h.label };
  });
  return { headers, headerMaplabel, headerMapKey };
};
export default function Reports() {
  const dispatch = useDispatch();
  const metaData = useSelector(selectReportMetaDataState);
  useEffect(() => {
    dispatch(fetchReportMetaData());
  }, []);
  return metaData ? (
    <ReportsBody data={metaData} />
  ) : (
    <div className='w-[100vw] h-[90vh] flex items-center justify-center'>
      <Loader />
    </div>
  );
}

function ReportsBody({ data }) {
  const tableRef = useRef();

  const [headers, setHeaders] = useState([]);
  const [headersLabelMap, setHeadersLabelMap] = useState([]);
  const [headersKeyMap, setHeadersKeyMap] = useState([]);

  return (
    <main className='navbar-fix'>
      {data ? (
        <>
          <ReportsHeader text='iFIR$T Reports' data={data} />
          <ReportsSelections
            tableRef={tableRef}
            data={data}
            setHeadersLabelMap={setHeadersLabelMap}
            setHeadersKeyMap={setHeadersKeyMap}
            setHeaders={setHeaders}
          />
          {headers?.length > 0 && (
            <ReportsTable
              ref={tableRef}
              headers={headers}
              headersLabelMap={headersLabelMap}
              headersKeyMap={headersKeyMap}
            />
          )}
        </>
      ) : (
        ''
      )}
    </main>
  );
}

function ReportsSelections({ data, setHeaders, setHeadersLabelMap, setHeadersKeyMap, tableRef }) {
  const dispatch = useDispatch();
  const [tree, setTree] = useState(null);
  const [subTree, setSubTree] = useState(null);
  const [ternaryTree, setTernaryTree] = useState(null);
  const reportOptions = useSelector(selectReportFilterState);
  const [options, setOptions] = useState([]);
  const [options2, setOptions2] = useState([]);
  const [options3, setOptions3] = useState([]);
  const [payload, setPayload] = useState({});
  const [category, setCategory] = useState('');

  useEffect(() => {
    if (data?.asset_classes) {
      const assetClass = data?.asset_classes?.find((ac) => ac.label === reportOptions.market_type);
      let pload = { market_type: assetClass?.key };
      if (assetClass) {
        const indices = assetClass.indices || [assetClass];
        const index = indices.find((i) => i.label === reportOptions.market_index);
        pload = { ...pload, market_index: index?.key };
        setPayload(pload);
        const target = index || assetClass;
        setTree(target);
        if (target?.options) {
          const primaryOptions = target?.options?.map((e) => e.label) || [];
          setOptions(primaryOptions);

          setOptions3([]);
        } else {
          if (!target?.indices) {
            pload = { ...pload, options: target?.key };
            dispatch(fetchReports(pload)); //
            const { headers, headerMaplabel, headerMapKey } = processeHeaders(target);
            setHeaders(headers);
            setHeadersLabelMap(headerMaplabel);
            setHeadersKeyMap(headerMapKey);

            setOptions([]);
            setOptions3([]);
            setOptions2([]);
          }
        }
      }
    }
  }, [reportOptions]);
  function handlePrimaryOptionChange(option) {
    if (!tree || !option) return;

    const secondaryOptionsMap = tree.options.find((e) => e.label === option);
    if (!secondaryOptionsMap) return;

    setSubTree(secondaryOptionsMap);
    const secondaryOptions = secondaryOptionsMap.options?.map((e) => e.label) || [];
    setOptions2(secondaryOptions);
    setOptions3([]);

    const pload = {
      market_type: payload?.market_type,
      market_index: payload?.market_index,
      options: secondaryOptionsMap.key,
    };
    setCategory(option);
    setPayload(pload);

    if (secondaryOptions.length === 0) {
      const { headers, headerMaplabel, headerMapKey } = processeHeaders(secondaryOptionsMap);
      setHeaders(headers);
      setHeadersLabelMap(headerMaplabel);
      setHeadersKeyMap(headerMapKey);
      dispatch(fetchReports(pload));
    }
  }

  function handleSecondaryOptionChange(option2) {
    if (subTree && option2) {
      const t = subTree?.options?.find((e) => e.label === option2);
      let processedHeaders, processedheaderLabelMap, processedheaderKeyMap;
      if (t && t.columns) {
        const { headers, headerMaplabel, headerMapKey } = processeHeaders(t);
        processedHeaders = headers;
        processedheaderLabelMap = headerMaplabel;
        processedheaderKeyMap = headerMapKey;
        const pload = {
          market_type: payload?.market_type,
          market_index: payload?.market_index,
          options: t?.key,
        };
        dispatch(fetchReports(pload));
        setOptions3([]);
        setPayload(pload);
      } else {
        const { headers, headerMaplabel, headerMapKey } = processeHeaders(subTree);
        processedHeaders = headers;
        processedheaderLabelMap = headerMaplabel;
        processedheaderKeyMap = headerMapKey;
        let pload;
        if (t?.options) {
          pload = {
            market_type: payload.market_type,
            market_index: payload.market_index,
            options: payload.options,
            ta_category: t?.key,
          };
          const tertiaryOptions = t?.options?.map((e) => e.label);
          setOptions3(tertiaryOptions);
          setTernaryTree(t);
          setPayload(pload);
        } else {
          pload = {
            market_type: payload?.market_type,
            market_index: payload?.market_index,
            options: payload?.options,
            analyst_company: t?.key,
          };
          setOptions3([]);
          setTernaryTree(null);
          dispatch(fetchReports(pload));
          setPayload(pload);
        }
      }
      setHeaders(processedHeaders);
      setHeadersLabelMap(processedheaderLabelMap);
      setHeadersKeyMap(processedheaderKeyMap);
    }
  }

  function handleTertiaryOptionChange(option) {
    if (ternaryTree) {
      const r = ternaryTree?.options?.find((i) => i.label === option);
      const { headers, headerMaplabel, headerMapKey } = processeHeaders(r);
      setHeaders(headers);
      setHeadersLabelMap(headerMaplabel);
      setHeadersKeyMap(headerMapKey);
      dispatch(fetchReports({ ...payload, time_frame: r.key }));
    }
  }

  return (
    <section className='reports'>
      <div className='reports-dropdown-options'>
        <div className='reports-dropdowns'>
          {options?.length > 0 ? (
            <>
              <span>Options</span>
              <div className='report-dropdown'>
                {options?.length > 0 ? <Select options={options} onChange={(e) => handlePrimaryOptionChange(e)} /> : ''}
                {options2?.length > 0 ? (
                  <Select options={options2} onChange={(e) => handleSecondaryOptionChange(e)} />
                ) : (
                  ''
                )}
                {options3?.length > 0 ? (
                  <Select options={options3} onChange={(e) => handleTertiaryOptionChange(e)} />
                ) : (
                  ''
                )}
              </div>
            </>
          ) : (
            ''
          )}
        </div>

        <div className='reports-download-btn' onClick={() => downloadFilteredData(tableRef, category)}>
          <ArrowDownTrayIcon />
        </div>
      </div>
    </section>
  );
}
