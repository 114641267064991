import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchMarketInfo } from 'src/store/MarketInfo/Marketinfo.reducer';
import { selectMarketInfoData, selectMarketInfoIsLoading } from 'src/store/MarketInfo/MarketInfo.selector';
export const useCachedMarketInfo = () => {
  const dispatch = useDispatch();
  const cachedData = useSelector(selectMarketInfoData); // Assume state.data holds the cached data
  const isLoading = useSelector(selectMarketInfoIsLoading);

  useEffect(() => {
    if (!cachedData) {
      dispatch(fetchMarketInfo());
    }
  }, [cachedData, dispatch]);

  return { data: cachedData, isLoading };
};
