import React, { useEffect, useState } from 'react';
import 'react-multi-carousel/lib/styles.css';
import 'react-calendar/dist/Calendar.css';
import CountryFlag from 'react-country-flag';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import DatePicker from 'react-date-picker';
import 'react-date-picker/dist/DatePicker.css';
import 'react-calendar/dist/Calendar.css';
import { selectMarketEconomicCalender } from 'src/pages/Markets/markets.selector';
import { fetchMarketEconomicCalender } from 'src/pages/Markets/markets.reducer';
import { IMarketEconomicCalenderEventData, IMarketResp } from 'src/lib/types/markets';

interface ItemsToShowNestedState {
  [key: string]: number;
}

export default function EconomicCalendarComponent() {
  const dispatch = useDispatch();
  const economicCalender: IMarketResp = useSelector(selectMarketEconomicCalender);

  useEffect(() => {
    dispatch(fetchMarketEconomicCalender());
  }, [dispatch]);

  const [fromDate, setFromDate] = useState<Date | null>(null);
  const [toDate, setToDate] = useState<Date | null>(null);
  const [filterActive, setFilterActive] = useState(false); // Initially false
  const [itemsToShow, setItemsToShow] = useState(10); // Default to show first 10 items
  const [itemsToShowNested, setItemsToShowNested] = useState<ItemsToShowNestedState>({});

  const getTimeFromDate = (dateString: string | number | Date) => {
    const date = new Date(dateString);
    return date.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
  };

  const handleShowMoreNested = (date: string) => {
    setItemsToShowNested((prevState) => {
      const newCount = (prevState[date] || 2) + 2;
      return {
        ...prevState,
        [date]: newCount,
      };
    });
  };

  const handleFilterApply = () => {
    setFilterActive(true);
  };

  const handleResetFilter = () => {
    setFromDate(null);
    setToDate(null);
    setFilterActive(false);
  };

  return (
    <section className='markets-economic-calender'>
      <div className='markets-economic-calender-header'>
        <h1>Economic Calendar</h1>
        <div className='markets-economic-calender-filter'>
          <label htmlFor='fromDate'>
            From:
            <DatePicker
              value={fromDate}
              onChange={(value) => setFromDate(value as Date)}
              className='if-date-picker'
              shouldCloseCalendar={({ reason }) =>
                ['buttonClick', 'outsideAction', 'select', 'escape'].includes(reason)
              }
            />
          </label>

          <label htmlFor='toDate'>
            To:
            <DatePicker
              value={toDate}
              onChange={(value) => setToDate(value as Date)}
              className='if-date-picker'
              shouldCloseCalendar={({ reason }) =>
                ['buttonClick', 'outsideAction', 'select', 'escape'].includes(reason)
              }
            />
          </label>
          <div className='flex gap-2'>
            <button onClick={handleFilterApply} className='bg-[#20c997] btn btn-lg'>
              Apply
            </button>
            <button onClick={handleResetFilter} className='bg-[#dc3545] btn btn-lg'>
              Reset
            </button>
          </div>
        </div>

        <div className='markets__sectionTwoOptions '>
          <span>Time</span>
          <span>Actual</span>
          <span>Forecast</span>
          <span>Prior</span>
          <span>Change</span>
          <span>Change(%)</span>
        </div>
      </div>

      {economicCalender?.data &&
        Object.entries(economicCalender?.data)
          .filter(([date, events]) => {
            if (!filterActive) return true; // Show all if filter is not active
            const itemDate = new Date(date);
            return fromDate && toDate ? itemDate >= fromDate && itemDate <= toDate : true;
          })
          .slice(0, itemsToShow)
          .map(([date, events]) => (
            <div className='markets__sectionTwoContainer' key={date}>
              <div className='markets__sectionTwoHeader '>
                <h2>{date}</h2>
              </div>
              {events
                .slice(0, itemsToShowNested[date] || 2)
                .map((event: IMarketEconomicCalenderEventData, index: number) => (
                  <div className='markets__container' key={index}>
                    <div className='markets__containerDate'>
                      <span>{getTimeFromDate(event.date)}</span>
                      <div className='markets__containerDate--Ticker display: flex; align-items: center; gap-[1rem]'>
                        <p className='text-[1.2rem] font-[400]'>{event.country}</p>
                        {event.country && <CountryFlag countryCode={event.country} svg />}
                      </div>
                    </div>
                    <div className='markets__containerInfo'>
                      <div className='markets__info'>
                        <div />
                        <h3>{event.event}</h3>
                      </div>
                      <div className='markets__calendarInfo'>
                        <ul>
                          <li>{event?.actual || '-'}</li>
                          <li>{event?.estimate || '-'}</li>
                          <li>{event?.previous || '-'}</li>
                          <li>{event?.change || '-'}</li>
                          <li>{event?.changePercentage || '-'}</li>
                        </ul>
                      </div>
                    </div>
                  </div>
                ))}
              {events.length > (itemsToShowNested[date] || 2) && (
                <div className='markets__sectionTwoMore' onClick={() => handleShowMoreNested(date)}>
                  More
                </div>
              )}
            </div>
          ))}

      {economicCalender?.data && itemsToShow < Object.keys(economicCalender?.data).length && (
        <div className='markets__sectionTwoMore' onClick={() => setItemsToShow((prev) => prev + 10)}>
          Load More
        </div>
      )}
    </section>
  );
}
