import { put, takeLatest } from 'redux-saga/effects';
import {
  getSentiment,
  getSentimentBiggestChange,
  getSentimentTrendingChange,
} from 'src/repositories/sentiment/sentiment';
import {
  fetchSocialSentiment,
  fetchSocialSentimentSuccess,
  fetchSocialSentimentFailure,
  fetchSocialSentimentTrendingChange,
  fetchSocialSentimentTrendingChangeSuccess,
  fetchSocialSentimentTrendingChangeFailure,
  fetchSocialSentimentBiggestChange,
  fetchSocialSentimentBiggestChangeSuccess,
  fetchSocialSentimentBiggestChangeFailure,
} from './socialsentiment.reducer';
import { IError } from 'src/lib/types/error';
function* fetchSocialSentimentSaga(action) {
  try {
    const data = yield getSentiment(action.payload).then((response) => response);
    yield put(fetchSocialSentimentSuccess(data.data.data));
  } catch (error) {
    yield put(fetchSocialSentimentFailure(error as IError));
  }
}

export function* watchSocialSentimentSaga() {
  yield takeLatest(fetchSocialSentiment.type, fetchSocialSentimentSaga);
}

function* fetchSocialSentimentBiggestChangeSaga(action) {
  try {
    const data = yield getSentimentBiggestChange(action.payload).then((response) => response);
    yield put(fetchSocialSentimentBiggestChangeSuccess(data.data.data));
  } catch (error) {
    yield put(fetchSocialSentimentBiggestChangeFailure(error as IError));
  }
}

export function* watchSocialSentimentBiggestChangeSaga() {
  yield takeLatest(fetchSocialSentimentBiggestChange.type, fetchSocialSentimentBiggestChangeSaga);
}
function* fetchSocialSentimentTrendingChangeSaga(action) {
  try {
    const data = yield getSentimentTrendingChange(action.payload).then((response) => response);
    yield put(fetchSocialSentimentTrendingChangeSuccess(data.data.data));
  } catch (error) {
    yield put(fetchSocialSentimentTrendingChangeFailure(error as IError));
  }
}

export function* watchSocialSentimentTrendingChangeSaga() {
  yield takeLatest(fetchSocialSentimentTrendingChange.type, fetchSocialSentimentTrendingChangeSaga);
}
