import { createSelector } from 'reselect';

const selectTvConfigState = (state: { tvConfig: any }) => state.tvConfig;

export const selectTvConfig = createSelector([selectTvConfigState], (TvConfigState) => TvConfigState.data);
export const selectTvConfigIsLoading = createSelector(
  [selectTvConfigState],
  (TvConfigState) => TvConfigState.isLoading,
);
const selectTvSymbolState = (state: { tvSymbol: any }) => state.tvSymbol;

export const selectTvSymbol = createSelector([selectTvSymbolState], (TvSymbolState) => TvSymbolState.data);
export const selectTvSymbolIsLoading = createSelector(
  [selectTvSymbolState],
  (TvSymbolState) => TvSymbolState.isLoading,
);
