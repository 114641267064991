import React from 'react';
import { PriceBar } from 'src/components/d3Components/priceBar';
import { useParams } from 'react-router-dom';
import { useWatchlistData } from 'src/hooks/useWatchlistData';
export default function Keymatrics() {
  const { ticker } = useParams();

  const { lastNonNullDataSource } = useWatchlistData(ticker);

  const dayHigh = lastNonNullDataSource?.dayHigh || 'NA';
  const dayLow = lastNonNullDataSource?.dayLow || 'NA';
  const open = lastNonNullDataSource?.open || 'NA';
  const price = lastNonNullDataSource?.price || 'NA';
  const yearHigh = lastNonNullDataSource?.yearHigh || 'NA';
  const yearLow = lastNonNullDataSource?.yearLow || 'NA';
  return (
    <div className='overview__MetricsOne'>
      <h1 className='financials-section-header'>KEY METRICS</h1>
      <article className='keymatrics-container '>
        <div className='overview__PriceBarOne'>
          {dayHigh !== 'NA' ? (
            <PriceBar
              high={dayHigh}
              low={dayLow}
              startValue={open}
              currentValue={price}
              width={600}
              height={100}
              dateRange="DAY'S RANGE"
            />
          ) : (
            <h2 className='ca-nodata'>Data Not available</h2>
          )}
        </div>
        <div className='overview__PriceBarTwo'>
          {yearHigh !== 'NA' ? (
            <PriceBar
              high={yearHigh}
              low={yearLow}
              startValue={open}
              currentValue={price}
              width={600}
              height={100}
              dateRange='52 Week Range'
            />
          ) : (
            <h2 className='ca-nodata'>Data Not available</h2>
          )}
        </div>
      </article>
    </div>
  );
}
