import { useEffect } from 'react';
import { WS_EVENT } from 'src/constants/ws.constant';
import { ScrollCarousal } from '../carousel/ScrollCarousel.component';
import { TopBarCard } from '../Card/card.component';
import { useSelector, useDispatch } from 'react-redux';
import { connectCarouselWebSocket, disconnectCarouselWebSocket } from 'src/store/Websocket/carouselWebsocket';
import { useCachedCarousel } from 'src/hooks/cachedCarousal';
const Topbar = () => {
  const dispatch = useDispatch();
  const { data } = useCachedCarousel();
  const websocketData = useSelector((state) => state.carouselWebsocket);

  useEffect(() => {
    if (websocketData?.data?.status !== WS_EVENT.WS_EVENT_SERVER_CONNECTED) {
      dispatch(connectCarouselWebSocket());
    }
    return () => {
      dispatch(disconnectCarouselWebSocket());
    };
  }, []);

  return (
    <div className='topbar '>
      {data ? (
        <div className='w-[90vw] max-w-[90vw] overflow-hidden '>
          <ScrollCarousal autoplay autoplaySpeed={8} speed={5}>
            {data?.map((item, index) => (
              <TopBarCard key={index} item={item} liveQuote={websocketData} />
            ))}
          </ScrollCarousal>
        </div>
      ) : (
        ''
      )}
    </div>
  );
};

export default Topbar;
