import { useEffect, useState } from 'react';
import { PrimaryHeader, AcademyCard, Popover, PopoverTrigger, PopoverContent, PopoverClose } from 'src/components';
import Loader from 'src/components/loadingAnimation/loadingAnimation.component';
import { Bars3Icon } from '@heroicons/react/20/solid';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { selectAcademyContent, selectAcademyMetaData } from './academy.selector';
import { fetchAcademyContent, fetchAcademyMetaData } from './academy.reducer';
import { IAcademyContent, IAcademyReq } from 'src/lib/types/academy';
import Pagination from 'src/components/Pagination/pagination.component';
import { NewsCardloader } from 'src/components/loadingAnimation/cardloader';

function Academy() {
  const dispatch = useDispatch();
  const metaData = useSelector(selectAcademyMetaData);
  const content = useSelector(selectAcademyContent);
  const [payload, setPayload] = useState<IAcademyReq>({
    category: null,
    subcategory: null,
    page: 1,
  });
  const [openCategory, setOpenCategory] = useState<string | null>(null);

  const handleCategoryClick = (item: string) => {
    dispatch(
      fetchAcademyContent({
        category: item,
        subcategory: payload.category === item ? payload.subcategory : null,
        page: payload.category === item ? payload.page : 1,
      }),
    );
    setPayload((prev) => ({
      category: item,
      subcategory: prev.category === item ? prev.subcategory : null,
      page: prev.category === item ? prev.page : 1,
    }));
    setOpenCategory((prev) => (prev === item ? null : item));
  };

  useEffect(() => {
    dispatch(fetchAcademyMetaData());
    dispatch(fetchAcademyContent({}));
  }, []);
  return (
    <div className='navbar-fix'>
      <PrimaryHeader text='Academy' className='color-primary' />
      <div className='academy'>
        <div className='academy-nav'>
          <ul className='academy-nav-list'>
            <ul className='dropdown dropdown-bottom academy-nav-mobile-icon'>
              <div tabIndex={0} role='button'>
                <Bars3Icon className='size-8' />
              </div>
              <ul tabIndex={0} className='dropdown-content shadow academy-mobile-nav'>
                {metaData &&
                  Object.keys(metaData?.categories).map((item, i) => (
                    <li
                      key={i}
                      className={`${payload.category === item ? 'active' : ''} academy-mobile-nav-item`}
                      onClick={() => {
                        dispatch(
                          fetchAcademyContent({
                            category: item,
                            subcategory: payload.category === item ? payload.subcategory : null,
                            page: payload.category === item ? payload.page : 1,
                          }),
                        );
                        setPayload((prev) => ({
                          category: item,
                          subcategory: prev.category === item ? prev.subcategory : null,
                          page: prev.category === item ? prev.page : 1,
                        }));
                      }}>
                      {item}
                    </li>
                  ))}
              </ul>
            </ul>

            {metaData &&
              Object.keys(metaData?.categories).map((item, i) => (
                <li
                  key={i}
                  className={`${payload.category === item ? 'active' : ''} academy-nav-item `}
                  onClick={() => handleCategoryClick(item)}>
                  {item}
                </li>
              ))}
          </ul>
        </div>
        {content?.isLoading ? (
          <div className='academy-body'>
            {Array.from({ length: 9 }).map((_, rowIndex) => (
              <NewsCardloader key={rowIndex} />
            ))}
          </div>
        ) : content?.data?.length > 0 ? (
          <>
            <div className='academy-body'>
              {content?.data
                ? content.data?.map((item: IAcademyContent) => <AcademyCard item={item} key={item.id} />)
                : ''}
            </div>
          </>
        ) : (
          <h2 className='ca-nodata'> No data available</h2>
        )}

        <div className='my-[3rem]'>
          {metaData && content?.data?.length > 0 ? (
            <Pagination
              totalPages={
                payload?.category
                  ? metaData?.category_wise_num_pages[payload?.category]['']
                    ? metaData?.category_wise_num_pages[payload?.category]['']
                    : metaData?.category_wise_num_pages[payload?.category][payload?.subcategory!]
                  : metaData?.total_num_pages
              }
              onPageChange={(value) => {
                dispatch(fetchAcademyContent({ page: value, category: payload.category }));
              }}
              dependency={[payload.category]}
            />
          ) : null}
        </div>
      </div>
    </div>
  );
}

export default Academy;
