import React, { useEffect } from 'react';
import Slider from 'react-slick'; // Importing the Slider from react-slick
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { useDispatch, useSelector } from 'react-redux';
import { selectMarketEconomicIndicator } from 'src/pages/Markets/markets.selector';
import { fetchMarketEconomicIndicator } from 'src/pages/Markets/markets.reducer';
import { EconomicIndicatorGraph } from '../graph/economicIndicatorGraph';

const indicators = [
  { title: 'CPI', key: 'CPI' },
  { title: 'GDP', key: 'GDP' },
  { title: 'Inflation Rate', key: 'inflationRate' },
  { title: 'Unemployment Rate', key: 'unemploymentRate' },
];

export default function MarketsEconomicIndicator() {
  const dispatch = useDispatch();
  const indicator = useSelector(selectMarketEconomicIndicator);

  useEffect(() => {
    dispatch(fetchMarketEconomicIndicator());
  }, [dispatch]);

  const settings = {
    dots: false,
    infinite: true,
    arrows: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1366,
        settings: {
          slidesToShow: 2.6,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,

          centerMode: false, // Center the item when there's only one
        },
      },
      {
        breakpoint: 1023,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,

          centerMode: false, // Center the item when there's only one
        },
      },
    ],
  };

  return (
    <>
      {indicator?.data ? (
        <section className='markets-economic-insdicators'>
          <Slider {...settings}>
            {indicators.map(({ title, key }, index) => (
              <div style={{ margin: '0 .2rem' }}>
                <div key={index} className='markets-economic-indicator'>
                  <h1>{title}</h1>

                  <EconomicIndicatorGraph
                    data={indicator?.data[key]}
                    color='#007BFF'
                    height='300px'
                    dateKey='date'
                    valueKey='value'
                    dateFormat='T'
                  />
                </div>
              </div>
            ))}
          </Slider>
        </section>
      ) : (
        ''
      )}
    </>
  );
}
