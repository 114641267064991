import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchCarousel } from 'src/components/Topbar/Topbar.reducer';
import { selectDataCarousel, selectLoadingStatusCarousel } from 'src/components/Topbar/Topbar.selector';
export const useCachedCarousel = () => {
  const dispatch = useDispatch();
  const cachedData = useSelector(selectDataCarousel); // Assume state.data holds the cached data
  const isLoading = useSelector(selectLoadingStatusCarousel);

  useEffect(() => {
    if (!cachedData) {
      dispatch(fetchCarousel());
    }
  }, [cachedData, dispatch]);

  return { data: cachedData, isLoading };
};
