import { WS_EVENT } from 'src/constants/ws.constant';
import { processTradeDataToBars } from 'src/lib/tvUtils/tvUtils';
import { isEmpty } from 'src/lib/utils/utils';
export const channelToSubscription = new Map();

/**
 * Subscribe to streaming data for a specific symbol.
 * @param {object} symbolInfo - Information about the symbol to subscribe.
 * @param {string} resolution - Time resolution for the data (e.g., '1D').
 * @param {function} onRealtimeCallback - Callback to handle real-time data.
 * @param {string} subscriberUID - Unique identifier for the subscriber.
 * @param {function} onResetCacheNeededCallback - Callback to reset cache.
 * @param {object} lastDailyBar - Last known bar (OHLC).
 */
export function subscribeOnStream(
  symbolInfo,
  resolution,
  onRealtimeCallback,
  subscriberUID,
  onResetCacheNeededCallback,
  lastDailyBar,
) {
  const channelString = symbolInfo.name;
  const handler = { id: subscriberUID, callback: onRealtimeCallback };

  let subscriptionItem = channelToSubscription.get(channelString);
  if (subscriptionItem) {
    // If already subscribed, just add the new handler
    subscriptionItem.handlers.push(handler);
  } else {
    // Otherwise, create a new subscription
    subscriptionItem = { subscriberUID, resolution, lastDailyBar, handlers: [handler] };
    channelToSubscription.set(channelString, subscriptionItem);
  }
}

/**
 * Unsubscribe from streaming data.
 * @param {string} subscriberUID - Unique identifier of the subscriber to remove.
 */
export function unsubscribeFromStream(subscriberUID) {
  for (const channelString of channelToSubscription.keys()) {
    const subscriptionItem = channelToSubscription.get(channelString);
    const handlerIndex = subscriptionItem.handlers.findIndex((handler) => handler.id === subscriberUID);

    if (handlerIndex !== -1) {
      // Remove the handler for this subscriber
      subscriptionItem.handlers.splice(handlerIndex, 1);

      if (subscriptionItem.handlers.length === 0) {
        // If no handlers left, unsubscribe from the channel
        channelToSubscription.delete(channelString);
      }
      break;
    }
  }
}

export function HandleLiveQuote(marketData) {
  if (marketData.event === WS_EVENT.WS_EVENT_SERVER_STREAMING_QUOTE) {
    if (!isEmpty(marketData.data)) {
      Object.keys(marketData.data).forEach((symbol) => {
        const symbolData = marketData.data[symbol];
        if (symbolData.lp) {
          const matchedItem = {
            symbol,
            price: symbolData.lp,
            time: symbolData.t / 100000000,
          };

          processBar(symbol, matchedItem);
        }
      });
    }
  }

  if (marketData?.event === WS_EVENT.WS_EVENT_SERVER_LIVE_QUOTE) {
    marketData?.data?.forEach((item) => {
      processBar(item.symbol, item);
    });
  }
}

function processBar(symbol, item) {
  const channelString = `${symbol}`; //`${matchedItem.symbol}:${exchnage}`
  const subscriptionItem = channelToSubscription.get(channelString);
  if (subscriptionItem) {
    const updatedBar = processTradeDataToBars(item, subscriptionItem.lastDailyBar);
    subscriptionItem.lastDailyBar = updatedBar;
    // Notify subscribers about the new/updated bar
    subscriptionItem.handlers.forEach((handler) => handler.callback(updatedBar));
  }
}
