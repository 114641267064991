import axios, { InternalAxiosRequestConfig, AxiosResponse } from 'axios';
import { ACCESS_TOKEN_KEY } from 'src/constants/token.constant';
import CookieToken from '../lib/session-token';
import { config } from 'src/lib/config';
import { TokenValidate } from 'src/lib/auth';

const host = `${config.API_BASE_URL}${config.API_VER}`;
export const apiClient = axios.create({
  baseURL: host,
});

apiClient.interceptors.request.use((request: InternalAxiosRequestConfig) => {
  const { method, url } = request;
  const sub = config.API_SVC_PATH_IFIRST_SUBSCRIPTION + config.SUBSCRIPTION_META_DATA;
  const jwtToken = CookieToken.getToken(ACCESS_TOKEN_KEY);
  if (
    url?.includes(config.MGMT_SIGNIN_INITIATE) ||
    url?.includes(config.MGMT_SIGNIN) ||
    url?.includes(config.API_SUPPORT_FAQ) ||
    url?.includes(config.API_SUPPORT_FAQ_DETAILS) ||
    url?.includes(config.API_SUPPORT_SECTION) ||
    url?.includes(config.API_SUPPORT_SHORT_VIDEO) ||
    url?.includes(sub) ||
    url?.includes(config.MGMT_MARKET_INFO) ||
    url?.includes(config.API_SVC_PATH_IFIRST_ACADEMY) ||
    (url?.includes(config.API_SVC_PATH_IFIRST_CLUB) && method !== 'post') ||
    (url?.includes(config.API_SUPPORT_TICKET) && method !== 'get') ||
    url?.includes(config.MGMT_SIGNUP_INITIATE) ||
    url?.includes(config.MGMT_CAROUSAL) ||
    url?.includes(config.MGMT_SIGNUP) ||
    url?.includes(config.MGMT_FORGOT_PASSWORD_INITIATE) ||
    url?.includes(config.MGMT_FORGOT_PASSWORD)
  ) {
    if (jwtToken) {
      request.headers.Authorization = `Bearer ${jwtToken}`;
    }
    return request;
  }

  TokenValidate();
  if (jwtToken) {
    request.headers.Authorization = `Bearer ${jwtToken}`;
  }

  return request;
});

apiClient.interceptors.response.use(
  (response: AxiosResponse) => {
    return response;
  },
  (error) => {
    try {
      const { status } = error.response;
      return Promise.reject({
        status,
        message: error.response.data.error.message,
      });
    } catch (err) {
      if (err === 'response is undefined') {
        return Promise.reject({
          status: 503,
          message: 'You are not connected to the server',
        });
      }
    }
  },
);

// for s3 requests
export const axiosClientS3 = axios.create();
