import React from 'react';
// import './PageNotFound.styles.css';
import { Button } from 'src/components';
import { Link } from 'react-router-dom';

function PageNotFound() {
  return (
    <section className='pnf'>
      <div className='pnf__container'>
        <Link to='/'>
          <Button label='Go Back' className='btn btn-lg btn-secondary pnf-btn' />
        </Link>
      </div>
    </section>
  );
}

export default PageNotFound;
