import { useState, useEffect } from 'react';
import { WS_EVENT } from 'src/constants/ws.constant';

export function getSiblings(e) {
  const siblings: HTMLElement[] = [];
  // if no parent, return no sibling
  if (!e.parentNode) {
    return siblings;
  }
  // first child of the parent node
  let sibling = e.parentNode.firstChild;

  // collecting siblings
  while (sibling) {
    if (sibling.nodeType === 1 && sibling !== e) {
      siblings.push(sibling);
    }
    sibling = sibling.nextSibling;
  }
  return siblings;
}
export function convertDate(inputDate) {
  if (!inputDate) return '';
  const [year, month, day] = inputDate?.split('-');
  return `${day}-${month}-${year}`;
}
export const findMaxIndex = (dataArray) => {
  let maxIndex = 0;
  dataArray?.forEach((data, i) => {
    if (data && data.length > 0) {
      if (data?.length > dataArray[maxIndex]?.length) {
        maxIndex = i;
      }
    }
  });
  return maxIndex;
};

export function getNormalizedKeys(obj) {
  const keys: string[] = [];
  for (const key in obj) {
    if (Object.hasOwnProperty.call(obj, key)) {
      if (typeof obj[key] === 'object' && obj[key] !== null) {
        keys.push(...getNormalizedKeys(obj[key]));
      } else {
        keys.push(key);
      }
    }
  }
  return keys;
}

export function flattenObject(obj) {
  const flattened = {};

  function recurse(obj, currentKey = '') {
    for (const key in obj) {
      if (obj.hasOwnProperty(key)) {
        const newKey = currentKey ? `${currentKey}.${key}` : key;
        if (typeof obj[key] === 'object' && obj[key] !== null) {
          recurse(obj[key], newKey);
        } else {
          flattened[newKey] = obj[key];
        }
      }
    }
  }

  recurse(obj);
  return flattened;
}

export function flattenArrays(arrays) {
  const flattenedObject = {};

  arrays.forEach((array) => {
    array.forEach((obj) => {
      const ticker = obj.ticker;
      if (!flattenedObject[ticker]) {
        flattenedObject[ticker] = { ticker };
      }
      Object.assign(flattenedObject[ticker], obj);
    });
  });

  return flattenedObject;
}

export function convertNumberToString(num: string, convertNumber = true) {
  if (!convertNumber) return num;
  const value = +num;
  if (isNaN(value)) {
    return num;
  }

  const absValue = Math.abs(value); // Get the absolute value to format
  let formattedValue = '';

  if (absValue >= 1e9) {
    formattedValue = `${(absValue / 1e9).toFixed(2)}B`; // Billion
  } else if (absValue >= 1e6) {
    formattedValue = `${(absValue / 1e6).toFixed(2)}M`; // Million
  } else if (absValue >= 1e3) {
    formattedValue = `${(absValue / 1e3).toFixed(2)}K`; // Thousand
  } else {
    formattedValue = absValue.toString(); // Less than 1000
  }
  return value < 0 ? `-${formattedValue}` : formattedValue;
}
export function convertToNumber(value) {
  value = String(value);
  let num = parseFloat(value);
  if (value.includes('K')) {
    num *= 1e3;
  } else if (value.includes('M')) {
    num *= 1e6;
  } else if (value.includes('B')) {
    num *= 1e9;
  } else if (value.includes('T')) {
    num *= 1e12;
  }
  return num;
}

export function getColorValue(text = '') {
  switch (text) {
    case 'Strong Sell':
      return 'sentissell';
    case 'Sell':
      return 'sentisell';
    case 'Neutral':
      return 'sentiHold';
    case 'Buy':
      return 'sentibuy';
    case 'Strong Buy':
      return 'sentisbuy';
    case 'Outperforming':
      return 'sentibuy';
    case 'Underperforming':
      return 'sentisell';
    case 'Underweight':
      return 'sentibuy';
    case 'Overweight':
      return 'sentisell';
    default:
      return 'sentiHold'; // Default color if text doesn't match any condition
  }
}
export function DebouncedInput({ value: initialValue, onChange, debounce = 500, ...props }) {
  const [value, setValue] = useState(initialValue);

  useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  useEffect(() => {
    const timeout = setTimeout(() => {
      onChange(value);
    }, debounce);

    return () => clearTimeout(timeout);
  }, [value]);

  return (
    <input
      {...props}
      value={value}
      onChange={(e) => setValue(e.target.value)}
      className=' bg-[#474747] border border-[#1E222D] rounded-md text-white outline-none p-2  w-full'
    />
  );
}

export const findObjectOrPrevious = (symbol, websocketData, setData) => {
  if (websocketData.event === WS_EVENT.WS_EVENT_SERVER_LIVE_QUOTE) {
    const matchedObject = websocketData?.data?.find((obj) => obj.symbol === symbol);

    if (matchedObject) {
      setData(matchedObject);
    }
  }
};

export const activeElement = (valid) => (valid ? 'activeTf' : '');
export const formattedDate = (dateInput: string | Date): string => {
  const dateStr = typeof dateInput === 'string' ? dateInput.split('T')[0] : dateInput;
  const date = typeof dateStr === 'string' ? new Date(dateStr) : dateStr;
  if (isNaN(date?.getTime())) {
    return '';
  }

  // Format the date without worrying about the time component
  const formattedDate = `${date.toLocaleString('en-IN', { month: 'long' })} ${date.getDate()}, ${date.getFullYear()}`;

  return formattedDate;
};

export function isEmpty(obj) {
  return Object.keys(obj).length === 0;
}
