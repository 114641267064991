export function processTradeDataToBars(tradeData, lastBar) {
  const tradePrice = parseFloat(tradeData.price); // Assuming 'price' is in the raw data
  const tradeTime = parseInt(tradeData.time); // Assuming 'time' is a UNIX timestamp

  // Calculate the next bar time (daily, in this case)
  const nextDailyBarTime = getNextDailyBarTime(lastBar.time);

  let bar;
  if (tradeTime >= nextDailyBarTime) {
    // If trade time is in the next period, create a new bar
    bar = {
      time: nextDailyBarTime,
      open: tradePrice,
      high: tradePrice,
      low: tradePrice,
      close: tradePrice,
    };
  } else {
    // Update the existing bar
    bar = {
      ...lastBar,
      high: Math.max(lastBar.high, tradePrice),
      low: Math.min(lastBar.low, tradePrice),
      close: tradePrice,
    };
  }

  return bar;
}

// Example utility function to calculate next daily bar time
function getNextDailyBarTime(lastBarTime) {
  const date = new Date(lastBarTime * 1000); // Convert to milliseconds
  date.setDate(date.getDate() + 1); // Move to the next day
  return date.getTime() / 1000; // Return as UNIX timestamp
}
