import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchTvConfig, fetchTvSymbol } from 'src/components/TvContainer/tv.reducer';
import {
  selectTvConfig,
  selectTvSymbol,
  selectTvConfigIsLoading,
  selectTvSymbolIsLoading,
} from 'src/components/TvContainer/tv.selctor';

export const useCachedTvConfig = () => {
  const dispatch = useDispatch();

  // Assuming these selectors will fetch the cached state
  const cachedTvConfig = useSelector(selectTvConfig);
  const cachedTvSymbol = useSelector(selectTvSymbol);
  const isLoadingConfig = useSelector(selectTvConfigIsLoading);
  const isLoadingSymbol = useSelector(selectTvSymbolIsLoading);

  useEffect(() => {
    if (!cachedTvConfig) {
      dispatch(fetchTvConfig());
    }
    if (!cachedTvSymbol) {
      dispatch(fetchTvSymbol());
    }
  }, [cachedTvConfig, cachedTvSymbol, dispatch]);

  return { tvConfig: cachedTvConfig, tvSymbol: cachedTvSymbol, isLoading: Boolean(isLoadingConfig || isLoadingSymbol) };
};
