import { Popover, PopoverClose, PopoverContent, PopoverTrigger } from 'src/components';
import { convertNumberToString, DebouncedInput, getColorValue } from './utils';
import * as XLSX from 'xlsx';
import { postScreenerFlaggedColumn, setScreenerFlaggedColumn } from 'src/pages/Screener/Screener.reducer';
import { useDispatch, useSelector } from 'react-redux';
import { EllipsisVerticalIcon } from '@heroicons/react/20/solid';
import { selectScreenerFlaggedColumn } from 'src/pages/Screener/Screener.selector';

export const getCommonPinningStyles = (column, headers) => {
  const isPinned = column.getIsPinned();
  const img = headers?.find((e) => e.accessorKey === 'image');
  if (img)
    return {
      left:
        isPinned === 'left' && column.id === 'image'
          ? '-1px'
          : isPinned === 'left' && column.id === 'ticker'
          ? '4.7rem'
          : undefined,

      position: isPinned
        ? 'sticky'
        : (undefined as 'sticky' | 'static' | 'relative' | 'absolute' | 'fixed' | undefined),
    };
  else
    return {
      left: column.id === 'ticker' ? '0px' : undefined,

      position: isPinned
        ? 'sticky'
        : (undefined as 'sticky' | 'static' | 'relative' | 'absolute' | 'fixed' | undefined),
    };
};

export function TableFilter({ column }) {
  const columnFilterValue = column.getFilterValue();
  const { filterVariant } = column.columnDef.meta ?? {};

  return filterVariant === 'range' ? (
    <div className='reports-filter '>
      <div className=' flex flex-col '>
        <h2 className='text-left '>Filter</h2>
        <div className=' flex w-full '>
          <DebouncedInput
            type='number'
            value={columnFilterValue?.[0] ?? ''}
            onChange={(value) => column.setFilterValue((old) => [value, old?.[1]])}
            placeholder='Min'
          />
          <DebouncedInput
            type='number'
            value={columnFilterValue?.[1] ?? ''}
            onChange={(value) => column.setFilterValue((old) => [old?.[0], value])}
            placeholder='Max'
          />
        </div>
      </div>
    </div>
  ) : (
    <>
      <DebouncedInput
        type='text'
        value={columnFilterValue ?? ''}
        onChange={(value) => column.setFilterValue(value || undefined)}
        placeholder={`Search... `}
        list={column.id + 'list'}
      />
    </>
  );
}

export const exportTableToExcel = (id, tableName) => {
  const table = document.getElementById(id);
  const worksheet = XLSX.utils.table_to_sheet(table);
  const workbook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
  XLSX.writeFile(workbook, `${tableName}.xlsx`);
};
export const downloadFilteredData = (tableRef, filename) => {
  const filteredRows = tableRef.current.getFilteredRows();
  const headers = tableRef.current.getColumnHeaders();
  const jsonData = filteredRows.map((row) => {
    const rowData = {};
    row.getVisibleCells().forEach((cell) => {
      rowData[cell.column.columnDef.header] = cell.getValue();
    });
    return rowData;
  });

  const combinedData = [headers, ...jsonData.map((row) => headers.map((header) => row[header]))];

  const worksheet = XLSX.utils.aoa_to_sheet(combinedData);
  const workbook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(workbook, worksheet, 'Filtered Data');
  XLSX.writeFile(workbook, `${filename}.xlsx`);
};

export function TickerExtraOptions({ row, extraOptionsEnabled = false }) {
  const dispatch = useDispatch();
  const flagged = useSelector(selectScreenerFlaggedColumn);
  if (!extraOptionsEnabled) return null;
  return (
    <Popover placement='right'>
      <PopoverTrigger>
        <EllipsisVerticalIcon className='size-6 cursor-pointer' />
      </PopoverTrigger>
      <PopoverContent>
        <ul className='screener-row-tooltip'>
          <PopoverClose>
            <li
              onClick={() => {
                const cols =
                  flagged.flaggedColumns.length > 0
                    ? [...flagged.flaggedColumns, row.original.ticker]
                    : [row.original.ticker];

                dispatch(setScreenerFlaggedColumn({ cols }));
                dispatch(postScreenerFlaggedColumn({ tickers: cols, flag: true }));
              }}>
              Flag
            </li>
          </PopoverClose>
          <PopoverClose>
            <li
              onClick={() => {
                const cols = flagged.flaggedColumns.filter((e) => e !== row.original.ticker);
                dispatch(setScreenerFlaggedColumn({ cols }));
                dispatch(postScreenerFlaggedColumn({ tickers: [row.original.ticker], flag: false }));
              }}>
              Unflag
            </li>
          </PopoverClose>
          <PopoverClose>
            <li
              onClick={() => {
                dispatch(setScreenerFlaggedColumn({ cols: [] }));
                dispatch(postScreenerFlaggedColumn({ tickers: [], flag: true }));
              }}>
              Unflag All
            </li>
          </PopoverClose>
        </ul>
      </PopoverContent>
    </Popover>
  );
}

export function DataGridTableRow({
  row,
  headersKeyMap,
  extraOptionsEnabled = false,
  flaggedColumnsEnabled = false,
  convertNumber = true,
  headers,
}) {
  const flagged = useSelector(selectScreenerFlaggedColumn);
  const getTrClass = (cell) =>
    headersKeyMap[cell.id.replace('_', ' ').split(' ')[1]]
      ? headersKeyMap[cell.id.replace('_', ' ').split(' ')[1]]?.dataType === 'string'
        ? 'text-left'
        : 'text-right'
      : '';
  const performanceClass = (val) => (val ? 'sentibuy' : 'sentisell');
  return (
    <>
      {row.getVisibleCells().map((cell) =>
        [
          'one_day',
          'five_day',
          'one_month',
          'three_month',
          'six_month',
          'year_to_date',
          'one_year',
          'five_year',
          'three_year',
          'ten_year',
          'max',
        ].some((period) => cell.id.includes(period)) ? (
          <td key={cell.id} className={` ${getTrClass(cell)} ${performanceClass(cell.getValue() > 0)}`}>
            {`${cell.getValue()}%`}
          </td>
        ) : cell.id.toLowerCase().includes('image') ? (
          <td
            key={cell.id}
            style={{
              padding: 0,
              paddingRight: '.2rem',
              ...getCommonPinningStyles(cell.column, headers),
            }}>
            <div
              className={`screener-flagged-image   ${
                flagged.flaggedColumns.includes(row.original.ticker) && flaggedColumnsEnabled ? 'flagged' : ''
              }`}>
              <svg
                xmlns='http://www.w3.org/2000/svg'
                viewBox='0 0 14 12'
                width='14'
                height='12'
                fill='#20c997'
                focusable='false'
                preserveAspectRatio='none'>
                <path d='M14 12l-4-6 4-6H0v12z'></path>
              </svg>

              <img src={cell.getValue()} alt='' className='reports-table-ticker-img' />
            </div>
          </td>
        ) : cell.id.toLowerCase().includes('url') ? (
          <td key={cell.id} className='reports-link-row color-secondary'>
            <a href={cell.getValue()} target='_blank' rel='noreferrer'>
              Link
            </a>
          </td>
        ) : cell.id.toLowerCase().includes('_ticker') && !cell.id.toLowerCase().includes('_ticker_') ? (
          <td
            key={cell.id}
            style={{ ...getCommonPinningStyles(cell.column, headers) }}
            className={`screener-ticker-row-options ${getTrClass(cell)} ${getColorValue(cell.getValue())}`}>
            {convertNumberToString(cell.getValue(), convertNumber)}
            <TickerExtraOptions row={row} extraOptionsEnabled={extraOptionsEnabled} />
          </td>
        ) : (
          <td key={cell.id} className={`${getTrClass(cell)} ${getColorValue(cell.getValue())}`}>
            {convertNumberToString(cell.getValue(), convertNumber)
              ? convertNumberToString(cell.getValue(), convertNumber)
              : 'NA'}
          </td>
        ),
      )}
    </>
  );
}

export const downloadTableData = (tableData, tableName) => {
  // Create a new workbook and worksheet
  const worksheet = XLSX.utils.json_to_sheet(tableData);
  const workbook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(workbook, worksheet, 'Table Data');

  // Generate an Excel file and trigger the download
  XLSX.writeFile(workbook, `${tableName}.xlsx`);
};
