import React from 'react';
import Input from 'src/components/InputElements/input';
import { useForm } from '@tanstack/react-form';
import { Button } from 'src/components';
import { useDispatch, useSelector } from 'react-redux';
import TextField from 'src/components/InputElements/textField.component';
import { DialogContent, DialogClose, DialogTrigger, useDialogContext } from 'src/components/Modal/dialogbox';
import { selectProfile, selectProfileEmail, selectProfileEmailInit } from 'src/pages/Profile/profile.selector';
import { emailUpdateReset, emailUpdate, emailUpdateInitiate, fetchProfile } from 'src/pages/Profile/profile.reducer';
import { yupValidator } from '@tanstack/yup-form-adapter';
import * as Yup from 'yup';
import YupPassword from 'yup-password';
import validator from 'validator';
YupPassword(Yup);

Yup.addMethod(Yup.string, 'emailCheck', function (errorMessage) {
  return this.test('emailCheck', errorMessage, function (value) {
    const { path, createError } = this;
    return (typeof value === 'string' && validator.isEmail(value)) || createError({ path, message: errorMessage });
  });
});
Yup.addMethod(Yup.string, 'mobileCheck', function (errorMessage) {
  return this.test(` `, errorMessage, function (value) {
    const { path, createError } = this;
    if (typeof value === 'string')
      return validator.isMobilePhone(value) || createError({ path, message: errorMessage });
  });
});
export default function UpdateEmail() {
  const dispatch = useDispatch();
  const { setOpen } = useDialogContext();
  const profileData = useSelector(selectProfile);
  const otpId = useSelector(selectProfileEmailInit);
  const emailUpdateStatus = useSelector(selectProfileEmail);
  const emailForm = useForm({
    defaultValues: {
      otp: '',
      email: '',
    },
    onSubmit: async ({ value }) => {
      dispatch(
        emailUpdate({
          email: value.email,
          otp_val: value.otp,
          otp_id: otpId?.data,
        }),
      );
    },
    validatorAdapter: yupValidator(),
  });

  return (
    <>
      <div>
        <div className='profile-pwd-update-btn-wrapper'>
          <Input type='email' name='password' disabled value={profileData?.data?.email} />
          <DialogTrigger
            className='btn profile-pwd-update-btn'
            onClick={() => {
              dispatch(emailUpdateInitiate({}));
            }}>
            Update Email
          </DialogTrigger>
        </div>
      </div>
      <DialogContent className='profile-pwdmodal'>
        <h1 className='profile-pwdmodal-header'>Update Email</h1>
        <form
          onSubmit={(e) => {
            e.preventDefault();
            e.stopPropagation();
            emailForm.handleSubmit();
          }}>
          <div>
            <emailForm.Field
              name='otp'
              validators={{
                onChange: ({ value }) => (!value ? 'Otp is required' : null),
              }}
              children={(field) => <TextField field={field} placeholder='One Time password' />}
            />
            <emailForm.Field
              name='email'
              validators={{
                onChange: Yup.string().emailCheck('Invalid email').required('Required'),
              }}
              children={(field) => <TextField field={field} placeholder='New Email' />}
            />
          </div>
          {emailUpdateStatus?.data ? <h2 className='ca-nodata'>Email Updated Successfully</h2> : ''}
          {emailUpdateStatus?.error ? <h2 className='ca-nodata'>Email Updation Failed</h2> : ''}
          <div className='profile-pwdmodal-btns'>
            {emailUpdateStatus?.data || emailUpdateStatus?.error ? (
              <div
                className='btn-grey btn-rounded btn-lg btn'
                onClick={() => {
                  emailForm.reset();
                  setOpen(false);
                  dispatch(fetchProfile());
                  dispatch(emailUpdateReset());
                }}>
                Close
              </div>
            ) : (
              <>
                <DialogClose className='btn-grey btn-rounded btn-lg btn'>Cancel</DialogClose>
                <Button type='submit' className='btn-primary btn-rounded btn-lg '>
                  {emailUpdateStatus?.isLoading ? <div className='loader' /> : 'Update'}
                </Button>
              </>
            )}
          </div>
        </form>
      </DialogContent>
    </>
  );
}
