import React, { useState, useEffect } from 'react';
import { activeElement } from 'src/lib/utils/utils';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { selectWatchlistDividend, selectWatchlistStockSplit } from '../Watchlist.selector';
import { fetchWatchlistDividend, fetchWatchlistStockSplit } from '../Watchlist.reducer';
import { IWatchlistDividend } from 'src/lib/types/watchlist';
import { useParams } from 'react-router-dom';
import DatePicker from 'react-date-picker';
import { flexRender, getCoreRowModel, useReactTable } from '@tanstack/react-table';
import { data as staticData } from 'src/static-data';
import Skeleton from 'react-loading-skeleton';

export default function CorporateActions() {
  const { ticker } = useParams();
  const [fromDate, setFromDate] = useState<Date | null>();
  const [toDate, setToDate] = useState<Date | null>();
  const dispatch = useDispatch();
  const dividend = useSelector(selectWatchlistDividend);
  const stockSplit = useSelector(selectWatchlistStockSplit);
  const [caData, setCaData] = useState<IWatchlistDividend[] | null>(null);
  const [active, setActive] = useState('Dividends');
  const [duration, setDuration] = useState<{ from: string; to: string }>({
    from: '',
    to: '',
  });
  useEffect(() => {
    dispatch(fetchWatchlistDividend({ ticker }));
    dispatch(fetchWatchlistStockSplit({ ticker }));
  }, [ticker]);
  useEffect(() => {
    if (dividend?.data) setCaData(dividend?.data?.slice(0, 10));
    else setCaData(null);
  }, [dividend]);
  const handleDate = (name, val) => {
    setDuration((prevState) => {
      const updatedDuration = { ...prevState, [name]: val ? val.toISOString() : '' };

      const from = new Date(updatedDuration.from).getTime();
      const to = new Date(updatedDuration.to || val).getTime(); // Handle the 'to' value correctly here

      let filteredData: IWatchlistDividend[] = [];

      if (!updatedDuration.from && !val) {
        filteredData = dividend?.data || [];
      } else if (!updatedDuration.from && val) {
        filteredData = dividend?.data?.filter((item) => new Date(item.date).getTime() < to) || [];
      } else if (updatedDuration.from && !val) {
        filteredData = dividend?.data?.filter((item) => new Date(item.date).getTime() > from) || [];
      } else if (updatedDuration.from && val) {
        filteredData =
          dividend?.data?.filter((item) => {
            const stockDate = new Date(item.date).getTime();
            return stockDate >= from && stockDate <= to;
          }) || [];
      }

      setCaData(filteredData);
      return updatedDuration;
    });
  };

  return (
    <main className='corporateactions'>
      <section className='corporateactions__nav'>
        <div className='corporateactions__Left'>
          <div className='corporateactions__selector'>
            <div className='corporateactions__selectorSub'>
              <ul className=' menu menu-horizontal '>
                <li className={activeElement('Dividends' === active)} onClick={() => setActive('Dividends')}>
                  Dividends
                </li>
                <li className={activeElement('Stock Splits' === active)} onClick={() => setActive('Stock Splits')}>
                  Stock Splits
                </li>
              </ul>
            </div>
          </div>
        </div>
        {active === 'Dividends' ? (
          <div className='corporateactions__Right'>
            <h3>Time Period:</h3>
            <h3>From</h3>
            <DatePicker
              value={fromDate}
              onChange={(value) => {
                setFromDate(value as Date);
                handleDate('from', value);
              }}
              className='if-date-picker'
              shouldCloseCalendar={({ reason }) =>
                ['buttonClick', 'outsideAction', 'select', 'escape'].includes(reason)
              }
            />
            <h3>To</h3>
            <DatePicker
              value={toDate}
              onChange={(value) => {
                setToDate(value as Date);
                handleDate('to', value);
              }}
              className='if-date-picker'
              shouldCloseCalendar={({ reason }) =>
                ['buttonClick', 'outsideAction', 'select', 'escape'].includes(reason)
              }
            />
          </div>
        ) : null}
      </section>

      <section className='corporateActions__table'>
        {active === 'Dividends' ? (
          <Dividends data={caData} isLoading={dividend?.isLoading} />
        ) : (
          <StockSplits data={stockSplit?.data} isLoading={stockSplit?.isLoading} />
        )}
      </section>
    </main>
  );
}

function Dividends({ data, isLoading }) {
  const table = useReactTable({
    columns: staticData.watchlist.dividend,

    data: data ? data : [],

    getCoreRowModel: getCoreRowModel(),
  });
  return (
    <section className='dividends'>
      <h2>Dividend Payout History</h2>
      <div className='dividends__Table'>
        <table>
          <thead>
            {table.getHeaderGroups().map((headerGroup) => (
              <tr key={headerGroup.id} className=''>
                {headerGroup.headers.map((header) => (
                  <th key={header.id}>{flexRender(header.column.columnDef.header, header.getContext())}</th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody>
            {isLoading
              ? Array.from({ length: 10 }).map((_, rowIndex) => (
                  <tr key={rowIndex}>
                    {Array.from({ length: 5 }).map((_, colIndex) => (
                      <td key={colIndex}>
                        <Skeleton />
                      </td>
                    ))}
                  </tr>
                ))
              : table.getRowModel().rows.map((row) => (
                  <tr key={row.id}>
                    {row.getVisibleCells().map((cell) => (
                      <td key={cell.id}>{flexRender(cell.column.columnDef.cell, cell.getContext())}</td>
                    ))}
                  </tr>
                ))}
          </tbody>
        </table>
        {!data ? <h2 className='ca-nodata'>No data is available </h2> : null}
      </div>
    </section>
  );
}

function StockSplits({ data, isLoading }) {
  const stockSplitheaders = [
    { header: 'Date', accessorKey: 'date' },
    {
      header: 'Stock Split',
      accessorFn: (row) => `${row.numerator}:${row.denominator}`,
    },
  ];
  const table = useReactTable({
    columns: stockSplitheaders,
    data: data ? data : [],
    getCoreRowModel: getCoreRowModel(),
  });
  return (
    <section className='stocksplits'>
      <h2>Historical Stock Splits</h2>
      <div className='stocksplits__Table'>
        <table>
          <thead>
            {table.getHeaderGroups().map((headerGroup) => (
              <tr key={headerGroup.id} className=''>
                {headerGroup.headers.map((header) => (
                  <th key={header.id}>{flexRender(header.column.columnDef.header, header.getContext())}</th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody>
            {isLoading
              ? Array.from({ length: 10 }).map((_, rowIndex) => (
                  <tr key={rowIndex}>
                    {Array.from({ length: 5 }).map((_, colIndex) => (
                      <td key={colIndex}>
                        <Skeleton />
                      </td>
                    ))}
                  </tr>
                ))
              : table.getRowModel().rows.map((row) => (
                  <tr key={row.id}>
                    {row.getVisibleCells().map((cell) => (
                      <td key={cell.id}>{flexRender(cell.column.columnDef.cell, cell.getContext())}</td>
                    ))}
                  </tr>
                ))}
          </tbody>
        </table>
        {!data ? <h2 className='ca-nodata'>No data is available </h2> : null}
      </div>
    </section>
  );
}
