import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { connectWebSocket, sendMessage, startCandleProcessing } from 'src/store/Websocket/Websocket.saga'; // Your actions
import { WS_EVENT } from 'src/constants/ws.constant';

export function useWebSocketTicker(ticker: string, shouldProcessCandles: boolean = false) {
  const dispatch = useDispatch();

  const isWebSocketConnected = useSelector((state: any) => state.websocket.connected);
  useEffect(() => {
    if (!ticker) return;
    if (isWebSocketConnected) {
      dispatch(sendMessage({ event: WS_EVENT.WS_EVENT_CLIENT_SUBSCRIBE, data: ticker }));
      if (shouldProcessCandles) dispatch(startCandleProcessing());
    } else {
      dispatch(connectWebSocket());
    }

    return () => {
      if (isWebSocketConnected) {
        dispatch(sendMessage({ event: WS_EVENT.WS_EVENT_CLIENT_UNSUBSCRIBE, data: ticker }));
      } else {
      }
    };
  }, [ticker, isWebSocketConnected, dispatch]);

  return null;
}
