import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { flexRender, getCoreRowModel, useReactTable } from '@tanstack/react-table';
import Skeleton from 'react-loading-skeleton';

export default function SentimentChange({
  type = 'Bullish',
  source = 'Stocktwits',
  fetchDataAction, // Redux action to fetch data (Tre  nding or Biggest Change)
  selector, // Redux selector for the specific data (Trending or Biggest Change)
  headers,
}) {
  const tableData = useSelector(selector);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchDataAction());
  }, [dispatch, fetchDataAction]);
  const table = useReactTable({
    columns: headers,

    data: tableData?.data?.[`${type.toLowerCase()}_${source.toLowerCase()}`] || [],

    getCoreRowModel: getCoreRowModel(),
  });
  const sentimentChangeClass = (val) => (val ? 'sentibuy' : 'sentisell');
  return (
    <section className='trending'>
      <div className='trending-table'>
        <table>
          <thead>
            <tr>
              <th>&nbsp;</th>
              <th colSpan='5' className={`${source === 'Twitter' ? 'sentiment-col-tw' : 'sentiment-col-sw'}`}>
                {source}
              </th>
            </tr>
          </thead>
          <tbody className={`${source === 'Twitter' ? 'sentiment-table-tw' : 'sentiment-table-sw'}`}>
            {table.getHeaderGroups().map((headerGroup) => (
              <tr key={headerGroup.id} className=''>
                {headerGroup.headers.map((header) => (
                  <td key={header.id}>{flexRender(header.column.columnDef.header, header.getContext())}</td>
                ))}
              </tr>
            ))}
            {tableData.isLoading
              ? Array.from({ length: 10 }).map((_, rowIndex) => (
                  <tr key={rowIndex}>
                    {Array.from({ length: 5 }).map((_, colIndex) => (
                      <td key={colIndex}>
                        <Skeleton />
                      </td>
                    ))}
                  </tr>
                ))
              : table.getRowModel().rows.map((row) => (
                  <tr key={row.id}>
                    {row.getVisibleCells().map((cell) => (
                      <>
                        {cell.id.includes('sentimentChange') ? (
                          <td key={cell.id} className={sentimentChangeClass(cell.getValue() > 0)}>
                            {`${cell.getValue().toFixed(2)}%`}
                          </td>
                        ) : (
                          <td key={cell.id}>{flexRender(cell.column.columnDef.cell, cell.getContext())}</td>
                        )}
                      </>
                    ))}
                  </tr>
                ))}
          </tbody>
        </table>
      </div>
    </section>
  );
}
