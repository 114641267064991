import { createSelector } from 'reselect';

const selectProfileData = (state: { profile: any }) => state.profile;
export const selectProfile = createSelector([selectProfileData], (state) => state);
const selectProfilePhotoData = (state: { profilePicUpdate: any }) => state.profilePicUpdate;
export const selectProfilePhoto = createSelector([selectProfilePhotoData], (state) => state);
const selectProfileUpdateData = (state: { postProfile: any }) => state.postProfile;
export const selectProfileUpdate = createSelector([selectProfileUpdateData], (state) => state);

const selectProfilePasswordInitData = (state: { passwordUpdateInitiate: any }) => state.passwordUpdateInitiate;
export const selectProfilePasswordInit = createSelector([selectProfilePasswordInitData], (state) => state);

const selectProfilePasswordData = (state: { passwordUpdate: any }) => state.passwordUpdate;
export const selectProfilePassword = createSelector([selectProfilePasswordData], (state) => state);

const selectProfileEmailInitData = (state: { emailUpdateInitiate: any }) => state.emailUpdateInitiate;
export const selectProfileEmailInit = createSelector([selectProfileEmailInitData], (state) => state);

const selectProfileEmailData = (state: { emailUpdate: any }) => state.emailUpdate;
export const selectProfileEmail = createSelector([selectProfileEmailData], (state) => state);

const selectProfileSubscription = (state: { accountSubscription: any }) => state.accountSubscription;
export const profileSubscriptions = createSelector([selectProfileSubscription], (state) => state);
