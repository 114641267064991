import React, { useEffect, useState } from 'react';
import { DataCardTwo, DataCardThree } from 'src/components/Card/card.component';
import { useDispatch, useSelector } from 'react-redux';
import { data } from 'src/static-data';
import {
  selectMarketEodPrice,
  selectMarketPERatio,
  selectMarketSectorPerformance,
  selectMarketTopStocks,
} from 'src/pages/Markets/markets.selector';
import {
  fetchMarketEodPrice,
  fetchMarketPERatios,
  fetchMarketSectorsPerformance,
  fetchMarketTopStocks,
} from 'src/pages/Markets/markets.reducer';
import { IMarketTopStocks } from 'src/lib/types/markets';
import { activeElement } from 'src/lib/utils/utils';
import { EconomicIndicatorGraph } from '../graph/economicIndicatorGraph';
import Skeleton from 'react-loading-skeleton';

export default function MarketSidebar() {
  const [itemsToShowStock, setItemsToShowStock] = useState(7);
  const [stockType, setStockType] = useState('active');
  const [timelimit, setTimeLimit] = useState('Month1');
  const [activeTicker, setActiveTicker] = useState('');
  const dispatch = useDispatch();
  const eodData = useSelector(selectMarketEodPrice);
  const peRatio = useSelector(selectMarketPERatio);
  const topStocks = useSelector(selectMarketTopStocks);
  const sectorPerformance = useSelector(selectMarketSectorPerformance);

  const handleShowMoreStock = () => {
    setItemsToShowStock((prev) => prev + 10);
  };

  useEffect(() => {
    dispatch(fetchMarketSectorsPerformance());
    dispatch(fetchMarketPERatios());
    dispatch(fetchMarketTopStocks());
  }, []);

  const tfHandler = (tf: string) => {
    setTimeLimit(tf);
    dispatch(fetchMarketEodPrice({ ticker: activeTicker, time_limit: tf }));
  };

  const handleStockTypeChange = (type: string) => {
    setStockType(type);
    if (topStocks?.data && topStocks.data[type]?.length > 0) {
      const firstTicker = topStocks.data[type][0].symbol;
      setActiveTicker(firstTicker);
      dispatch(fetchMarketEodPrice({ ticker: firstTicker, time_limit: timelimit }));
    }
  };
  useEffect(() => {
    handleStockTypeChange('active');
  }, [topStocks]);
  return (
    <>
      <div className='m-sidebar'>
        <div className='sidebar1'>
          <div className={`${itemsToShowStock > 10 ? 'overflow-y' : ''}`}>
            <div className='markets-sidebar-charts-header'>
              <h2>Stocks</h2>
              <h2>NYSE</h2>
            </div>
            <ul className=' menu menu-horizontal '>
              <li
                className={`${activeElement(stockType === 'active')} `}
                onClick={() => handleStockTypeChange('active')}>
                Active
              </li>
              <li
                className={`${activeElement(stockType === 'losers')} `}
                onClick={() => handleStockTypeChange('losers')}>
                Losers
              </li>
              <li
                className={`${activeElement(stockType === 'gainers')} `}
                onClick={() => handleStockTypeChange('gainers')}>
                Gainers
              </li>
            </ul>

            {topStocks?.data && topStocks?.data[stockType] ? (
              <>
                {eodData?.data?.length > 0 ? (
                  <EconomicIndicatorGraph
                    data={eodData?.data}
                    color='rgba(0, 122, 255, 1)'
                    height='150px'
                    dateKey='datetime'
                    valueKey='close'
                    dateFormat=' '
                  />
                ) : (
                  ''
                )}
                <ul className='menu menu-horizontal'>
                  {data.marketTfmap.map(({ full, short }) => (
                    <li key={full} className={`${activeElement(timelimit === full)} `} onClick={() => tfHandler(full)}>
                      {short}
                    </li>
                  ))}
                </ul>

                <div className='markets__StocksDetails '>
                  {topStocks?.data[stockType]
                    ?.slice(0, itemsToShowStock)
                    .map((item: IMarketTopStocks, index: number) => (
                      <div
                        className='markets__dataCardOne__container'
                        key={index}
                        onClick={() => {
                          setActiveTicker(item.symbol);
                          dispatch(fetchMarketEodPrice({ ticker: item.symbol, time_limit: timelimit }));
                        }}>
                        <div
                          className={`${
                            activeTicker === item.symbol ? 'bg-[#25334D]' : ''
                          } markets__dataCardOne__list`}>
                          <div className='markets__dataCardOne__Left'>
                            <div className='markets__dataCardOne__LeftSecond'>
                              <p className='font-[400]'>{item.symbol}</p>
                              <p title={item.name}>{item.name}</p>
                            </div>
                          </div>
                          <div className='markets__dataCardOne__Right'>
                            <p>{item.price}</p>

                            {item.change >= 0 ? (
                              <p style={{ color: '#00CF93' }}>
                                {item.change} ({item.changesPercentage.toFixed(2)}%)
                              </p>
                            ) : (
                              <p style={{ color: '#FF3540' }}>
                                {item.change} ({item.changesPercentage.toFixed(2)}%)
                              </p>
                            )}
                          </div>
                        </div>
                      </div>
                    ))}
                  {topStocks?.data &&
                  topStocks?.data[stockType] &&
                  itemsToShowStock < topStocks?.data[stockType].length ? (
                    <div className='markets__sectionTwoMore' onClick={handleShowMoreStock}>
                      More
                    </div>
                  ) : (
                    ''
                  )}
                </div>
              </>
            ) : (
              ''
            )}
          </div>
        </div>
        <div className='sidebar2'>
          {sectorPerformance?.isLoading ? (
            <Skeleton className='dataCardTwo' />
          ) : (
            <DataCardTwo data={sectorPerformance?.data} title='Sector Performance' />
          )}
        </div>
        <div className='sidebar3'>
          {peRatio?.isLoading ? (
            <Skeleton className='dataCardOne' />
          ) : (
            <DataCardThree data={peRatio?.data} title='PE' />
          )}
        </div>
      </div>
    </>
  );
}
