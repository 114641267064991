import React, { useState } from 'react';
import { FinancialChart } from 'src/components';
import { FinancialDataType, StatementTf } from 'src/lib/enum/enum';
import { activeElement } from 'src/lib/utils/utils';
import Skeleton from 'react-loading-skeleton';

export function FinancialGraphComponent({ data, isLoading }) {
  const [graphTf, setGraphTf] = useState(StatementTf.Annual);
  const [statementTypeGraph, setStatementTypeGraph] = useState(FinancialDataType.Income);
  return (
    <section>
      <h1 className='financials-section-header'>Consolidated Financial Statements</h1>

      <div className='financials-graphcontianer'>
        <div className='financials-navbar'>
          <ul className=' menu menu-horizontal '>
            <li
              className={activeElement(FinancialDataType.Income === statementTypeGraph)}
              onClick={() => setStatementTypeGraph(FinancialDataType.Income)}>
              Income Statement
            </li>
            <li
              className={activeElement(FinancialDataType.BalanceSheet === statementTypeGraph)}
              onClick={() => setStatementTypeGraph(FinancialDataType.BalanceSheet)}>
              Balance Sheet
            </li>
            <li
              className={activeElement(FinancialDataType.Cashflow === statementTypeGraph)}
              onClick={() => setStatementTypeGraph(FinancialDataType.Cashflow)}>
              Cashflow
            </li>
          </ul>
          <ul className=' menu menu-horizontal '>
            <li
              className={activeElement(StatementTf.Annual === graphTf)}
              onClick={() => setGraphTf(StatementTf.Annual)}>
              Annual
            </li>
            <li
              className={activeElement(StatementTf.Quarter === graphTf)}
              onClick={() => setGraphTf(StatementTf.Quarter)}>
              Quarter
            </li>
          </ul>
        </div>
        <div>
          {isLoading ? (
            <Skeleton height='40rem' />
          ) : data?.[`${statementTypeGraph}${graphTf}`]?.length > 0 ? (
            <FinancialChart
              data={data?.[`${statementTypeGraph}${graphTf}`]}
              tf={graphTf}
              dataType={statementTypeGraph}
            />
          ) : (
            <div className='min-h-[40rem] w-full'>
              <h2 className='ca-nodata'>No Data Available</h2>
            </div>
          )}
        </div>
      </div>
    </section>
  );
}
