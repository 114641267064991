import React, { useMemo } from 'react';
import ReactEcharts from 'echarts-for-react';
import { convertNumberToString } from 'src/lib/utils/utils';
import { IEconomicIndicatorData } from 'src/lib/types/markets';

export interface IFinancialgraph {
  data: IEconomicIndicatorData[];
  color: string;
  height?: string;
  dateKey: string;
  valueKey: string;
  dateFormat?: string; // New prop to handle date format
}

export const EconomicIndicatorGraph = ({
  data,
  color,
  height = '250px',
  dateKey,
  valueKey,
  dateFormat = 'T',
}: IFinancialgraph) => {
  // Memoized x-axis data with dynamic split for the date
  const xAxisData = useMemo(
    () => data.map((item: IEconomicIndicatorData) => item[dateKey].split(dateFormat)[0]),
    [data, dateKey, dateFormat],
  );

  // Memoized chart options
  const chartOptions = useMemo(
    () => ({
      xAxis: {
        type: 'category',
        data: xAxisData,
        axisLine: {
          lineStyle: {
            color: '#ffffff', // White axis line
          },
        },
        axisLabel: {
          color: '#ffffff', // White label text
        },
        splitLine: {
          show: false, // Hide X-axis grid lines
        },
      },
      yAxis: {
        type: 'value',
        min: 'dataMin',
        max: 'dataMax',
        axisLine: {
          show: true, // Ensure Y-axis line is shown
          lineStyle: {
            color: '#ffffff', // White axis line
          },
        },
        axisLabel: {
          color: '#ffffff', // White label text
          formatter: convertNumberToString,
        },
        splitLine: {
          show: false, // Hide Y-axis grid lines
        },
      },
      grid: {
        left: dateFormat === 'T' ? 20 : 0,
        right: 20,
        top: 30,
        bottom: 30,
        containLabel: true, // Ensure labels are not cut off
      },
      tooltip: {
        trigger: 'axis',
        formatter: (params: any) => {
          const dataPoint = params[0].data;
          const date = xAxisData[params[0].dataIndex];
          const formattedDate = new Date(date).toLocaleDateString();
          const formattedValue = convertNumberToString(dataPoint); // Format value like axis

          return `Date: ${formattedDate}<br/><span style="color: ${color}">Value: ${formattedValue}</span>`;
        },
        axisPointer: {
          type: 'shadow',
        },
        backgroundColor: '#091A3A',
        borderColor: '#25334D',
        textStyle: {
          color: '#ffffff',
        },
      },
      series: [
        {
          type: 'line',
          showSymbol: false, // Hide symbols (dots)
          color: color,
          areaStyle: {
            color: color,
          },
          data: data.map((item: any) => item[valueKey]),
        },
      ],
    }),
    [xAxisData, data, color, valueKey],
  );

  // Render the chart using ReactEcharts
  return <ReactEcharts option={chartOptions} style={{ width: '100%', height: height }} />;
};
