// src/sagas/academyMetaDataSaga.ts
import { put, takeLatest } from 'redux-saga/effects';
import {
  fetchClubMetaData,
  fetchClubMetaDataSuccess,
  fetchClubMetaDataFailure,
  fetchClubContent,
  fetchClubContentSuccess,
  fetchClubContentFailure,
  fetchClubDetail,
  fetchClubDetailSuccess,
  fetchClubDetailFailure,
  postClubLike,
  postClubLikeSuccess,
  postClubLikeFailure,
  postClubComment,
  postClubCommentSuccess,
  postClubCommentFailure,
  postClubPost,
  postClubPostSuccess,
  postClubPostFailure,
  postClubCommentDeleteSuccess,
  postClubCommentDeleteFailure,
  postClubCommentDelete,
} from './club.reducer';
import {
  getClubMetaData,
  getClubContentCards,
  getClubDetails,
  postClubPosts,
  postClubComments,
  postClubLikes,
  postClubDeleteComment,
} from 'src/repositories/club/club';
import { IError } from 'src/lib/types/error';

function* fetchClubMetaDataSaga(action) {
  try {
    const data = yield getClubMetaData(action.payload).then((response) => response);
    yield put(fetchClubMetaDataSuccess(data.data.data));
  } catch (error) {
    yield put(fetchClubMetaDataFailure(error as IError));
  }
}

export function* watchClubMetaDataSaga() {
  yield takeLatest(fetchClubMetaData.type, fetchClubMetaDataSaga);
}

function* fetchClubContentSaga(action) {
  try {
    const data = yield getClubContentCards(action.payload).then((response) => response);
    yield put(fetchClubContentSuccess(data.data.data));
  } catch (error) {
    yield put(fetchClubContentFailure(error as IError));
  }
}

export function* watchClubContentSaga() {
  yield takeLatest(fetchClubContent.type, fetchClubContentSaga);
}

function* fetchClubDetailSaga(action) {
  try {
    const data = yield getClubDetails(action.payload).then((response) => response);
    yield put(fetchClubDetailSuccess(data.data.data));
  } catch (error) {
    yield put(fetchClubDetailFailure(error as IError));
  }
}

export function* watchClubDetailSaga() {
  yield takeLatest(fetchClubDetail.type, fetchClubDetailSaga);
}

function* postClubLikeSaga(action) {
  try {
    const data = yield postClubLikes(action.payload).then((response) => response);
    yield put(postClubLikeSuccess(data.data.data));
  } catch (error) {
    yield put(postClubLikeFailure(error as IError));
  }
}

export function* watchClubLikeSaga() {
  yield takeLatest(postClubLike.type, postClubLikeSaga);
}

function* postClubCommentSaga(action) {
  try {
    const data = yield postClubComments(action.payload).then((response) => response);
    yield put(postClubCommentSuccess(data.data.data));
  } catch (error) {
    yield put(postClubCommentFailure(error as IError));
  }
}

export function* watchClubCommentSaga() {
  yield takeLatest(postClubComment.type, postClubCommentSaga);
}

function* postClubPostSaga(action) {
  try {
    const data = yield postClubPosts(action.payload).then((response) => response);
    yield put(postClubPostSuccess(data.data.data));
  } catch (error) {
    yield put(postClubPostFailure(error as IError));
  }
}

export function* watchClubPostSaga() {
  yield takeLatest(postClubPost.type, postClubPostSaga);
}

function* postClubCommentDeleteSaga(action) {
  try {
    const data = yield postClubDeleteComment(action.payload).then((response) => response);
    yield put(postClubCommentDeleteSuccess(data.data.data));
  } catch (error) {
    yield put(postClubCommentDeleteFailure(error as IError));
  }
}

export function* watchClubCommentDeleteSaga() {
  yield takeLatest(postClubCommentDelete.type, postClubCommentDeleteSaga);
}
