import React from 'react';
import { useParams } from 'react-router-dom';
import { useWatchlistData } from 'src/hooks/useWatchlistData'; // Import the custom hook
import { formattedDate, convertNumberToString } from 'src/lib/utils/utils';
import Avatar from 'react-avatar';
export function WatchlistHeader() {
  const { ticker } = useParams();
  const { lastNonNullDataSource, imageSrc } = useWatchlistData(ticker!);
  return (
    <section className='watchlistheader'>
      <section className='watchlistheader__Container'>
        <section className='watchlistheader__Top'>
          <div className='watchlistheader__Left'>
            <Avatar src={imageSrc} name={lastNonNullDataSource?.name} round={true} className='sbimg' />
            <span>{lastNonNullDataSource?.name || 'NA'}</span>
          </div>

          <div className='watchlistheader__Right'>
            <div className='watchlistheader__Right--L'>
              <div className='watchlistheader__Right--SubHeader'>
                <h1>
                  {lastNonNullDataSource?.price?.toFixed(2)} <span>USD</span>
                </h1>
                <h3 className={lastNonNullDataSource?.change > 0 ? 'growth' : 'nogrowth'}>
                  {lastNonNullDataSource?.change} ({lastNonNullDataSource?.changesPercentage}%)
                </h3>
              </div>
            </div>
            <div className='watchlistheader__Right--R'>
              <p>{ticker}</p>
              <Avatar src={imageSrc} name={lastNonNullDataSource?.name} size='2rem' round={true} />

              <p>{lastNonNullDataSource?.exchange}</p>
            </div>
          </div>
        </section>
        <hr />
        <section className='watchlistheader__Bottom'>
          {lastNonNullDataSource?.earningsAnnouncement && (
            <div className='watchlistheader__BLeft'>
              <h3>UPCOMING EARNINGS</h3>
              <h1>{formattedDate(lastNonNullDataSource?.earningsAnnouncement)}</h1>
            </div>
          )}
          <div className='watchlistheader__BRight'>
            {lastNonNullDataSource?.eps && (
              <div className='watchlistheader__Card'>
                <h3>EPS</h3>
                <h1>{lastNonNullDataSource?.eps}</h1>
              </div>
            )}
            {lastNonNullDataSource?.marketCap && (
              <div className='watchlistheader__Card'>
                <h3>MARKET CAP</h3>
                <h1>{convertNumberToString(lastNonNullDataSource?.marketCap)}</h1>
              </div>
            )}
            {lastNonNullDataSource?.pe && (
              <div className='watchlistheader__Card'>
                <h3>P/E</h3>

                <h1>{lastNonNullDataSource?.pe}</h1>
              </div>
            )}
          </div>
        </section>
      </section>
    </section>
  );
}
