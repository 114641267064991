import { createSlice } from '@reduxjs/toolkit';
import { IUser } from 'src/lib/types/user';
const INITIAL_STATE: IUser = {
  accessToken: '',
  accessTokenExp: 0,
  refreshToken: '',
  refreshTokenExp: 0,
  userName: '',
  subscriptionTier: [],
  riskProfile: '',
  user_avatar: '',
  imageKey: 1,
  id: '',
};
export const userSlice = createSlice({
  name: 'user',
  initialState: INITIAL_STATE,
  reducers: {
    setCurrentUser(state, action) {
      state.accessToken = action.payload.accessToken;
      state.refreshToken = action.payload.refreshToken;
      state.accessTokenExp = action.payload.accessTokenExp;
      state.refreshTokenExp = action.payload.refreshTokenExp;
      state.userName = action.payload.userName;
      state.riskProfile = action.payload.riskProfile;
      state.subscriptionTier = action.payload.subscriptionTier;
      state.user_avatar = action.payload.user_avatar;
      state.id = action.payload.id;
    },
    clearCurrentUser(state) {
      return INITIAL_STATE;
    },
    setCurrentUserImage(state, action) {
      state.user_avatar = action.payload.user_avatar;
      state.imageKey = action.payload.imageKey;
    },
  },
});

export const { setCurrentUser, clearCurrentUser, setCurrentUserImage } = userSlice.actions;
export const userReducer = userSlice.reducer;
