import React, { useState } from 'react';
import { activeElement } from 'src/lib/utils/utils';
import { FinancialTrendGraph } from 'src/components';
import { StatementTf } from 'src/lib/enum/enum';
import Skeleton from 'react-loading-skeleton';

export function FinancialTredGraphComponent({ data, isLoading }) {
  const [growthTf, setGrowthTf] = useState(StatementTf.Annual);

  return (
    <section>
      <h1 className='financials-section-header'>FINANCIAL TREND</h1>
      <div className='financials-graphcontianer'>
        <div className='financials-navbar'>
          <ul className=' menu menu-horizontal '>
            <li
              className={activeElement(StatementTf.Annual === growthTf)}
              onClick={() => setGrowthTf(StatementTf.Annual)}>
              Annual
            </li>
            <li
              className={activeElement(StatementTf.Quarter === growthTf)}
              onClick={() => setGrowthTf(StatementTf.Quarter)}>
              Quarter
            </li>
          </ul>
        </div>
        <div>
          {isLoading ? (
            <Skeleton height='40rem' width='100%' />
          ) : data?.[`finanical_statement_growth_${growthTf}`]?.length > 0 ? (
            <FinancialTrendGraph data={data?.[`finanical_statement_growth_${growthTf}`]} tf={growthTf} />
          ) : (
            <div className='min-h-[40rem] w-full'>
              <h2 className='ca-nodata'>No Data Available</h2>
            </div>
          )}
        </div>
      </div>
    </section>
  );
}
