/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { ArrowRightIcon } from '@heroicons/react/20/solid';
import { SupportsFooter, SupportsHeader, VideoCard } from 'src/components';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import { useSelector } from 'react-redux';
import { selectSupportFaq, selectSupportSection, selectSupportVideos } from './support.selector';
import { useDispatch } from 'react-redux';
import { fetchSupportFaq, fetchSupportSection, fetchSupportVideo } from './support.reducer';

interface Section {
  id: any;
}

interface Video {
  id: React.Key | null | undefined;
}

interface FAQ {
  id: any;
  header: string;
  section: string;
}

interface SupportLinksProps {
  data: Record<string, FAQ[]>;
  sectionIds: any[];
}
const responsive = {
  desktop: {
    breakpoint: { max: 2500, min: 1367 },
    items: 3,
  },
  largeTablet: {
    breakpoint: { max: 1366, min: 1281 },
    items: 2,
  },
  nestHubLarge: {
    breakpoint: { max: 1280, min: 1025 },
    items: 2,
  },
  nestHubMedium: {
    breakpoint: { max: 1024, min: 913 },
    items: 2,
  },
  nestHubSmall: {
    breakpoint: { max: 912, min: 769 },
    items: 2,
  },
  tablet: {
    breakpoint: { max: 768, min: 465 },
    items: 1,
    centerMode: true, // Ensures centering when there is only one item
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
    centerMode: true, // Ensures centering when there is only one item
  },
};
function Support() {
  const [sectionIds, setSectionIds] = useState<any[]>([]);
  const [fetchSections, setFetchSections] = useState<string>('');
  const [fetchSectionsIndex, setFetchSectionsIndex] = useState<number>(6);
  const dispatch = useDispatch();
  const faq = useSelector(selectSupportFaq);
  const videos = useSelector(selectSupportVideos);
  const section = useSelector(selectSupportSection);

  useEffect(() => {
    dispatch(fetchSupportSection({}));
    dispatch(fetchSupportVideo({}));
  }, []);
  useEffect(() => {
    if (section?.data) {
      const ids = section?.data.map((item: Section) => item.id);
      setSectionIds(ids);
      const ii = ids.slice(0, fetchSectionsIndex).join(',');
      setFetchSections(ii);
      dispatch(fetchSupportFaq({ section: ii }));
    }
  }, [section?.data]);

  const fetchMoreItems = () => {
    setFetchSections(sectionIds.slice(0, fetchSectionsIndex + 6).join(','));
    setFetchSectionsIndex((prevIndex) => prevIndex + 6);
    const ids = sectionIds.slice(0, fetchSectionsIndex).join(',');
    setFetchSections(ids);
    dispatch(fetchSupportFaq({ section: ids }));
  };

  return (
    <div className='support navbar-fix'>
      <SupportsHeader />
      <main>
        <section className='supports-video-reco'>
          <h1>Short Videos</h1>
          {videos?.data ? (
            <Carousel
              responsive={responsive}
              infinite={true}
              autoPlay={false}
              arrows={true}
              showDots={false}
              keyBoardControl={true}
              containerClass='carousel-container'
              itemClass='carousel-item' // Apply a class to each item
            >
              {videos?.data.map((e: Video) => (
                <VideoCard item={e} key={e.id} />
              ))}
            </Carousel>
          ) : null}
        </section>

        <h2>
          FAQs <span>(Frequently Asked Question)</span>
        </h2>
        <h1>To create a ticket, select a relevant topic</h1>

        {faq?.data && faq?.data.length !== 0 && <SupportLinks data={faq?.data} sectionIds={sectionIds} />}

        {sectionIds.length > fetchSectionsIndex ? (
          <span className='support-more-items' onClick={fetchMoreItems}>
            more articles...
          </span>
        ) : null}
        <SupportsFooter />
      </main>
    </div>
  );
}

export default Support;

function SupportLinks({ data, sectionIds }: SupportLinksProps) {
  return (
    <div className='support-faq'>
      {data
        ? Object.entries(data)?.map((item, index) => (
            <div className='support-faq-item' key={index}>
              <h4>{item[1][0]?.section}</h4>
              {item[1]?.map((element: FAQ, i: React.Key | null | undefined) => (
                <Link to={`/support/faq/${element?.id}`} key={i}>
                  <ArrowRightIcon />
                  <p>{element?.header}</p>
                </Link>
              ))}
            </div>
          ))
        : ''}
    </div>
  );
}
