import { postTvBars } from 'src/repositories/tv/tv.ts';
import { subscribeOnStream, unsubscribeFromStream } from './streaming.js';
const lastBarsCache = new Map();

export const createDatafeed = (config, symbols) => ({
  onReady: (callback) => {
    const configurationData = {
      supported_resolutions: config.supported_resolutions,
      exchanges: config.exchanges,
      symbols_types: config.symbols_types,
    };
    setTimeout(() => callback(configurationData));
  },
  searchSymbols: (userInput, exchange, symbolType, onResultReadyCallback) => {
    const newSymbols = symbols?.filter((symbol) => {
      const isExchangeValid = exchange === '' || symbol.exchange === exchange;
      const isFullSymbolContainsInput = symbol.full_name.toLowerCase().includes(userInput.toLowerCase());
      const isSymbolContainsInput = symbol.symbol.toLowerCase().includes(userInput.toLowerCase());
      return isExchangeValid && (isFullSymbolContainsInput || isSymbolContainsInput);
    });
    onResultReadyCallback(newSymbols);
  },
  resolveSymbol: (symbolName, onSymbolResolvedCallback, onResolveErrorCallback) => {
    const symbolItem = symbols.find(({ symbol, full_name }) => symbol === symbolName || full_name === symbolName);
    if (!symbolItem) {
      onResolveErrorCallback('cannot resolve symbol');
      return;
    }
    const symbolInfo = {
      ticker: symbolItem.full_name,
      name: symbolItem.symbol,
      description: symbolItem.description,
      type: symbolItem.type,
      session: symbolItem.session,
      timezone: 'America/New_York',
      exchange: symbolItem?.exchange,
      minmov: 1,
      pricescale: 10000,
      has_intraday: true,
      intraday_multipliers: symbolItem.intraday_multipliers,
      visible_plots_set: 'ohlcv',
      has_weekly_and_monthly: false,
      has_seconds: true,
      supported_resolutions: [...symbolItem.supported_resolution],
      volume_precision: 2,
      data_status: 'endofday',
    };
    onSymbolResolvedCallback(symbolInfo);
  },
  getBars: async (symbolInfo, resolution, periodParams, onHistoryCallback, onErrorCallback) => {
    const { from, to, firstDataRequest } = periodParams;
    let delta = 0;
    if (resolution === '1D') {
      delta = 86400 * 100;
    } else {
      delta = 3600 * 100;
    }
    const adjustedFrom = from - delta;

    try {
      const result = await postTvBars({
        timeframe: resolution,
        from: adjustedFrom,
        to,
        mkt_type: symbolInfo.type,
        ticker: symbolInfo.name,
      });

      if (result.data.data.length === 0) {
        onHistoryCallback([], { noData: true }); // Set noData to true if there are no bars
        return;
      }

      const bars = result.data.data
        .filter((bar) => Date.parse(bar.datetime) >= adjustedFrom * 1000 && Date.parse(bar.datetime) < to * 1000)
        .map((bar) => ({
          time: Date.parse(bar.datetime),
          low: bar.low,
          high: bar.high,
          open: bar.open,
          close: bar.close,
          volume: bar.volume,
        }));

      if (firstDataRequest && bars.length > 0) {
        lastBarsCache.set(symbolInfo.symbol, { ...bars[bars.length - 1] });
      }

      onHistoryCallback(bars);
    } catch (err) {
      onErrorCallback(err);
    }
  },

  subscribeBars: (symbolInfo, resolution, onRealtimeCallback, subscriberUID, onResetCacheNeededCallback) => {
    subscribeOnStream(
      symbolInfo,
      resolution,
      onRealtimeCallback,
      subscriberUID,
      onResetCacheNeededCallback,
      lastBarsCache.get(symbolInfo.symbol),
    );
  },
  unsubscribeBars: (subscriberUID) => {
    unsubscribeFromStream(subscriberUID);
  },
});
