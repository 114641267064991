import React, { useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Button, SupportsHeader } from 'src/components';
import { useDispatch, useSelector } from 'react-redux';
import { selectSupportDetails } from './support.selector';
import { fetchSupportDetail } from './support.reducer';
import Skeleton from 'react-loading-skeleton';
function FaqDetails() {
  const { id } = useParams();
  const dispatch = useDispatch();
  const details = useSelector(selectSupportDetails);

  useEffect(() => {
    dispatch(fetchSupportDetail({ id }));
    setTimeout(() => {
      window.scrollTo({
        top: 250,
        left: 100,
        behavior: 'smooth',
      });
    }, 200);
  }, [id]);
  return (
    <main className='navbar-fix'>
      <SupportsHeader />
      <section className='details-container'>
        {
          <div className='support-faq-details'>
            {details?.isLoading ? (
              <div className='support-faq-details'>
                <Skeleton height='2.1rem' />
                <Skeleton height='2.1rem' />
                <Skeleton height='2.1rem' />
              </div>
            ) : (
              <>
                <h1>{details?.data?.header}</h1>
                <p>{details?.data?.content}</p>
              </>
            )}
            <span className='support-faq-help'>Still need help?</span>
            <Link to='/support/ticket'>
              <Button className='btn-primary btn-lg' label='Create a ticket' />
            </Link>
            <h3 className='support-related-topic-header'>RELATED TOPIC</h3>
            {details?.isLoading ? (
              <>
                <Skeleton height='2.1rem' />
                <Skeleton height='2.1rem' />
              </>
            ) : (
              details?.data?.related_topics?.map(
                (
                  item: {
                    header: string;
                    id: string;
                  },
                  i: React.Key | null | undefined,
                ) => (
                  <Link className='support-faq-topic' to={`/support/faq/${item?.id}`}>
                    <p key={i}>{item.header}</p>
                  </Link>
                ),
              )
            )}
          </div>
        }
      </section>
    </main>
  );
}

export default FaqDetails;
