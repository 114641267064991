import React, { useEffect, useState, useRef, forwardRef, useImperativeHandle } from 'react';
import {
  flexRender,
  getCoreRowModel,
  useReactTable,
  getSortedRowModel,
  getPaginationRowModel,
  getFilteredRowModel,
} from '@tanstack/react-table';
import { TableFilter, DataGridTableRow } from 'src/lib/utils/tableUtils';
import { BarsArrowDownIcon, BarsArrowUpIcon, ArrowDownIcon, ArrowUpIcon, XMarkIcon } from '@heroicons/react/20/solid';
import { useSelector } from 'react-redux';
import { getCommonPinningStyles } from 'src/lib/utils/tableUtils';
import {
  Pagination,
  Tooltip,
  TooltipTrigger,
  TooltipContent,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverClose,
  Select,
} from 'src/components';
import { selectHeatmapState } from 'src/pages/Heatmap/heatmap.selector';
import { selectScreenerFilter } from 'src/pages/Screener/Screener.selector';
const pageNumber = [10, 20, 50, 100];

const ScreenerTable = forwardRef(({ dataKey, headers, headersLabelMap, headersKeyMap, advancedFilterActive }, ref) => {
  const tableRef = useRef(null);
  // Expose methods to the parent component

  const screenerData = useSelector(selectHeatmapState);
  const filterData = useSelector(selectScreenerFilter);
  const [columnOrder, setColumnOrder] = useState(headers.map((column) => column.accessorKey));

  const [columnPinning, setColumnPinning] = useState({
    left: ['image', 'ticker'],
    right: [],
  });
  const [sorting, setSorting] = useState([]);
  const [columnFilters, setColumnFilters] = useState([]);

  const [itemCount, setItemCount] = useState(10);
  const table = useReactTable({
    columns: headers,
    data: screenerData?.data ? (dataKey ? screenerData?.data[dataKey] : screenerData?.data) : [],
    getPaginationRowModel: getPaginationRowModel(),
    onSortingChange: setSorting,
    getSortedRowModel: getSortedRowModel(),
    onColumnFiltersChange: setColumnFilters,
    getFilteredRowModel: getFilteredRowModel(),
    onColumnPinningChange: setColumnPinning,
    state: {
      columnOrder,
      sorting,
      columnFilters,
      columnPinning,
    },

    onColumnOrderChange: setColumnOrder,
    getCoreRowModel: getCoreRowModel(),
  });
  const setNumberOfItems = (size) => {
    table.setPageSize(size);
  };
  const handleScroll = () => {
    if (tableRef.current) {
      tableRef.current.scrollIntoView({
        block: 'start',
      });
    }
  };

  useEffect(() => {
    if (advancedFilterActive && filterData.data) {
      const filteredTickers = filterData.data.map((e) => e.ticker);
      setColumnFilters([{ id: 'ticker', value: filteredTickers }]);
    }
    if (!advancedFilterActive) {
      setColumnFilters([]);
    }
  }, [filterData.data, advancedFilterActive]);
  useImperativeHandle(ref, () => ({
    getFilteredRows: () => table.getFilteredRowModel().rows,
    getColumnHeaders: () => headers.map((column) => column.header), // Adjust as needed
  }));
  useEffect(() => {
    table.resetColumnFilters();
    table.resetSorting();
  }, [headers]);
  return (
    <>
      <div className='screener-table '>
        <div className='reports-table-container'>
          <table className='table table-pin-rows  ' id='screener-table'>
            {screenerData?.error ? (
              <tbody className='td-error'>
                <div className='td-error-div'>Internal Server Error</div>
              </tbody>
            ) : (
              <>
                <thead className=''>
                  {table.getHeaderGroups().map((headerGroup) => (
                    <tr key={headerGroup.id} className=''>
                      {headerGroup.headers.map((header) => (
                        <TableHeaderRow
                          header={header}
                          table={table}
                          headersLabelMap={headersLabelMap}
                          headers={headers}
                        />
                      ))}
                    </tr>
                  ))}
                </thead>
                <tbody>
                  <span ref={tableRef} />
                  {table.getRowModel().rows.map((row) => (
                    <tr key={row.id}>
                      <DataGridTableRow
                        headers={headers}
                        row={row}
                        headersKeyMap={headersKeyMap}
                        extraOptionsEnabled={true}
                        flaggedColumnsEnabled={true}
                      />
                    </tr>
                  ))}
                </tbody>
              </>
            )}
          </table>
        </div>

        <div className='reports-paginate '>
          <div className='reports-page-items '>
            <Select
              options={pageNumber}
              selected
              onChange={(val) => {
                setNumberOfItems(val);
                setItemCount(val);
              }}
            />
            <div className='reports-page-items-info '>
              <span>
                {itemCount < table?.getFilteredRowModel()?.rows?.length
                  ? itemCount
                  : table?.getFilteredRowModel()?.rows?.length}
              </span>{' '}
              of <span>{table?.getFilteredRowModel()?.rows?.length}</span> items
            </div>
          </div>
          {table.getPageCount() > 0 ? (
            <div className='reports-page-selection'>
              <Pagination
                totalPages={table.getPageCount() || 1}
                onPageChange={(value) => {
                  table.setPageIndex(value - 1);
                  handleScroll();
                }}
                dependency={[screenerData?.data, dataKey]}
              />
            </div>
          ) : null}
        </div>
      </div>
    </>
  );
});

function TableHeaderRow({ header, table, headersLabelMap, headers }) {
  const { column } = header;

  const getToolTipClass = (sorting, key) =>
    `reports-tooltip-option  ${sorting === key ? 'reports-tooltip-option-active' : ''}`;
  const getTrClass = (header) =>
    headersLabelMap[flexRender(header.column.columnDef.header, header.getContext())]
      ? headersLabelMap[flexRender(header.column.columnDef.header, header.getContext())]?.dataType === 'string'
        ? 'justify-start items-start'
        : 'justify-end items-end'
      : '';
  return (
    <th
      style={{ ...getCommonPinningStyles(column, headers) }}
      key={header.id}
      className={`${header.column.getIsSorted() ? 'color-secondary' : ''}`}>
      <Popover>
        <PopoverTrigger className='w-full'>
          {!header.isPlaceholder && !header.column.id.includes('image') ? (
            <>
              <Tooltip>
                <TooltipTrigger
                  className={`reports-table-header ${getTrClass(header)} ${
                    header?.column?.getFilterValue() ? 'reports-table-header-val' : ''
                  }`}>
                  <div className='reports-column-order'>
                    {flexRender(header.column.columnDef.header, header.getContext())}
                    {
                      {
                        asc: <ArrowUpIcon className='size-6' />,
                        desc: <ArrowDownIcon className='size-6' />,
                      }[header.column.getIsSorted()]
                    }
                  </div>
                  {header?.column?.getFilterValue() ? (
                    header.column.getFilterValue() instanceof Array ? (
                      <div className='reports-filter-val'>
                        {header.column.getFilterValue()[0] && <span>Min: {header.column.getFilterValue()[0]}</span>}
                        {header.column.getFilterValue()[1] && <span>Max: {header.column.getFilterValue()[1]}</span>}
                      </div>
                    ) : (
                      <div className='reports-filter-val'>
                        <span>Value: {header.column.getFilterValue()}</span>
                      </div>
                    )
                  ) : (
                    ''
                  )}
                </TooltipTrigger>
                <TooltipContent className='Tooltip'>
                  {headersLabelMap[flexRender(header.column.columnDef.header, header.getContext())]?.label}
                </TooltipContent>
              </Tooltip>
            </>
          ) : (
            <div>{flexRender(header.column.columnDef.header, header.getContext())}</div>
          )}
        </PopoverTrigger>
        <PopoverContent>
          <div className='reports-tooltip'>
            <PopoverClose
              className={getToolTipClass(header.column.getIsSorted(), 'asc')}
              onClick={() => header.column.toggleSorting(false)}>
              <BarsArrowUpIcon className='size-7' /> Ascending
            </PopoverClose>
            <PopoverClose
              className={getToolTipClass(header.column.getIsSorted(), 'desc')}
              onClick={() => header.column.toggleSorting(true)}>
              <BarsArrowDownIcon className='size-7' />
              Descending
            </PopoverClose>
            <PopoverClose
              className='reports-tooltip-option'
              onClick={() => {
                header.column.clearSorting();
                header.column.getFilterValue() instanceof Array
                  ? header.column.setFilterValue([])
                  : header.column.setFilterValue('');
              }}>
              <XMarkIcon className='size-7' /> Clear
            </PopoverClose>
            <TableFilter column={header.column} table={table} />
          </div>
        </PopoverContent>
      </Popover>
    </th>
  );
}
export default ScreenerTable;
