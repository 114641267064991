import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, PrimaryHeader, TextEditor, ModalWrapper, Select } from 'src/components';
import { useSelector, useDispatch } from 'react-redux';
import { selectClubMetaData, selectClubPostStatus } from '../club.selector';
import { fetchClubMetaData, postClubPost } from '../club.reducer';
import FileInput from 'src/components/InputElements/fileInut';
import 'react-quill/dist/quill.snow.css';
import * as Yup from 'yup';
function Post() {
  const navigate = useNavigate();
  const [successModal, setSuccessModal] = useState(false);
  const [file, setFile] = useState(null);
  const [category, setCategory] = useState('');
  const [headline, setHeadline] = useState('');
  const [content, setContent] = useState('');
  const [errors, setErrors] = useState({});
  const metaData = useSelector(selectClubMetaData);
  const postStatus = useSelector(selectClubPostStatus);
  const dispatch = useDispatch();
  const quillRef = useRef();

  const validationSchema = Yup.object().shape({
    category: Yup.string().required('Category is required'),
    headline: Yup.string().required('Headline is required'),
    content: Yup.string().required('Content is required'),
  });

  const validateForm = async () => {
    try {
      await validationSchema.validate({ category, headline, content }, { abortEarly: false });
      setErrors({});
      return true;
    } catch (err) {
      const validationErrors = {};
      if (err instanceof Yup.ValidationError) {
        err.inner.forEach((error) => {
          if (error.path) {
            validationErrors[error.path] = error.message;
          }
        });
      }
      setErrors(validationErrors);
      return false;
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const isValid = await validateForm();
    if (!isValid) return;

    const formData = new FormData();
    if (file) {
      formData.append('files', file);
    }
    formData.append('category', category);
    formData.append('headline', headline);
    formData.append('details', content);
    dispatch(postClubPost(formData));
    setSuccessModal(true);
  };

  useEffect(() => {
    dispatch(fetchClubMetaData());
  }, []);

  return (
    <main className='navbar-fix'>
      <div className='clubpost'>
        <PrimaryHeader text='iFIR$T Club' className='color-primary' />
        <div className='clubpost-main'>
          <h1>Create A Post</h1>
          <form className='clubpost-input'>
            <FileInput onFileChange={(selectedFile) => setFile(selectedFile)} />
            <div className='clubpost-input-item'>
              <label>Category</label>
              <Select options={metaData?.data?.categories} onChange={(e) => setCategory(e)} className='w-full' />
              {errors.category && <p className='error-message'>{errors.category}</p>}
            </div>
            <div className='clubpost-input-item'>
              <label>Headline</label>
              <input type='text' name='headline' value={headline} onChange={(e) => setHeadline(e.target.value)} />
              {errors.headline && <p className='error-message'>{errors.headline}</p>}
            </div>
            <div className='clubpost-input-item textEditor'>
              <label>Details</label>
              <TextEditor
                ref={quillRef}
                onTextChange={() => {
                  if (quillRef.current) {
                    const content = quillRef.current.getContent();
                    setContent(content.html);
                  }
                }}
              />
              {errors.content && <p className='error-message'>{errors.content}</p>}
            </div>
            {postStatus?.error && <div className='label-text-alt ca-nodata'>{postStatus?.error.message}</div>}
            <div className='clubpost-buttons'>
              <Button type='submit' className='btn btn-primary btn-rounded btn-lg ' onClick={handleSubmit}>
                {postStatus?.isLoading ? <div className='loader' /> : 'Publish'}
              </Button>
            </div>
          </form>
          {postStatus?.data?.id ? (
            <ModalWrapper open={successModal} onClose={() => setSuccessModal(false)}>
              <div className='postSuccessModal'>
                <h1>Post Submitted Successfully!</h1>
                <Button
                  className='btn-primary btn-rounded'
                  label='OK'
                  onClick={() => {
                    setSuccessModal(false);
                    navigate(`/club/${postStatus?.data?.id}`);
                  }}
                />
              </div>
            </ModalWrapper>
          ) : null}
        </div>
      </div>
    </main>
  );
}
export default Post;
