import { take, put, call, fork, ActionPattern, delay, race } from 'redux-saga/effects';
import { eventChannel, END } from 'redux-saga';
import { createAction, Action } from '@reduxjs/toolkit';
import { putMessage } from './Websocket.reducer';
import { WS_EVENT } from 'src/constants/ws.constant';
import { IWebsocket } from 'src/lib/types/websocket';
import SessionToken from 'src/lib/session-token';
import { ACCESS_TOKEN_KEY } from 'src/constants/token.constant';
import { HandleLiveQuote } from 'src/components/TvContainer/streaming';
import { config } from 'src/lib/config';

export const sendMessage = createAction<IWebsocket>('message/sendMessage');
export const reconnectWebSocket = createAction('websocket/reconnect');
export const connectWebSocket = createAction('websocket/connect');
export const disconnectWebSocket = createAction('websocket/disconnect');
export const startCandleProcessing = createAction('websocket/startCandleProcessing'); // Declare before use

export function webSocketListener(serviceWebSocket: WebSocket) {
  return eventChannel((emitter) => {
    serviceWebSocket.onmessage = ({ data }: MessageEvent) => emitter(data);
    serviceWebSocket.onclose = () => emitter(END);
    serviceWebSocket.onerror = () => emitter(END);

    return () => serviceWebSocket.close();
  });
}

export function* webSocketSaga(): Generator {
  let serviceWebSocket: WebSocket | null = null;

  while (true) {
    const result = (yield race({
      connect: take(connectWebSocket.toString()) as Action,
      disconnect: take(disconnectWebSocket.toString()) as Action,
    })) as { connect?: Action; disconnect?: Action };

    const { connect, disconnect } = result;

    if (disconnect && serviceWebSocket) {
      (serviceWebSocket as WebSocket).close();
      serviceWebSocket = null;
      yield put(putMessage({ event: WS_EVENT.WS_EVENT_SERVER_DICONNECT }));
      continue;
    }

    if (connect && !serviceWebSocket) {
      try {
        const socketUrl = `${config.WEBSOCKET_BASE_URL}${config.API_VER}${config.API_SVC_PATH_IFIRST_MGMT_SERVICE}/authd`;
        serviceWebSocket = new WebSocket(socketUrl);
        const socket = yield call(webSocketListener, serviceWebSocket);
        yield put(putMessage({ event: WS_EVENT.WS_EVENT_SERVER_CONNECTED }));
        yield fork(sendMessageSaga, serviceWebSocket);

        yield delay(1000);
        if (SessionToken.getToken(ACCESS_TOKEN_KEY)) {
          yield put(
            sendMessage({ event: WS_EVENT.WS_EVENT_CLIENT_LOGIN, data: `${SessionToken.getToken(ACCESS_TOKEN_KEY)}` }),
          );
        }
        // Forking a new saga to handle candle processing
        yield fork(handleCandleProcessing, socket);
        // Handle incoming messages
        while (true) {
          const messageResult = (yield race({
            payload: take(socket as ActionPattern) as unknown as string, // Assume payload is a string
            disconnect: take(disconnectWebSocket.toString()) as unknown as boolean,
          })) as { payload?: string; disconnect?: boolean };

          const { payload, disconnect } = messageResult;

          if (disconnect) {
            break;
          }

          if (payload) {
            const data: IWebsocket = JSON.parse(payload);
            yield put(putMessage({ event: data.event, data: data.data }));
          }
        }
      } finally {
        if (serviceWebSocket) {
          serviceWebSocket.close();
        }

        yield put(
          putMessage({
            event: WS_EVENT.WS_EVENT_SERVER_DICONNECT,
            data: null,
          }),
        );
        serviceWebSocket = null;
      }
    }
  }
}

export function* sendMessageSaga(serviceWebSocket: WebSocket) {
  while (true) {
    const { payload } = yield take(sendMessage.toString());
    try {
      yield put(putMessage({ event: payload.event, data: payload.data }));
      yield call([serviceWebSocket, 'send'], JSON.stringify(payload));
    } catch (error) {
      console.error('WebSocket send error:', error); // Log the error in case of failure
      yield put(
        putMessage({
          event: WS_EVENT.WS_EVENT_SERVER_DICONNECT,
          data: null,
        }),
      );
    }
  }
}

function* handleCandleProcessing(socket: any) {
  while (true) {
    yield take(startCandleProcessing.toString());
    while (true) {
      const payload = yield take(socket); // Take WebSocket messages from the eventChannel
      if (payload) {
        yield call(HandleLiveQuote, payload ? JSON.parse(payload) : {});
      }
    }
  }
}
