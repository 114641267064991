import React, { useState } from 'react';
import 'react-multi-carousel/lib/styles.css';
import 'react-calendar/dist/Calendar.css';

import { PrimaryHeader } from 'src/components';
import EconomicCalendarComponent from 'src/components/marketsComponents/economicCalender';

import 'react-date-picker/dist/DatePicker.css';
import 'react-calendar/dist/Calendar.css';
import MarketSidebar from 'src/components/marketsComponents/marketSidebar';
import MarketsEconomicIndicator from 'src/components/marketsComponents/economicIndicator';
import MarketsHoliday from 'src/components/marketsComponents/marketHolidays';
import MarketNews from 'src/components/marketsComponents/marketNews';
import MarketsTreasuryRates from 'src/components/marketsComponents/tresuaryRates';

export default function Markets() {
  const [tab, setTab] = useState('one');

  return (
    <>
      <main className='navbar-fix'>
        <PrimaryHeader text='Markets' className='color-primary' />
        <div className='markets'>
          <div className='markets__btns'>
            <button type='button' onClick={() => setTab('one')} className={tab === 'one' ? 'active' : ''}>
              Markets
            </button>
            <button type='button' onClick={() => setTab('two')} className={tab === 'two' ? 'active' : ''}>
              Movements
            </button>
          </div>

          <div className='m-container'>
            <>
              <div className='m-news'>
                <MarketNews />
              </div>
              <div className='m-eco'>
                <EconomicCalendarComponent />
              </div>
              <div className='m-tre'>
                <MarketsTreasuryRates />
              </div>
              <div className='m-cal'>
                <MarketsHoliday />
              </div>
              <div className='m-indi'>
                <MarketsEconomicIndicator />
              </div>
            </>

            <MarketSidebar />
          </div>
          {tab === 'one' ? (
            <div className='m-container1'>
              <>
                <div className='m-news'>
                  <MarketNews />
                </div>
                <div className='m-eco'>
                  <EconomicCalendarComponent />
                </div>
                <div className='m-tre'>
                  <MarketsTreasuryRates />
                </div>
                <div className='m-cal'>
                  <MarketsHoliday />
                </div>
                <div className='m-indi'>
                  <MarketsEconomicIndicator />
                </div>
              </>
            </div>
          ) : (
            <div>
              <MarketSidebar />
            </div>
          )}
        </div>
      </main>
    </>
  );
}
