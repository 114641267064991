import React from 'react';
import StockpeerCard from '../Card/stockpeerCard';
import Skeleton from 'react-loading-skeleton';
import Carousel from 'react-multi-carousel';

export default function Stockpeers({ data, isLoading }) {
  const responsive = {
    desktop: {
      breakpoint: { max: 2500, min: 1367 },
      items: 3,
    },
    largeTablet: {
      breakpoint: { max: 1366, min: 1281 },
      items: 3,
    },
    nestHubLarge: {
      breakpoint: { max: 1280, min: 1025 },
      items: 2,
    },
    nestHubMedium: {
      breakpoint: { max: 1024, min: 913 },
      items: 2,
    },
    nestHubSmall: {
      breakpoint: { max: 912, min: 769 },
      items: 2,
    },
    tablet: {
      breakpoint: { max: 768, min: 465 },
      items: 1,
      centerMode: true,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
      centerMode: true,
    },
  };
  return (
    <section className='watchlist-stockpeer'>
      <h1 className='financials-section-header'>PEERS</h1>
      {data?.stock_peers?.length > 0 ? (
        <div>
          <Carousel
            responsive={responsive}
            infinite={true}
            autoPlay={false}
            arrows={true}
            showDots={false}
            keyBoardControl={true}>
            {isLoading ? (
              <Skeleton className='stockpeer' />
            ) : (
              data?.stock_peers?.map((item, i) => <StockpeerCard item={item} key={i} />)
            )}
          </Carousel>
        </div>
      ) : (
        <h2 className='ca-nodata'>No Data Available</h2>
      )}
    </section>
  );
}
