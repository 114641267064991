import React from 'react';
import { NewsCardSm } from 'src/components';
import { Link } from 'react-router-dom';
import { PressReleseCard2loader } from '../loadingAnimation/cardloader';
import { selectWatchlistNews } from 'src/pages/Watchlist/Watchlist.selector';
import { useSelector } from 'react-redux';

export default function OverviewNews() {
  const news = useSelector(selectWatchlistNews);
  if (news?.data?.length > 0)
    return (
      <div className='overview-news-section-container'>
        <div className='overview-news-section-header'>
          <h1 className='financials-section-header'>News</h1>
          <h2 className='financials-section-header'>
            <Link to='news'>MORE</Link>
          </h2>
        </div>
        <div>
          {news?.isLoading ? (
            <>
              <PressReleseCard2loader />
              <PressReleseCard2loader />
            </>
          ) : (
            news?.data?.slice(0, 5).map((data, index) => <NewsCardSm item={data} key={index} />)
          )}
          {news?.error && <h2 className='ca-nodata'>No Data Available</h2>}
        </div>
      </div>
    );
  return null;
}
