import IntlTelInput from 'react-intl-tel-input';
import { useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import TextField from 'src/components/InputElements/textField.component';
import 'react-intl-tel-input/dist/main.css';
import { useForm } from '@tanstack/react-form';
import validator from 'validator';
import { useDispatch } from 'react-redux';
import { fetchSupportSection, supportCreateTicketReset, supportCreateTicket } from './support.reducer';
import { Button, ModalWrapper, Select } from 'src/components';
import { selectSupportCreateTicket, selectSupportSection } from './support.selector';
import { useEffect } from 'react';
import { useState } from 'react';
import Loader from 'src/components/loadingAnimation/loadingAnimation.component';
import { loggedIn } from 'src/lib/auth';
interface TicketFormValues {
  name: string;
  email: string;
  mobile: { status: boolean; fullNumber: string };
  topic: string;
  query: string;
  description: string;
  files: File | null;
}

export default function RaiseSupportTicket() {
  const isSignedIn = loggedIn();
  const nav = useNavigate();
  const sectionsData = useSelector(selectSupportSection);
  const [sections, setSections] = useState([]);
  const [successModal, setSuccessModal] = useState(false);
  const ticketCreate = useSelector((state: any) => state.supportCreateTicket);
  const dispatch = useDispatch();
  const ticketForm = useForm<TicketFormValues>({
    defaultValues: {
      name: '',
      email: '',
      mobile: { status: false, fullNumber: '' },
      topic: '',
      query: '',
      description: '',
      files: null,
    },
    onSubmit: async ({ value }) => handleSubmit(value),
  });

  const handleSubmit = async (values: TicketFormValues) => {
    const payload = new FormData();
    const secId = sectionsData?.data?.find((e) => e.name === values.topic)?.id;
    if (!isSignedIn) {
      payload.append('name', values.name);
      payload.append('email', values.email);
      payload.append('mobile', values.mobile.fullNumber);
    }
    payload.append('section', secId);
    payload.append('query', values.query);
    payload.append('description', values.description);
    if (values.files) payload.append('files', values.files);
    dispatch(supportCreateTicket(payload));
    setSuccessModal(true);
  };
  useEffect(() => {
    dispatch(supportCreateTicketReset());
    if (sectionsData?.data) {
      const sec = sectionsData?.data?.map((e) => e.name);
      setSections(sec);
    } else {
      dispatch(fetchSupportSection({}));
    }
  }, [sectionsData]);
  return (
    <>
      <section className='support-raise-ticket navbar-fix'>
        <h1>
          Submit your <span>request</span>
        </h1>
        {sections?.length > 0 ? (
          <main>
            <form
              onSubmit={(e) => {
                e.preventDefault();
                e.stopPropagation();
                ticketForm.handleSubmit();
              }}
              className='support-raise-ticket-form'>
              {/* Only show name, email, and mobile fields if the user is not signed in */}
              {!isSignedIn && (
                <>
                  <ticketForm.Field
                    name='name'
                    validators={{
                      onChange: ({ value }) => (!value ? 'Name is required' : null),
                    }}
                    children={(field) => <TextField field={field} placeholder='Name' svg='user' />}
                  />
                  <ticketForm.Field
                    name='email'
                    validators={{
                      onChange: ({ value }) => (!validator.isEmail(value) ? 'Enter a valid email' : null),
                    }}
                    children={(field) => (
                      <TextField field={field} placeholder='Your email address' svg='email' type='email' />
                    )}
                  />
                  <ticketForm.Field
                    name='mobile'
                    validators={{
                      onChange: ({ value }) => {
                        if (!value.status) {
                          return 'Enter a valid mobile number';
                        }
                      },
                    }}
                    children={(field) => (
                      <>
                        <IntlTelInput
                          fieldName={field.name}
                          onSelectFlag={(_v, _s, fullNumber, status) => {
                            field.handleChange({ status, fullNumber });
                          }}
                          onPhoneNumberChange={(status, _v, _c, fullNumber) => {
                            field.handleChange({ status, fullNumber });
                          }}
                          containerClassName='intl-tel-input'
                          inputClassName='form-control'
                        />
                        <div className='label'>
                          {field.state.meta.touchedErrors ? (
                            <span className='label-text-alt'>{field.state.meta.touchedErrors}</span>
                          ) : null}
                        </div>
                      </>
                    )}
                  />
                </>
              )}

              {/* Other fields */}
              {sections?.length > 0 ? (
                <ticketForm.Field
                  name='topic'
                  validators={{
                    onChange: ({ value }) => (!value ? 'Topic is required' : null),
                  }}
                  children={(field) => (
                    <>
                      <Select
                        className='w-full'
                        preSelectDisabled
                        options={sections}
                        onChange={(val) => field.handleChange(val)}
                      />
                      <div className='label'>
                        {field.state.meta.touchedErrors ? (
                          <span className='label-text-alt'>{field.state.meta.touchedErrors}</span>
                        ) : null}
                      </div>
                    </>
                  )}
                />
              ) : null}

              <ticketForm.Field
                name='query'
                validators={{
                  onChange: ({ value }) => (!value ? 'Query is required' : null),
                }}
                children={(field) => <TextField field={field} placeholder='Your queries' svg='query' />}
              />

              <ticketForm.Field
                name='files'
                children={(field) => (
                  <>
                    <input
                      type='file'
                      accept='image/*'
                      className='grow file-input input-bordered w-full'
                      id={field.name}
                      name={field.name}
                      placeholder='Describe your problem'
                      onBlur={field.handleBlur}
                      onChange={(e) => {
                        if (e?.target?.files) {
                          const file = e?.target.files[0];
                          if (file != null) field.handleChange(file);
                        }
                      }}
                    />
                    <div className='label'>
                      {field.state.meta.touchedErrors ? (
                        <span className='label-text-alt'>{field.state.meta.touchedErrors}</span>
                      ) : null}
                    </div>
                  </>
                )}
              />

              <ticketForm.Field
                name='description'
                validators={{
                  onChange: ({ value }) => (!value ? 'Please describe your problem.' : null),
                }}
                children={(field) => (
                  <>
                    <textarea
                      className='input-bordered support-raise-ticket-form-desc'
                      placeholder='Write a description of your problem'
                      id={field.name}
                      name={field.name}
                      value={field.state.value}
                      onBlur={field.handleBlur}
                      onChange={(e) => field.handleChange(e.target.value)}
                    />
                    <div className='label'>
                      {field.state.meta.touchedErrors ? (
                        <span className='label-text-alt'>{field.state.meta.touchedErrors}</span>
                      ) : null}
                    </div>
                  </>
                )}
              />

              {ticketCreate?.error && <div className='label-text-alt ca-nodata'>{ticketCreate?.error.message}</div>}
              <div className='support-submit'>
                <ticketForm.Subscribe
                  selector={(state) => state}
                  children={({ isTouched, isFieldsValid }) => (
                    <Button
                      type='submit'
                      disabled={!(isTouched && isFieldsValid) || ticketCreate?.data}
                      className={`btn btn-primary btn btn-rounded btn-lg ${
                        !(isTouched && isFieldsValid) ? 'btn-disabled' : 'btn-primary'
                      }`}>
                      {ticketCreate?.isLoading ? (
                        <div className='p-3'>
                          <div className='loader' />
                        </div>
                      ) : (
                        'Submit'
                      )}
                    </Button>
                  )}
                />
              </div>

              {isSignedIn ? (
                <Link to='/support/tickets' className='support-check-tickets'>
                  <p>view all raised ticket lists...</p>
                </Link>
              ) : null}
            </form>
          </main>
        ) : (
          <Loader />
        )}
      </section>

      {ticketCreate?.data && (
        <ModalWrapper open={successModal} onClose={() => setSuccessModal(false)}>
          <div className='postSuccessModal'>
            <h1>Your Query Is Submitted Successfully!</h1>
            <Button
              className='btn-primary btn-rounded btn-lg'
              label='OK'
              onClick={() => {
                ticketForm.reset();
                dispatch(supportCreateTicketReset());
                if (isSignedIn) nav('/support/tickets', { replace: true });
                else nav('/support', { replace: true });
                setSuccessModal(false);
              }}
            />
          </div>
        </ModalWrapper>
      )}
    </>
  );
}
