import { take, put, call, fork, ActionPattern, race } from 'redux-saga/effects';
import { eventChannel, END } from 'redux-saga';
import { createAction, Action } from '@reduxjs/toolkit';
import { putMessageCarousel } from './Websocket.reducer';
import { WS_EVENT } from 'src/constants/ws.constant';
import { IWebsocket } from 'src/lib/types/websocket';
import { config } from 'src/lib/config';

export function carouselWebSocketListener(serviceWebSocket: WebSocket) {
  return eventChannel((emitter) => {
    serviceWebSocket.onmessage = ({ data }: MessageEvent) => emitter(data);
    serviceWebSocket.onclose = () => emitter(END);
    serviceWebSocket.onerror = () => emitter(END);

    return () => serviceWebSocket.close();
  });
}

export function* carouselWebSocketSaga(): Generator {
  let serviceWebSocket: WebSocket | null = null;

  while (true) {
    const result = (yield race({
      connect: take(connectCarouselWebSocket.toString()) as Action,
      disconnect: take(disconnectCarouselWebSocket.toString()) as Action,
    })) as { connect?: Action; disconnect?: Action };

    const { connect, disconnect } = result;

    if (disconnect && serviceWebSocket) {
      (serviceWebSocket as WebSocket).close();
      serviceWebSocket = null;
      yield put(putMessageCarousel({ event: WS_EVENT.WS_EVENT_SERVER_DICONNECT }));
      continue;
    }

    if (connect && !serviceWebSocket) {
      try {
        const socketUrl = `${config.WEBSOCKET_BASE_URL}${config.API_VER}${config.API_SVC_PATH_IFIRST_MGMT_SERVICE}/carousel`;
        serviceWebSocket = new WebSocket(socketUrl);
        const socket = yield call(carouselWebSocketListener, serviceWebSocket);
        yield put(putMessageCarousel({ event: WS_EVENT.WS_EVENT_SERVER_CONNECTED }));
        yield fork(sendMessageCarouselSaga, serviceWebSocket);

        while (true) {
          const messageResult = (yield race({
            payload: take(socket as ActionPattern) as unknown as string,
            disconnect: take(disconnectCarouselWebSocket.toString()) as unknown as boolean,
          })) as { payload?: string; disconnect?: boolean };

          const { payload, disconnect } = messageResult;

          if (disconnect) {
            break;
          }

          if (payload) {
            const data: IWebsocket = JSON.parse(payload as string);
            yield put(putMessageCarousel({ event: data.event, data: data.data }));
          }
        }
      } finally {
        if (serviceWebSocket) {
          serviceWebSocket.close();
        }
        yield put(
          putMessageCarousel({
            event: WS_EVENT.WS_EVENT_SERVER_DICONNECT,
            data: null,
          }),
        );
        serviceWebSocket = null;
      }
    }
  }
}

export const sendMessageCarousel = createAction<IWebsocket>('message/sendMessageCarousel');
export function* sendMessageCarouselSaga(serviceWebSocket: WebSocket) {
  while (true) {
    const { payload } = yield take(sendMessageCarousel.toString());
    try {
      if (payload.event === WS_EVENT.WS_EVENT_SERVER_STREAMING_QUOTE)
        yield put(putMessageCarousel({ event: payload.event, data: payload.data }));
      yield call([serviceWebSocket, 'send'], JSON.stringify(payload));
    } catch (error) {
      yield put(
        putMessageCarousel({
          event: WS_EVENT.WS_EVENT_SERVER_DICONNECT,
          data: null,
        }),
      );
    }
  }
}
export const reconnectWebSocket = createAction('websocketCarousel/reconnect');
export const connectCarouselWebSocket = createAction('websocketCarousel/connect');
export const disconnectCarouselWebSocket = createAction('websocketCarousel/disconnect');
