import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { fetchWatchlistRatings } from '../../Watchlist.reducer';
import { selectWatchlistRatings } from '../../Watchlist.selector';
import { getColorValue } from 'src/lib/utils/utils';
import { useParams } from 'react-router-dom';
import { Stockgrades } from 'src/components/WatchlistComponents/stockgrades';
function RatingGrades() {
  const { ticker, market } = useParams();

  const dispatch = useDispatch();
  const ratings = useSelector(selectWatchlistRatings);
  useEffect(() => {
    dispatch(fetchWatchlistRatings({ ticker }));
  }, [ticker]);
  return (
    <main className='rating'>
      <div className='rating-matrics'>
        <div className='rating__Left'>
          <h1 className='financials-section-header'>Rating</h1>
          <div className='rating__LeftCard'>
            <article className='rating__LeftContainer'>
              <div className='rating__L'>
                <div className='rating__Details'>
                  <h3>ROE</h3>
                  <h3>{ratings?.data?.rating?.roe_score}</h3>
                </div>
                <div className='rating__Details'>
                  <h3>ROA</h3>
                  <h3>{ratings?.data?.rating?.roa_score}</h3>
                </div>
                <div className='rating__Details'>
                  <h3>Operating Margin</h3>
                  <h3>{ratings?.data?.rating?.de_score}</h3>
                </div>
                <div className='rating__Details'>
                  <h3>Debt/Equity</h3>
                  <h3>{ratings?.data?.rating?.de_score}</h3>
                </div>
                <div className='rating__Details'>
                  <h3>P/E</h3>
                  <h3>{ratings?.data?.rating?.pe_score}</h3>
                </div>
                <div className='rating__Details'>
                  <h3>P/B</h3>
                  <h3>{ratings?.data?.rating?.pb_score}</h3>
                </div>
              </div>
              <div className='rating__R'>
                <h3 className={`${getColorValue(ratings?.data?.rating?.roe_recommendation)}`}>
                  {ratings?.data?.rating?.roe_recommendation}
                </h3>
                <h3 className={`${getColorValue(ratings?.data?.rating?.roa_recommendation)}`}>
                  {ratings?.data?.rating?.roa_recommendation}
                </h3>
                <h3 className={`${getColorValue(ratings?.data?.rating?.de_recommendation)}`}>
                  {ratings?.data?.rating?.de_recommendation}
                </h3>
                <h3 className={`${getColorValue(ratings?.data?.rating?.de_recommendation)}`}>
                  {ratings?.data?.rating?.de_recommendation}
                </h3>
                <h3 className={`${getColorValue(ratings?.data?.rating?.pe_recommendation)}`}>
                  {ratings?.data?.rating?.pe_recommendation}
                </h3>
                <h3 className={`${getColorValue(ratings?.data?.rating?.pb_recommendation)}`}>
                  {ratings?.data?.rating?.pb_recommendation}
                </h3>
              </div>
            </article>
            <hr />
            <div className='rating__Stars'>
              <h3>Rating: </h3>
              {ratings?.data?.rating?.ratingScore && <StarRating value={ratings?.data?.rating?.ratingScore} />}
              <StarRating rating={ratings?.data?.rating?.recommendation} />
            </div>
            <div className='rating__recommendation'>
              <h3>Recommendation:</h3>
              <h3 className={`${getColorValue(ratings?.data?.rating?.recommendation)}`}>
                {ratings?.data?.rating?.recommendation}
              </h3>
            </div>
          </div>
        </div>
        <Stockgrades data={ratings?.data} isLoading={ratings?.isLoading} />
      </div>
    </main>
  );
}

export default RatingGrades;
const ratings = {
  'Strong Sell': 1,
  Sell: 2,
  Neutral: 3,
  Buy: 4,
  'Strong Buy': 5,
};

function StarRating({ rating }) {
  const numberOfStars = ratings[rating] || 0;
  return (
    <div className='star-rating'>
      {[...Array(5)].map((_, index) => {
        const isActive = index < numberOfStars;
        return (
          <span key={index} className={`mask mask-star-2  ${isActive ? 'active' : 'disabled'}`} checked={isActive} />
        );
      })}
    </div>
  );
}
