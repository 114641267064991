import React, { useState } from 'react';
import { activeElement } from 'src/lib/utils/utils';
import { AnalystEstimateChart } from 'src/components';
import { FinancialDataType, StatementTf } from 'src/lib/enum/enum';
import Skeleton from 'react-loading-skeleton';

export function AnalystEstimateGraphComponent({ isLoading, data, activeReported, activeEstimated, activeSurprise }) {
  const [tf, setTf] = useState(StatementTf.Annual);
  const [statementType, setStatementType] = useState(FinancialDataType.Revenue);
  return (
    <section>
      <h1 className='financials-section-header'>Analyst Estimates</h1>
      <div className='financials-graphcontianer'>
        <div className='financials-navbar'>
          <ul className=' menu menu-horizontal '>
            <li
              className={activeElement(FinancialDataType.Revenue === statementType)}
              onClick={() => setStatementType(FinancialDataType.Revenue)}>
              Revenue
            </li>
            <li
              className={activeElement(FinancialDataType.EIBTDA === statementType)}
              onClick={() => setStatementType(FinancialDataType.EIBTDA)}>
              EBITDA
            </li>
            <li
              className={activeElement(FinancialDataType.EBIT === statementType)}
              onClick={() => setStatementType(FinancialDataType.EBIT)}>
              EBIT
            </li>
            <li
              className={activeElement(FinancialDataType.NetIncome === statementType)}
              onClick={() => setStatementType(FinancialDataType.NetIncome)}>
              Net Income
            </li>
            <li
              className={activeElement(FinancialDataType.SGExpense === statementType)}
              onClick={() => setStatementType(FinancialDataType.SGExpense)}>
              SG&A Expense
            </li>
            <li
              className={activeElement(FinancialDataType.EPS === statementType)}
              onClick={() => setStatementType(FinancialDataType.EPS)}>
              EPS
            </li>
          </ul>
          <ul className=' menu menu-horizontal '>
            <li className={activeElement(StatementTf.Annual === tf)} onClick={() => setTf(StatementTf.Annual)}>
              Annual
            </li>
            <li className={activeElement(StatementTf.Quarter === tf)} onClick={() => setTf(StatementTf.Quarter)}>
              Quarter
            </li>
          </ul>
        </div>
        {isLoading ? (
          <Skeleton height='40rem' width='100%' />
        ) : data?.[tf]?.[statementType]?.length > 0 ? (
          <AnalystEstimateChart
            data={data?.[tf]?.[statementType]}
            tf={tf}
            activeReported={activeReported}
            activeEstimated={activeEstimated}
            activeSurprise={activeSurprise}
          />
        ) : (
          <div className='min-h-[40rem] w-full'>
            <h2 className='ca-nodata'>No Data Available</h2>
          </div>
        )}
      </div>
    </section>
  );
}
