interface IWindow extends Window {
  env: {
    REACT_APP_API_URL: string;
    REACT_APP_WEBSOCKET: string;
    REACT_APP_STRIPE_PK: string;
    ALPACA_REDIRECT_URI: string;
  };
}
declare const window: IWindow;

export const config = {
  WEBSOCKET_BASE_URL: window.env.REACT_APP_WEBSOCKET,
  STRIPE_PK: window.env.REACT_APP_STRIPE_PK,
  API_BASE_URL: window.env.REACT_APP_API_URL,
  ALPACA_REDIRECT_URI: window.env.ALPACA_REDIRECT_URI,
  API_VER: process.env.REACT_APP_API_VER as string,
  API_VER_DEV: process.env.REACT_APP_API_VER_DEV as string,

  API_PORT_IFIRST_TA_SERVICE: process.env.REACT_APP_API_PORT_IFIRST_TA_SERVICE as string,
  API_PORT_IFIRST_SIGNAL_SERVICE: process.env.REACT_APP_API_PORT_IFIRST_SIGNAL_SERVICE as string,
  API_PORT_IFIRST_MGMT_SERVICE: process.env.REACT_APP_API_PORT_IFIRST_MGMT_SERVICE as string,
  API_PORT_IFIRST_360_VIEW_SERVICE: process.env.REACT_APP_API_PORT_IFIRST_360_VIEW_SERVICE as string,
  API_PORT_IFIRST_SCREENER: process.env.REACT_APP_API_PORT_IFIRST_SCREENER as string,
  API_PORT_IFIRST_HEATMAP: process.env.REACT_APP_API_PORT_IFIRST_HEATMAP as string,
  API_PORT_IFIRST_SUPPORT: process.env.REACT_APP_API_PORT_IFIRST_SUPPORT as string,
  API_PORT_IFIRST_CLUB: process.env.REACT_APP_API_PORT_IFIRST_CLUB as string,
  API_PORT_IFIRST_ACADEMY: process.env.REACT_APP_API_PORT_IFIRST_ACADEMY as string,
  API_PORT_IFIRST_CHARTING_BACKEND: process.env.REACT_APP_API_PORT_IFIRST_CHARTING_BACKEND as string,
  API_PORT_IFIRST_SENTIMENT: process.env.REACT_APP_API_PORT_IFIRST_SENTIMENT as string,
  API_PORT_IFIRST_SUBSCRIPTION: process.env.REACT_APP_API_PORT_IFIRST_SUBSCRIPTION as string,
  API_PORT_IFIRST_NEWS: process.env.REACT_APP_API_PORT_IFIRST_NEWS as string,

  API_SVC_PATH_IFIRST_TA_SERVICE: process.env.REACT_APP_API_SVC_PATH_IFIRST_TA_SERVICE as string,
  API_SVC_PATH_IFIRST_SIGNAL_SERVICE: process.env.REACT_APP_API_SVC_PATH_IFIRST_SIGNAL_SERVICE as string,
  API_SVC_PATH_IFIRST_MGMT_SERVICE: process.env.REACT_APP_API_SVC_PATH_IFIRST_MGMT_SERVICE as string,
  API_SVC_PATH_IFIRST_360_VIEW_SERVICE: process.env.REACT_APP_API_SVC_PATH_IFIRST_360_VIEW_SERVICE as string,
  API_SVC_PATH_IFIRST_SCREENER: process.env.REACT_APP_API_SVC_PATH_IFIRST_SCREENER as string,
  API_SVC_PATH_IFIRST_HEATMAP: process.env.REACT_APP_API_SVC_PATH_IFIRST_HEATMAP as string,
  API_SVC_PATH_IFIRST_ACADEMY: process.env.REACT_APP_API_SVC_PATH_IFIRST_ACADEMY as string,
  API_SVC_PATH_IFIRST_SUPPORT: process.env.REACT_APP_API_SVC_PATH_IFIRST_SUPPORT as string,
  API_SVC_PATH_IFIRST_CLUB: process.env.REACT_APP_API_SVC_PATH_IFIRST_CLUB as string,
  API_SVC_PATH_IFIRST_CHARTING_BACKEND: process.env.REACT_APP_API_SVC_PATH_IFIRST_CHARTING_BACKEND as string,
  API_SVC_PATH_IFIRST_SENTIMENT: process.env.REACT_APP_API_SVC_PATH_IFIRST_SENTIMENT as string,
  API_SVC_PATH_IFIRST_SUBSCRIPTION: process.env.REACT_APP_API_SVC_PATH_IFIRST_SUBSCRIPTION as string,
  API_SVC_PATH_IFIRST_NEWS: process.env.REACT_APP_API_SVC_PATH_IFIRST_NEWS as string,
  API_SVC_PATH_IFIRST_MARKETS: process.env.REACT_APP_API_SVC_PATH_IFIRST_MARKETS as string,

  API_MARKETS_ECONOMIC_CALENDAR: process.env.REACT_APP_API_MARKETS_ECONOMIC_CALENDAR as string,
  API_MARKETS_NEWS: process.env.REACT_APP_API_MARKETS_NEWS as string,
  API_MARKETS_ECONOMIC_INDICATOR: process.env.REACT_APP_API_MARKETS_ECONOMIC_INDICATOR as string,
  API_MARKETS_TREASURY_RATES: process.env.REACT_APP_API_MARKETS_TREASURY_RATES as string,
  API_MARKETS_SECTORS_PERFORMANCE: process.env.REACT_APP_API_MARKETS_SECTORS_PERFORMANCE as string,
  API_MARKETS_TOP_STOCKS: process.env.REACT_APP_API_MARKETS_TOP_STOCKS as string,
  API_MARKETS_TRADING_HOURS: process.env.REACT_APP_API_MARKETS_TRADING_HOURS as string,
  API_MARKETS_PE_RATIOS: process.env.REACT_APP_API_MARKETS_PE_RATIOS as string,
  API_MARKETS_EOD_PRICES: process.env.REACT_APP_API_MARKETS_EOD_PRICES as string,
  API_SVC_PATH_IFIRST_WATCHLIST: process.env.REACT_APP_API_SVC_PATH_IFIRST_WATCHLIST as string,
  API_SVC_PATH_IFIRST_REPORTS: process.env.REACT_APP_API_SVC_PATH_IFIRST_REPORTS as string,

  API_MARKET_NEWS: process.env.REACT_APP_API_MARKET_NEWS as string,
  API_GENERAL_NEWS: process.env.REACT_APP_API_GENERAL_NEWS as string,

  API_ACADEMY_CONTENT: process.env.REACT_APP_API_ACADEMY_CONTENT as string,
  API_ACADEMY_CONTENT_CARDS: process.env.REACT_APP_API_ACADEMY_CONTENT_CARDS as string,
  API_ACADEMY_CONTENT_DETAILS: process.env.REACT_APP_API_ACADEMY_CONTENT_DETAILS as string,

  API_CLUB_URI_CONTENT: process.env.REACT_APP_API_CLUB_URI_CONTENT as string,
  API_CLUB_URI_COMPONENT: process.env.REACT_APP_API_CLUB_URI_COMPONENT as string,
  API_CLUB_URI_CONTENT_DETAILS: process.env.REACT_APP_API_CLUB_URI_CONTENT_DETAILS as string,
  API_CLUB_URI_CONTENT_LIKE: process.env.REACT_APP_API_CLUB_URI_CONTENT_LIKE as string,
  API_CLUB_URI_CONTENT_CARDS: process.env.REACT_APP_API_CLUB_URI_CONTENT_CARDS as string,
  API_CLUB_URI_CONTENT_COMMENT: process.env.REACT_APP_API_CLUB_URI_CONTENT_COMMENT as string,

  API_SUPPORT_FAQ: process.env.REACT_APP_API_SUPPORT_FAQ as string,
  API_SUPPORT_FAQ_SEARCH: process.env.REACT_APP_API_SUPPORT_FAQ_SEARCH as string,
  API_SUPPORT_FAQ_DETAILS: process.env.REACT_APP_API_SUPPORT_FAQ_DETAILS as string,
  API_SUPPORT_SECTION: process.env.REACT_APP_API_SUPPORT_SECTION as string,
  API_SUPPORT_SHORT_VIDEO: process.env.REACT_APP_API_SUPPORT_SHORT_VIDEO as string,
  API_SUPPORT_TICKET: process.env.REACT_APP_API_SUPPORT_TICKET as string,

  MGMT_FORGOT_PASSWORD_INITIATE: process.env.REACT_APP_MGMT_FORGOT_PASSWORD_INITIATE as string,
  MGMT_FORGOT_PASSWORD: process.env.REACT_APP_MGMT_FORGOT_PASSWORD as string,
  MGMT_SIGNUP_INITIATE: process.env.REACT_APP_MGMT_SIGNUP_INITIATE as string,
  MGMT_SIGNIN_INITIATE: process.env.REACT_APP_MGMT_SIGNIN_INITIATE as string,
  MGMT_SIGNIN: process.env.REACT_APP_MGMT_SIGNIN as string,
  MGMT_SIGNUP: process.env.REACT_APP_MGMT_SIGNUP as string,
  MGMT_MARKET_INFO: process.env.REACT_APP_MGMT_MARKET_INFO as string,
  MGMT_CAROUSAL: process.env.REACT_APP_MGMT_CAROUSAL as string,
  API_SEARCH: process.env.REACT_APP_API_SEARCH as string,
  TOKEN_KEY: process.env.REACT_APP_TOKEN_KEY as string,
  API_MGMT_TOKEN_REFRESH: process.env.REACT_APP_TOKEN_KEY as string,
  API_PROFILE: process.env.REACT_APP_API_PROFILE as string,
  API_PROFILE_AVATAR: process.env.REACT_APP_API_PROFILE_AVATAR as string,
  API_PROFILE_EMAIL: process.env.REACT_APP_API_PROFILE_EMAIL as string,
  API_PROFILE_PASSWORD: process.env.REACT_APP_API_PROFILE_PASSWORD as string,
  API_PROFILE_EMAIL_INIT: process.env.REACT_APP_API_PROFILE_EMAIL_INIT as string,
  API_PROFILE_PASSWORD_INIT: process.env.REACT_APP_API_PROFILE_PASSWORD_INIT as string,
  API_PROFILE_SUBSCRIPTION: process.env.REACT_APP_API_PROFILE_SUBSCRIPTION as string,

  API_360_COMPANY_PROFILE: process.env.REACT_APP_API_360_COMPANY_PROFILE as string,
  API_360_OVERVIEW: process.env.REACT_APP_API_360_OVERVIEW as string,
  API_360_RATIOS: process.env.REACT_APP_API_360_RATIOS as string,
  API_360_ESG: process.env.REACT_APP_API_360_ESG as string,
  API_360_PRESS_RELEASE: process.env.REACT_APP_API_360_PRESS_RELEASE as string,
  API_360_FOREX_NEWS: process.env.REACT_APP_API_360_FOREX_NEWS as string,
  API_360_STOCK_NEWS: process.env.REACT_APP_API_360_STOCK_NEWS as string,
  API_360_INCOME_STATEMENT: process.env.REACT_APP_API_360_INCOME_STATEMENT as string,
  API_360_CORPORATE_ACTION_DIVIDEND: process.env.REACT_APP_API_360_CORPORATE_ACTION_DIVIDEND as string,
  API_360_CORPORATE_ACTION_SPLIT: process.env.REACT_APP_API_360_CORPORATE_ACTION_SPLIT as string,
  API_360_DEEP_ANALYSIS_SOCIAL_SENTIMENT: process.env.REACT_APP_API_360_DEEP_ANALYSIS_SOCIAL_SENTIMENT as string,
  API_360_DEEP_ANALYSIS_RATING: process.env.REACT_APP_API_360_DEEP_ANALYSIS_RATING as string,
  API_360_DEEP_ANALYSIS_OWNERSHIP: process.env.REACT_APP_API_360_DEEP_ANALYSIS_OWNERSHIP as string,
  API_360_DEEP_ANALYSIS_ANALYST_RECO: process.env.REACT_APP_API_360_DEEP_ANALYSIS_ANALYST_RECO as string,
  API_360_DEEP_ANALYSIS_ANALYST_EST: process.env.REACT_APP_API_360_DEEP_ANALYSIS_ANALYST_EST as string,

  API_SENTIMENT: process.env.REACT_APP_API_SENTIMENT as string,
  API_SENTIMENT_CHANGE: process.env.REACT_APP_API_SENTIMENT_CHANGE as string,
  API_SENTIMENT_TREND: process.env.REACT_APP_API_SENTIMENT_TREND as string,
  SUBSCRIPTION_META_DATA: process.env.REACT_APP_SUBSCRIPTION_META_DATA as string,
  SUBSCRIPTION_STATUS: process.env.REACT_APP_SUBSCRIPTION_STATUS as string,
  SUBSCRIPTION_CREATE: process.env.REACT_APP_SUBSCRIPTION_CREATE as string,
  SUBSCRIPTION: process.env.REACT_APP_SUBSCRIPTION as string,

  API_HEATMAP_COLUMNS_META_DATA: process.env.REACT_APP_API_HEATMAP_COLUMNS_META_DATA as string,
  API_SCREENER_COLUMNS_META_DATA: process.env.REACT_APP_API_SCREENER_COLUMNS_META_DATA as string,
  API_SCREENER_COLUMNS_STOCK_DEFAULT_CATEGORY: process.env
    .REACT_APP_API_SCREENER_STOCK_COLUMNS_DEFAULT_CATEGORY as string,
  API_SCREENER_COLUMNS_FOREX_DEFAULT_CATEGORY: process.env
    .REACT_APP_API_SCREENER_FOREX_COLUMNS_DEFAULT_CATEGORY as string,
  API_SCREENER_COLUMNS_CRYPTO_DEFAULT_CATEGORY: process.env
    .REACT_APP_API_SCREENER_CRYPTO_COLUMNS_DEFAULT_CATEGORY as string,
  API_SCREENER_COLUMNS_COMMODT_DEFAULT_CATEGORY: process.env
    .REACT_APP_API_SCREENER_COMMODT_COLUMNS_DEFAULT_CATEGORY as string,

  API_SCREENER_STOCK_COLUMNS: process.env.REACT_APP_API_SCREENER_STOCK_COLUMNS as string,
  API_SCREENER_FOREX_COLUMNS: process.env.REACT_APP_API_SCREENER_FOREX_COLUMNS as string,
  API_SCREENER_COMMODT_COLUMNS: process.env.REACT_APP_API_SCREENER_COMMODT_COLUMNS as string,
  API_SCREENER_CRYPTO_COLUMNS: process.env.REACT_APP_API_SCREENER_CRYPTO_COLUMNS as string,
  API_SCREENER_TICKER_META_DATA: process.env.REACT_APP_API_SCREENER_TICKER_META_DATA as string,
  API_SCREENER_UPDATE_USER_COLUMNS: process.env.REACT_APP_API_SCREENER_UPDATE_USER_COLUMNS as string,
  API_SCREENER_FLAG_TICKERS: process.env.REACT_APP_API_SCREENER_FLAG_TICKERS as string,
  API_SCREENER_PIN_TICKERS: process.env.REACT_APP_API_SCREENER_PIN_TICKERS as string,

  API_TV_CONFIG: process.env.REACT_APP_API_TV_CONFIG as string,
  API_TV_SYMBOLS: process.env.REACT_APP_API_TV_SYMBOLS as string,
  API_TV_HISTORIC_SIGNAL: process.env.REACT_APP_API_TV_HISTORIC_SIGNAL as string,
  API_TV_BARS: process.env.REACT_APP_API_TV_BARS as string,
  API_TV_CURRENT_SIGNAL: process.env.REACT_APP_API_TV_CURRENT_SIGNAL as string,

  API_WATCHLIST: process.env.REACT_APP_API_WATCHLIST as string,
  API_WATCHLIST_GROUP: process.env.REACT_APP_API_WATCHLIST_GROUP as string,
  API_WATCHLIST_GROUP_CREATE: process.env.REACT_APP_API_WATCHLIST_GROUP_CREATE as string,
  API_WATCHLIST_PAGE: process.env.REACT_APP_API_WATCHLIST_PAGE as string,
  API_WATCHLIST_TICKER: process.env.REACT_APP_API_WATCHLIST_TICKER as string,

  API_REPORTS_METADATA: process.env.REACT_APP_API_REPORTS_METADATA as string,
  API_REPORTS_OPTIONS: process.env.REACT_APP_API_REPORTS_OPTIONS as string,
};
