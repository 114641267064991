import React, { useState, useRef, forwardRef, useImperativeHandle, useEffect } from 'react';
import { TableFilter, DataGridTableRow } from 'src/lib/utils/tableUtils';
import {
  flexRender,
  getCoreRowModel,
  useReactTable,
  getSortedRowModel,
  getPaginationRowModel,
  getFilteredRowModel,
} from '@tanstack/react-table';
import { getCommonPinningStyles } from 'src/lib/utils/tableUtils';
import {
  Pagination,
  Tooltip,
  TooltipTrigger,
  TooltipContent,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverClose,
  Select,
} from 'src/components';
import { BarsArrowDownIcon, BarsArrowUpIcon, ArrowDownIcon, ArrowUpIcon, XMarkIcon } from '@heroicons/react/20/solid';
import { useSelector } from 'react-redux';
import { selectCurrentReportState } from 'src/pages/Reports/Reports.selector';
import Tableloader from '../loadingAnimation/tableloader';
const pageNumber = [10, 20, 50, 100];

const ReportsTable = forwardRef(({ headers, headersLabelMap, headersKeyMap }, ref) => {
  const tablerRef = useRef(null);

  const reportData = useSelector(selectCurrentReportState);
  const [sorting, setSorting] = useState([]);
  const [columnFilters, setColumnFilters] = useState([]);
  const [columnPinning, setColumnPinning] = useState({
    left: ['image', 'ticker'],
    right: [],
  });
  const [itemCount, setItemCount] = useState(10);
  const table = useReactTable({
    columns: headers,
    data: reportData?.data,
    getPaginationRowModel: getPaginationRowModel(),
    onSortingChange: setSorting,
    getSortedRowModel: getSortedRowModel(),
    onColumnFiltersChange: setColumnFilters,
    getFilteredRowModel: getFilteredRowModel(),
    onColumnPinningChange: setColumnPinning,
    state: {
      sorting,
      columnFilters,
      columnPinning,
    },
    getCoreRowModel: getCoreRowModel(),
    initialState: {},
  });
  const setNumberOfItems = (size) => {
    table.setPageSize(size);
  };
  const handleScroll = () => {
    if (tablerRef.current) {
      tablerRef.current.scrollIntoView({
        block: 'start',
      });
    }
  };
  useImperativeHandle(ref, () => ({
    getFilteredRows: () => table.getFilteredRowModel().rows,
    getColumnHeaders: () => headers.map((column) => column.header),
  }));
  useEffect(() => {
    table.resetColumnFilters();
    table.resetSorting();
  }, [headers]);
  return (
    <div className='reports-table '>
      <div className='reports-table-container'>
        <table className='table table-pin-rows ' id='reports-table'>
          <thead className=''>
            {table.getHeaderGroups().map((headerGroup) => (
              <tr key={headerGroup.id} className=''>
                {headerGroup.headers.map((header) => (
                  <th
                    key={header.id}
                    className={` ${header.column.getIsSorted() ? 'color-secondary' : ''} `}
                    style={{ ...getCommonPinningStyles(header.column, headers) }}>
                    <TableHeaderRow header={header} table={table} headersLabelMap={headersLabelMap} />
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          {reportData?.isLoading ? (
            <Tableloader count={headers.length} />
          ) : reportData?.error ? (
            <tbody className='td-error'>
              <div className='td-error-div'>No data Available</div>
            </tbody>
          ) : (
            <tbody>
              <div ref={tablerRef} />
              {table.getRowModel().rows.map((row) => (
                <tr key={row.id}>
                  <DataGridTableRow row={row} headersKeyMap={headersKeyMap} convertNumber={false} headers={headers} />
                </tr>
              ))}
            </tbody>
          )}
        </table>
      </div>

      <div className='reports-paginate '>
        <div className='reports-page-items '>
          <Select
            options={pageNumber}
            selected
            onChange={(val) => {
              setNumberOfItems(val);
              setItemCount(val);
            }}
          />
          <div className='reports-page-items-info '>
            <span>
              {itemCount < table?.getFilteredRowModel()?.rows?.length
                ? itemCount
                : table?.getFilteredRowModel()?.rows?.length}
            </span>{' '}
            of <span>{table?.getFilteredRowModel()?.rows?.length}</span> items
          </div>
        </div>
        {table.getPageCount() > 0 ? (
          <div className='reports-page-selection'>
            <Pagination
              totalPages={table.getPageCount() || 1}
              onPageChange={(value) => {
                table.setPageIndex(value - 1);
                handleScroll();
              }}
              dependency={[]}
            />
          </div>
        ) : null}
      </div>
    </div>
  );
});
export default ReportsTable;
function TableHeaderRow({ header, table, headersLabelMap }) {
  const getToolTipClass = (sorting, key) =>
    `reports-tooltip-option  ${sorting === key ? 'reports-tooltip-option-active' : ''}`;
  return (
    <>
      <Popover>
        <PopoverTrigger className='w-full'>
          {!header.isPlaceholder && !header.column.id.includes('image') ? (
            <>
              <Tooltip>
                <TooltipTrigger
                  className={` reports-table-header  ${
                    headersLabelMap[flexRender(header.column.columnDef.header, header.getContext())].dataType ===
                    'string'
                      ? 'justify-start items-start'
                      : 'justify-end items-end'
                  } ${header?.column?.getFilterValue() ? 'reports-table-header-val' : ''}`}>
                  <div className=' reports-column-order '>
                    {flexRender(header.column.columnDef.header, header.getContext())}
                    {
                      {
                        asc: <ArrowUpIcon className='size-6' />,
                        desc: <ArrowDownIcon className='size-6' />,
                      }[header.column.getIsSorted()]
                    }
                  </div>

                  {header?.column?.getFilterValue() ? (
                    header.column.getFilterValue() instanceof Array ? (
                      <div className='reports-filter-val'>
                        {header.column.getFilterValue()[0] ? (
                          <span>Min: {header.column.getFilterValue()[0] ? header.column.getFilterValue()[0] : ''}</span>
                        ) : null}
                        {header.column.getFilterValue()[1] ? (
                          <span>Max: {header.column.getFilterValue()[1] ? header.column.getFilterValue()[1] : ''}</span>
                        ) : null}
                      </div>
                    ) : (
                      <div className='reports-filter-val'>
                        <span>Value: {header.column.getFilterValue()}</span>
                      </div>
                    )
                  ) : (
                    ''
                  )}
                </TooltipTrigger>
                <TooltipContent className='Tooltip'>
                  {headersLabelMap[flexRender(header.column.columnDef.header, header.getContext())].label}
                </TooltipContent>
              </Tooltip>
            </>
          ) : (
            <div>{flexRender(header.column.columnDef.header, header.getContext())}</div>
          )}
        </PopoverTrigger>
        <PopoverContent>
          <div className=' reports-tooltip'>
            {header.column.getCanSort() ? (
              <>
                <PopoverClose
                  className={getToolTipClass(header.column.getIsSorted(), 'asc')}
                  onClick={() => header.column.toggleSorting(false)}>
                  <BarsArrowUpIcon className='size-7' /> Ascending
                </PopoverClose>
                <PopoverClose
                  className={getToolTipClass(header.column.getIsSorted(), 'desc')}
                  onClick={() => header.column.toggleSorting(true)}>
                  <BarsArrowDownIcon className='size-7' />
                  Descending
                </PopoverClose>
              </>
            ) : null}
            <PopoverClose
              className='reports-tooltip-option'
              onClick={() => {
                header.column.clearSorting();
                header.column.getFilterValue() instanceof Array
                  ? header.column.setFilterValue([])
                  : header.column.setFilterValue('');
              }}>
              <XMarkIcon className='size-7' />
              Clear
            </PopoverClose>
            <TableFilter column={header.column} table={table} />
          </div>
        </PopoverContent>
      </Popover>
    </>
  );
}
