import React, { useState } from 'react';
import VisibilitySensor from 'react-visibility-sensor';
import { Link } from 'react-router-dom';
import { data } from 'src/static-data';
import { MotionDiv, MotionH1, MotionH2, MotionImg, MotionP } from '../motion/motion.component';

export default function Blog() {
  const [isVisible, setIsVisible] = useState([false, false, false]);

  const handleVisibilityChange = (index: number, visible: boolean) => {
    const newVisibility = [...isVisible];
    newVisibility[index] = visible;
    setIsVisible(newVisibility);
  };

  const isVisibleProp = (index: number, prop1, prop2) => (isVisible[index] ? prop1 : prop2);
  return (
    <section className='blog'>
      <h1 className='blog__heading'>New Era of Trading</h1>
      {data.blogData.map((blog, index) => (
        <VisibilitySensor
          key={blog.id}
          partialVisibility
          onChange={(visible: boolean) => handleVisibilityChange(index, visible)}
          offset={{ bottom: 100 }}>
          <MotionDiv
            className='blog__main'
            initial={{ x: -100, opacity: 0 }}
            animate={isVisibleProp(index, { x: 0, opacity: 1 }, { x: -100, opacity: 0 })}
            transition={{ duration: 1.5, delay: 0.5 * index }}>
            <div className='blog__section'>
              <MotionDiv
                className='blog__card'
                initial={{ x: 100, opacity: 0 }}
                animate={isVisible[index] ? { x: 0, opacity: 1 } : { x: 100, opacity: 0 }}
                transition={{ duration: 1.5, delay: 0.5 * index }}>
                <div className='blog__cardLeft'>
                  <MotionH1
                    initial={{ x: -100, opacity: 0 }}
                    animate={isVisibleProp(index, { x: 0, opacity: 1 }, { x: -100, opacity: 0 })}
                    transition={{ duration: 1.5, delay: 0.5 * index }}>{`# ${blog.header}`}</MotionH1>
                  <MotionH2
                    initial={{ x: -100, opacity: 0 }}
                    animate={isVisibleProp(index, { x: 0, opacity: 1 }, { x: -100, opacity: 0 })}
                    transition={{ duration: 1.5, delay: 0.5 * index }}>
                    {blog.subHeaderOne}
                  </MotionH2>
                  <MotionP
                    initial={{ x: -100, opacity: 0 }}
                    animate={isVisibleProp(index, { x: 0, opacity: 1 }, { x: -100, opacity: 0 })}
                    transition={{ duration: 1.5, delay: 0.5 * index }}>
                    {blog.detailsFirst}
                  </MotionP>
                  <MotionH2
                    initial={{ x: -100, opacity: 0 }}
                    animate={isVisibleProp(index, { x: 0, opacity: 1 }, { x: -100, opacity: 0 })}
                    transition={{ duration: 1.5, delay: 0.5 * index }}>
                    {blog.subHeaderTwo}
                  </MotionH2>
                  <MotionP
                    initial={{ x: -100, opacity: 0 }}
                    animate={isVisibleProp(index, { x: 0, opacity: 1 }, { x: -100, opacity: 0 })}
                    transition={{ duration: 1.5, delay: 0.5 * index }}>
                    {blog.detailsSecond}
                  </MotionP>
                  <Link to={blog.link}>
                    <MotionP
                      className='blog__cardLeft--readMore'
                      initial={{ x: -100, opacity: 0 }}
                      animate={isVisibleProp(index, { x: 0, opacity: 1 }, { x: -100, opacity: 0 })}
                      transition={{ duration: 1.5, delay: 0.5 * index }}>
                      Read more...
                    </MotionP>
                  </Link>
                </div>
                <div className='blog__cardRight'>
                  <MotionImg
                    src={blog.image}
                    alt=''
                    initial={{ x: 100, opacity: 0 }}
                    animate={isVisibleProp(index, { x: 0, opacity: 1 }, { x: 100, opacity: 0 })}
                    transition={{ duration: 1.5, delay: 0.5 * index }}
                  />
                </div>
              </MotionDiv>
            </div>
          </MotionDiv>
        </VisibilitySensor>
      ))}
    </section>
  );
}
