import React, { useEffect, useState } from 'react';
import { convertNumberToString } from 'src/lib/utils/utils';
import { SocialSentimentGraph, FearGreedGauge } from 'src/components';
import { useDispatch, useSelector } from 'react-redux';
import { selectWatchlistSocialSentiment } from '../../Watchlist.selector';
import { fetchWatchlistSocialSentiment } from '../../Watchlist.reducer';
import Skeleton from 'react-loading-skeleton';
import { useParams } from 'react-router-dom';

import {
  watchlistSocialSentimentStat,
  watchlistSocialSentimentLike,
  watchlistSocialSentimentComment,
} from 'src/assets/Images';

function SocialSentiment() {
  const { ticker } = useParams();
  const dispatch = useDispatch();
  const socialSentiment = useSelector(selectWatchlistSocialSentiment);
  const [isTwitter, setIsTwitter] = useState(false);

  useEffect(() => {
    dispatch(fetchWatchlistSocialSentiment({ ticker }));
  }, [ticker, dispatch]);

  const colorMap = [
    { limit: 20, color: '#C72B2E', text: 'Extreme Fear' },
    { limit: 40, color: '#E76134', text: 'Fear' },
    { limit: 60, color: '#707070', text: 'Neutral' },
    { limit: 80, color: '#86C342', text: 'Greed' },
    { limit: 100, color: '#339F41', text: 'Extreme Greed' },
  ];

  const getSentimentData = (isTwitter) => {
    const sentiment = socialSentiment?.data?.social_sentiments[socialSentiment?.data?.social_sentiments.length - 1];

    return isTwitter ? sentiment?.twitter_sentiment : sentiment?.stocktwits_sentiment;
  };
  const getSentimentDataWeekAgo = (isTwitter) => {
    const sentiment = socialSentiment?.data?.social_sentiments[socialSentiment?.data?.social_sentiments.length - 8];

    return isTwitter ? sentiment?.twitter_sentiment : sentiment?.stocktwits_sentiment;
  };

  const pickColorAndText = (value) => {
    const sentiment = value * 100;
    const { color, text } = colorMap.find(({ limit }) => sentiment < limit) || {};
    return { sentiment: sentiment.toFixed(0), color, text };
  };

  const renderCard = (value, label, imageSrc) => (
    <article className='socialsentiment__Card'>
      <div>
        <p>{convertNumberToString(value)}</p>
        <p>{label}</p>
      </div>
      <img src={imageSrc} alt={label} />
    </article>
  );

  const handleClick = (isTwitter) => {
    setIsTwitter(isTwitter);
  };

  const sentimentData = getSentimentData(isTwitter);
  const { sentiment: currentSentiment, color: currentColor, text: currentText } = pickColorAndText(sentimentData || 0);
  const sentimentDataWeekAgo = getSentimentDataWeekAgo(isTwitter);
  const {
    sentiment: weekAgoSentiment,
    color: weekAgoColor,
    text: weekAgoText,
  } = pickColorAndText(sentimentDataWeekAgo || 0);

  return (
    <main className='socialsentiment'>
      <div className='socialsentiment__Main'>
        <div className='watchlistnews-btns'>
          <div className={`watchlistnews-nav ${!isTwitter && 'active'}`} onClick={() => handleClick(false)}>
            Stocktwits
          </div>
          <div className={`watchlistnews-nav ${isTwitter && 'active'}`} onClick={() => handleClick(true)}>
            Twitter
          </div>
        </div>
        {socialSentiment?.data ? (
          <div className='socialsentiment__Top'>
            <div className='socialsentiment__Left'>
              {renderCard(
                isTwitter ? socialSentiment?.data?.twitter_posts : socialSentiment?.data?.stocktwits_posts,
                'Posts',
                watchlistSocialSentimentStat,
                isTwitter,
              )}
              {renderCard(
                isTwitter ? socialSentiment?.data?.twitter_likes : socialSentiment?.data?.stocktwits_likes,
                'Likes',
                watchlistSocialSentimentLike,
                isTwitter,
              )}
              {renderCard(
                isTwitter ? socialSentiment?.data?.twitter_comments : socialSentiment?.data?.stocktwits_comments,
                'Comments',
                watchlistSocialSentimentComment,
                isTwitter,
              )}
            </div>
            <div className='socialsentiment__Right'>
              {socialSentiment?.data?.social_sentiments?.length > 0 && (
                <SocialSentimentGraph data={socialSentiment.data.social_sentiments} isTwitter={isTwitter} />
              )}
            </div>
          </div>
        ) : (
          <h2 className='ca-nodata'>No Data Available</h2>
        )}
        {socialSentiment?.isLoading ? (
          <Skeleton className='socialsentiment__Bottom' width='100%' />
        ) : socialSentiment?.data ? (
          <div className='socialsentiment__Bottom'>
            <div className='socialsentiment__BottomOne'>
              <div className='socialsentiment__BottomOneContainer'>
                <div className='fearGreed__left' style={{ background: currentColor }}>
                  <span className='fearGreed__leftText'>{currentSentiment}</span>
                </div>
                <div className='fearGreed__right'>
                  <span>Previous Close</span>
                  <span style={{ color: currentColor }}>{currentText}</span>
                </div>
              </div>

              <div className='socialsentiment__BottomOneContainer'>
                <div className='fearGreed__left' style={{ background: weekAgoColor }}>
                  <span className='fearGreed__leftText'>{weekAgoSentiment}</span>
                </div>
                <div className='fearGreed__right'>
                  <span>1 Week Ago</span>
                  <span style={{ color: weekAgoColor }}>{weekAgoText}</span>
                </div>
              </div>
            </div>

            <div className='socialsentiment__BottomTwo'>
              {socialSentiment?.data && (
                <FearGreedGauge
                  Width={420}
                  Height={280}
                  innerRadius={15}
                  needleGap={4}
                  segments={6}
                  colors={['#C72B2E', '#E76134', '#FFB300', '#FFC87C', '#86C342', '#339F41']}
                  textSize={4}
                  textColor='#b2b5be'
                  needleValue={currentSentiment}
                  needleWidth={3}
                  needleRadii={3}
                />
              )}
            </div>
          </div>
        ) : null}
      </div>
    </main>
  );
}

export default SocialSentiment;
