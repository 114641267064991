import { useState, useEffect } from 'react';
import { PriceGraph } from '../graph/PriceGraph';
import { activeElement } from 'src/lib/utils/utils';
import { WS_EVENT } from 'src/constants/ws.constant';
export function DataCardTwo({ data, title }) {
  return (
    <article className='dataCardTwo'>
      <div className='dataCardTwo__nav'>
        <h1>{title}</h1>
        <p>NYSE</p>
      </div>
      <div className='dataCardTwo__container'>
        {data?.map((item) => (
          <div className='dataCardTwo__list' key={item?.id}>
            <div className='dataCardTwo__Left'>
              <p className='text-[1.4rem] font-[700]'>{item?.sector}</p>
            </div>
            {parseFloat(item?.changesPercentage) >= 0 ? (
              <p style={{ color: '#00CF93' }} className='text-[1.4rem] font-[600]'>
                {' '}
                {parseFloat(item?.changesPercentage).toFixed(2)}%
              </p>
            ) : (
              <p style={{ color: '#FF3540' }} className='text-[1.4rem] font-[600]'>
                {' '}
                {parseFloat(item?.changesPercentage).toFixed(2)}%
              </p>
            )}
          </div>
        ))}
      </div>
    </article>
  );
}

export function DataCardThree({ data, title }) {
  const [active, setActive] = useState('tab-1');
  const [itemsToShow, setItemsToShow] = useState(10);
  const [itemsToShowSectors, setItemsToShowSectors] = useState(10);

  const handleShowMore = () => {
    setItemsToShow((prev) => prev + 10);
  };

  const handleShowMoreSectors = () => {
    setItemsToShowSectors((prev) => prev + 10);
  };

  const handleTabClick = (tab) => {
    setActive(tab);
  };

  return (
    <div>
      <div className='dataCardOne__nav'>
        <h1>{title}</h1>
        <p>NYSE</p>
      </div>
      <ul className=' menu menu-horizontal '>
        <li className={`${activeElement(active === 'tab-1')} `} onClick={() => handleTabClick('tab-1')}>
          Industries
        </li>
        <li className={`${activeElement(active === 'tab-2')} `} onClick={() => handleTabClick('tab-2')}>
          Sector
        </li>
      </ul>

      {data && active === 'tab-1' ? (
        <div>
          <div className='dataCardOne__container'>
            {data.industries_pe &&
              data.industries_pe.slice(0, itemsToShow).map((item) => (
                <div className='dataCardOne__list' key={item.id}>
                  <div className='dataCardOne__Left'>
                    <div className='dataCardOne__LeftSecond'>
                      <p>{item.industry}</p>
                      <p>{item.date}</p>
                    </div>
                  </div>
                  <div className='dataCardOne__Right'>
                    <p>{parseFloat(item.pe).toFixed(2)}</p>
                  </div>
                </div>
              ))}
            {data.industries_pe && itemsToShow < data.industries_pe.length && (
              <div className='dataCardOne__more' onClick={handleShowMore}>
                More
              </div>
            )}
          </div>
        </div>
      ) : (
        ''
      )}
      {data && active === 'tab-2' ? (
        <div>
          <div className='dataCardOne__container'>
            {data.sectors_pe &&
              data.sectors_pe.slice(0, itemsToShowSectors).map((item) => (
                <div className='dataCardOne__list' key={item.id}>
                  <div className='dataCardOne__Left'>
                    <div className='dataCardOne__LeftSecond'>
                      <p>{item.sector}</p>
                      <p>{item.date}</p>
                    </div>
                  </div>
                  <div className='dataCardOne__Right'>
                    <p>{parseFloat(item.pe).toFixed(2)}</p>
                  </div>
                </div>
              ))}
            {data.industries_pe && itemsToShowSectors < data.sectors_pe.length && (
              <div className='dataCardOne__more' onClick={handleShowMoreSectors}>
                More
              </div>
            )}
          </div>
        </div>
      ) : (
        ''
      )}
    </div>
  );
}

export function TopBarCard({ item, liveQuote = null }) {
  const { ticker, ticker_image, chart, last_close, last_change, last_change_perc } = item;

  const [lastValidLiveData, setLastValidLiveData] = useState({
    price: last_close,
    change: last_change,
    changesPercentage: last_change_perc,
  });

  const liveData =
    liveQuote?.event === WS_EVENT.WS_EVENT_SERVER_LIVE_QUOTE
      ? liveQuote?.data.find((i) => i.symbol === ticker) || {}
      : {};

  useEffect(() => {
    if (liveData.price != null || liveData.change != null || liveData.changesPercentage != null) {
      setLastValidLiveData({
        price: liveData.price ?? lastValidLiveData.price,
        change: liveData.change ?? lastValidLiveData.change,
        changesPercentage: liveData.changesPercentage ?? lastValidLiveData.changesPercentage,
      });
    }
  }, [liveData]);

  const price = liveData.price != null ? liveData.price : lastValidLiveData.price;
  const change = liveData.change != null ? liveData.change : lastValidLiveData.change;
  const changesPercentage =
    liveData.changesPercentage != null ? liveData.changesPercentage : lastValidLiveData.changesPercentage;
  return (
    <div className='topbar-card'>
      <div className='topbar-card-data'>
        <img src={ticker_image} className='' alt='company logo' />
        <div>
          <h1>{ticker}</h1>
          {price !== undefined && <h2>${price?.toFixed(4)}</h2>}
        </div>
      </div>
      <div className='topbar-card-graph'>
        <PriceGraph data={chart} color='#007BFF' showTooltip={false} />
        {change !== undefined && changesPercentage !== undefined && (
          <h3 className={`${change > 0 ? 'graph-green' : 'graph-red'}`}>{`${change?.toFixed(
            4,
          )} (${changesPercentage?.toFixed(2)}%)`}</h3>
        )}
      </div>
    </div>
  );
}

export const TopbarItem2 = ({ item, liveQuote }) => {
  const { ticker, ticker_image } = item;
  const liveData = liveQuote.find((i) => i.symbol === ticker) || {};

  return (
    <div className=' display: flex;align-items: center; w-[10rem] h-28 py-3'>
      <div className='flex'>
        <div className='w-16 h-16 rounded-full overflow-hidden 	 object-contain object-center'>
          <img src={ticker_image} className=' width: 100%; h-full object-center object-contain' alt='' />
        </div>
      </div>
      <div className='font-bold display: flex;flex-direction: column; py-3 w-[8rem] h-28 align-items: center; justify-content: center;'>
        <span className='font-bold text-2xl'>{ticker}</span>
        <p
          className={`${
            liveData.change?.toFixed(6) > 0 ? 'text-[rgba(32, 201, 151, 1)]' : 'text-[rgba(220, 53, 70, 1)]'
          } text-xl font-[600]`}>
          {` ${liveData.changesPercentage?.toFixed(6) || 0}%`}
        </p>
      </div>
    </div>
  );
};
