import { createSelector } from 'reselect';

const screenerMetaDataState = (state) => state.screenerMetaData;

export const selectScreenerMetaData = createSelector([screenerMetaDataState], (state) => state.data);
export const selectScreenerMetaDataLoading = createSelector([screenerMetaDataState], (state) => state.isLoading);
export const selectScreenerMarket = (state) => state.screenerMarket.marketType;
export const selectScreenerIndex = (state) => state.screenerMarket.index;
export const selectScreenerFlaggedColumn = (state) => state.screenerFlaggedColumn;
export const selectScreenerFilter = (state) => state.screenerFilter;
