import React, { useState, memo } from 'react';
import { DialogContent, DialogClose, DialogTrigger, useDialogContext } from 'src/components/Modal/dialogbox';
import { XMarkIcon } from '@heroicons/react/20/solid';
import { FunnelIcon } from '@heroicons/react/20/solid';
import { data as staticData } from 'src/static-data';
import { Select } from 'src/components';
import { useForm, Field } from '@tanstack/react-form';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';

import { fetchHeatmapFilterData } from 'src/pages/Heatmap/heatmap.reducer';
import { selectHeatmapMetaData } from 'src/pages/Heatmap/heatmap.selector';
interface FilterState {
  sector: string;
  industry: string;
  filters: Record<
    string,
    {
      filterOption: string;
      inputValue: string;
      multiplier: string;
    }
  >;
}

const HeatMapAdvancefilter = ({ sectors, marketIndex, market, setAdvancedFilteredActive }) => {
  const dispatch = useDispatch();
  const { setOpen } = useDialogContext();
  const [sector, setSector] = useState('');
  const metaData = useSelector(selectHeatmapMetaData);

  const form = useForm<FilterState>({
    onSubmit: (values) => {
      let columns = {};
      for (const f of Object.entries(values.value.filters)) {
        const [k, v] = f;
        if (v.multiplier !== '' || v.filterOption !== '' || v.inputValue !== '')
          columns[k] = {
            [v.filterOption]: +v.inputValue * staticData.multiplierMap[v.multiplier],
          };
      }
      const sector_filter = {};
      if (values.value.sector !== 'Select') {
        sector_filter['sector'] = values.value.sector;
      }
      if (values.value.industry !== 'Select') {
        sector_filter['industry'] = values.value.industry;
      }
      dispatch(
        fetchHeatmapFilterData({
          market_type: market,
          market_index: marketIndex,
          columns,
          sector_filter,
          time_frame: 'Daily',
        }),
      );
      setAdvancedFilteredActive(true);
      setOpen(false);
    },
    defaultValues: {
      sector: 'Select',
      industry: 'Select',
      filters: staticData.heatmapfilters.reduce((acc, val) => {
        acc[val] = {
          inputValue: '',
          filterOption: 'Select',
          multiplier: 'Select',
        };
        return acc;
      }, {} as FilterState['filters']),
    },
  });
  return (
    <>
      <DialogTrigger>
        <div className='heatmap-filter'>
          <FunnelIcon />
        </div>
      </DialogTrigger>
      <DialogContent className='advancefilter'>
        <div className='advancefilter-head'>
          <h1>Advance Filter</h1>
          <DialogClose>
            <XMarkIcon />
          </DialogClose>
        </div>
        <form
          onSubmit={(e) => {
            e.preventDefault();
            e.stopPropagation();
            form.handleSubmit();
          }}>
          <div className='advancefilter-body'>
            <ul className='advancefilter-body-list'>
              <li className='advancefilter-body-list-item'>
                <label htmlFor='sector'>
                  <h4>Sector</h4>
                  <Field form={form} name='sector'>
                    {({ state, setValue }) => (
                      <Select
                        preSelected={state.value as string}
                        preSelectDisabled={true}
                        options={sectors}
                        onChange={(e) => {
                          setSector(e);
                          setValue(e);
                        }}
                      />
                    )}
                  </Field>
                </label>
              </li>
              {sector !== 'Select' ? (
                <li className='advancefilter-body-list-item'>
                  <label htmlFor='industry'>
                    <h4>Industry</h4>
                    <Field form={form} name='industry'>
                      {({ state, setValue }) => (
                        <Select
                          preSelected={state.value}
                          preSelectDisabled
                          options={metaData.sector_industry[sector]}
                          onChange={setValue}
                        />
                      )}
                    </Field>
                  </label>
                </li>
              ) : null}

              {staticData.heatmapfilters.map((val, i) => (
                <li key={i} className='advancefilter-body-list-item'>
                  <FilterInputElement
                    form={form}
                    filterName={val}
                    options={staticData.heatmapFilters}
                    inputField
                    multiplierField
                  />
                </li>
              ))}
            </ul>
          </div>
          <div className='advancefilter-foot'>
            <button
              type='reset'
              className='btn btn-grey btn-rounded text-lg'
              onClick={() => {
                setAdvancedFilteredActive(false);
                form.reset();
                setOpen(false);
              }}>
              Reset
            </button>
            <button type='submit' className='btn btn-primary btn-rounded text-lg'>
              Apply
            </button>
          </div>
        </form>
      </DialogContent>
    </>
  );
};

export default memo(HeatMapAdvancefilter);
const FilterInputElement = ({ form, filterName, options, inputField, multiplierField }) => {
  return (
    <label className=''>
      <h4>{filterName}</h4>
      <div className='advancefilter-body-list-item-inputs'>
        <Field form={form} name={`filters.${filterName}.filterOption`}>
          {({ state, setValue }) => (
            <Select
              preSelected={state.value as string}
              className=''
              preSelectDisabled
              options={staticData.heatmapFilters}
              onChange={(val) => setValue(val !== 'Select' ? val : '')}
            />
          )}
        </Field>

        {inputField && (
          <Field form={form} name={`filters.${filterName}.inputValue`}>
            {({ state, setValue }) => (
              <input
                type='text'
                value={state.value as string}
                placeholder='Value'
                onChange={(e) => {
                  const inputValue = e.target.value;
                  if (/^\d*\.?\d*$/.test(inputValue)) setValue(inputValue);
                }}
              />
            )}
          </Field>
        )}

        {multiplierField && (
          <Field form={form} name={`filters.${filterName}.multiplier`}>
            {({ state, setValue }) => (
              <Select
                preSelectDisabled
                preSelected={state.value as string}
                options={staticData.multiplier}
                className=''
                onChange={(val) => setValue(val !== 'Select' ? val : '')}
              />
            )}
          </Field>
        )}
      </div>
    </label>
  );
};
