import React, { useEffect } from 'react';
import { ZScoreBar } from 'src/components';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { selectWatchlistFinancial } from '../Watchlist.selector';
import { fetchWatchlistFinancial } from '../Watchlist.reducer';
import { useParams } from 'react-router-dom';
import { FinancialTable } from 'src/components/WatchlistComponents/incomeTable';
import { FinancialGraphComponent } from 'src/components/WatchlistComponents/incomeGraph';
export default function Financials() {
  const { ticker } = useParams();
  const dispatch = useDispatch();
  const financials = useSelector(selectWatchlistFinancial);

  useEffect(() => {
    dispatch(fetchWatchlistFinancial({ ticker: ticker.toUpperCase() }));
  }, [ticker]);

  return (
    <main className='financials'>
      <FinancialGraphComponent data={financials?.data} isLoading={financials?.isLoading} />
      <section>
        <h1 className='financials-section-header'>Income Statement</h1>
        <div className='financials-keymatrics'>
          <div className='financials-keymatrics1'>
            <h3>
              The stocks enterprise value is:
              <br /> {financials?.data?.enterprise_value?.enterprise_value}
            </h3>
          </div>

          <div className='financials-keymatrics2'>
            <ZScoreBar
              high={500}
              low={0}
              currentValue={financials?.data?.financial_scores?.altmanZScore}
              width={500}
              height={100}
              dateRange='52 Week Range'
            />
          </div>
          <div className='financials-keymatrics1'>
            <h3>
              The stock is : <br />
              {financials?.data?.discounted_cash_flow?.dcf?.toFixed(2) >
              financials?.data?.discounted_cash_flow?.StockPrice
                ? 'Undervalued'
                : 'Overvalued'}
            </h3>
          </div>
        </div>
      </section>
      <FinancialTable />
    </main>
  );
}
