import React from 'react';
import { useSelector } from 'react-redux';
import { profileSubscriptions } from '../profile.selector';
import { convertDate } from 'src/lib/utils/utils';

const data = [
  {
    id: 1,
    slNO: 'Quant (Equities & CFC)',
    topic: '$495 (January)',
    date: 'Inactive',
    queries: '04-01-2022',
    attachment: 'Pay',
  },
  {
    id: 2,
    slNO: 'Quant (Equities & CFC)',
    topic: '$495 (January)',
    date: 'Inactive',
    queries: '04-01-2022',
    attachment: 'Pay',
  },
  {
    id: 3,
    slNO: 'Quant (Equities & CFC)',
    topic: '$495 (January)',
    date: 'Inactive',
    queries: '04-01-2022',
    attachment: 'Pay',
  },
  {
    id: 4,
    slNO: 'Quant (Equities & CFC)',
    topic: '$495 (January)',
    date: 'Inactive',
    queries: '04-01-2022',
    attachment: 'Pay',
  },
  {
    id: 5,
    slNO: 'Quant (Equities & CFC)',
    topic: '$495 (January)',
    date: 'Inactive',
    queries: '04-01-2022',
    attachment: 'Pay',
  },
];

export default function AccountSubscription() {
  const subscription = useSelector(profileSubscriptions);
  return (
    <section className='profile__subscription'>
      <h1>Accounts & Subscription</h1>
      <div className='profile__tableMain'>
        <table>
          <thead>
            <tr>
              <th>Plan Id</th>
              <th>Status</th>
              <th>Start Date</th>
              <th>End Date</th>
            </tr>
          </thead>
          <tbody>
            {subscription?.data.map((item) => (
              <tr key={item.id}>
                <td title={item.id}>#{item.id.slice(-10, -1)}</td>
                <td>{item.status}</td>
                <td>{convertDate(item.created_at.split('T')[0])}</td>
                <td>{convertDate(item.expires_at.split('T')[0])}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </section>
  );
}
