import React, { useEffect, useState, memo } from 'react';
import { DialogContent, DialogClose, DialogTrigger, useDialogContext } from 'src/components/Modal/dialogbox';
import { XMarkIcon } from '@heroicons/react/20/solid';
import { FunnelIcon } from '@heroicons/react/20/solid';
import { data as staticData } from 'src/static-data';
import { Select } from 'src/components';
import { useForm, Field } from '@tanstack/react-form';
import { fetchScreenerFilterData } from 'src/pages/Screener/Screener.reducer';
import { useDispatch, useSelector } from 'react-redux';

import {
  selectScreenerMetaData,
  selectScreenerMarket,
  selectScreenerIndex,
} from 'src/pages/Screener/Screener.selector';
interface FilterState {
  sector: string;
  industry: string;
  filters: Record<
    string,
    {
      filterOption: string;
      inputValue: string;
      multiplier: string;
    }
  >;
  ratings: Record<
    string,
    {
      filterOption: string;
    }
  >;
}
interface FilterOption {
  columns: Array<{ label: string; filters: string[] }>;
}

interface FilterStateItem {
  label: string;
  columns?: Array<{ label: string; filters: string[] }>;
  options?: Record<string, FilterOption>;
}
function ScreenerAdvancefilter({ setAdvancedFilteredActive }) {
  const [filterState, setFilterState] = useState<Array<FilterStateItem> | null>(null);
  const [selectedLabel, setSelectedLabel] = useState<string>('Overview');
  const [sector, setSector] = useState<string>('');
  const market = useSelector(selectScreenerMarket);
  const metadata = useSelector(selectScreenerMetaData);
  const screnerIndex = useSelector(selectScreenerIndex);
  const [sectors, setSectors] = useState(['']);
  const { setOpen } = useDialogContext();

  useEffect(() => {
    if (metadata) {
      const q = metadata?.metadata?.asset_classes?.find((ac) => ac.key === market);
      if (q?.indices) {
        const filters = q.indices[0];

        setFilterState(filters?.options);
      } else {
        setFilterState(q?.options);
      }
    }
  }, [metadata, market]);
  useEffect(() => {
    if (metadata?.sector_industry) {
      const sectors = Object.keys(metadata?.sector_industry);
      setSectors(sectors);
    }
  }, [metadata]);
  const dispatch = useDispatch();
  const form = useForm<FilterState>({
    onSubmit: (values) => {
      // debugger;
      // Extract the filter and rating columns in a single step
      const columns = Object.entries(values.value.filters).reduce((acc, [k, v]) => {
        if (v.multiplier && v.filterOption && v.inputValue && k !== 'dummy') {
          acc[k] = { [v.filterOption]: +v.inputValue * staticData.multiplierMap[v.multiplier] };
        }
        return acc;
      }, {});

      const rating_columns = Object.entries(values.value.ratings).reduce((acc, [k, v]) => {
        if (v.filterOption && k !== 'dummy') acc[k] = v.filterOption;
        return acc;
      }, {});

      // Create sector filter with a single object
      const sector_filter = {};
      if (values.value.sector !== 'Select') sector_filter['sector'] = values.value.sector;
      if (values.value.industry !== 'Select') sector_filter['industry'] = values.value.industry;

      // Dispatch with updated parameters
      dispatch(
        fetchScreenerFilterData({
          market_type: market,
          market_index: screnerIndex,
          columns,
          sector_filter,
          time_frame: 'Daily',
          rating_columns,
        }),
      );

      setAdvancedFilteredActive(true);
      setOpen(false);
    },
    defaultValues: {
      sector: 'Select',
      industry: 'Select',
      filters: ['dummy'].reduce((acc, val) => {
        acc[val] = {
          inputValue: '',
          filterOption: 'Select',
          multiplier: 'Select',
        };
        return acc;
      }, {} as FilterState['filters']),
      ratings: ['dummy'].reduce((acc, val) => {
        acc[val] = {
          filterOption: 'Select',
        };
        return acc;
      }, {} as FilterState['ratings']),
    },
  });
  const getScreenerNavClass = (val) => `advancefilter-nav-item ${val ? 'advancefilter-nav-item-active' : ''}`;

  return (
    <>
      <DialogTrigger>
        <div className='heatmap-filter'>
          <FunnelIcon />
        </div>
      </DialogTrigger>
      <DialogContent className='advancefilter'>
        <div className='advancefilter-screenerhead'>
          <div className='advancefilter-shead'>
            <h1>Advance Filter</h1>
            <DialogClose>
              <XMarkIcon />
            </DialogClose>
          </div>
          <ul className='advancefilter-nav'>
            {filterState?.map((e, i) => (
              <li
                key={i}
                onClick={() => {
                  setSelectedLabel(e.label);
                }}
                className={getScreenerNavClass(e.label === selectedLabel)}>
                {e.label}
              </li>
            ))}
          </ul>
        </div>

        <form
          onSubmit={(e) => {
            e.preventDefault();
            e.stopPropagation();
            form.handleSubmit();
          }}>
          <div className='advancefilter-body '>
            {filterState
              ? filterState.map((e) => (
                  <ul className={`advancefilter-body-list ${selectedLabel === e.label ? '' : 'hidden'}`} key={e.label}>
                    {e.label === 'Overview' && market === 'UsStocks' ? (
                      <>
                        <li className='advancefilter-body-list-item'>
                          <label htmlFor='sector'>
                            <h4>Sector</h4>
                            <Field form={form} name='sector'>
                              {({ state, setValue }) => (
                                <Select
                                  preSelected={state.value as string}
                                  preSelectDisabled={true}
                                  options={sectors}
                                  onChange={(e) => {
                                    setValue(e);
                                    setSector(e);
                                  }}
                                />
                              )}
                            </Field>
                          </label>
                        </li>
                        {sector !== 'Select' ? (
                          <li className='advancefilter-body-list-item'>
                            <label htmlFor='industry'>
                              <h4>Industry</h4>
                              <Field form={form} name='industry'>
                                {({ state, setValue }) => (
                                  <Select
                                    preSelected={state.value}
                                    preSelectDisabled
                                    options={metadata.sector_industry[sector]}
                                    onChange={setValue}
                                  />
                                )}
                              </Field>
                            </label>
                          </li>
                        ) : null}
                      </>
                    ) : null}
                    {e.label === 'Technicals'
                      ? staticData.technicals.map((f, ind) => (
                          <li className='advancefilter-body-list-item' key={`${ind}`}>
                            <FilterInputElement
                              form={form}
                              filterName={f}
                              options={staticData.heatmapFilters}
                              inputField
                              multiplierField
                            />
                          </li>
                        ))
                      : !e.options
                      ? e.columns?.map((ie, idx) =>
                          ie?.filters ? (
                            <li className='advancefilter-body-list-item' key={idx}>
                              <FilterInputElement
                                form={form}
                                filterName={ie.label}
                                options={ie?.filters}
                                inputField
                                multiplierField
                              />
                            </li>
                          ) : null,
                        )
                      : Object.values(e.options).map(
                          (t: { columns: Array<{ label: string; filters: string[] }> }, idx) =>
                            t.columns?.map((o, i) =>
                              o?.filters ? (
                                e.label === 'Rating Recommendations' ? (
                                  <li className='advancefilter-body-list-item' key={`${idx}-${i}`}>
                                    <FilterRatingInputElement
                                      form={form}
                                      filterName={o.label}
                                      options={o.filters}
                                      inputField={false}
                                      multiplierField={false}
                                    />
                                  </li>
                                ) : (
                                  <li className='advancefilter-body-list-item' key={`${idx}-${i}`}>
                                    <FilterInputElement
                                      form={form}
                                      filterName={o.label}
                                      options={o.filters}
                                      inputField
                                      multiplierField
                                    />
                                  </li>
                                )
                              ) : null,
                            ),
                        )}
                  </ul>
                ))
              : null}
          </div>
          <div className='advancefilter-foot'>
            <button
              type='reset'
              className='btn btn-grey  btn-rounded text-lg'
              onClick={() => {
                setAdvancedFilteredActive(false);
                form.reset();
                setOpen(false);
              }}>
              Reset
            </button>
            <button type='submit' className='btn btn-primary btn-rounded text-lg'>
              Apply
            </button>
          </div>
        </form>
      </DialogContent>
    </>
  );
}
export default memo(ScreenerAdvancefilter);
const FilterInputElement = ({ form, filterName, options, inputField, multiplierField }) => {
  return (
    <label className=''>
      <h4>{filterName}</h4>
      <div className='advancefilter-body-list-item-inputs'>
        <Field form={form} name={`filters.${filterName}.filterOption`}>
          {({ state, setValue }) => (
            <Select
              preSelected={state.value as string}
              className=''
              preSelectDisabled
              options={options}
              onChange={(val) => setValue(val !== 'Select' ? val : '')}
            />
          )}
        </Field>

        {inputField && (
          <Field form={form} name={`filters.${filterName}.inputValue`}>
            {({ state, setValue }) => (
              <input
                type='text'
                value={state.value as string}
                placeholder='Value'
                onChange={(e) => {
                  const inputValue = e.target.value;
                  if (/^\d*\.?\d*$/.test(inputValue)) setValue(inputValue);
                }}
              />
            )}
          </Field>
        )}

        {multiplierField && (
          <Field form={form} name={`filters.${filterName}.multiplier`}>
            {({ state, setValue }) => (
              <Select
                preSelectDisabled
                preSelected={state.value as string}
                options={staticData.multiplier}
                className=''
                onChange={(val) => setValue(val !== 'Select' ? val : '')}
              />
            )}
          </Field>
        )}
      </div>
    </label>
  );
};
const FilterRatingInputElement = ({ form, filterName, options, inputField, multiplierField }) => {
  return (
    <label className=''>
      <h4>{filterName}</h4>

      <Field form={form} name={`ratings.${filterName}.filterOption`}>
        {({ state, setValue }) => (
          <Select
            preSelected={state.value as string}
            className=''
            preSelectDisabled
            options={options}
            onChange={(val) => setValue(val !== 'Select' ? val : '')}
          />
        )}
      </Field>
    </label>
  );
};
