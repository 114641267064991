import Cookies from 'js-cookie';

class SessionTokenClass {
  // Method to get a token by key
  getToken(key: string): string | undefined {
    return Cookies.get(key);
  }

  setToken(key: string, token: string): void {
    Cookies.set(key, token, { expires: undefined });
  }

  // Method to remove a token by key
  removeToken(key: string): void {
    Cookies.remove(key);
  }
}

const SessionToken = new SessionTokenClass();
export default SessionToken;
