import { useRef, useState } from 'react';
import { Button } from '../Button/button.component';

interface FileInputProps {
  onFileChange: (file: File | null) => void;
}

const FileInput: React.FC<FileInputProps> = ({ onFileChange }) => {
  const [file, setFile] = useState<File | null>(null);
  const hiddenFileInput = useRef<HTMLInputElement>(null);

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const fileUploaded = event.target.files?.[0];
    if (fileUploaded) {
      setFile(fileUploaded);
      onFileChange(fileUploaded);
    }
  };

  const handleRemoveFile = () => {
    setFile(null);
    onFileChange(null);
  };

  return (
    <div>
      <div className='clubpost-input-dropzone' onClick={() => hiddenFileInput.current?.click()}>
        <label htmlFor='files'>
          <p>{file ? 'Change Photo' : 'Add files or Drop files here'}</p>
        </label>
        <input
          type='file'
          accept='image/jpeg, image/png'
          name='files'
          hidden
          ref={hiddenFileInput}
          onChange={handleFileChange}
          required
        />
      </div>
      {file && (
        <>
          <div className='clubpost-banner'>
            <img src={URL.createObjectURL(file)} alt='Preview' />
          </div>
          <div className='clubpost-buttons'>
            <Button className='btn-primary btn-lg' type='button' onClick={() => hiddenFileInput.current?.click()}>
              Change Photo
            </Button>
            <Button className='btn-primary btn-lg' type='button' onClick={handleRemoveFile}>
              Remove
            </Button>
          </div>
        </>
      )}
    </div>
  );
};

export default FileInput;
