import React, { useEffect, useState } from 'react';
import 'react-multi-carousel/lib/styles.css';
import 'react-calendar/dist/Calendar.css';
import { MarketsNews } from 'src/components';
import { Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import 'react-date-picker/dist/DatePicker.css';
import 'react-calendar/dist/Calendar.css';
import { selectMarketNews } from 'src/pages/Markets/markets.selector';
import { fetchMarketNews } from 'src/pages/Markets/markets.reducer';

import { activeElement } from 'src/lib/utils/utils';
import { loggedIn } from 'src/lib/auth';
export default function MarketNews() {
  const [marketType, setMarketType] = useState('');
  const dispatch = useDispatch();
  const news = useSelector(selectMarketNews);
  const isSignedIn = loggedIn();
  useEffect(() => {
    dispatch(fetchMarketNews({}));
  }, []);

  return (
    <section className='markets-news'>
      <h1>Latest News</h1>
      <ul className=' menu menu-horizontal '>
        <li
          className={`${activeElement(!marketType)} `}
          onClick={() => {
            setMarketType('');
            dispatch(fetchMarketNews({}));
          }}>
          General
        </li>
        <li
          className={`${activeElement(marketType === 'UsStocks')} `}
          onClick={() => {
            setMarketType('UsStocks');
            dispatch(fetchMarketNews({ mkt_type: 'UsStocks' }));
          }}>
          Stocks
        </li>
        <li
          className={`${activeElement(marketType === 'Fx')} `}
          onClick={() => {
            setMarketType('Fx');
            dispatch(fetchMarketNews({ mkt_type: 'Fx' }));
          }}>
          Forex
        </li>
        <li
          className={`${activeElement(marketType === 'Crypto')} `}
          onClick={() => {
            setMarketType('Crypto');
            dispatch(fetchMarketNews({ mkt_type: 'Crypto' }));
          }}>
          Crypto
        </li>
      </ul>

      {news?.data
        ? news?.data?.slice(0, 4).map((item: any, index: number) => <MarketsNews data={item} key={index} />)
        : ''}

      <Link to={isSignedIn ? '/watchlist/AMZN/stocks/news' : '/subscription'} className='markets-news-more'>
        more...
      </Link>
    </section>
  );
}
