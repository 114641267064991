import React, { useState } from 'react';
import { PrimaryHeader, SearchBar, Select } from 'src/components';
import { RatingsDownIcon, RatingsDummyChart, RatingsDownloadIcon } from 'src/assets/Images';

import { data } from 'src/static-data';

const dropdownOptions = ['Fundamental Ratings', 'Technical Ratings', 'Liquidity Ratings', 'Ratings Matrix'];

function Ratings() {
  const [headerText, setHeaderText] = useState('Ratings Matrix');
  return (
    <main className='ratings'>
      <PrimaryHeader text='iFIR$T Ratings' className='color-primary' />

      <section className='ratings__Body'>
        <section className='ratings__Top'>
          <div className='ratings__Top--Left'>
            <div className='ratings__Top--dropdown'>
              <h3>Options</h3>
              <Select options={dropdownOptions} title='Submitted Orders' setDropdown={setHeaderText} />
            </div>
            <div className='ratings__Top--dropdown'>
              <h3>Timeframe</h3>
              <Select options={dropdownOptions} title='Submitted Orders' setDropdown={setHeaderText} />
            </div>
            <SearchBar className='ratings__searchbar' />
          </div>
          <div className='ratings__Top--Right'>
            <img src={RatingsDownloadIcon} alt='' />
          </div>
        </section>
        <hr />
        <section className='ratings__Container'>
          <div className='ratings__ContainerLeft'>
            {headerText === 'Ratings Matrix' ? (
              <section className='ratingsmatrix'>
                <div className='ratingsmatrix__Container'>
                  <table>
                    <thead>
                      <tr>
                        <th>
                          <div className='ratings__HeaderContent'>
                            <span>Tickers</span>
                            <img src={RatingsDownIcon} alt='' />
                          </div>
                        </th>
                        <th>
                          <div className='ratings__HeaderContent'>
                            <span>Name</span>
                            <img src={RatingsDownIcon} alt='' />
                          </div>
                        </th>
                        <th>
                          <div className='ratings__HeaderContent'>
                            <span>Groups</span>
                            <img src={RatingsDownIcon} alt='' />
                          </div>
                        </th>
                        <th>
                          <div className='ratings__HeaderContent'>
                            <span>Fundamental</span>
                            <img src={RatingsDownIcon} alt='' />
                          </div>
                        </th>
                        <th>
                          <div className='ratings__HeaderContent'>
                            <span>Technical</span>
                            <img src={RatingsDownIcon} alt='' />
                          </div>
                        </th>
                        <th>
                          <div className='ratings__HeaderContent'>
                            <span>Liquidity</span>
                            <img src={RatingsDownIcon} alt='' />
                          </div>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {data.ratingMatrix.map((item) => (
                        <tr key={item.id}>
                          <td>
                            <img src={item.url} alt={item.id} />
                            <span>{item.tickerName}</span>
                          </td>
                          <td>{item.name}</td>
                          <td>{item.groups}</td>
                          <td>{item.fundamental}</td>
                          <td>{item.technical}</td>
                          <td>{item.liquidity}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </section>
            ) : (
              ''
            )}
          </div>
          <div className='ratings__ContainerRight'>
            <h1>Visual Representations</h1>
            <div className='ratings__Chart'>
              <img src={RatingsDummyChart} alt='' />
            </div>
          </div>
        </section>
      </section>
    </main>
  );
}

export default Ratings;
