// src/types/auth.types.ts
// src/reducers/authOtpLoginSlice.ts
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IAcademyReq } from 'src/lib/types/academy';
import { IClubResp, IClubMetaData, IClubContent, IClubDetails } from 'src/lib/types/club';
import { IError } from 'src/lib/types/error';

export interface IAcademyMContent {}

const INITIAL_STATE: IClubResp = {
  isLoading: false,
  data: null,
  error: null,
};

const clubMetaDataSlice = createSlice({
  name: 'clubMetaData',
  initialState: INITIAL_STATE,
  reducers: {
    fetchClubMetaData(state) {
      state.isLoading = true;
    },
    fetchClubMetaDataSuccess(state, action: PayloadAction<IClubMetaData>) {
      state.isLoading = false;
      state.data = action.payload;
      state.error = null;
    },
    fetchClubMetaDataFailure(state, action: PayloadAction<IError>) {
      state.isLoading = false;
      state.data = null;
      state.error = action.payload;
    },
  },
});

export const { fetchClubMetaData, fetchClubMetaDataSuccess, fetchClubMetaDataFailure } = clubMetaDataSlice.actions;
export const clubMetaDataReducer = clubMetaDataSlice.reducer;

const clubContentSlice = createSlice({
  name: 'clubContent',
  initialState: INITIAL_STATE,
  reducers: {
    fetchClubContent(state, action: PayloadAction<IAcademyReq>) {
      state.isLoading = true;
    },
    fetchClubContentSuccess(state, action: PayloadAction<IClubContent>) {
      state.isLoading = false;
      state.data = action.payload;
      state.error = null;
    },
    fetchClubContentFailure(state, action: PayloadAction<IError>) {
      state.isLoading = false;
      state.data = null;
      state.error = action.payload;
    },
  },
});

export const { fetchClubContent, fetchClubContentSuccess, fetchClubContentFailure } = clubContentSlice.actions;
export const clubContentReducer = clubContentSlice.reducer;

const clubDetailsSlice = createSlice({
  name: 'clubDetails',
  initialState: INITIAL_STATE,
  reducers: {
    fetchClubDetail(state, action: PayloadAction<IAcademyReq>) {
      state.isLoading = true;
    },
    fetchClubDetailSuccess(state, action: PayloadAction<IClubDetails>) {
      state.isLoading = false;
      state.data = action.payload;
      state.error = null;
    },
    fetchClubDetailFailure(state, action: PayloadAction<IError>) {
      state.isLoading = false;
      state.data = null;
      state.error = action.payload;
    },
  },
});

export const { fetchClubDetail, fetchClubDetailSuccess, fetchClubDetailFailure } = clubDetailsSlice.actions;
export const clubDetailsReducer = clubDetailsSlice.reducer;

const clubLikeSlice = createSlice({
  name: 'clubLike',
  initialState: INITIAL_STATE,
  reducers: {
    postClubLike(state, action: PayloadAction<any>) {
      state.isLoading = true;
    },
    postClubLikeSuccess(state, action: PayloadAction<any>) {
      state.isLoading = false;
      state.data = action.payload;
      state.error = null;
    },
    postClubLikeFailure(state, action: PayloadAction<IError>) {
      state.isLoading = false;
      state.data = null;
      state.error = action.payload;
    },
  },
});

export const { postClubLike, postClubLikeSuccess, postClubLikeFailure } = clubLikeSlice.actions;
export const clubLikeReducer = clubLikeSlice.reducer;

const clubCommentSlice = createSlice({
  name: 'clubComment',
  initialState: INITIAL_STATE,
  reducers: {
    postClubComment(state, action: PayloadAction<any>) {
      state.isLoading = true;
    },
    postClubCommentSuccess(state, action: PayloadAction<any>) {
      state.isLoading = false;
      state.data = action.payload;
      state.error = null;
    },
    postClubCommentFailure(state, action: PayloadAction<IError>) {
      state.isLoading = false;
      state.data = null;
      state.error = action.payload;
    },
  },
});

export const { postClubComment, postClubCommentSuccess, postClubCommentFailure } = clubCommentSlice.actions;
export const clubCommentReducer = clubCommentSlice.reducer;

const clubPostSlice = createSlice({
  name: 'clubPost',
  initialState: INITIAL_STATE,
  reducers: {
    postClubPost(state, action: PayloadAction<any>) {
      state.isLoading = true;
    },
    postClubPostSuccess(state, action: PayloadAction<any>) {
      state.isLoading = false;
      state.data = action.payload;
      state.error = null;
    },
    postClubPostFailure(state, action: PayloadAction<IError>) {
      state.isLoading = false;
      state.data = null;
      state.error = action.payload;
    },
  },
});

export const { postClubPost, postClubPostSuccess, postClubPostFailure } = clubPostSlice.actions;
export const clubPostReducer = clubPostSlice.reducer;

const clubCommentDeleteSlice = createSlice({
  name: 'clubCommentDelete',
  initialState: INITIAL_STATE,
  reducers: {
    postClubCommentDelete(state, action: PayloadAction<any>) {
      state.isLoading = true;
    },
    postClubCommentDeleteSuccess(state, action: PayloadAction<any>) {
      state.isLoading = false;
      state.data = action.payload;
      state.error = null;
    },
    postClubCommentDeleteFailure(state, action: PayloadAction<IError>) {
      state.isLoading = false;
      state.data = null;
      state.error = action.payload;
    },
  },
});

export const { postClubCommentDelete, postClubCommentDeleteSuccess, postClubCommentDeleteFailure } =
  clubCommentDeleteSlice.actions;
export const clubCommentDeleteReducer = clubCommentDeleteSlice.reducer;
