import React from 'react';
import Input from 'src/components/InputElements/input';
import { useForm } from '@tanstack/react-form';
import TextField from 'src/components/InputElements/textField.component';
import { DialogContent, DialogClose, DialogTrigger, useDialogContext } from 'src/components/Modal/dialogbox';
import { Button } from 'src/components';
import { useDispatch, useSelector } from 'react-redux';
import { selectProfilePassword, selectProfilePasswordInit } from 'src/pages/Profile/profile.selector';
import {
  fetchProfile,
  passwordUpdate,
  passwordUpdateInitiate,
  passwordUpdateReset,
} from 'src/pages/Profile/profile.reducer';
import * as Yup from 'yup';
import YupPassword from 'yup-password';
import { yupValidator } from '@tanstack/yup-form-adapter';
YupPassword(Yup);

export default function UpdatePassword() {
  const dispatch = useDispatch();
  const otpId = useSelector(selectProfilePasswordInit);
  const pwdUpdateStatus = useSelector(selectProfilePassword);
  const { setOpen } = useDialogContext();

  const passwordForm = useForm({
    defaultValues: {
      otp: '',
      current_password: '',
      create_password: '',
      repeat_password: '',
    },
    onSubmit: async ({ value }) => {
      dispatch(
        passwordUpdate({
          current_password: value.current_password,
          create_password: value.create_password,
          repeat_password: value.repeat_password,
          otp_id: otpId?.data,
          otp_val: value.otp,
        }),
      );
    },
    validatorAdapter: yupValidator(),
  });
  return (
    <>
      <div className='profile-pwd-update-btn-wrapper'>
        <Input type='password' name='password' disabled value='000000000' />
        <DialogTrigger className='btn profile-pwd-update-btn' onClick={() => dispatch(passwordUpdateInitiate({}))}>
          Update Password
        </DialogTrigger>
      </div>
      <DialogContent className='profile-pwdmodal'>
        <h1 className='profile-pwdmodal-header'>Update your password</h1>

        <form
          onSubmit={(e) => {
            e.preventDefault();
            e.stopPropagation();
            passwordForm.handleSubmit();
          }}>
          <div>
            <passwordForm.Field
              name='otp'
              validators={{
                onChange: ({ value }) => (!value ? 'Otp is required' : null),
              }}
              children={(field) => <TextField field={field} placeholder='One Time Password' />}
            />

            <passwordForm.Field
              name='current_password'
              validators={{
                onChange: Yup.string()
                  .required('Password is required')
                  .min(
                    8,
                    'password must contain 8 or more characters with at least one of each: uppercase, lowercase, number and special',
                  )
                  .minLowercase(1, 'password must contain at least 1 lower case letter')
                  .minUppercase(1, 'password must contain at least 1 upper case letter')
                  .minNumbers(1, 'password must contain at least 1 number')
                  .minSymbols(1, 'password must contain at least 1 special character'),
              }}
              children={(field) => <TextField type='password' field={field} placeholder='Current Password' />}
            />
            <passwordForm.Field
              name='create_password'
              validators={{
                onChange: Yup.string()
                  .required('Password is required')
                  .min(
                    8,
                    'password must contain 8 or more characters with at least one of each: uppercase, lowercase, number and special',
                  )
                  .minLowercase(1, 'password must contain at least 1 lower case letter')
                  .minUppercase(1, 'password must contain at least 1 upper case letter')
                  .minNumbers(1, 'password must contain at least 1 number')
                  .minSymbols(1, 'password must contain at least 1 special character'),
              }}
              children={(field) => <TextField type='password' field={field} placeholder='New Password' />}
            />
            <passwordForm.Field
              name='repeat_password'
              validators={{
                onChange: Yup.string()
                  .required('Password is required')
                  .min(
                    8,
                    'password must contain 8 or more characters with at least one of each: uppercase, lowercase, number and special',
                  )
                  .minLowercase(1, 'password must contain at least 1 lower case letter')
                  .minUppercase(1, 'password must contain at least 1 upper case letter')
                  .minNumbers(1, 'password must contain at least 1 number')
                  .minSymbols(1, 'password must contain at least 1 special character'),
              }}
              children={(field) => <TextField type='password' field={field} placeholder='Confirm Password' />}
            />
          </div>
          {pwdUpdateStatus?.data ? <h2 className='ca-nodata'>Password Updated Successfully</h2> : ''}
          {pwdUpdateStatus?.error ? <h2 className='ca-nodata'>Password Updation Failed</h2> : ''}
          <div className='profile-pwdmodal-btns'>
            {pwdUpdateStatus?.data || pwdUpdateStatus?.error ? (
              <div
                className='btn-grey btn-rounded btn-lg btn'
                onClick={() => {
                  passwordForm.reset();
                  setOpen(false);
                  dispatch(fetchProfile());
                  dispatch(passwordUpdateReset());
                }}>
                Close
              </div>
            ) : (
              <>
                <DialogClose className='btn-grey btn-rounded btn-lg btn'>Cancel</DialogClose>
                <Button type='submit' className='btn-primary btn-rounded btn-lg '>
                  {pwdUpdateStatus?.isLoading ? <div className='loader' /> : 'Update'}
                </Button>
              </>
            )}
          </div>
        </form>
      </DialogContent>
    </>
  );
}
