export function HeatmapBody({ filteredData, groupBy, sectors, timeFrame, filter }) {
  return (
    <div className=''>
      {groupBy === 'Sector' && sectors ? (
        sectors.map((sector) => {
          const sectorItems = filteredData.filter((item) => item.sector === sector);
          const filteredSectorItems = sectorItems.filter((item) =>
            shouldDisplayItem(item, timeFrame, filter.percentage, filter.symbol),
          );
          return filteredSectorItems.length > 0 ? (
            <div key={sector}>
              <h1 className='heatmap-sector'>{sector}</h1>
              <div className='heatmap-card-container'>
                {filteredSectorItems.map((item, index) => (
                  <HeatmapCard key={index} item={item} timeFrame={timeFrame} />
                ))}
              </div>
            </div>
          ) : null;
        })
      ) : (
        <div className='heatmap-card-container'>
          {filteredData
            ?.filter((item) => shouldDisplayItem(item, timeFrame, filter.percentage, filter.symbol))
            .map((item, index) => (
              <HeatmapCard key={index} item={item} timeFrame={timeFrame} />
            ))}
        </div>
      )}
    </div>
  );
}

export function HeatmapCard({ item, timeFrame }) {
  return (
    <article className={`${getColor(item[timeFrame.toLowerCase().trim().replaceAll(' ', '_')])} heatmap-card`}>
      <div className='heatmap-card-header'>
        <img src={item?.image} alt='company logo' />
        <h1>({item[timeFrame.toLowerCase().trim().replaceAll(' ', '_')]?.toFixed(2)}%)</h1>
      </div>
      <div className='heatmap-card-body'>
        <p>{item?.ticker}</p>
      </div>
    </article>
  );
}

function shouldDisplayItem(item, selection, percentage, symbol) {
  if (!percentage && !symbol) {
    return true;
  }
  return filterItem(item[selection.toLowerCase().trim().replaceAll(' ', '_')], percentage, symbol);
}

function filterItem(value, percentage, symbol) {
  if (symbol === 1 && percentage < value) {
    return true;
  } else if (symbol === 2 && percentage > value) {
    return true;
  } else if (symbol === 3 && 0 === value) {
    return true;
  } else if (!symbol && !percentage && value === 0) {
    return true;
  }
  return false;
}
const getColor = (value) => {
  if (value > 4) return 'bg-5u';
  if (value <= 4 && value > 3) return 'bg-4u';
  if (value <= 3 && value > 2) return 'bg-3u';
  if (value <= 2 && value > 1) return 'bg-2u';
  if (value <= 1 && value > 0) return 'bg-1u';
  if (value === 0) return 'bg-u';
  if (value < 0 && value >= -1) return 'bg-1b';
  if (value < -1 && value >= -2) return 'bg-2b';
  if (value < -2 && value >= -3) return 'bg-3b';
  if (value < -3 && value >= -4) return 'bg-4b';
  if (value < -4) return 'bg-5b';
};
