import React, { useEffect, useState } from 'react';
import 'react-calendar/dist/Calendar.css';
import Calendar from 'react-calendar';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import 'react-date-picker/dist/DatePicker.css';
import { selectMarketHoliday } from 'src/pages/Markets/markets.selector';
import { fetchMarketHoliday } from 'src/pages/Markets/markets.reducer';
import { IMarketHoliday } from 'src/lib/types/markets';

export default function MarketsHoliday() {
  const dispatch = useDispatch();
  const tradingHours = useSelector(selectMarketHoliday);
  useEffect(() => {
    dispatch(fetchMarketHoliday());
    const date = new Date();
    const year = date.getFullYear();
    setActiveCalendarYear(year);
  }, []);
  const [activeCalendarYear, setActiveCalendarYear] = useState(2024);
  function isHoliday(inputDate: Date) {
    if (tradingHours?.data) {
      const inputYear = inputDate.getFullYear().toString();

      if (inputYear in tradingHours?.data?.nyse_holidays) {
        const holidaysForYear = tradingHours?.data?.nyse_holidays[inputYear];
        return holidaysForYear.some((holiday: { date: string | number | Date }) => {
          const dt = new Date(holiday.date);
          dt.setHours(0);
          dt.setMinutes(0);
          dt.setSeconds(0);

          return dt.getTime() === inputDate.getTime() ? true : false;
        });
      }
    }
  }

  return (
    <section className='markets-holidays-container'>
      <div className='markets-holidays'>
        <h1>NYSE - Holidays and Trading Hours :</h1>
        <h6>Stock Market Hours</h6>
        <span>- Opening Hour: {tradingHours?.data?.nyse_market_open} AM ET</span>
        <span>- Closing Hour: {tradingHours?.data?.nyse_market_close} PM ET</span>
        <div className='markets-holidays-box'>
          <Calendar
            maxDate={new Date('2026')}
            minDate={new Date('2019')}
            onActiveStartDateChange={(e) => {
              if (e.activeStartDate) {
                const date = new Date(e.activeStartDate);
                const year = date.getFullYear();
                setActiveCalendarYear(year);
              }
            }}
            tileClassName={({ date }) => {
              return isHoliday(date) ? 'highlight' : null;
            }}
          />
        </div>{' '}
      </div>
      <div className='markets-holiday-info  '>
        <h3>USA Holidays & Observances</h3>

        {tradingHours?.data && activeCalendarYear ? (
          <div className='markets-holiday-info-items '>
            {tradingHours?.data?.nyse_holidays[activeCalendarYear]?.map((holiday: IMarketHoliday, index: number) => (
              <div key={index} className='markets-holiday-info-item'>
                <p>{holiday.date}</p>
                <p>{holiday.holiday}</p>
              </div>
            ))}
          </div>
        ) : null}
      </div>
    </section>
  );
}
