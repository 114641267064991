import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { ClubCard } from 'src/components';
import Carousal from 'src/components/carousel/carousel.component';
import { useDispatch, useSelector } from 'react-redux';
import { fetchClubContent, fetchClubMetaData } from '../club.reducer';
import { selectClubContent, selectClubMetaData } from '../club.selector';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import ImageFullCard from 'src/components/Card/ImageFullCard.component';
import { IClubContent } from 'src/lib/types/club';
import Pagination from 'src/components/Pagination/pagination.component';
import { NewsCardloader } from 'src/components/loadingAnimation/cardloader';
import Skeleton from 'react-loading-skeleton';

export default function ClubIndex() {
  const dispatch = useDispatch();
  const metaData = useSelector(selectClubMetaData);
  const content = useSelector(selectClubContent);
  const [category, setCategory] = useState('');
  useEffect(() => {
    dispatch(fetchClubMetaData());
    dispatch(fetchClubContent({}));
  }, []);
  const responsive1 = {
    desktop: {
      breakpoint: { max: 3000, min: 0 },
      items: 1,
    },
  };
  const responsive2 = {
    desktop: {
      breakpoint: { max: 2500, min: 1367 },
      items: 4,
    },
    largeTablet: {
      breakpoint: { max: 1366, min: 1281 },
      items: 3,
    },
    nestHubLarge: {
      breakpoint: { max: 1280, min: 1025 },
      items: 2,
    },
    nestHubMedium: {
      breakpoint: { max: 1024, min: 913 },
      items: 2,
    },
    nestHubSmall: {
      breakpoint: { max: 912, min: 769 },
      items: 2,
    },
    tablet: {
      breakpoint: { max: 768, min: 465 },
      items: 1,
      centerMode: true, // Ensures centering when there is only one item
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
      centerMode: true, // Ensures centering when there is only one item
    },
  };
  const responsive3 = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 1024 },
      items: 9, // Adjust this number for super large screens
    },
    desktop: {
      breakpoint: { max: 1024, min: 768 },
      items: 9, // Adjust this number for desktop screens
    },
    tablet: {
      breakpoint: { max: 768, min: 464 },
      items: 6, // Adjust this number for tablet screens
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 6, // Adjust this number for mobile screens
    },
  };

  return (
    <section className=' club-body-container '>
      <div className=' club-body-container-main'>
        {metaData?.isLoading ? (
          <div className='club-card-lg'>
            <figure>
              <Skeleton height='38rem' />
            </figure>
            <div className='club-card-lg-data'>
              <Skeleton height='2.1rem' />
              <Skeleton height='2.1rem' />
              <Skeleton height='2.1rem' />
              <Skeleton height='2.1rem' />
            </div>
          </div>
        ) : (
          <Carousel
            responsive={responsive1}
            infinite={true}
            autoPlay={false}
            arrows={true}
            showDots={false}
            keyBoardControl={true}
            containerClass='carousel-container'
            itemClass='carousel-item'>
            {metaData?.data?.most_liked_contents.map((item: IClubContent) => (
              <Link className='club-card-lg' to={`${item.id}`}>
                <figure>
                  <img src={item.image_short} alt='' />
                </figure>

                <div className='club-card-lg-data'>
                  <h3>{item.category}</h3>
                  <h1>{item.headline}</h1>
                  <h4>
                    By <span>{item.user_name}</span>
                  </h4>
                  <time>
                    <span>
                      {`${new Date(item.updated_at).toLocaleString('en-US', {
                        month: 'long',
                      })} ${new Date(item.updated_at).getDate()},${new Date(item.updated_at).getFullYear()}` || ' '}
                    </span>
                    <div />
                  </time>
                  <p>{item.short_content}</p>
                  <h4>Continue Reading</h4>
                </div>
              </Link>
            ))}
          </Carousel>
        )}

        {metaData?.data && (
          <Carousel
            responsive={responsive2}
            infinite={true}
            autoPlay={false}
            arrows={true}
            showDots={false}
            keyBoardControl={true}
            containerClass='carousel-container'
            itemClass='carousel-item'>
            {metaData?.data?.categories.map((i: string) =>
              metaData?.data?.category_wise_most_liked_contents[i].map((item: IClubContent, e: number) => (
                <ImageFullCard key={e} item={item} />
              )),
            )}
          </Carousel>
        )}

        {metaData?.data && (
          <Carousal>
            {metaData?.data?.categories.map((item: string, i: number) => (
              <div
                className='badge badge-outline badge-primary'
                key={i}
                title={item}
                onClick={() => {
                  dispatch(fetchClubContent({ page: 1, category: item }));
                  setCategory(item);
                }}>
                {item}
              </div>
            ))}
          </Carousal>
        )}

        <div className='club-content-cards'>
          {content?.isLoading ? (
            <>
              {Array.from({ length: 9 }).map((_, rowIndex) => (
                <NewsCardloader key={rowIndex} />
              ))}
            </>
          ) : content?.data?.length > 0 ? (
            content?.data?.map((item: IClubContent) => <ClubCard item={item} key={item.id} />)
          ) : (
            <h2 className='text-[1.4rem]'>No Data Available</h2>
          )}
        </div>

        <Pagination
          totalPages={category ? metaData?.data?.category_wise_num_pages[category] : metaData?.data?.total_num_pages}
          onPageChange={(value) => {
            dispatch(fetchClubContent({ page: value, category }));
          }}
          dependency={[category]}
        />
      </div>

      <div className='club-container-sub'>
        {metaData?.data &&
          metaData?.data?.most_active_contents.map((item: IClubContent, i: number) => (
            <Link to={`${item.id}`} key={i}>
              {item.headline}
            </Link>
          ))}
      </div>
    </section>
  );
}
