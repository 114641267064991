import { useEffect } from 'react';
import HTMLReactParser from 'html-react-parser';
import { useParams, useNavigate } from 'react-router-dom';
import { Para, AcademyCard, PrimaryHeader } from 'src/components';
import Loader from 'src/components/loadingAnimation/loadingAnimation.component';
import { ChevronLeftIcon } from '@heroicons/react/20/solid';
import { useDispatch, useSelector } from 'react-redux';
import { selectAcademyDetails } from '../academy.selector';
import { fetchAcademyDetail } from '../academy.reducer';
import { IAcademyContent } from 'src/lib/types/academy';
import { cardDummyImage } from 'src/assets/Images';
import Skeleton from 'react-loading-skeleton';
import { NewsCardloader } from 'src/components/loadingAnimation/cardloader';

export default function AcademyDetails() {
  const { id } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const details = useSelector(selectAcademyDetails);
  useEffect(() => {
    dispatch(fetchAcademyDetail({ id }));
    setTimeout(() => {
      window.scrollTo({
        top: 250,
        left: 100,
        behavior: 'smooth',
      });
    }, 200);
  }, [id]);

  return (
    <div className='navbar-fix'>
      <PrimaryHeader text='Academy' className='color-primary' />

      <div className='details-container'>
        <div className='academy-details-nav-back' onClick={() => navigate(-1)}>
          <ChevronLeftIcon className='size-6' />
          <span>Back</span>
        </div>
        <section className='academy-details-body'>
          {details?.isLoading ? (
            <>
              <Skeleton className='img' />
              <Skeleton className='academy-details-header' />
              <Skeleton height='2.1rem' />
              <Skeleton height='2.1rem' />
              <Skeleton height='2.1rem' />
              <Skeleton height='2.1rem' />
            </>
          ) : (
            details?.data && (
              <>
                <img src={details?.data?.image_long || cardDummyImage} alt='' />
                <h1 className='academy-details-header'>{HTMLReactParser(details?.data?.headline)}</h1>
                <Para url={details?.data?.details} />
                <div className='academy-details-hashtag'>
                  {details?.data?.hash_tags?.map((item: string) => `#${item} `)}
                </div>
              </>
            )
          )}
        </section>

        <div className='academy-details-others '>
          <h1>Other Topics</h1>
          <div className='academy-details-others-body'>
            {details?.isLoading
              ? Array.from({ length: 2 }).map((_, rowIndex) => <NewsCardloader key={rowIndex} />)
              : details?.data?.similar_contents.map((item: IAcademyContent, index: number) => (
                  <AcademyCard item={item} key={index} />
                ))}
          </div>
        </div>
      </div>
    </div>
  );
}
