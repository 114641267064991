import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { SearchBar, Select, Button } from 'src/components';
import { ArrowRightIcon } from '@heroicons/react/20/solid';
import { loggedIn } from 'src/lib/auth';

import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { updateMarket, updateIndex } from 'src/pages/Reports/Reports.reducer';
import { selectReportFilterMarketState } from 'src/pages/Reports/Reports.selector';
import { brandLogoNetflix } from 'src/assets/Images';
import { HeaderEditIcon, HeaderWhiteDeleteIcon, HeaderGrayLeftArrowIcon } from 'src/assets/Images';
import SupportSearchbox from '../searchbox/supportSearchBox';
const stock = ['Stocks', 'Forex', 'Crypto', 'Commodities'];
export function Header({ text }) {
  return (
    <section className='header'>
      <div>
        <span>{text}</span>
      </div>
    </section>
  );
}
export function PortfolioHeader({ text }) {
  return (
    <section className='portfolioHeader'>
      <div className='portfolioHeader__container'>{text}</div>
    </section>
  );
}
export function IRecommendHeader({ text }) {
  return (
    <header className='irecommendHeader primary-header color-primary'>
      <h1>{text}</h1>
    </header>
  );
}
export function QuantHeaderSecond({ text, subText }) {
  return (
    <section className='quantheadersecond'>
      <div className='quantheadersecond__main'>
        <div className='quantheadersecond__title'>
          <div className='quantheadersecond__sub'>
            <img src={HeaderGrayLeftArrowIcon} alt='' />
            <h1>{text}</h1>
          </div>
          <span>( {subText} )</span>
          <div className='quantHeaderSecond__icons'>
            <img src={HeaderEditIcon} alt='' />
            <img src={HeaderWhiteDeleteIcon} alt='' />
          </div>
        </div>
      </div>
    </section>
  );
}
export function SupportsHeader() {
  const nav = useNavigate();
  const isAuth = loggedIn();
  return (
    <header className='support-header '>
      <h1>Supports</h1>
      <p>Search for an answer or browse help topics to create a ticket</p>

      <SupportSearchbox onClick={(id) => nav(`/support/faq/${id}`)} />

      <div className='support-header-btns'>
        {isAuth && (
          <Link to='/support/tickets' className='btn-primary btn-rounded btn '>
            MY REQUESTS
          </Link>
        )}
        <Link to='/support/ticket' className='btn-primary btn-rounded btn '>
          NEW REQUEST
        </Link>
      </div>
    </header>
  );
}

export function ReportsHeader({ text, data }) {
  const dispatch = useDispatch();
  const market = useSelector(selectReportFilterMarketState);
  const [markets] = useState(data?.asset_classes.map((e) => e.label));
  const [indexs, setIndexs] = useState([]);
  useEffect(() => {
    if (data?.asset_classes) {
      const q = data?.asset_classes?.find((ac) => ac.label === market);
      if (q?.indices) {
        setIndexs(q.indices.map((e) => e.label));
      } else {
        setIndexs([]);
      }
    }
  }, [market]);

  return (
    <header className='reportsHeader'>
      <div className='reportsHeader__section'>
        <span>{text}</span>
        <div className='reportsHeader__dropdowns'>
          <>
            <Select
              options={markets}
              onChange={(e) => {
                dispatch(updateMarket({ market_type: e }));
              }}
            />
            {indexs.length > 0 ? (
              <Select
                options={indexs}
                onChange={(e) => {
                  dispatch(updateIndex({ market_index: e }));
                }}
              />
            ) : (
              ''
            )}
          </>
        </div>
      </div>
    </header>
  );
}

export function IExpertHeader({ text }) {
  return (
    <header className='iexpertHeader'>
      <section className='iexpertHeader__container'>
        <div className='iexpertHeader__title color-primary'>{text}</div>
        <div className='iexpertHeader__options'>
          <Select options={stock} />
          <SearchBar className='iexpertHeader__searchbar' />
        </div>
      </section>
    </header>
  );
}

export function IExpertDetailsHeader() {
  return (
    <section className='iexpertDetails'>
      <section className='iexpertDetails__Top'>
        <div className='iexpertDetails__Left'>
          <img src={brandLogoNetflix} alt='' />
          <h1>NETFLIX, INC</h1>
        </div>
        <section className='iexpertDetails__Right'>
          <div className='iexpertDetails__Right--L'>
            <h1>
              350.43 <span>USD</span> <span>+6.33(+1.84%)</span>
            </h1>
            <p>MARKET CLOSED (AS OF APRIL 22, 19:59 EDT)</p>
          </div>
          <div className='iexpertDetails__Right--R'>
            <h3>NFLX</h3>
            <img src={brandLogoNetflix} alt='' />
            <h3>NASDAQ</h3>
          </div>
        </section>
      </section>
      <hr />
      {/* <section className='iexpertDetails__Bottom'>
        <div className='iexpertDetails__Bottom--L'>
          <p>UPCOMING EARNINGS</p>
          <h1>In 5 Days</h1>
        </div>
        <div className='iexpertDetails__Bottom--R'>
          <div className='iexpertDetails__values'>
            <p>EPS</p>
            <h3>6.08</h3>
          </div>
          <div className='iexpertDetails__values'>
            <p>MARKET CAP</p>
            <h3>2.64T</h3>
          </div>
          <div className='iexpertDetails__values'>
            <p>DIV YIELD</p>
            <h3>0.54%</h3>
          </div>
          <div className='iexpertDetails__values'>
            <p>P/E</p>
            <h3>27.62</h3>
          </div>
        </div>
      </section> */}
    </section>
  );
}

// Backtesting Header :
export function BackTestingHeader({ text }) {
  return (
    <section className='backtestingheader'>
      <div className='backtestingheader__main'>
        <div className='backtestingheader__left'>
          <h1 className='color:$color-primary'>{text}</h1>
          <SearchBar text='Serach item' className='backtestingheader__searchBar' />
        </div>
        <div className='backtestingheader__right'>
          <h2>
            Last updated at: <span>26-04-2022 20:32:16 EST</span>
          </h2>
        </div>
      </div>
    </section>
  );
}

export function RatingsHeader({ text }) {
  return (
    <header className='reportsHeader'>
      <div className='reportsHeader__section'>
        <span>{text}</span>
        <div className='reportsHeader__dropdowns'>
          <Select options={stock} />
          <Select options={stock} />
        </div>
      </div>
    </header>
  );
}
