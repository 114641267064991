import {
  BlogImgOne,
  BlogImgThree,
  BlogImgTwo,
  CTAMarketsIcon,
  CTAPortfolioIcon,
  CTAScreenerIcon,
  CTASignalsIcon,
  LinkedInIcon,
  FacebookIcon,
  YoutubeIcon,
  TwitterIcon,
  QuantLandingImageOne,
  QuantLandingImageTwo,
  QuantLandingImageThree,
  TelegramIcon,
  ProfileZerodhaLogo,
  ProfileAlpacaLogo,
  ProfileBingxLogo,
  ProfileGeminiLogo,
  ProfileForexLogo,
  ProfileTickMillLogo,
  ProfileTradeStationLogo,
  ProfileTradovateLogo,
  ProfileCurrencyLogo,
  AboutIconOne,
  AboutIconThree,
  AboutIconTwo,
  AboutIconFour,
  AboutIconFive,
  AboutIconSix,
  AboutIconSeven,
  AboutIconEight,
  AboutIconNine,
  AboutIconTen,
  NetflixDummyLogo,
  QuantPortfolioCommodityIcon,
  QuantPortfolioCryptoIcon,
  QuantPortfolioEquityIcon,
  QuantPortfolioMicrosoftDummyIcon,
  PortfolioArtificialIntelligenceImage,
  PortfolioAutonomousImage,
  PortfolioBlockchainImage,
  PortfolioCyberSecurityImage,
  PortfolioDigitalConsumerImage,
  PortfolioDigitalWalletImage,
  PortfolioDisruptiveInnovationImage,
  PortfolioEarlyStageDisruptionImage,
  PortfolioEfficientEnergyImage,
  PortfolioElectricVehicleImage,
  PortfolioESGImage,
  PortfolioFintechInnovationImage,
  RiskProfileDummyAppleLogo,
  PortfolioHydrogenEconomyImage,
  PortfolioMetaverseImage,
  PortfolioMobilityImage,
  PortfolioNextGenerationInternetImage,
  PortfolioSmartCitiesImage,
  PortfolioSpaceExplorationImage,
  PortfolioTheDigitalEconomyImage,
  PortfolioThePrintingImage,
  PortfolioTheSemiconductorImage,
  PortfolioWebThreeImage,
  RatingsDownIcon,
  RatingsDummyCircleIcon,
  RatingsDownloadIcon,
} from 'src/assets/Images';

export const data = {
  ratingMatrix: [
    {
      id: 1,
      url: RatingsDummyCircleIcon,
      tickerName: 'MSFT',
      name: 'Microsoft Corp.',
      groups: 'Computer',
      fundamental: 1,
      technical: 1,
      liquidity: 1,
    },
    {
      id: 2,
      url: RatingsDummyCircleIcon,
      tickerName: 'MSFT',
      name: 'Microsoft Corp.',
      groups: 'Computer',
      fundamental: 1,
      technical: 1,
      liquidity: 1,
    },
    {
      id: 3,
      url: RatingsDummyCircleIcon,
      tickerName: 'MSFT',
      name: 'Microsoft Corp.',
      groups: 'Computer',
      fundamental: 1,
      technical: 1,
      liquidity: 1,
    },
    {
      id: 4,
      url: RatingsDummyCircleIcon,
      tickerName: 'MSFT',
      name: 'Microsoft Corp.',
      groups: 'Computer',
      fundamental: 1,
      technical: 1,
      liquidity: 1,
    },
    {
      id: 5,
      url: RatingsDummyCircleIcon,
      tickerName: 'MSFT',
      name: 'Microsoft Corp.',
      groups: 'Computer',
      fundamental: 1,
      technical: 1,
      liquidity: 1,
    },
    {
      id: 6,
      url: RatingsDummyCircleIcon,
      tickerName: 'MSFT',
      name: 'Microsoft Corp.',
      groups: 'Computer',
      fundamental: 1,
      technical: 1,
      liquidity: 1,
    },
    {
      id: 7,
      url: RatingsDummyCircleIcon,
      tickerName: 'MSFT',
      name: 'Microsoft Corp.',
      groups: 'Computer',
      fundamental: 1,
      technical: 1,
      liquidity: 1,
    },
    {
      id: 8,
      url: RatingsDummyCircleIcon,
      tickerName: 'MSFT',
      name: 'Microsoft Corp.',
      groups: 'Computer',
      fundamental: 1,
      technical: 1,
      liquidity: 1,
    },
    {
      id: 9,
      url: RatingsDummyCircleIcon,
      tickerName: 'MSFT',
      name: 'Microsoft Corp.',
      groups: 'Computer',
      fundamental: 1,
      technical: 1,
      liquidity: 1,
    },
    {
      id: 10,
      url: RatingsDummyCircleIcon,
      tickerName: 'MSFT',
      name: 'Microsoft Corp.',
      groups: 'Computer',
      fundamental: 1,
      technical: 1,
      liquidity: 1,
    },
  ],
  marketTfmap: [
    { full: 'Month1', short: '1M' },
    { full: 'Month3', short: '3M' },
    { full: 'Month6', short: '6M' },
    { full: 'Year1', short: '1Y' },
    { full: 'Year3', short: '3Y' },
    { full: 'Year5', short: '5Y' },
    { full: 'Year10', short: '10Y' },
  ],
  sentiment: {
    biggestChange: [
      { header: 'Symbol', accessorKey: 'symbol' },
      { header: 'Name', accessorKey: 'name' },
      { header: 'Sentiment', accessorKey: 'sentiment' },
      { header: 'Sentiment Change (%)', accessorKey: 'sentimentChange' },
    ],
    trendChange: [
      { header: 'Symbol', accessorKey: 'symbol' },
      { header: 'Name', accessorKey: 'name' },
      { header: 'Sentiment', accessorKey: 'sentiment' },
      { header: 'Last Sentiment', accessorKey: 'lastSentiment' },
    ],
  },
  blogData: [
    {
      id: 1,
      header: 'Quant',
      subHeaderOne: 'Buy and sell signals with Book Profits levels',
      subHeaderTwo: 'Buy and sell signals with Book Profits levels',
      detailsFirst:
        "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
      detailsSecond:
        "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.",
      link: '/quantlandingpage',
      image: BlogImgOne,
    },
    {
      id: 2,
      header: 'iFIR$T Portfolio',
      subHeaderOne: 'Customized to the individual investor’s profile.',
      subHeaderTwo: 'Manage and execute your portfolio’s day-to-day buy and sell decisions',
      detailsFirst:
        "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
      detailsSecond:
        "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.",
      link: '/thematic-portfolio',
      image: BlogImgTwo,
    },
    {
      id: 3,
      header: 'iPackage',
      subHeaderOne: 'Customized to the individual investor’s profile.',
      subHeaderTwo: 'Manage and execute your portfolio’s day-to-day buy and sell decisions',
      detailsFirst:
        "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
      detailsSecond:
        "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.",
      link: '/ipackage',
      image: BlogImgThree,
    },
  ],
  CtaData: [
    {
      id: 1,
      src: CTAMarketsIcon,
      text: 'MARKETS',
      to: '/markets',
    },
    {
      id: 2,
      src: CTASignalsIcon,
      text: 'SIGNALS',
      to: '/signals',
    },
    {
      id: 3,
      src: CTAPortfolioIcon,
      text: 'PORTFOLIO',
      to: '/portfolio',
    },
    {
      id: 4,
      src: CTAScreenerIcon,
      text: 'SCREENER',
      to: '/screener',
    },
  ],
  socials: [LinkedInIcon, FacebookIcon, YoutubeIcon, TwitterIcon, TelegramIcon],
  footerData: [
    {
      id: 1,
      header: 'COMPANY',
      links: [
        {
          label: 'About Us',
          url: '/about',
        },
        // {
        //   label: 'Blogs',
        //   url: '/',
        // },
        {
          label: 'Pricing',
          url: '/subscription',
        },
        {
          label: 'Support',
          url: '/support',
        },
        // {
        //   label: 'Terms of use',
        //   url: '/',
        // },
        // {
        //   label: 'Disclaimer',
        //   url: '/',
        // },
        // {
        //   label: 'Privacy policy',
        //   url: '/',
        // },
      ],
    },
    {
      id: 2,
      header: 'PRODUCTS',
      links: [
        {
          label: 'Chart',
          url: '/',
        },
        {
          label: 'Screener',
          url: '/screener',
        },

        {
          label: 'Heatmap',
          url: '/heatmap',
        },

        {
          label: 'News',
          url: '/watchlist/AMZN/stocks/news',
        },
      ],
    },
    {
      id: 3,
      header: 'QUICK links',
      links: [
        {
          label: 'My Workstation',
          url: '',
        },
        {
          label: 'HeatMaps',
          url: '/heatmap',
        },
        {
          label: 'Screeners',
          url: '/screener',
        },
        {
          label: 'Back test',
          url: '/backtesting',
        },
        {
          label: 'Risk Profile',
          url: '/risk-profile',
        },
        {
          label: 'Social Sentiment',
          url: '/sentiment/AMZN',
        },
        {
          label: 'Custom Portfolio',
          url: '/custom-portfolio',
        },
      ],
    },
    {
      id: 4,
      header: null,
      links: [
        {
          label: 'Thematic Investment Portfolio',
          url: '/thematic-portfolio',
        },
        {
          label: 'Market Portfolio',
          url: '/markets-portfolio',
        },
        {
          label: 'Sector Portfolio',
          url: '/sector-portfolio',
        },
        {
          label: 'i-Recommend',
          url: '/irecommend',
        },
        {
          label: 'Risk Profile',
          url: '/risk-profile',
        },
        {
          label: 'Social Sentiment',
          url: '/sentiment/AMZN',
        },
        {
          label: 'i-Expert',
          url: '/iexpert',
        },
      ],
    },
  ],
  brokerData: [
    {
      id: 1,
      img: ProfileZerodhaLogo,
      name: 'Zerodha',
      category: 'Stocks',
      available: 'yes',
    },
    {
      id: 2,
      img: ProfileAlpacaLogo,
      name: 'Alpaca',
      category: 'Stocks',
      available: 'yes',
    },
    {
      id: 3,
      img: ProfileBingxLogo,
      name: 'BingX',
      category: 'Crypto',
      available: 'no',
    },
    {
      id: 4,
      img: ProfileGeminiLogo,
      name: 'Gemini',
      category: 'Stocks',
      available: 'no',
    },
    {
      id: 5,
      img: ProfileForexLogo,
      name: 'FOREX.com',
      category: 'Forex',
      available: 'yes',
    },
    {
      id: 6,
      img: ProfileTickMillLogo,
      name: 'Tickmill',
      category: 'Forex',
      available: 'no',
    },
    {
      id: 7,
      img: ProfileCurrencyLogo,
      name: 'Currency.com',
      category: 'Crypto',
      available: 'yes',
    },
    {
      id: 8,
      img: ProfileTradeStationLogo,
      name: 'TradeStation',
      category: 'Stocks',
      available: 'yes',
    },
    {
      id: 9,
      img: ProfileTradovateLogo,
      name: 'Tradovate',
      category: 'Crypto',
      available: 'yes',
    },
  ],
  aboutPageData: {
    sectionFirstData: [
      {
        id: 1,
        icon: AboutIconOne,
        heading: 'Quality Services',
        details: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus bibendum congue',
      },
      {
        id: 2,
        icon: AboutIconTwo,
        heading: 'Valuable Ideas',
        details: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus bibendum congue',
      },
      {
        id: 3,
        icon: AboutIconThree,
        heading: 'Support 24 / 7',
        details: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus bibendum congue',
      },
      {
        id: 4,
        icon: AboutIconFour,
        heading: 'Budget Friendly',
        details: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus bibendum congue',
      },
    ],
    sectionThirdData: [
      {
        id: 1,
        icon: AboutIconFive,
        header: 'Audit & Assurance',
        details: 'Read more',
      },
      {
        id: 2,
        icon: AboutIconSix,
        header: 'Financial Consulting',
        details: 'Read more',
      },
      {
        id: 3,
        icon: AboutIconSeven,
        header: 'Trades & Stocks',
        details: 'Read more',
      },
      {
        id: 4,
        icon: AboutIconEight,
        header: 'Bonds & Commodities',
        details: 'Read more',
      },
      {
        id: 5,
        icon: AboutIconNine,
        header: 'Strategic Planning',
        details: 'Read more',
      },
      {
        id: 6,
        icon: AboutIconTen,
        header: 'Financial Projections',
        details: 'Read more',
      },
    ],
  },
  financialTableData: {
    income: [
      {
        label: 'Revenue',
        key: 'revenue',
        growth_key: 'growth_revenue',
      },
      {
        label: 'Gross Profit',
        key: 'gross_profit',
        growth_key: 'growth_gross_profit',
      },
      {
        label: 'Operating Expenses',
        key: 'operating_expenses',
        growth_key: 'growth_operating_expenses',
      },
      {
        label: 'Selling, General and Administrative Expenses',
        key: 'selling_general_and_administrative_expenses',
        growth_key: 'growth_general_and_administrative_expenses',
      },
      {
        label: 'General and Administrative Expenses',
        key: 'general_and_administrative_expenses',
        growth_key: 'growth_general_and_administrative_expenses',
      },
      {
        label: 'Selling and Marketing Expenses',
        key: 'selling_and_marketing_expenses',
        growth_key: 'growth_selling_and_marketing_expenses',
      },
      {
        label: 'Research and Development Expenses',
        key: 'research_and_development_expenses',
        growth_key: 'growth_research_and_development_expenses',
      },
      {
        label: 'Other Expenses',
        key: 'other_expenses',
        growth_key: 'growth_other_expenses',
      },
      {
        label: 'Cost and Expenses',
        key: 'cost_and_expenses',
        growth_key: 'growth_cost_and_expenses',
      },
      {
        label: 'Operating Income',
        key: 'operating_income',
        growth_key: 'growth_operating_income',
      },
      {
        label: 'Interest Expense',
        key: 'interest_expense',
        growth_key: 'growth_interest_expense',
      },
      {
        label: 'Income Tax Expense',
        key: 'income_tax_expense',
        growth_key: 'growth_income_tax_expense',
      },
      {
        label: 'Earnings before Tax',
        key: 'income_before_tax',
        growth_key: 'growth_income_before_tax',
      },
      {
        label: 'Net Income',
        key: 'net_income',
        growth_key: 'growth_net_income',
      },
      {
        label: 'Earnings Per Share Basic',
        key: 'eps',
        growth_key: 'growth_eps',
      },
      {
        label: 'Earnings Per Share Diluted',
        key: 'epsdiluted',
        growth_key: 'growth_eps_diluted',
      },
      {
        label: 'Weighted Average Shares Outstanding',
        key: 'weighted_average_shs_out',
        growth_key: 'growth_weighted_average_shs_out',
      },
      {
        label: 'Weighted Average Shares Outstanding (Diluted)',
        key: 'weighted_average_shs_out_dil',
        growth_key: 'growth_weighted_average_shs_out_dil',
      },
      {
        label: 'Gross Margin',
        key: 'gross_profit_ratio',
        growth_key: 'growth_gross_profit_ratio',
      },
      {
        label: 'EBIT Margin',
        key: 'ebitda_ratio',
        growth_key: 'growth_ebitda_ratio',
      },
      {
        label: 'Profit Margin',
        key: 'net_income_ratio',
        growth_key: 'growth_net_income_ratio',
      },
      {
        label: 'EBITDA',
        key: 'ebitda',
        growth_key: 'growth_ebitda',
      },
      {
        label: 'Earnings Before Tax Margin',
        key: 'income_before_tax_ratio',
        growth_key: 'growth_income_before_tax_ratio',
      },
    ],
    balanceSheet: [
      {
        label: 'Cash and Cash Equivalents',
        key: 'cash_and_cash_equivalents',
        growth_key: 'growth_cash_and_cash_equivalents',
      },
      {
        label: 'Short-term Investments',
        key: 'short_term_investments',
        growth_key: 'growth_short_term_investments',
      },
      {
        label: 'Inventory',
        key: 'inventory',
        growth_key: 'growth_inventory',
      },
      {
        label: 'Other Current Assets',
        key: 'other_current_assets',
        growth_key: 'growth_other_current_assets',
      },
      {
        label: 'Total Current Assets',
        key: 'total_current_assets',
        growth_key: 'growth_total_current_assets',
      },
      {
        label: 'Goodwill',
        key: 'goodwill',
        growth_key: 'growth_goodwill',
      },
      {
        label: 'Intangible Assets',
        key: 'intangible_assets',
        growth_key: 'growth_intangible_assets',
      },
      {
        label: 'Long-term Investments',
        key: 'long_term_investments',
        growth_key: 'growth_long_term_investments',
      },
      {
        label: 'Tax Assets',
        key: 'tax_assets',
        growth_key: 'growth_tax_assets',
      },
      {
        label: 'Other Non-Current Assets',
        key: 'other_non_current_assets',
        growth_key: 'growth_other_non_current_assets',
      },
      {
        label: 'Total Non-Current Assets',
        key: 'total_non_current_assets',
        growth_key: 'growth_total_non_current_assets',
      },
      {
        label: 'Total Assets',
        key: 'total_assets',
        growth_key: 'growth_total_assets',
      },
      {
        label: 'Short-term Debt',
        key: 'short_term_debt',
        growth_key: 'growth_short_term_debt',
      },
      {
        label: 'Total Current Liabilities',
        key: 'total_current_liabilities',
        growth_key: 'growth_total_current_liabilities',
      },
      {
        label: 'Long-term Debt',
        key: 'long_term_debt',
        growth_key: 'growth_long_term_debt',
      },
      {
        label: 'Deferred Revenue',
        key: 'deferred_revenue',
        growth_key: 'growth_deferred_revenue',
      },
      {
        label: 'Other Non-Current Liabilities',
        key: 'other_non_current_liabilities',
        growth_key: 'growth_other_non_current_liabilities',
      },
      {
        label: 'Total Non-Current Liabilities',
        key: 'total_non_current_liabilities',
        growth_key: 'growth_total_non_current_liabilities',
      },
      {
        label: 'Total Liabilities',
        key: 'total_liabilities',
        growth_key: 'growth_total_liabilities',
      },
      {
        label: 'Common Stock',
        key: 'common_stock',
        growth_key: 'growth_common_stock',
      },
      {
        label: 'Retained Earnings',
        key: 'retained_rarnings',
        growth_key: 'growth_retained_earnings',
      },
    ],
    cashFlow: [
      {
        label: 'Net Income',
        key: 'net_income',
        growth_key: 'growth_netIncome',
      },
      {
        label: 'Depreciation and Amortization',
        key: 'depreciation_and_amortization',
        growth_key: 'growth_depreciation_and_amortization',
      },
      {
        label: 'Deferred Income Tax',
        key: 'deferred_income_tax',
        growth_key: 'growth_deferred_income_tax',
      },
      {
        label: 'Stock Based Compensation',
        key: 'stock_based_compensation',
        growth_key: 'growth_stock_based_compensation',
      },
      {
        label: 'Change in Working Capital',
        key: 'change_in_working_capital',
        growth_key: 'growth_change_in_working_capital',
      },
      {
        label: 'Other Working Capital',
        key: 'other_working_capital',
        growth_key: 'growth_other_working_capital',
      },
      {
        label: 'Other Non-Cash Items',
        key: 'other_non_cash_items',
        growth_key: 'growth_other_non_cash_items',
      },
      {
        label: 'Net Cash Provided by Operating Activities',
        key: 'net_cash_provided_by_operating_activities',
        growth_key: 'growth_net_cash_provided_by_operating_activities',
      },
      {
        label: 'Investments in Property, Plant, and Equipment',
        key: 'investments_in_property_plant_and_equipment',
        growth_key: 'growth_investments_in_property_plant_and_equipment',
      },
      {
        label: 'Acquisitions Net',
        key: 'acquisitions_net',
        growth_key: 'growth_acquisitions_net',
      },
      {
        label: 'Purchases of Investments',
        key: 'purchases_of_investments',
        growth_key: 'growth_purchases_of_investments',
      },
      {
        label: 'Sales/Maturities of Investments',
        key: 'sales_maturities_of_investments',
        growth_key: 'growth_sales_maturities_of_investments',
      },
      {
        label: 'Debt Repayment',
        key: 'debt_repayment',
        growth_key: 'growth_debt_repayment',
      },
      {
        label: 'Common Stock Issued',
        key: 'common_stock_issued',
        growth_key: 'growth_common_stock_issued',
      },
      {
        label: 'Common Stock Repurchased',
        key: 'common_stock_repurchased',
        growth_key: 'growth_common_stock_repurchased',
      },
      {
        label: 'Dividends Paid',
        key: 'dividends_paid',
        growth_key: 'growth_dividends_paid',
      },
      {
        label: 'Net Change in Cash',
        key: 'net_change_in_cash',
        growth_key: 'growth_net_change_in_cash',
      },
      {
        label: 'Cash at Beginning of Period',
        key: 'cash_at_beginning_of_period',
        growth_key: 'growth_cash_at_beginning_of_period',
      },
      {
        label: 'Cash at End of Period',
        key: 'cash_at_end_of_period',
        growth_key: 'growth_cash_at_end_of_period',
      },
    ],
  },
  heatmapfilters: [
    'Market Cap',
    'Price Book Value Ratio TTM',
    'Price Cash Flow Ratio TTM',
    'Price Earnings Ratio TTM',
    'Price Earnings to Growth Ratio TTM',
    'Price Sales Ratio TTM',
    'Dividend Yield TTM',
    'Price Fair Value TTM',
  ],
  multiplierMap: {
    Ones: 1,
    Thousand: 1000,
    Million: 1000000,
    Billion: 1000000000,
  },
  multiplier: ['Ones', 'Thousand', 'Million', 'Billion'],
  heatmapFilters: [
    'Below',
    'Below Or Equal',
    'Above',
    'Above Or Equal',
    'Between Min',
    'Between Max',
    'Outside Min',
    'Outside Max',
    'Equal',
    'Not Equal',
  ],
  technicals: [
    'Accumulation Distribution Line',
    'Average True Range (14)',
    'Aroon Oscillator (14)',
    'Average Directional Index (14)',
    'Bollinger Bands (20)',
    'Bollinger Upper Bands (20)',
    'Bollinger Lower Bands (20)',
    'Bollinger Simple Moving Average Bands (20)',
    'Bollinger Bands %B (20)',
    'Bollinger Width (20)',
    'Chande Momentum Oscillator (9)',
    'Commodity Channel Index (5)',
    'Commodity Channel Index (20)',
    'Detrended Price Oscillator (21)',
    'Donchian Channels Upper Band (20)',
    'Donchian Channels Lower Band (20)',
    'Envelope Upper Bound (20)',
    'Envelope Lower Bound (20)',
    'Force Index (13)',
    'Hull Moving Average (9)',
    'Ichimoku Cloud Tenkansen (9 26 52 26)',
    'Ichimoku Cloud Kijusen (9 26 52 26)',
    'Kaufman Adaptive Moving Average (10 2 30)',
    'Keltner Channel Upper Band (20)',
    'Keltner Channel Lower Band (20)',
    'Linear Regression Channel Line (100 2 2)',
    'Linear Regression Upper Channel Line (100 2 2)',
    'Linear Regression Lower Channel Line (100 2 2)',
    'Momentum (10)',
    'Momentum Rate of Change (10)',
    'Money Flow Index (14)',
    'Simple Moving Average (10)',
    'Simple Moving Average (20)',
    'Simple Moving Average (50)',
    'Simple Moving Average (100)',
    'Simple Moving Average (200)',
    'Average High Moving Average (10)',
    'Average High Moving Average (20)',
    'Average High Moving Average (50)',
    'Average High Moving Average (100)',
    'Average High Moving Average (200)',
    'Average Low Moving Average (10)',
    'Average Low Moving Average (20)',
    'Average Low Moving Average (50)',
    'Average Low Moving Average (100)',
    'Average Low Moving Average (200)',
    'Weighted Moving Average (10)',
    'Weighted Moving Average (20)',
    'Weighted Moving Average (50)',
    'Weighted Moving Average (100)',
    'Weighted Moving Average (200)',
    'Exponential Moving Average (10)',
    'Exponential Moving Average (20)',
    'Exponential Moving Average (50)',
    'Exponential Moving Average (100)',
    'Exponential Moving Average (200)',
    'MACD Level (12 26)',
    'MACD Signal (12 26)',
    'On-Balance Volume (SMA 5)',
    'Price Oscillator (10 21)',
    'Percentage Price Oscillator (10 21)',
    'Pivot Points (15 1)',
    'Pivot Points R1 (15 1)',
    'Pivot Points R2 (15 1)',
    'Pivot Points S1(15 1)',
    'Pivot Points S2(15 1)',
    'Price Volume Trend',
    'Rate of Change (9)',
    'Relative Strength Index (14)',
    'Stochastic Relative Strength Index (14)',
    'Stochastic Oscillator (5 3 3)',
    'Standard Deviation (21)',
    'Supertrend Upper Band (10 3 Multiplier)',
    'Supertrend Lower Band (10 3 Multiplier)',
    'Volume Oscillator (5 10)',
    'Volume Weighted Average Price (0 1)',
    'Volume Moving Average (10)',
    'Volume Moving Average (20)',
    'Volume Moving Average (50)',
    'Volume Moving Average (100)',
    'Volume Moving Average (200)',
    'Williams Percent Range (%R) (14)',
  ],
  watchlist: {
    ownershipTableHeaders: [
      {
        header: 'Date',
        accessorKey: 'date',
      },
      {
        header: 'Filing Date',
        accessorKey: 'filing_date',
      },
      {
        header: 'Investor name',
        accessorKey: 'investor_name',
      },
      {
        header: 'Shares no',
        accessorKey: 'shares_number',
      },
      {
        header: 'Last shares no',
        accessorKey: 'last_shares_number',
      },
      {
        header: 'Chg in shares no',
        accessorKey: 'change_in_shares_number',
      },
      {
        header: '% Chg in shares no',
        accessorKey: 'change_in_shares_number_percentage',
      },
      {
        header: 'Ownership',
        accessorKey: 'ownership',
      },
      {
        header: 'Last owner..',
        accessorKey: 'last_ownership',
      },
      {
        header: 'Chg in owner..',
        accessorKey: 'change_in_ownership',
      },
      {
        header: '% Chg in owner..',
        accessorKey: 'change_in_ownership_percentage',
      },
      {
        header: 'Holding Period',
        accessorKey: 'holding_period',
      },
    ],
    dividend: [
      {
        header: 'Ex-Date',
        accessorKey: 'date',
      },
      {
        header: 'Record Date',
        accessorKey: 'record_date',
      },
      {
        header: 'Pay Date',
        accessorKey: 'payment_date',
      },
      {
        header: 'Declaration Date',
        accessorKey: 'declaration_date',
      },
      {
        header: 'Amount',
        accessorKey: 'dividend',
      },
    ],
    ratios: {
      liquidityMeasurementRatiosData: [
        {
          name: 'Current Ratio',
          formula: 'Current Assets / Current Liabilities',
          valueKey: 'current_ratio_ttm',
          description:
            'A current ratio of 1.0 or greater indicates that the company is well-positioned to cover its current or short-term liabilities.',
        },
        {
          name: 'Quick Ratio',
          formula: '(Cash and Cash Equivalents + Short Term Investments + Account Receivables) / Current Liabilities',
          valueKey: 'quick_ratio_ttm',
          description:
            'The quick ratio is more conservative than the current ratio because it excludes inventory and other current assets. A higher quick ratio means a more liquid current position.',
        },
        {
          name: 'Cash Ratio',
          formula: 'Cash and Cash Equivalents / Current Liabilities',
          valueKey: 'cash_ratio_ttm',
          description:
            "The cash ratio indicates a firm's value under the worst-case scenario where the company is about to go out of business.",
        },
        {
          name: 'Days of Sales Outstanding (DSO)',
          formula: '(AccountReceivable(start) + AccountReceivable(end)) / 2 / (Revenue / 365)',
          valueKey: 'days_of_sales_outstanding_ttm',
          description: 'DSO tells you how many days after the sale it takes people to pay you on average.',
        },
        {
          name: 'Days of Inventory Outstanding (DIO)',
          formula: '(Inventories(start) + Inventories(end)) / 2 / (COGS / 365)',
          valueKey: 'days_of_inventory_outstanding_ttm',
          description: 'DIO tells you how many days inventory sits on the shelf on average.',
        },
        {
          name: 'Operating Cycle',
          formula: 'DSO + DIO',
          valueKey: 'operating_cycle_ttm',
          description:
            'The Operating Cycle tells you how many days it takes for something to go from first being in inventory to receiving the cash after the sale.',
        },
        {
          name: 'Days of Payables Outstanding (DPO)',
          formula: '(AccountsPayable(start) + AccountsPayable(end)) / 2 / (COGS / 365)',
          valueKey: 'days_of_payables_outstanding_ttm',
          description: 'DPO tells you how many days the company takes to pay its suppliers.',
        },
        {
          name: 'Cash Conversion Cycle (CCC)',
          formula: 'DSO + DIO - DPO',
          valueKey: 'cash_conversion_cycle_ttm',
          description:
            'The Cash Conversion Cycle measures the number of days a company’s cash is tied up in the production and sales process and the benefit it derives from payment.',
        },
      ],
      profitabilityIndicatorRatiosData: [
        {
          name: 'Gross Profit Margin',
          formula: 'Gross Profit / Revenue',
          valueKey: 'gross_profit_margin_ttm',
          description: 'Indicates the percentage of revenue that exceeds the cost of goods sold (COGS).',
        },
        {
          name: 'Operating Profit Margin',
          formula: 'Operating Income / Revenue',
          valueKey: 'operating_profit_margin_ttm',
          description: 'Shows the percentage of revenue left after subtracting operating expenses.',
        },
        {
          name: 'Pretax Profit Margin',
          formula: 'Pretax Income / Revenue',
          valueKey: 'pretax_profit_margin_ttm',
          description: 'Represents the percentage of revenue that remains as profit before taxes are deducted.',
        },
        {
          name: 'Net Profit Margin',
          formula: 'Net Income / Revenue',
          valueKey: 'net_profit_margin_ttm',
          description:
            'Represents the percentage of revenue that remains as profit after all expenses, including taxes, are deducted.',
        },
        {
          name: 'Effective Tax Rate',
          formula: 'Income Tax Expense / Pretax Income',
          valueKey: 'effective_tax_rate_ttm',
          description: 'Indicates the average rate at which pre-tax income is taxed.',
        },
        {
          name: 'Return on Assets (ROA)',
          formula: 'Net Income / Total Average Assets',
          valueKey: 'return_on_assets_ttm',
          description: 'Measures how efficiently a company uses its assets to generate profit.',
        },
        {
          name: 'Return on Equity (ROE)',
          formula: "Net Income / Shareholder's Equity",
          valueKey: 'return_on_equity_ttm',
          description: 'Indicates the profitability of a company in generating income for its shareholders.',
        },
        {
          name: 'Return on Capital Employed (ROCE)',
          formula: 'EBIT / (Total Assets - Current Liabilities)',
          valueKey: 'return_on_capital_employed_ttm',
          description: "Measures the profitability and efficiency of a company's capital investments.",
        },
        {
          name: 'Net Income per EBT',
          formula: 'Net Income / EBT',
          valueKey: 'net_income_per_ebt_ttm',
          description: 'Shows the amount of net income generated per unit of Earnings Before Tax (EBT).',
        },
        {
          name: 'EBT per EBIT',
          formula: 'EBT / EBIT',
          valueKey: 'ebt_per_ebit_ttm',
          description: 'Indicates the portion of EBIT that remains as EBT before interest and taxes.',
        },
        {
          name: 'EBIT per Revenue',
          formula: 'EBIT / Revenue',
          valueKey: 'ebit_per_revenue_ttm',
          description: 'Shows the percentage of revenue that remains as EBIT before interest and taxes.',
        },
      ],
      debtRatiosData: [
        {
          name: 'Debt Ratio',
          formula: 'Total Liabilities / Total Assets',
          valueKey: 'debt_ratio_ttm',
          description: 'The debt ratio tells us the degree of leverage used by the company.',
        },
        {
          name: 'Debt to Equity Ratio',
          formula: 'Total Debt / Total Equity',
          valueKey: 'debt_equity_ratio_ttm',
          description:
            'This is a measurement of the percentage of the company’s balance sheet that is financed by suppliers, lenders, creditors, and obligors versus what the shareholders have committed.',
        },
        {
          name: 'Long-term Debt to Capitalization',
          formula: 'Long Term Debt / (Long Term Debt + Shareholders Equity)',
          valueKey: 'long_term_debt_to_capitalization_ttm',
          description:
            'While a high capitalization ratio can increase the return on equity because of the tax shield of debt, a higher proportion of debt increases the risk of bankruptcy for a company.',
        },
        {
          name: 'Total Debt to Capitalization',
          formula: 'Total Debt / (Total Debt + Shareholders Equity)',
          valueKey: 'total_debt_to_capitalization_ttm',
          description:
            'Capitalization ratio describes to investors the extent to which a company is using debt to fund its business and expansion plans.',
        },
        {
          name: 'Interest Coverage Ratio',
          formula: 'EBIT / Interest Expenses',
          valueKey: 'interest_coverage_ttm',
          description:
            'The lower a company’s interest coverage ratio is, the more its debt expenses burden the company.',
        },
        {
          name: 'Cash Flow to Debt Ratio',
          formula: 'Operating Cash Flows / Total Debt',
          valueKey: 'cash_flow_to_debt_ratio_ttm',
          description:
            'The cash flow to debt ratio reveals the ability of a business to support its debt obligations from its operating cash flows.',
        },
        {
          name: 'Company Equity Multiplier',
          formula: 'Total Assets / Total Equity',
          valueKey: 'company_equity_multiplier_ttm',
          description: 'This is a measure of financial leverage.',
        },
      ],
      operatingPerformanceRatiosData: [
        {
          name: 'Fixed Asset Turnover',
          formula: 'Revenue / Net PPE',
          valueKey: 'fixed_asset_turnover_ttm',
          description: 'Calculates how efficiently a company is producing sales with its machines and equipment.',
        },
        {
          name: 'Asset Turnover',
          formula: 'Revenue / Total Average Assets',
          valueKey: 'asset_turnover_ttm',
          description:
            'The Asset Turnover ratio can often be used as an indicator of the efficiency with which a company is deploying its assets in generating revenue.',
        },
      ],
      cashFlowIndicatorRatiosData: [
        {
          name: 'Operating Cash Flow Sales Ratio',
          formula: 'Current Assets / Current Liabilities',
          valueKey: 'operating_cash_flow_sales_ratio_ttm',
          description:
            'A current ratio of 1.0 or greater is an indication that the company is well-positioned to cover its current or short-term liabilities.',
        },
        {
          name: 'Free Cash Flow Operating Cash Flow Ratio',
          formula: 'Short Term Investments / Current Liabilities',
          valueKey: 'free_cash_flow_operating_cash_flow_ratio_ttm',
          description:
            'The quick ratio is more conservative than the current ratio because it excludes inventory and other current assets, which generally are more difficult to turn into cash. A higher quick ratio means a more liquid current position.',
        },
        {
          name: 'Cash Flow Coverage Ratios',
          formula: 'Cash and Cash Equivalents / Current Liabilities',
          valueKey: 'cash_flow_coverage_ratios_ttm',
          description:
            'The cash ratio is almost like an indicator of a firm’s value under the worst-case scenario where the company is about to go out of business.',
        },
        {
          name: 'Short-Term Coverage Ratios',
          formula: 'Account Receivable / (Revenue / 365)',
          valueKey: 'short_term_coverage_ratios_ttm',
          description: 'DSO tells you how many days after the sale it takes people to pay you on average.',
        },
        {
          name: 'Capital Expenditure Coverage Ratios',
          formula: '(Inventories (start) + Inventories (end)) / 2 / (COGS / 365)',
          valueKey: 'capital_expenditure_coverage_ratio_ttm',
          description: 'DIO tells you how many days inventory sits on the shelf on average.',
        },
        {
          name: 'Dividend Paid and Capex Coverage Ratios',
          formula: 'DSO + DIO',
          valueKey: 'dividend_paid_and_capex_coverage_ratio_ttm',
          description:
            '(DSO + DIO) Basically the Operating Cycle tells you how many days it takes for something to go from first being in inventory to receiving the cash after the sale.',
        },
        {
          name: 'Dividend Payout Ratio',
          formula: 'Accounts Payable / (COGS / 365)',
          valueKey: 'payout_ratio_ttm',
          description: 'DPO tells you how many days the company takes to pay its suppliers.',
        },
      ],
      investmentValuationRatiosData: [
        {
          name: 'Price Book Value Ratio',
          formula: 'Stock Price per Share / Equity Per Share',
          valueKey: 'price_book_value_ratio_ttm',
          description:
            'The price-to-book value ratio, expressed as a multiple (i.e., how many times a company’s stock is trading per share compared to the company’s book value per share), is an indication of how much shareholders are paying for the net assets of a company.',
        },
        {
          name: 'Price Cash Flow Ratio',
          formula: 'Stock Price per Share / Operating Cash Flow Per Share',
          valueKey: 'price_cash_flow_ratio_ttm',
          description:
            'The price/cash flow ratio is used by investors to evaluate the investment attractiveness, from a value standpoint, of a company’s stock.',
        },
        {
          name: 'Price Earnings Ratio',
          formula: 'Stock Price per Share / EPS',
          valueKey: 'price_earnings_ratio_ttm',
          description:
            'The financial reporting of both companies and investment research services use a basic earnings per share (EPS) figure divided into the current stock price to calculate the P/E multiple (i.e., how many times a stock is trading (its price) per each dollar of EPS).',
        },
        {
          name: 'Price Earnings to Growth Ratio',
          formula: 'Price Earnings Ratio / Expected Revenue Growth',
          valueKey: 'price_earnings_to_growth_ratio_ttm',
          description:
            'The PEG ratio is a refinement of the P/E ratio and factors in a stock’s estimated earnings growth into its current valuation. The general consensus is that if the PEG ratio indicates a value of 1, this means that the market is correctly valuing (the current P/E ratio) a stock in accordance with...',
        },
        {
          name: 'Price Sales Ratio',
          formula: 'Stock Price per Share / Revenue Per Share',
          valueKey: 'price_sales_ratio_ttm',
          description:
            'The P/E ratio and P/S reflects how many times investors are paying for every dollar of a company’s sales. Since earnings are subject to one degree or another to accounting estimates and management manipulation, many investors consider a company’s sales (revenue) figure a more reliable ratio.',
        },
        {
          name: 'Dividend Yield',
          formula: 'Dividend per Share / Stock Price per Share',
          valueKey: 'dividend_yield_ttm',
          description:
            'Income investors value a dividend-paying stock, while growth investors have little interest in dividends, preferring to capture large capital gains. Whatever your investing style, it is a matter of historical record that dividend-paying stocks have performed better than non-paying-dividend stocks.',
        },
        {
          name: 'Enterprise Value Multiplier',
          formula: 'Enterprise Value / EBITDA',
          valueKey: 'enterprise_value_multiple_ttm',
          description:
            'Overall, this measurement allows investors to assess a company on the same basis as that of an acquirer. As a rough calculation, enterprise value multiple serves as a proxy for how long it would take for an acquisition to earn enough to pay off its costs in years (assuming no change in EBITDA).',
        },
        {
          name: 'Price Fair Value',
          formula: 'Stock Price per Share / Intrinsic Value',
          valueKey: 'price_fair_value_ttm',
          description:
            'Helps investors determine whether a stock is trading at, below, or above its fair value estimate. A price/fair value ratio below 1 suggests the stock is trading at a discount to its fair value, while a ratio above 1 suggests it is trading at a premium to its fair value.',
        },
      ],
    },
  },
  ScaleValues: [
    {
      value: 'CCC',
    },
    {
      value: 'B',
    },
    {
      value: 'BB',
    },
    {
      value: 'BBB',
    },
    {
      value: 'A',
    },
    {
      value: 'AA',
    },
    {
      value: 'AAA',
    },
  ],
  irecommend: {
    dropdownOptions: ['Fundamental View', 'Technical View'],
    dropdownMarkets: ['S&P 500', 'DOWJ', 'NASDAQ'],
    fundamentalData: [
      {
        id: 1,
        logo: NetflixDummyLogo,
        ticker: 'NFLX',
        name: 'Alphabet Inc (Google)',
        sector: 'Internet',
        PE: '0.12',
        PB: '2.3',
        PFCF: '2.3',
        ROE: '0.12',
        ROA: '2.3',
        EPS: '2.3',
        yield: '2.4',
        signal: 'BUY',
        duration: 'Weekly',
      },
      {
        id: 2,
        logo: NetflixDummyLogo,
        ticker: 'NFLX',
        name: 'Alphabet Inc (Google)',
        sector: 'Internet',
        PE: '0.12',
        PB: '2.3',
        PFCF: '2.3',
        ROE: '0.12',
        ROA: '2.3',
        EPS: '2.3',
        yield: '2.4',
        signal: 'BUY',
        duration: 'Weekly',
      },
      {
        id: 3,
        logo: NetflixDummyLogo,
        ticker: 'NFLX',
        name: 'Alphabet Inc (Google)',
        sector: 'Internet',
        PE: '0.12',
        PB: '2.3',
        PFCF: '2.3',
        ROE: '0.12',
        ROA: '2.3',
        EPS: '2.3',
        yield: '2.4',
        signal: 'BUY',
        duration: 'Weekly',
      },
      {
        id: 4,
        logo: NetflixDummyLogo,
        ticker: 'NFLX',
        name: 'Alphabet Inc (Google)',
        sector: 'Internet',
        PE: '0.12',
        PB: '2.3',
        PFCF: '2.3',
        ROE: '0.12',
        ROA: '2.3',
        EPS: '2.3',
        yield: '2.4',
        signal: 'BUY',
        duration: 'Weekly',
      },
      {
        id: 5,
        logo: NetflixDummyLogo,
        ticker: 'NFLX',
        name: 'Alphabet Inc (Google)',
        sector: 'Internet',
        PE: '0.12',
        PB: '2.3',
        PFCF: '2.3',
        ROE: '0.12',
        ROA: '2.3',
        EPS: '2.3',
        yield: '2.4',
        signal: 'BUY',
        duration: 'Weekly',
      },
      {
        id: 6,
        logo: NetflixDummyLogo,
        ticker: 'NFLX',
        name: 'Alphabet Inc (Google)',
        sector: 'Internet',
        PE: '0.12',
        PB: '2.3',
        PFCF: '2.3',
        ROE: '0.12',
        ROA: '2.3',
        EPS: '2.3',
        yield: '2.4',
        signal: 'BUY',
        duration: 'Weekly',
      },
      {
        id: 7,
        logo: NetflixDummyLogo,
        ticker: 'NFLX',
        name: 'Alphabet Inc (Google)',
        sector: 'Internet',
        PE: '0.12',
        PB: '2.3',
        PFCF: '2.3',
        ROE: '0.12',
        ROA: '2.3',
        EPS: '2.3',
        yield: '2.4',
        signal: 'BUY',
        duration: 'Weekly',
      },
      {
        id: 8,
        logo: NetflixDummyLogo,
        ticker: 'NFLX',
        name: 'Alphabet Inc (Google)',
        sector: 'Internet',
        PE: '0.12',
        PB: '2.3',
        PFCF: '2.3',
        ROE: '0.12',
        ROA: '2.3',
        EPS: '2.3',
        yield: '2.4',
        signal: 'BUY',
        duration: 'Weekly',
      },
      {
        id: 9,
        logo: NetflixDummyLogo,
        ticker: 'NFLX',
        name: 'Alphabet Inc (Google)',
        sector: 'Internet',
        PE: '0.12',
        PB: '2.3',
        PFCF: '2.3',
        ROE: '0.12',
        ROA: '2.3',
        EPS: '2.3',
        yield: '2.4',
        signal: 'BUY',
        duration: 'Weekly',
      },
      {
        id: 10,
        logo: NetflixDummyLogo,
        ticker: 'NFLX',
        name: 'Alphabet Inc (Google)',
        sector: 'Internet',
        PE: '0.12',
        PB: '2.3',
        PFCF: '2.3',
        ROE: '0.12',
        ROA: '2.3',
        EPS: '2.3',
        yield: '2.4',
        signal: 'BUY',
        duration: 'Weekly',
      },
    ],
    technicalData: [
      {
        id: 1,
        logo: NetflixDummyLogo,
        ticker: 'NFLX',
        name: 'Netflix Inc.',
        sector: 'Internet',
        benchmark: 'Outperforming',
        volatility: 'High',
        momentum: 'High',
        sentiment: 'Optimum',
        cycle: 'Neutral',
        signal: 'Buy',
        date: '21/10/2023',
      },
      {
        id: 2,
        logo: NetflixDummyLogo,
        ticker: 'NFLX',
        name: 'Netflix Inc.',
        sector: 'Internet',
        benchmark: 'Outperforming',
        volatility: 'High',
        momentum: 'High',
        sentiment: 'Optimum',
        cycle: 'Neutral',
        signal: 'Buy',
        date: '21/10/2023',
      },
      {
        id: 3,
        logo: NetflixDummyLogo,
        ticker: 'NFLX',
        name: 'Netflix Inc.',
        sector: 'Internet',
        benchmark: 'Outperforming',
        volatility: 'High',
        momentum: 'High',
        sentiment: 'Optimum',
        cycle: 'Neutral',
        signal: 'Buy',
        date: '21/10/2023',
      },
      {
        id: 4,
        logo: NetflixDummyLogo,
        ticker: 'NFLX',
        name: 'Netflix Inc.',
        sector: 'Internet',
        benchmark: 'Outperforming',
        volatility: 'High',
        momentum: 'High',
        sentiment: 'Optimum',
        cycle: 'Neutral',
        signal: 'Buy',
        date: '21/10/2023',
      },
      {
        id: 5,
        logo: NetflixDummyLogo,
        ticker: 'NFLX',
        name: 'Netflix Inc.',
        sector: 'Internet',
        benchmark: 'Outperforming',
        volatility: 'High',
        momentum: 'High',
        sentiment: 'Optimum',
        cycle: 'Neutral',
        signal: 'Buy',
        date: '21/10/2023',
      },
      {
        id: 6,
        logo: NetflixDummyLogo,
        ticker: 'NFLX',
        name: 'Netflix Inc.',
        sector: 'Internet',
        benchmark: 'Outperforming',
        volatility: 'High',
        momentum: 'High',
        sentiment: 'Optimum',
        cycle: 'Neutral',
        signal: 'Buy',
        date: '21/10/2023',
      },
      {
        id: 7,
        logo: NetflixDummyLogo,
        ticker: 'NFLX',
        name: 'Netflix Inc.',
        sector: 'Internet',
        benchmark: 'Outperforming',
        volatility: 'High',
        momentum: 'High',
        sentiment: 'Optimum',
        cycle: 'Neutral',
        signal: 'Buy',
        date: '21/10/2023',
      },
      {
        id: 8,
        logo: NetflixDummyLogo,
        ticker: 'NFLX',
        name: 'Netflix Inc.',
        sector: 'Internet',
        benchmark: 'Outperforming',
        volatility: 'High',
        momentum: 'High',
        sentiment: 'Optimum',
        cycle: 'Neutral',
        signal: 'Buy',
        date: '21/10/2023',
      },
      {
        id: 9,
        logo: NetflixDummyLogo,
        ticker: 'NFLX',
        name: 'Netflix Inc.',
        sector: 'Internet',
        benchmark: 'Outperforming',
        volatility: 'High',
        momentum: 'High',
        sentiment: 'Optimum',
        cycle: 'Neutral',
        signal: 'Buy',
        date: '21/10/2023',
      },
      {
        id: 10,
        logo: NetflixDummyLogo,
        ticker: 'NFLX',
        name: 'Netflix Inc.',
        sector: 'Internet',
        benchmark: 'Outperforming',
        volatility: 'High',
        momentum: 'High',
        sentiment: 'Optimum',
        cycle: 'Neutral',
        signal: 'Buy',
        date: '21/10/2023',
      },
    ],
  },
  submittedOrders: [
    {
      id: 1,
      ticker: 'TECHM',
      price: '238.18',
      action: 'SELL',
      qty: 2282,
      dateTime: '17-03-2006 22:29',
      status: 'Pending',
      iFirstID: 12784,
      broker: 'Currency.com',
      brokerID: 82727,
      type: 'Auto',
    },
  ],
  positionHolding: [
    {
      id: 1,
      instrument: 'GOLDBEES',
      qty: 20,
      avgCost: 44.4,
      ltp: 44.15,
      currValue: 883.0,
      pl: '-5.00',
      netChange: '-0.56%',
      dayChange: '+0.39%',
    },
  ],
  excutedOrders: [
    {
      id: 1,
      ticker: 'TECHM',
      qty: 2282,
      wac: 'ABCD',
      action: 'SELL',
      pl: '8137.239',
      plPercent: '6 %',
      iFirstID: 12784,
      brokerID: 82727,
      type: 'Auto',
    },
    {
      id: 2,
      ticker: 'TECHM',
      qty: 2282,
      wac: 'ABCD',
      action: 'SELL',
      pl: '8137.239',
      plPercent: '6 %',
      iFirstID: 12784,
      brokerID: 82727,
      type: 'Auto',
    },
    {
      id: 3,
      ticker: 'TECHM',
      qty: 2282,
      wac: 'ABCD',
      action: 'SELL',
      pl: '8137.239',
      plPercent: '6 %',
      iFirstID: 12784,
      brokerID: 82727,
      type: 'Auto',
    },
    {
      id: 4,
      ticker: 'TECHM',
      qty: 2282,
      wac: 'ABCD',
      action: 'SELL',
      pl: '8137.239',
      plPercent: '6 %',
      iFirstID: 12784,
      brokerID: 82727,
      type: 'Auto',
    },
    {
      id: 5,
      ticker: 'TECHM',
      qty: 2282,
      wac: 'ABCD',
      action: 'SELL',
      pl: '8137.239',
      plPercent: '6 %',
      iFirstID: 12784,
      brokerID: 82727,
      type: 'Auto',
    },
    {
      id: 6,
      ticker: 'TECHM',
      qty: 2282,
      wac: 'ABCD',
      action: 'SELL',
      pl: '8137.239',
      plPercent: '6 %',
      iFirstID: 12784,
      brokerID: 82727,
      type: 'Auto',
    },
    {
      id: 7,
      ticker: 'TECHM',
      qty: 2282,
      wac: 'ABCD',
      action: 'SELL',
      pl: '8137.239',
      plPercent: '6 %',
      iFirstID: 12784,
      brokerID: 82727,
      type: 'Auto',
    },
    {
      id: 8,
      ticker: 'TECHM',
      qty: 2282,
      wac: 'ABCD',
      action: 'SELL',
      pl: '8137.239',
      plPercent: '6 %',
      iFirstID: 12784,
      brokerID: 82727,
      type: 'Auto',
    },
  ],
  quantPortFolioData: [
    {
      id: 1,
      ticker: 'TECHM',
      price: '238.18',
      action: 'SELL',
      qty: 2282,
      dateTime: '17-03-2006 22:29',
      status: 'Pending',
      iFirstID: 12784,
      broker: 'Currency.com',
      brokerID: 82727,
      type: 'Auto',
    },
    {
      id: 2,
      ticker: 'TECHM',
      price: '238.18',
      action: 'SELL',
      qty: 2282,
      dateTime: '17-03-2006 22:29',
      status: 'Pending',
      iFirstID: 12784,
      broker: 'Currency.com',
      brokerID: 82727,
      type: 'Auto',
    },
    {
      id: 3,
      ticker: 'TECHM',
      price: '238.18',
      action: 'SELL',
      qty: 2282,
      dateTime: '17-03-2006 22:29',
      status: 'Pending',
      iFirstID: 12784,
      broker: 'Currency.com',
      brokerID: 82727,
      type: 'Auto',
    },
    {
      id: 4,
      ticker: 'TECHM',
      price: '238.18',
      action: 'SELL',
      qty: 2282,
      dateTime: '17-03-2006 22:29',
      status: 'Pending',
      iFirstID: 12784,
      broker: 'Currency.com',
      brokerID: 82727,
      type: 'Auto',
    },
    {
      id: 5,
      ticker: 'TECHM',
      price: '238.18',
      action: 'SELL',
      qty: 2282,
      dateTime: '17-03-2006 22:29',
      status: 'Pending',
      iFirstID: 12784,
      broker: 'Currency.com',
      brokerID: 82727,
      type: 'Auto',
    },
    {
      id: 6,
      ticker: 'TECHM',
      price: '238.18',
      action: 'SELL',
      qty: 2282,
      dateTime: '17-03-2006 22:29',
      status: 'Pending',
      iFirstID: 12784,
      broker: 'Currency.com',
      brokerID: 82727,
      type: 'Auto',
    },
    {
      id: 7,
      ticker: 'TECHM',
      price: '238.18',
      action: 'SELL',
      qty: 2282,
      dateTime: '17-03-2006 22:29',
      status: 'Pending',
      iFirstID: 12784,
      broker: 'Currency.com',
      brokerID: 82727,
      type: 'Auto',
    },
    {
      id: 8,
      ticker: 'TECHM',
      price: '238.18',
      action: 'SELL',
      qty: 2282,
      dateTime: '17-03-2006 22:29',
      status: 'Pending',
      iFirstID: 12784,
      broker: 'Currency.com',
      brokerID: 82727,
      type: 'Auto',
    },
  ],
  quantDashboardData: {
    tableData: [
      {
        id: 1,
        symbol: 'MSFT',
        time: '10D',
        ch: 92.5,
        chPercent: '12.5%',
      },
      {
        id: 2,
        symbol: 'MSFT',
        time: '10D',
        ch: 92.5,
        chPercent: '12.5%',
      },
      {
        id: 3,
        symbol: 'MSFT',
        time: '10D',
        ch: 92.5,
        chPercent: '12.5%',
      },
      {
        id: 4,
        symbol: 'MSFT',
        time: '10D',
        ch: 92.5,
        chPercent: '12.5%',
      },
      {
        id: 5,
        symbol: 'MSFT',
        time: '10D',
        ch: 92.5,
        chPercent: '12.5%',
      },
      {
        id: 6,
        symbol: 'MSFT',
        time: '10D',
        ch: 92.5,
        chPercent: '12.5%',
      },
      {
        id: 7,
        symbol: 'MSFT',
        time: '10D',
        ch: 92.5,
        chPercent: '12.5%',
      },
      {
        id: 8,
        symbol: 'MSFT',
        time: '10D',
        ch: 92.5,
        chPercent: '12.5%',
      },
      {
        id: 9,
        symbol: 'MSFT',
        time: '10D',
        ch: 92.5,
        chPercent: '12.5%',
      },
      {
        id: 10,
        symbol: 'MSFT',
        time: '10D',
        ch: 92.5,
        chPercent: '12.5%',
      },
    ],
    cardDataOne: [
      {
        id: 1,
        Img: QuantPortfolioEquityIcon,
        title: 'EQUITY',
        v1: null,
        value: '8.65L',
        marginsUsed: '51.75k',
        accountValue: '9.03L',
        percentage: null,
      },
      {
        id: 2,
        Img: QuantPortfolioCommodityIcon,
        title: 'COMMODITY',
        v1: null,
        value: '8.65L',
        marginsUsed: '51.75k',
        accountValue: '9.03L',
        percentage: null,
      },
      {
        id: 3,
        Img: QuantPortfolioCryptoIcon,
        title: 'CRYPTO',
        v1: null,
        value: '8.65L',
        marginsUsed: '51.75k',
        accountValue: '9.03L',
        percentage: null,
      },
      {
        id: 4,
        Img: QuantPortfolioCommodityIcon,
        title: 'HOLDINGS',
        v1: 134,
        value: '8.65L',
        marginsUsed: '51.75k',
        accountValue: '9.03L',
        percentage: '0.79%',
      },
    ],
    statusTable: [
      {
        id: 1,
        symbol: 'MSFT',
        pe: '565.45',
        low52: '6.87',
        price: '18.79',
        unitCost: '18.79',
        high52: '18.79',
        mktVal: '4941.77',
        gain: '4941.77',
        gainPercent: '10.77%',
        yield: '10.77',
        lastChg: '81.77%',
        lastChgPercent: '10.77%',
      },
      {
        id: 2,
        symbol: 'GOOG',
        pe: '8,51',
        low52: '6.87',
        price: '18.79',
        unitCost: '18.79',
        high52: '18.79',
        mktVal: '4941.77',
        gain: '4941.77',
        gainPercent: '10.77%',
        yield: '10.77',
        lastChg: '81.77%',
        lastChgPercent: '10.77%',
      },
      {
        id: 3,
        symbol: 'MSFT',
        pe: '565.45',
        low52: '6.87',
        price: '18.79',
        unitCost: '18.79',
        high52: '18.79',
        mktVal: '4941.77',
        gain: '4941.77',
        gainPercent: '10.77%',
        yield: '10.77',
        lastChg: '81.77%',
        lastChgPercent: '10.77%',
      },
      {
        id: 4,
        symbol: 'MSFT',
        pe: '565.45',
        low52: '6.87',
        price: '18.79',
        unitCost: '18.79',
        high52: '18.79',
        mktVal: '4941.77',
        gain: '4941.77',
        gainPercent: '10.77%',
        yield: '10.77',
        lastChg: '81.77%',
        lastChgPercent: '10.77%',
      },
      {
        id: 5,
        symbol: 'MSFT',
        pe: '565.45',
        low52: '6.87',
        price: '18.79',
        unitCost: '18.79',
        high52: '18.79',
        mktVal: '4941.77',
        gain: '4941.77',
        gainPercent: '10.77%',
        yield: '10.77',
        lastChg: '81.77%',
        lastChgPercent: '10.77%',
      },
      {
        id: 6,
        symbol: 'MSFT',
        pe: '565.45',
        low52: '6.87',
        price: '18.79',
        unitCost: '18.79',
        high52: '18.79',
        mktVal: '4941.77',
        gain: '4941.77',
        gainPercent: '10.77%',
        yield: '10.77',
        lastChg: '81.77%',
        lastChgPercent: '10.77%',
      },
      {
        id: 7,
        symbol: 'MSFT',
        pe: '565.45',
        low52: '6.87',
        price: '18.79',
        unitCost: '18.79',
        high52: '18.79',
        mktVal: '4941.77',
        gain: '4941.77',
        gainPercent: '10.77%',
        yield: '10.77',
        lastChg: '81.77%',
        lastChgPercent: '10.77%',
      },
      {
        id: 8,
        symbol: 'MSFT',
        pe: '565.45',
        low52: '6.87',
        price: '18.79',
        unitCost: '18.79',
        high52: '18.79',
        mktVal: '4941.77',
        gain: '4941.77',
        gainPercent: '10.77%',
        yield: '10.77',
        lastChg: '81.77%',
        lastChgPercent: '10.77%',
      },
      {
        id: 9,
        symbol: 'MSFT',
        pe: '565.45',
        low52: '6.87',
        price: '18.79',
        unitCost: '18.79',
        high52: '18.79',
        mktVal: '4941.77',
        gain: '4941.77',
        gainPercent: '10.77%',
        yield: '10.77',
        lastChg: '81.77%',
        lastChgPercent: '10.77%',
      },
    ],
    statusTableData: [
      {
        id: 1,
        date: '5 Mar 21',
        symbol: 'MSFT',
        type: 'DEPOSIT',
        amount: 470,
        chnage: '12.5',
      },
      {
        id: 2,
        date: '5 Mar 21',
        symbol: 'MSFT',
        type: 'DEPOSIT',
        amount: 470,
        chnage: '12.5',
      },
      {
        id: 3,
        date: '5 Mar 21',
        symbol: 'MSFT',
        type: 'DEPOSIT',
        amount: 470,
        chnage: '12.5',
      },
      {
        id: 4,
        date: '5 Mar 21',
        symbol: 'MSFT',
        type: 'DEPOSIT',
        amount: 470,
        chnage: '12.5',
      },
      {
        id: 5,
        date: '5 Mar 21',
        symbol: 'MSFT',
        type: 'DEPOSIT',
        amount: 470,
        chnage: '12.5',
      },
      {
        id: 6,
        date: '5 Mar 21',
        symbol: 'MSFT',
        type: 'DEPOSIT',
        amount: 470,
        chnage: '12.5',
      },
      {
        id: 7,
        date: '5 Mar 21',
        symbol: 'MSFT',
        type: 'DEPOSIT',
        amount: 470,
        chnage: '12.5',
      },
      {
        id: 8,
        date: '5 Mar 21',
        symbol: 'MSFT',
        type: 'DEPOSIT',
        amount: 470,
        chnage: '12.5',
      },
      {
        id: 9,
        date: '5 Mar 21',
        symbol: 'MSFT',
        type: 'DEPOSIT',
        amount: 470,
        chnage: '12.5',
      },
      {
        id: 10,
        date: '5 Mar 21',
        symbol: 'MSFT',
        type: 'DEPOSIT',
        amount: 470,
        chnage: '12.5',
      },
    ],
  },
  backtestResult: {
    cardData: [
      {
        id: 1,
        logo: QuantPortfolioMicrosoftDummyIcon,
        ticker: 'MSFT',
        fullName: 'Microsoft Limited.',
        timeframe: '15 days',
      },
      {
        id: 2,
        logo: QuantPortfolioMicrosoftDummyIcon,
        ticker: 'AAPL',
        fullName: 'Microsoft Limited.',
        timeframe: '15 days',
      },
    ],
    dataOne: [
      {
        id: 1,
        title: 'Initial Capital',
        allTrades: '400000.00',
        longTrades: '400000.00',
        shortTrades: '400000.00',
      },
      {
        id: 2,
        title: 'Ending Capital',
        allTrades: '400000.00',
        longTrades: '400000.00',
        shortTrades: '400000.00',
      },
      {
        id: 3,
        title: 'Net Profit',
        allTrades: '400000.00',
        longTrades: '400000.00',
        shortTrades: '400000.00',
      },
      {
        id: 4,
        title: 'Net Profit %',
        allTrades: '400000.00',
        longTrades: '400000.00',
        shortTrades: '400000.00',
      },
      {
        id: 5,
        title: 'Exposure %',
        allTrades: '400000.00',
        longTrades: '400000.00',
        shortTrades: '400000.00',
      },
      {
        id: 6,
        title: 'Annual Return %',
        allTrades: '400000.00',
        longTrades: '400000.00',
        shortTrades: '400000.00',
      },
      {
        id: 7,
        title: 'Risk Adjusted Return %',
        allTrades: '400000.00',
        longTrades: '400000.00',
        shortTrades: '400000.00',
      },
      {
        id: 8,
        title: 'Transaction Cost',
        allTrades: '400000.00',
        longTrades: '400000.00',
        shortTrades: '400000.00',
      },
    ],
    data1: [
      {
        id: 1,
        title: 'Avg. Profit/Loss',
        allTrades: '400000.00',
        longTrades: '400000.00',
        shortTrades: '400000.00',
      },
      {
        id: 2,
        title: 'Avg. Profit/Loss %',
        allTrades: '400000.00',
        longTrades: '400000.00',
        shortTrades: '400000.00',
      },
      {
        id: 3,
        title: 'Avg. Bars Held',
        allTrades: '400000.00',
        longTrades: '400000.00',
        shortTrades: '400000.00',
      },
      {
        id: 4,
        title: 'All Trades',
        allTrades: '400000.00',
        longTrades: '400000.00',
        shortTrades: '400000.00',
      },
    ],
    BoxData: [
      {
        id: 1,
        value: 1,
      },
      {
        id: 2,
        value: 0.6,
      },
      {
        id: 3,
        value: 0.85,
      },
      {
        id: 4,
        value: 0.5,
      },
      {
        id: 5,
        value: 1,
      },
      {
        id: 6,
        value: 0.62,
      },
      {
        id: 7,
        value: 0.3,
      },
      {
        id: 8,
        value: 0.3,
      },
      {
        id: 9,
        value: 1,
      },
    ],
  },
  symbollistData: {
    cardData: [
      {
        id: 1,
        // image: whiteSquareBox,
        header: 'Artificial Intelligence',
        info: ' India’s most powerful companies in one portfolio. Solid stability, scre-ened, momentum using...',
        author: 'by Niveshaay',
        amount: '$ 1,774',
        cagr: '80.24 %',
        // image2: yellowPowerButton,
        volatility: 'Min.Volatility',
      },
      {
        id: 2,
        // image: whiteSquareBox,
        header: 'Metaverse',
        info: 'One investment for all market conditions. Works for everyone that stand to benefit from increasing rural consumption...',
        author: 'by Niveshaay',
        amount: '$ 1,774',
        cagr: '80.24 %',
        // image2: redPowerButton,
        volatility: 'Min.Volatility',
      },
      {
        id: 3,
        // image: whiteSquareBox,
        header: 'Smart Cities',
        info: ' India’s most powerful companies in one portfolio. Solid stability, scre-ened, momentum using...',
        author: 'by Niveshaay',
        amount: '$ 1,774',
        cagr: '80.24 %',
        // image2: redPowerButton,
        volatility: 'Min.Volatility',
      },
      {
        id: 4,
        // image: whiteSquareBox,
        header: 'Artificial Intelligence',
        info: ' India’s most powerful companies in one portfolio. Solid stability, scre-ened, momentum using...',
        author: 'by Niveshaay',
        amount: '$ 1,774',
        cagr: '80.24 %',
        // image2: greenPowerButton,
        volatility: 'Min.Volatility',
      },
      {
        id: 5,
        // image: whiteSquareBox,
        header: 'Efficient Energy',
        info: ' India’s most powerful companies in one portfolio. Solid stability, scre-ened, momentum using...',
        author: 'by Niveshaay',
        amount: '$ 1,774',
        cagr: '80.24 %',
        // image2: yellowPowerButton,
        volatility: 'Min.Volatility',
      },
      {
        id: 6,
        // image: whiteSquareBox,
        header: 'Artificial Intelligence',
        info: ' India’s most powerful companies in one portfolio. Solid stability, scre-ened, momentum using...',
        author: 'by Niveshaay',
        amount: '$ 1,774',
        cagr: '80.24 %',
        // image2: yellowPowerButton,
        volatility: 'Min.Volatility',
      },
      {
        id: 7,
        // image: whiteSquareBox,
        header: 'Artificial Intelligence',
        info: ' India’s most powerful companies in one portfolio. Solid stability, scre-ened, momentum using...',
        author: 'by Niveshaay',
        amount: '$ 1,774',
        cagr: '80.24 %',
        // image2: greenPowerButton,
        volatility: 'Min.Volatility',
      },
      {
        id: 8,
        // image: whiteSquareBox,
        header: 'Artificial Intelligence',
        info: ' India’s most powerful companies in one portfolio. Solid stability, scre-ened, momentum using...',
        author: 'by Niveshaay',
        amount: '$ 1,774',
        cagr: '80.24 %',
        // image2: yellowPowerButton,
        volatility: 'Min.Volatility',
      },
      {
        id: 9,
        // image: whiteSquareBox,
        header: 'Artificial Intelligence',
        info: ' India’s most powerful companies in one portfolio. Solid stability, scre-ened, momentum using...',
        author: 'by Niveshaay',
        amount: '$ 1,774',
        cagr: '80.24 %',
        // image2: yellowPowerButton,
        volatility: 'Min.Volatility',
      },
      {
        id: 10,
        // image: whiteSquareBox,
        header: 'Artificial Intelligence',
        info: ' India’s most powerful companies in one portfolio. Solid stability, scre-ened, momentum using...',
        author: 'by Niveshaay',
        amount: '$ 1,774',
        cagr: '80.24 %',
        // image2: yellowPowerButton,
        volatility: 'Min.Volatility',
      },
    ],
    tableData: [
      {
        id: 1,
        constituents: 'Varun Breverages Ltd',
        currPrice: '735.55',
        weights: '--',
        quantity: 0,
        type: 'BUY',
      },
      {
        id: 2,
        constituents: 'Varun Breverages Ltd',
        currPrice: '735.55',
        weights: '--',
        quantity: 0,
        type: 'BUY',
      },
      {
        id: 3,
        constituents: 'Varun Breverages Ltd',
        currPrice: '735.55',
        weights: '--',
        quantity: 0,
        type: 'BUY',
      },
      {
        id: 4,
        constituents: 'Varun Breverages Ltd',
        currPrice: '735.55',
        weights: '--',
        quantity: 0,
        type: 'BUY',
      },
    ],
    investmentAmount: [
      'Any',
      'Under $ 5,000',
      'Under $ 10,000',
      'Under $ 15,000',
      'Under $ 20,000',
      'Under $ 25,000',
      'Under $ 30,000',
      'Under $ 40,000',
      'Under $ 50,000',
    ],
    cardDatas: [
      {
        id: 1,
        icon: NetflixDummyLogo,
        text: 'Top gainers in last year',
      },
      {
        id: 2,
        icon: NetflixDummyLogo,
        text: 'Low investment amount',
      },
      {
        id: 3,
        icon: NetflixDummyLogo,
        text: 'Popular investment strategies',
      },
      {
        id: 4,
        icon: NetflixDummyLogo,
        text: 'Recently rebalanced',
      },
    ],
  },
  portfolioData: [
    {
      img: PortfolioArtificialIntelligenceImage,
      title: 'Artificial Intelligence',
      bg: '#EAAD6B',
    },
    {
      img: PortfolioAutonomousImage,
      title: 'Metaverse',
      bg: '#FFC87C',
    },
    { img: PortfolioBlockchainImage, title: 'Smart Cities', bg: '#F4C257' },
    {
      img: PortfolioCyberSecurityImage,
      title: 'Efficient Energy',
      bg: '#EFAF28',
    },
    {
      img: PortfolioDigitalConsumerImage,
      title: 'The 3D Printing',
      bg: '#C38B14',
    },
    {
      img: PortfolioDigitalWalletImage,
      title: 'Hydrogen Economy',
      bg: '#FFC28C',
    },
    {
      img: PortfolioDisruptiveInnovationImage,
      title: 'ESG',
      bg: '#F1A75D',
    },
    {
      img: PortfolioEarlyStageDisruptionImage,
      title: 'Disruptive Innovation',
      bg: '#FF97A1',
    },
    {
      img: PortfolioDisruptiveInnovationImage,
      title: 'Cybersecurity',
      bg: '#E46D79',
    },
    {
      img: PortfolioEfficientEnergyImage,
      title: 'Autonomous Tech & Robotics',
      bg: '#FF5B6B',
    },
    {
      img: PortfolioElectricVehicleImage,
      title: 'Electric Vehicles',
      bg: '#E3555A',
    },
    {
      img: PortfolioWebThreeImage,
      title: 'Digital Wallets',
      bg: '#C24955',
    },
    {
      img: PortfolioTheSemiconductorImage,
      title: 'Blockchain',
      bg: '#BF4146',
    },
    {
      img: PortfolioThePrintingImage,
      title: 'Next Generation Internet',
      bg: '#CF6758',
    },
    {
      img: PortfolioTheDigitalEconomyImage,
      title: 'Genomic Revolution',
      bg: '#A1ACC0',
    },
    {
      img: PortfolioSpaceExplorationImage,
      title: 'Web3',
      bg: '#739DED',
    },
    {
      img: PortfolioSmartCitiesImage,
      title: 'The Semiconductor',
      bg: '#309CD6',
    },
    {
      img: PortfolioNextGenerationInternetImage,
      title: 'Fintech Innovation',
      bg: '#2E6AD8',
    },
    {
      img: PortfolioMobilityImage,
      title: 'Early-Stage Disruptors',
      bg: '#21C998',
    },
    {
      img: PortfolioESGImage,
      title: 'Digital Consumers',
      bg: '#00C7C5',
    },
    {
      img: PortfolioMetaverseImage,
      title: 'The Digital Economy',
      bg: '#298E8D',
    },
    {
      img: PortfolioFintechInnovationImage,
      title: 'Mobility',
      bg: '#F49587',
    },
    {
      img: PortfolioHydrogenEconomyImage,
      title: 'Space Exploration',
      bg: '#CF6758',
    },
  ],
  trades: [
    {
      id: 1,
      ticket: 'NIFTY-I',
      entry: '01 / 4 / 2011 - 6207.35',
      exit: '01/4/2011  -  6207.35',
      change: '- 0.52%',
      profit: '42034.00 - - 2.10 %',
      cumProfit: '- 400000.00',
      bars: 2,
      scale: '0/0',
    },
    {
      id: 2,
      ticket: 'NIFTY-I',
      entry: '01 / 4 / 2011 - 6207.35',
      exit: '01/4/2011  -  6207.35',
      change: '- 0.52%',
      profit: '42034.00 - - 2.10 %',
      cumProfit: '- 400000.00',
      bars: 2,
      scale: '0/0',
    },
    {
      id: 3,
      ticket: 'NIFTY-I',
      entry: '01 / 4 / 2011 - 6207.35',
      exit: '01/4/2011  -  6207.35',
      change: '- 0.52%',
      profit: '42034.00 - - 2.10 %',
      cumProfit: '- 400000.00',
      bars: 2,
      scale: '0/0',
    },
    {
      id: 4,
      ticket: 'NIFTY-I',
      entry: '01 / 4 / 2011 - 6207.35',
      exit: '01/4/2011  -  6207.35',
      change: '- 0.52%',
      profit: '42034.00 - - 2.10 %',
      cumProfit: '- 400000.00',
      bars: 2,
      scale: '0/0',
    },
    {
      id: 5,
      ticket: 'NIFTY-I',
      entry: '01 / 4 / 2011 - 6207.35',
      exit: '01/4/2011  -  6207.35',
      change: '- 0.52%',
      profit: '42034.00 - - 2.10 %',
      cumProfit: '- 400000.00',
      bars: 2,
      scale: '0/0',
    },
    {
      id: 6,
      ticket: 'NIFTY-I',
      entry: '01 / 4 / 2011 - 6207.35',
      exit: '01/4/2011  -  6207.35',
      change: '- 0.52%',
      profit: '42034.00 - - 2.10 %',
      cumProfit: '- 400000.00',
      bars: 2,
      scale: '0/0',
    },
    {
      id: 7,
      ticket: 'NIFTY-I',
      entry: '01 / 4 / 2011 - 6207.35',
      exit: '01/4/2011  -  6207.35',
      change: '- 0.52%',
      profit: '42034.00 - - 2.10 %',
      cumProfit: '- 400000.00',
      bars: 2,
      scale: '0/0',
    },
    {
      id: 8,
      ticket: 'NIFTY-I',
      entry: '01 / 4 / 2011 - 6207.35',
      exit: '01/4/2011  -  6207.35',
      change: '- 0.52%',
      profit: '42034.00 - - 2.10 %',
      cumProfit: '- 400000.00',
      bars: 2,
      scale: '0/0',
    },
    {
      id: 9,
      ticket: 'NIFTY-I',
      entry: '01 / 4 / 2011 - 6207.35',
      exit: '01/4/2011  -  6207.35',
      change: '- 0.52%',
      profit: '42034.00 - - 2.10 %',
      cumProfit: '- 400000.00',
      bars: 2,
      scale: '0/0',
    },
    {
      id: 10,
      ticket: 'NIFTY-I',
      entry: '01 / 4 / 2011 - 6207.35',
      exit: '01/4/2011  -  6207.35',
      change: '- 0.52%',
      profit: '42034.00 - - 2.10 %',
      cumProfit: '- 400000.00',
      bars: 2,
      scale: '0/0',
    },
    {
      id: 11,
      ticket: 'NIFTY-I',
      entry: '01 / 4 / 2011 - 6207.35',
      exit: '01/4/2011  -  6207.35',
      change: '- 0.52%',
      profit: '42034.00 - - 2.10 %',
      cumProfit: '- 400000.00',
      bars: 2,
      scale: '0/0',
    },
    {
      id: 12,
      ticket: 'NIFTY-I',
      entry: '01 / 4 / 2011 - 6207.35',
      exit: '01/4/2011  -  6207.35',
      change: '- 0.52%',
      profit: '42034.00 - - 2.10 %',
      cumProfit: '- 400000.00',
      bars: 2,
      scale: '0/0',
    },
    {
      id: 13,
      ticket: 'NIFTY-I',
      entry: '01 / 4 / 2011 - 6207.35',
      exit: '01/4/2011  -  6207.35',
      change: '- 0.52%',
      profit: '42034.00 - - 2.10 %',
      cumProfit: '- 400000.00',
      bars: 2,
      scale: '0/0',
    },
    {
      id: 14,
      ticket: 'NIFTY-I',
      entry: '01 / 4 / 2011 - 6207.35',
      exit: '01/4/2011  -  6207.35',
      change: '- 0.52%',
      profit: '42034.00 - - 2.10 %',
      cumProfit: '- 400000.00',
      bars: 2,
      scale: '0/0',
    },
    {
      id: 15,
      ticket: 'NIFTY-I',
      entry: '01 / 4 / 2011 - 6207.35',
      exit: '01/4/2011  -  6207.35',
      change: '- 0.52%',
      profit: '42034.00 - - 2.10 %',
      cumProfit: '- 400000.00',
      bars: 2,
      scale: '0/0',
    },
  ],
  statistics: {
    data: [
      {
        id: 1,
        title: 'Initial Capital',
        allTrades: '400000.00',
        longTrades: '400000.00',
        shortTrades: '400000.00',
      },
      {
        id: 2,
        title: 'Ending Capital',
        allTrades: '400000.00',
        longTrades: '400000.00',
        shortTrades: '400000.00',
      },
      {
        id: 3,
        title: 'Net Profit',
        allTrades: '400000.00',
        longTrades: '400000.00',
        shortTrades: '400000.00',
      },
      {
        id: 4,
        title: 'Net Profit %',
        allTrades: '400000.00',
        longTrades: '400000.00',
        shortTrades: '400000.00',
      },
      {
        id: 5,
        title: 'Exposure %',
        allTrades: '400000.00',
        longTrades: '400000.00',
        shortTrades: '400000.00',
      },
      {
        id: 6,
        title: 'Annual Return %',
        allTrades: '400000.00',
        longTrades: '400000.00',
        shortTrades: '400000.00',
      },
      {
        id: 7,
        title: 'Risk Adjusted Return %',
        allTrades: '400000.00',
        longTrades: '400000.00',
        shortTrades: '400000.00',
      },
      {
        id: 8,
        title: 'Transaction Cost',
        allTrades: '400000.00',
        longTrades: '400000.00',
        shortTrades: '400000.00',
      },
    ],
    data1: [
      {
        id: 1,
        title: 'Avg. Profit/Loss',
        allTrades: '400000.00',
        longTrades: '400000.00',
        shortTrades: '400000.00',
      },
      {
        id: 2,
        title: 'Avg. Profit/Loss %',
        allTrades: '400000.00',
        longTrades: '400000.00',
        shortTrades: '400000.00',
      },
      {
        id: 3,
        title: 'Avg. Bars Held',
        allTrades: '400000.00',
        longTrades: '400000.00',
        shortTrades: '400000.00',
      },
      {
        id: 4,
        title: 'All Trades',
        allTrades: '400000.00',
        longTrades: '400000.00',
        shortTrades: '400000.00',
      },
    ],
  },
  montecarlo: [
    {
      id: 1,
      percentile: '1 %',
      equity: '0',
      return: '-100.00 %',
      maxDollar: '- 464484',
      maxPercentage: '- 100.00 %',
      lowEquity: 0,
    },
    {
      id: 2,
      percentile: '1 %',
      equity: '0',
      return: '-100.00 %',
      maxDollar: '- 464484',
      maxPercentage: '- 100.00 %',
      lowEquity: 0,
    },
    {
      id: 3,
      percentile: '1 %',
      equity: '0',
      return: '-100.00 %',
      maxDollar: '- 464484',
      maxPercentage: '- 100.00 %',
      lowEquity: 0,
    },
    {
      id: 4,
      percentile: '1 %',
      equity: '0',
      return: '-100.00 %',
      maxDollar: '- 464484',
      maxPercentage: '- 100.00 %',
      lowEquity: 0,
    },
    {
      id: 5,
      percentile: '1 %',
      equity: '0',
      return: '-100.00 %',
      maxDollar: '- 464484',
      maxPercentage: '- 100.00 %',
      lowEquity: 0,
    },
    {
      id: 6,
      percentile: '1 %',
      equity: '0',
      return: '-100.00 %',
      maxDollar: '- 464484',
      maxPercentage: '- 100.00 %',
      lowEquity: 0,
    },
    {
      id: 7,
      percentile: '1 %',
      equity: '0',
      return: '-100.00 %',
      maxDollar: '- 464484',
      maxPercentage: '- 100.00 %',
      lowEquity: 0,
    },
    {
      id: 8,
      percentile: '1 %',
      equity: '0',
      return: '-100.00 %',
      maxDollar: '- 464484',
      maxPercentage: '- 100.00 %',
      lowEquity: 0,
    },
    {
      id: 9,
      percentile: '1 %',
      equity: '0',
      return: '-100.00 %',
      maxDollar: '- 464484',
      maxPercentage: '- 100.00 %',
      lowEquity: 0,
    },
    {
      id: 10,
      percentile: '1 %',
      equity: '0',
      return: '-100.00 %',
      maxDollar: '- 464484',
      maxPercentage: '- 100.00 %',
      lowEquity: 0,
    },
  ],
  backtestingCharts: [
    {
      id: 1,
      year: 2015,
      jan: '-5.9',
      feb: '1.02%',
      mar: '2.0%',
      apr: '1.0%',
      may: '-1.5%',
      jun: '0.14%',
      jul: '0.12%',
      aug: '-1.0%',
      sep: '3.12%',
      oct: '-0.4%',
      nov: '3.6%',
      dec: '5.9%',
      yearPercent: '-1.4%',
    },
    {
      id: 2,
      year: 2016,
      jan: '-5.9',
      feb: '1.02%',
      mar: '2.0%',
      apr: '1.0%',
      may: '-1.5%',
      jun: '0.14%',
      jul: '0.12%',
      aug: '-1.0%',
      sep: '3.12%',
      oct: '-0.4%',
      nov: '3.6%',
      dec: '5.9%',
      yearPercent: '-1.4%',
    },
    {
      id: 3,
      year: 2017,
      jan: '-5.9',
      feb: '1.02%',
      mar: '2.0%',
      apr: '1.0%',
      may: '-1.5%',
      jun: '0.14%',
      jul: '0.12%',
      aug: '-1.0%',
      sep: '3.12%',
      oct: '-0.4%',
      nov: '3.6%',
      dec: '5.9%',
      yearPercent: '-1.4%',
    },
    {
      id: 4,
      year: 2018,
      jan: '-5.9',
      feb: '1.02%',
      mar: '2.0%',
      apr: '1.0%',
      may: '-1.5%',
      jun: '0.14%',
      jul: '0.12%',
      aug: '-1.0%',
      sep: '3.12%',
      oct: '-0.4%',
      nov: '3.6%',
      dec: '5.9%',
      yearPercent: '-1.4%',
    },
    {
      id: 5,
      year: 2019,
      jan: '-5.9',
      feb: '1.02%',
      mar: '2.0%',
      apr: '1.0%',
      may: '-1.5%',
      jun: '0.14%',
      jul: '0.12%',
      aug: '-1.0%',
      sep: '3.12%',
      oct: '-0.4%',
      nov: '3.6%',
      dec: '5.9%',
      yearPercent: '-1.4%',
    },
    {
      id: 6,
      year: 2020,
      jan: '-5.9',
      feb: '1.02%',
      mar: '2.0%',
      apr: '1.0%',
      may: '-1.5%',
      jun: '0.14%',
      jul: '0.12%',
      aug: '-1.0%',
      sep: '3.12%',
      oct: '-0.4%',
      nov: '3.6%',
      dec: '5.9%',
      yearPercent: '-1.4%',
    },
    {
      id: 7,
      year: 2021,
      jan: '-5.9',
      feb: '1.02%',
      mar: '2.0%',
      apr: '1.0%',
      may: '-1.5%',
      jun: '0.14%',
      jul: '0.12%',
      aug: '-1.0%',
      sep: '3.12%',
      oct: '-0.4%',
      nov: '3.6%',
      dec: '5.9%',
      yearPercent: '-1.4%',
    },
    {
      id: 8,
      year: 2022,
      jan: '-5.9',
      feb: '1.02%',
      mar: '2.0%',
      apr: '1.0%',
      may: '-1.5%',
      jun: '0.14%',
      jul: '0.12%',
      aug: '-1.0%',
      sep: '3.12%',
      oct: '-0.4%',
      nov: '3.6%',
      dec: '5.9%',
      yearPercent: '-1.4%',
    },
    {
      id: 9,
      year: 2023,
      jan: '-5.9',
      feb: '1.02%',
      mar: '2.0%',
      apr: '1.0%',
      may: '-1.5%',
      jun: '0.14%',
      jul: '0.12%',
      aug: '-1.0%',
      sep: '3.12%',
      oct: '-0.4%',
      nov: '3.6%',
      dec: '5.9%',
      yearPercent: '-1.4%',
    },
  ],
  riskProfile: {
    sidebarHeader: [
      {
        id: 1,
        logo: RiskProfileDummyAppleLogo,
        ticker: 'AAPL',
        fullName: 'Apple Inc.',
        price: '251.87',
        value1: '+1.07',
        value2: '+0.43%',
        time: '03/25 16:00 EDT',
      },
    ],
    data: [
      {
        id: 1,
        open: 252.88,
        close: 250.8,
        high: 254.0,
        low: 249.13,
        marketCap: '327.66B',
        turnover: '1.28B',
        range: '2.67%',
        volume: '2.18K',
        freeFloat: '347.03K',
      },
    ],
    performanceData: [
      {
        id: 1,
        percentage: '1.08%',
        range: '1W',
      },
      {
        id: 2,
        percentage: '8.35%',
        range: '1M',
      },
      {
        id: 3,
        percentage: '8.35%',
        range: '3M',
      },
      {
        id: 4,
        percentage: '1.45%',
        range: '6M',
      },
      {
        id: 5,
        percentage: '-9.71%',
        range: '3M',
      },
      {
        id: 6,
        percentage: '28.42%',
        range: '1Y',
      },
    ],
    tableData: [
      {
        id: 1,
        img: RiskProfileDummyAppleLogo,
        ticker: 'AAPL',
        price: 2780.49,
        d: '%2780.49',
        volume: 24342562,
        bid: 24342562,
        ask: 24342562,
        time: '10W',
      },
      {
        id: 2,
        img: RiskProfileDummyAppleLogo,
        ticker: 'MSFT',
        price: 2780.49,
        d: '%2780.49',
        volume: 24342562,
        bid: 24342562,
        ask: 24342562,
        time: '10W',
      },
      {
        id: 3,
        img: RiskProfileDummyAppleLogo,
        ticker: 'GOOG',
        price: 2780.49,
        d: '%2780.49',
        volume: 24342562,
        bid: 24342562,
        ask: 24342562,
        time: '10W',
      },
      {
        id: 4,
        img: RiskProfileDummyAppleLogo,
        ticker: 'AMZN',
        price: 2780.49,
        d: '%2780.49',
        volume: 24342562,
        bid: 24342562,
        ask: 24342562,
        time: '10W',
      },
      {
        id: 5,
        img: RiskProfileDummyAppleLogo,
        ticker: 'TSLA',
        price: 2780.49,
        d: '%2780.49',
        volume: 24342562,
        bid: 24342562,
        ask: 24342562,
        time: '10W',
      },
      {
        id: 6,
        img: RiskProfileDummyAppleLogo,
        ticker: 'BRKA',
        price: 2780.49,
        d: '%2780.49',
        volume: 24342562,
        bid: 24342562,
        ask: 24342562,
        time: '10W',
      },
      {
        id: 7,
        img: RiskProfileDummyAppleLogo,
        ticker: 'NVDA',
        price: 2780.49,
        d: '%2780.49',
        volume: 24342562,
        bid: 24342562,
        ask: 24342562,
        time: '10W',
      },
      {
        id: 8,
        img: RiskProfileDummyAppleLogo,
        ticker: 'META',
        price: 2780.49,
        d: '%2780.49',
        volume: 24342562,
        bid: 24342562,
        ask: 24342562,
        time: '10W',
      },
      {
        id: 9,
        img: RiskProfileDummyAppleLogo,
        ticker: 'UNH',
        price: 2780.49,
        d: '%2780.49',
        volume: 24342562,
        bid: 24342562,
        ask: 24342562,
        time: '10W',
      },
      {
        id: 10,
        img: RiskProfileDummyAppleLogo,
        ticker: 'VISA',
        price: 2780.49,
        d: '%2780.49',
        volume: 24342562,
        bid: 24342562,
        ask: 24342562,
        time: '10W',
      },
      {
        id: 11,
        img: RiskProfileDummyAppleLogo,
        ticker: 'JPM',
        price: 2780.49,
        d: '%2780.49',
        volume: 24342562,
        bid: 24342562,
        ask: 24342562,
        time: '10W',
      },
      {
        id: 12,
        img: RiskProfileDummyAppleLogo,
        ticker: 'WMT',
        price: 2780.49,
        d: '%2780.49',
        volume: 24342562,
        bid: 24342562,
        ask: 24342562,
        time: '10W',
      },
      {
        id: 13,
        img: RiskProfileDummyAppleLogo,
        ticker: 'PG',
        price: 2780.49,
        d: '%2780.49',
        volume: 24342562,
        bid: 24342562,
        ask: 24342562,
        time: '10W',
      },
      {
        id: 14,
        img: RiskProfileDummyAppleLogo,
        ticker: 'BAC',
        price: 2780.49,
        d: '%2780.49',
        volume: 24342562,
        bid: 24342562,
        ask: 24342562,
        time: '10W',
      },
      {
        id: 15,
        img: RiskProfileDummyAppleLogo,
        ticker: 'XOM',
        price: 2780.49,
        d: '%2780.49',
        volume: 24342562,
        bid: 24342562,
        ask: 24342562,
        time: '10W',
      },
      {
        id: 16,
        img: RiskProfileDummyAppleLogo,
        ticker: 'MA',
        price: 2780.49,
        d: '%2780.49',
        volume: 24342562,
        bid: 24342562,
        ask: 24342562,
        time: '10W',
      },
      {
        id: 17,
        img: RiskProfileDummyAppleLogo,
        ticker: 'HD',
        price: 2780.49,
        d: '%2780.49',
        volume: 24342562,
        bid: 24342562,
        ask: 24342562,
        time: '10W',
      },
      {
        id: 18,
        img: RiskProfileDummyAppleLogo,
        ticker: 'CVX',
        price: 2780.49,
        d: '%2780.49',
        volume: 24342562,
        bid: 24342562,
        ask: 24342562,
        time: '10W',
      },
    ],
  },
  quantLandingPage: {
    sectionOneData: [
      {
        id: 1,
        Img: QuantLandingImageOne,
        header: 'Multiple Trading Strategies',
        listItems: [
          {
            id: 1,
            listItem: 'Different profile of strategies',
          },
          {
            id: 2,
            listItem: 'Trend following, mean reversion etc',
          },
          {
            id: 3,
            listItem: 'Cater to different timeframes',
          },
          {
            id: 4,
            listItem: 'Back-test any strategy on any symbol on any time frame ',
          },
          {
            id: 5,
            listItem: 'Ranking of symbols for any strategy sortable to any individual risk reward profile',
          },
        ],
      },
    ],
    sectionTwoData: [
      {
        id: 1,
        Img: QuantLandingImageTwo,
        header: 'Back-test module for individual symbol as well as portfolios',
        listItems: [
          {
            id: 1,
            listItem: 'Different profile of strategies',
          },
          {
            id: 2,
            listItem: 'Trend following, mean reversion etc',
          },
          {
            id: 3,
            listItem: 'Cater to different timeframes',
          },
          {
            id: 4,
            listItem: 'Back-test any strategy on any symbol on any time frame ',
          },
          {
            id: 5,
            listItem: 'Ranking of symbols for any strategy sortable to any individual risk reward profile',
          },
        ],
      },
    ],
    sectionThreeData: [
      {
        id: 1,
        Img: QuantLandingImageThree,
        header: 'Volatility Analysis',
        listItems: [
          {
            id: 1,
            listItem: 'Different profile of strategies',
          },
          {
            id: 2,
            listItem: 'Trend following, mean reversion etc',
          },
          {
            id: 3,
            listItem: 'Cater to different timeframes',
          },
          {
            id: 4,
            listItem: 'Back-test any strategy on any symbol on any time frame ',
          },
          {
            id: 5,
            listItem: 'Ranking of symbols for any strategy sortable to any individual risk reward profile',
          },
        ],
      },
    ],
  },
};
