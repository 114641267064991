import React, { useEffect, useRef, useMemo, memo, useCallback } from 'react';
import * as echarts from 'echarts';
import { convertNumberToString } from 'src/lib/utils/utils';

const dt = (od, pd) => {
  const date = new Date(od);
  const prevDate = pd ? new Date(pd) : null;

  const day = String(date.getDate()).padStart(2, '0');
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const year = String(date.getFullYear()).slice(-2);
  const time = date.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });

  const isSameDay = prevDate && date.toDateString() === prevDate.toDateString();

  return isSameDay ? time : `${day}-${month}-${year}`;
};

export const SocialSentimentGraph = memo(({ data, isTwitter }) => {
  const chartRef = useRef(null);

  const xAxisData = useMemo(
    () => data?.map((item, index) => dt(item.date, index > 0 ? data[index - 1].date : null)),
    [data],
  );

  const yAxisOption = useMemo(
    () => [
      {
        type: 'value',
        axisLine: {
          lineStyle: {
            color: '#FFFF',
          },
        },
        splitLine: {
          lineStyle: {
            color: 'rgba(255, 255, 255, 0.09)',
          },
        },
        axisLabel: {
          formatter: convertNumberToString,
        },
      },
      {
        type: 'value',
        splitLine: {
          show: false,
        },
        axisTick: {
          show: false,
        },
        axisLabel: {
          show: true,
        },
      },
    ],
    [],
  );
  const legend = ['SENTIMENT', 'POSTS', 'LIKES', 'COMMENTS'];
  const lineSeries = useMemo(
    () => [
      {
        type: 'line',
        name: 'POSTS',
        data: data.map((value) => (!isTwitter ? value.stocktwits_posts : value.twitter_posts)),
        yAxisIndex: 0,
        itemStyle: {
          color: '#14C17E',
        },
        symbol: 'none',
      },
      {
        type: 'line',
        name: 'LIKES',
        data: data.map((value) => (!isTwitter ? value.stocktwits_likes : value.twitter_likes)),
        yAxisIndex: 0,
        itemStyle: {
          color: '#007DFF',
        },
        symbol: 'none',
      },
      {
        type: 'line',
        name: 'COMMENTS',
        data: data.map((value) => (!isTwitter ? value.stocktwits_comments : value.twitter_comments)),
        yAxisIndex: 0,
        itemStyle: {
          color: '#DE334A',
        },
        symbol: 'none',
      },
      {
        type: 'bar',
        name: 'SENTIMENT',
        data: data.map((value) =>
          ((!isTwitter ? value.stocktwits_sentiment : value.twitter_sentiment) * 100).toFixed(2),
        ),
        yAxisIndex: 1,
        barWidth: 4,
        itemStyle: {
          color: '#FFB300',
        },
        symbol: 'none',
      },
    ],
    [data, isTwitter],
  );

  const createChart = useCallback(() => {
    const chart = echarts.init(chartRef.current);
    const option = {
      xAxis: {
        type: 'category',
        data: xAxisData,
        axisLabel: {
          color: '#ffffff',
          rotate: 35,
        },
        axisLine: {
          lineStyle: {
            color: '#ffffff',
          },
        },
      },
      yAxis: yAxisOption,
      tooltip: {
        trigger: 'axis',
        axisPointer: {
          type: 'shadow',
        },
        backgroundColor: 'rgba(0, 0, 0, 0.8)',
        borderColor: 'rgba(0, 0, 0, 0.8)',
        textStyle: {
          color: '#ffffff',
        },
        formatter: (params) => {
          const date = new Date(data[params[0].dataIndex].date).toLocaleString('en-GB', {
            day: '2-digit',
            month: '2-digit',
            year: '2-digit',
            hour: '2-digit',
            minute: '2-digit',
          });

          let tooltipContent = `Date: ${date}<br/>`;

          params.forEach((param) => {
            const seriesName = param.seriesName;
            const formattedValue = convertNumberToString(param.data);
            const color = param.color;
            tooltipContent += `<span style="color:${color}">${seriesName}: ${formattedValue}</span><br/>`;
          });

          return tooltipContent;
        },
      },
      legend: {
        data: legend,
        textStyle: {
          color: '#ffffff',
        },
        padding: [10, 10, 10, 10],
      },
      series: [...lineSeries],
    };
    chart.setOption(option);

    return () => {
      chart.dispose();
    };
  }, [xAxisData, yAxisOption, lineSeries, data]);

  useEffect(() => {
    const cleanup = createChart();

    return () => {
      cleanup();
    };
  }, [createChart]);

  return <div ref={chartRef} style={{ width: '100%', height: '400px' }} />;
});
