import { put, takeLatest } from 'redux-saga/effects';
import { postTickerSearch } from 'src/repositories/mgmt/mgmt';
import {
  fetchSearch,
  fetchSearchFailure,
  fetchSearchSuccess,
  fetchSupportSearch,
  fetchSupportSearchFailure,
  fetchSupportSearchSuccess,
} from './reducer';
import { searchSupportFaq } from 'src/repositories/support/support';

function* fetchSearchSaga(action) {
  try {
    const data = yield postTickerSearch(action.payload).then((response) => response);
    yield put(fetchSearchSuccess(data?.data?.data));
  } catch (error) {
    yield put(fetchSearchFailure(error));
  }
}

export function* watchSearchSaga() {
  yield takeLatest(fetchSearch.type, fetchSearchSaga);
}

function* fetchSupportSearchSaga(action) {
  try {
    const data = yield searchSupportFaq(action.payload).then((response) => response);
    yield put(fetchSupportSearchSuccess(data?.data?.data));
  } catch (error) {
    yield put(fetchSupportSearchFailure(error));
  }
}

export function* watchSupportSearchSaga() {
  yield takeLatest(fetchSupportSearch.type, fetchSupportSearchSaga);
}
