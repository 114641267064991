import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { selectWatchlistOwnership } from '../../Watchlist.selector';
import { fetchWatchlistOwnership } from '../../Watchlist.reducer';
import { useParams } from 'react-router-dom';
import Skeleton from 'react-loading-skeleton';
import { flexRender, getCoreRowModel, useReactTable } from '@tanstack/react-table';
import { data as staticData } from 'src/static-data';
import { Pagination } from 'src/components';
function Ownership() {
  const { ticker, market } = useParams();

  const dispatch = useDispatch();
  const ownership = useSelector(selectWatchlistOwnership);
  useEffect(() => {
    dispatch(fetchWatchlistOwnership({ ticker }));
  }, [ticker]);

  const table = useReactTable({
    columns: staticData.watchlist.ownershipTableHeaders,
    data: ownership?.data ? ownership?.data?.stock_ownership : [],

    getCoreRowModel: getCoreRowModel(),
  });
  return (
    <main className='ownership'>
      <h1>Ownership</h1>
      <div className='ownership-table'>
        <table>
          <thead>
            {table.getHeaderGroups().map((headerGroup) => (
              <tr key={headerGroup.id} className=''>
                {headerGroup.headers.map((header) => (
                  <th key={header.id}>{flexRender(header.column.columnDef.header, header.getContext())}</th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody>
            {ownership?.isLoading
              ? Array.from({ length: 10 }).map((_, rowIndex) => (
                  <tr key={rowIndex}>
                    {Array.from({ length: 12 }).map((_, colIndex) => (
                      <td key={colIndex}>
                        <Skeleton />
                      </td>
                    ))}
                  </tr>
                ))
              : table.getRowModel().rows.map((row) => (
                  <tr key={row.id}>
                    {row.getVisibleCells().map((cell) => (
                      <td key={cell.id}>{flexRender(cell.column.columnDef.cell, cell.getContext())}</td>
                    ))}
                  </tr>
                ))}
          </tbody>
        </table>
        {ownership?.data?.length > 0 || ownership?.error ? <h2 className='ca-nodata'>No Data Available</h2> : null}
      </div>
      {!(ownership?.data?.length > 0 || ownership?.error) ? (
        <div className='pagination  display: flex;align-items: center; justify-content: center; py-8'>
          <Pagination
            totalPages={ownership?.data?.page}
            onPageChange={(number) => dispatch(fetchWatchlistOwnership({ ticker, page: number }))}
          />
        </div>
      ) : null}
    </main>
  );
}

export default Ownership;
