import React, { useEffect, useState } from 'react';
import { NavLink, Outlet, useParams, useNavigate } from 'react-router-dom';
import { WatchlistHeader } from 'src/components';
import Select from 'src/components/InputElements/select.component';
import WatchlistSidebar from 'src/components/WatchlistComponents/sidebar';
import { useDispatch, useSelector } from 'react-redux';
import { selectWatchlistOverview } from './Watchlist.selector';
import {
  fetchWatchlistBars,
  fetchWatchlistOverview,
  fetchWatchlistOverviewFailure,
  fetchWatchlistTickerImages,
} from './Watchlist.reducer';
import { useCachedMarketInfo } from 'src/hooks/marketInfo';
import Searchbox from 'src/components/searchbox/searchbox';
import { fetchLiveQuote } from 'src/store/livequote/reducer';
import { useWebSocketTicker } from 'src/hooks/subscribeTicker';
function Watchlist() {
  const { ticker, market } = useParams();
  const dispatch = useDispatch();
  const nav = useNavigate();
  useWebSocketTicker(ticker, true);

  const overview = useSelector(selectWatchlistOverview);
  const { data: marketInfo } = useCachedMarketInfo();
  const [marketIndex, setMartketIndex] = useState('');
  const [mkt, setMkt] = useState('');

  const fetchWatchlistData = async () => {
    const mk = market[0].toUpperCase() + market.slice(1);
    dispatch(
      fetchLiveQuote({
        market_type: marketInfo?.market_supported_map?.[mk],
        tickers: [ticker.toUpperCase()],
      }),
    );

    if (typeof ticker === 'string' && mk === 'Stocks') {
      dispatch(fetchWatchlistOverview({ ticker: ticker.toUpperCase() }));
      dispatch(
        fetchWatchlistTickerImages({
          market_type: marketInfo?.market_supported_map?.[mk],
          tickers: [ticker.toUpperCase()],
        }),
      );
    } else {
      dispatch(fetchWatchlistOverviewFailure({}));
      dispatch(
        fetchWatchlistTickerImages({
          market_type: marketInfo?.market_supported_map?.[mk],
          tickers: [ticker.toUpperCase()],
        }),
      );
    }

    const currentTimeEpoch = Date.now();
    const oneYearInMilliseconds = 365.25 * 24 * 60 * 60 * 1000;
    const oneYearAgo = new Date(currentTimeEpoch - oneYearInMilliseconds);
    const epochOneYearAgo = Math.floor(oneYearAgo.getTime() / 1000);

    dispatch(
      fetchWatchlistBars({
        timeframe: '1D',
        from: epochOneYearAgo,
        to: Math.floor(currentTimeEpoch / 1000),
        mkt_type: marketInfo?.market_supported_map?.[mk],
        ticker: ticker.toUpperCase(),
      }),
    );
  };
  useEffect(() => {
    if (ticker && market) {
      fetchWatchlistData();
    }
  }, [ticker, market]);

  return (
    <>
      <section className='navbar-fix'>
        <WatchlistHeader />

        <main className='watchlist'>
          <section className='watchlist__dropdowns'>
            <Select
              options={marketInfo?.markets || []}
              onChange={(e) => {
                setMkt(e);
                setMartketIndex('');
              }}
            />
            {mkt?.toLowerCase() === 'stocks' ? (
              <Select
                options={marketInfo?.index_list || []}
                onChange={(e) => {
                  setMartketIndex(e);
                }}
              />
            ) : (
              ''
            )}
            <Searchbox
              market={mkt}
              marketIndex={marketIndex}
              onClick={(ticker) => nav(`/watchlist/${ticker}/${mkt.toLowerCase()}/`)}
            />
          </section>
          <hr />
          <nav className='watchlist__navigation'>
            <ul>
              <NavLink end activeClassName='active' to=''>
                <li>Overview</li>
              </NavLink>
              {market?.toLowerCase() === 'stocks' ? (
                <NavLink end activeClassName='active' to='financials'>
                  <li>Financials</li>
                </NavLink>
              ) : (
                ''
              )}
              {market?.toLowerCase() === 'stocks' ? (
                <NavLink end activeClassName='active' to='ratios'>
                  <li>Ratios</li>
                </NavLink>
              ) : (
                ''
              )}
              {market?.toLowerCase() === 'stocks' ? (
                <NavLink end activeClassName='active' to='corporate-actions'>
                  <li>Corporate Actions</li>
                </NavLink>
              ) : (
                ''
              )}
              <NavLink activeClassName='active' to='news'>
                <li>News</li>
              </NavLink>
              {market?.toLowerCase() === 'stocks' ? (
                <NavLink end activeClassName='active' to='deep-analysis'>
                  <li>Deep Analysis & Estimate</li>
                </NavLink>
              ) : (
                ''
              )}
              <NavLink end activeClassName='active' to='ranking'>
                <li>iFIR$T Ranking</li>
              </NavLink>
              {market?.toLowerCase() === 'stocks' ? (
                <NavLink end activeClassName='active' to='esg'>
                  <li>ESG</li>
                </NavLink>
              ) : (
                ''
              )}
            </ul>
          </nav>
          <hr />
          <section className='watchlist__body'>
            <article className='watchlist__sidebar'>
              <WatchlistSidebar data={overview?.data} />
            </article>
            <section className='watchlist__main'>
              <Outlet />
            </section>
          </section>
        </main>
      </section>
    </>
  );
}

export default Watchlist;
