import React, { useState } from 'react';
import { motion } from 'framer-motion';
import VisibilitySensor from 'react-visibility-sensor';
import { Footer, Stats } from '../../components';
import {
  QuantLandingBannerImage,
  QuantLandingBgTwo,
  QuantBGCircleOne,
  QuantBGCircleTwo,
  QuantLandingBgThree,
  QuantLandingBgFour,
  QuantLandingCurveOne,
  QuantLandingCurveTwo,
  QuantLandingCurveThree,
  QuantLandingCurveFour,
  QuantLandingCurveFive,
} from 'src/assets/Images';
import { data } from 'src/static-data';
function QuantLandingPage() {
  const [isVisibleSectionOne, setIsVisibleSectionOne] = useState(
    Array(data.quantLandingPage.sectionOneData.length).fill(false),
  );
  const [isVisibleSectionTwo, setIsVisibleSectionTwo] = useState(
    Array(data.quantLandingPage.sectionTwoData.length).fill(false),
  );
  const [isVisibleSectionThree, setIsVisibleSectionThree] = useState(
    Array(data.quantLandingPage.sectionThreeData.length).fill(false),
  );
  const [isVisibleOne, setIsVisibleOne] = useState(false);
  const [isVisibleTwo, setIsVisibleTwo] = useState(false);
  const [isVisibleSVG, setIsVisibleSVG] = useState(false);
  const [isVisibleThree, setIsVisibleThree] = useState(false);
  const [isVisibleFour, setIsVisibleFour] = useState(false);
  const [isVisibleFive, setIsVisibleFive] = useState(false);

  const handleVisibilityChangeFive = (isVisible) => {
    setIsVisibleFive(isVisible);
  };

  const variants = {
    visible: { x: 0, transition: { duration: 0.8, ease: 'easeInOut' } },
    hidden: { x: '100%', transition: { duration: 0.8, ease: 'easeInOut' } },
  };

  const handleVisibilityChangeFour = (isVisible) => {
    setIsVisibleFour(isVisible);
  };
  const onChange = (visibility) => {
    setIsVisibleSVG(visibility);
  };

  const handleVisibilityChangeTwo = (visible) => {
    setIsVisibleTwo(visible);
  };

  const handleVisibilityChangeThree = (isVisible) => {
    setIsVisibleThree(isVisible);
  };

  const handleVisibilityChange = (index, visible, section) => {
    if (section === 'sectionOne') {
      const newVisibility = [...isVisibleSectionOne];
      newVisibility[index] = visible;
      setIsVisibleSectionOne(newVisibility);
    } else if (section === 'sectionTwo') {
      const newVisibility = [...isVisibleSectionTwo];
      newVisibility[index] = visible;
      setIsVisibleSectionTwo(newVisibility);
    } else if (section === 'sectionThree') {
      const newVisibility = [...isVisibleSectionThree];
      newVisibility[index] = visible;
      setIsVisibleSectionThree(newVisibility);
    }
  };

  return (
    <main className='quantLanding'>
      <div className='quantLanding__main'>
        <div className='quantLanding__headerContainer'>
          <div className='quantLanding__header'>
            <motion.h1
              initial={{ y: -100, opacity: 0 }}
              animate={{ y: 0, opacity: 1 }}
              transition={{ duration: 1, delay: 1 }}>
              Quant
            </motion.h1>
            <motion.p
              initial={{ y: 100, opacity: 0 }}
              animate={{ y: 0, opacity: 1 }}
              transition={{ duration: 1, delay: 2 }}>
              Let us build your custom strategy
            </motion.p>
          </div>
          {/* Cicle Behind The Banner */}
          <div className='quantLanding__bannerImg'>
            <div className='quantLanding__banner-subOne'>
              <img src={QuantBGCircleOne} />
            </div>
            {/* Main Banner Image */}
            <motion.img
              src={QuantLandingBannerImage}
              initial={{ opacity: 0, scale: 0.5 }}
              animate={{ opacity: 1, scale: 1 }}
              transition={{ duration: 1.5, delay: 0.5 }}
            />
            {/* Coin Image */}
            <VisibilitySensor partialVisibility onChange={(visible) => setIsVisibleOne(visible)}>
              <div className='quantLanding__banner-subTwo'>
                <motion.img
                  src={QuantLandingBgTwo}
                  initial={{ x: -200, opacity: 0, scale: 0.5 }}
                  animate={{ x: isVisibleOne ? 0 : -100, opacity: isVisibleOne ? 1 : 0, scale: 1 }}
                  transition={{ duration: 1.5, delay: 0.5 }}
                />
              </div>
            </VisibilitySensor>
            {/* Second Ball entering From Top */}
            <VisibilitySensor partialVisibility onChange={handleVisibilityChangeTwo}>
              <div className='quantLanding__banner-subThree'>
                <motion.img
                  src={QuantBGCircleTwo}
                  initial={{ opacity: 0, y: '-100vh' }}
                  animate={{ opacity: isVisibleTwo ? 1 : 0, y: isVisibleTwo ? '0' : '-100vh' }}
                  transition={{ duration: 1 }}
                  className='quantLanding__bg-circle'
                />
              </div>
            </VisibilitySensor>
          </div>
          <div className='quantLanding__CurveOne '>
            <img src={QuantLandingCurveOne} alt='' />
          </div>
        </div>
        {/* Main Header And Banner Image */}
        <div className='quant__widthContainer'>
          <h1 className='quantLanding__headerSecond'>It&apos;s offering you...</h1>
          <div className='quant__widthContainer--CurveOne'>
            <img src={QuantLandingCurveTwo} alt='' />
          </div>
          <div className='quant__widthContainer--CurveTwo'>
            <img src={QuantLandingCurveThree} alt='' />
          </div>
          <div className='quant__widthContainer--CurveThree'>
            <img src={QuantLandingCurveFour} alt='' />
          </div>
          <div className='quant__widthContainer--CurveFour'>
            <img src={QuantLandingCurveFive} alt='' />
          </div>
          {/* Section One */}
          {data.quantLandingPage.sectionOneData.map((one, index) => (
            <VisibilitySensor
              key={one.id}
              partialVisibility
              onChange={(visible) => handleVisibilityChange(index, visible, 'sectionOne')}>
              <section className='quantLanding__sectionOne' key={one.id}>
                <motion.div
                  className='quantLanding__left'
                  initial={{ x: -100, opacity: 0 }}
                  animate={{ x: isVisibleSectionOne[index] ? 0 : -100, opacity: isVisibleSectionOne[index] ? 1 : 0 }}
                  transition={{ duration: 1.5, delay: isVisibleSectionOne[index] ? 0 : 0.5 }}>
                  <h1>
                    # <span>{one.header}</span>
                  </h1>
                  {one.listItems.map((item, listIndex) => (
                    <motion.ul
                      key={item.id}
                      initial={{ y: 100, opacity: 0 }}
                      animate={{ y: isVisibleSectionOne[index] ? 0 : 100, opacity: isVisibleSectionOne[index] ? 1 : 0 }}
                      transition={{ duration: 1, delay: isVisibleSectionOne[index] ? listIndex * 0.2 + 0.5 : 0 }}>
                      <li>
                        <div />
                        <span>{item.listItem}</span>
                      </li>
                    </motion.ul>
                  ))}
                </motion.div>
                <motion.div
                  className='quantLanding__right'
                  initial={{ x: '100vw', opacity: 0 }}
                  animate={{ x: isVisibleSectionOne[index] ? 0 : '100vw', opacity: isVisibleSectionOne[index] ? 1 : 0 }}
                  transition={{ duration: 1.5, delay: isVisibleSectionOne[index] ? 0 : 0.5 }}>
                  <img src={one.Img} alt='' />
                </motion.div>
              </section>
            </VisibilitySensor>
          ))}
          <VisibilitySensor partialVisibility onChange={handleVisibilityChangeThree}>
            <motion.img
              src={QuantLandingBgThree}
              initial={{ scale: 0.5 }} // Start with smaller scale
              animate={{ scale: isVisibleThree ? 1 : 0.5 }} // Zoom in if visible, else keep smaller scale
              transition={{ duration: 2 }} // Transition duration set to 2 seconds for slower animation
              style={{ visibility: isVisibleThree ? 'visible' : 'hidden' }} // Hide when not visible
              className='quant__widthContainer--BGOne'
            />
          </VisibilitySensor>
          {/* Section Two */}
          {data.quantLandingPage.sectionTwoData.map((one, index) => (
            <VisibilitySensor
              key={one.id}
              partialVisibility
              onChange={(visible) => handleVisibilityChange(index, visible, 'sectionTwo')}>
              <section className='quantLanding__sectionTwo' key={one.id}>
                <motion.div
                  className='quantLanding__left'
                  initial={{ x: -100, opacity: 0 }}
                  animate={{ x: isVisibleSectionTwo[index] ? 0 : -100, opacity: isVisibleSectionTwo[index] ? 1 : 0 }}
                  transition={{ duration: 1.5, delay: isVisibleSectionTwo[index] ? 0 : 0.5 }}>
                  <h1>
                    # <span>{one.header}</span>
                  </h1>
                  {one.listItems.map((item, listIndex) => (
                    <motion.ul
                      key={item.id}
                      initial={{ y: 100, opacity: 0 }}
                      animate={{ y: isVisibleSectionTwo[index] ? 0 : 100, opacity: isVisibleSectionTwo[index] ? 1 : 0 }}
                      transition={{ duration: 1, delay: isVisibleSectionTwo[index] ? listIndex * 0.2 + 0.5 : 0 }}>
                      <li>
                        <div />
                        <span>{item.listItem}</span>
                      </li>
                    </motion.ul>
                  ))}
                </motion.div>
                <motion.div
                  className='quantLanding__right'
                  initial={{ x: '-100vw', opacity: 0 }}
                  animate={{
                    x: isVisibleSectionTwo[index] ? 0 : '-100vw',
                    opacity: isVisibleSectionTwo[index] ? 1 : 0,
                  }}
                  transition={{ duration: 1.5, delay: isVisibleSectionTwo[index] ? 0 : 0.5 }}>
                  <img src={one.Img} alt='' />
                </motion.div>
              </section>
            </VisibilitySensor>
          ))}
          <VisibilitySensor partialVisibility onChange={handleVisibilityChangeFour}>
            <motion.img
              src={QuantLandingBgFour}
              initial={{ scale: 0.5 }} // Start with smaller scale
              animate={{ scale: isVisibleFour ? 1 : 0.5 }} // Zoom in if visible, else keep smaller scale
              transition={{ duration: 4 }} // Transition duration set to 5 seconds for even slower animation
              style={{ visibility: isVisibleFour ? 'visible' : 'hidden' }} // Hide when not visible
              className='quant__widthContainer--BGTwo'
            />
          </VisibilitySensor>
          {/* Candles SVG */}
          <VisibilitySensor onChange={onChange} partialVisibility>
            <motion.div
              className='quant__widthContainer--BGThree'
              initial='hidden'
              animate={isVisibleSVG ? 'visible' : 'hidden'}
              variants={variants}>
              <svg width='591' height='291' viewBox='0 0 591 291' fill='none' xmlns='http://www.w3.org/2000/svg'>
                <g opacity='0.4'>
                  <defs>
                    <linearGradient id='grad' x1='0%' y1='0%' x2='100%' y2='0%'>
                      <stop offset='0%' stopColor='#040D21' />
                      <stop offset='100%' stopColor='#007BEF' />
                    </linearGradient>
                  </defs>
                  <motion.path
                    d='M602.691 84.7054L587.567 98.8345L570.498 102.587L543.748 69.982L526.376 81.5668L516.446 123.926L490.828 96.4729L478.569 125.165L450.518 82.0414L422.422 43.3172L407.599 66.4954L389.076 65.1666L370.597 76.3198L362.78 117.539L340.381 106.259L331.867 155.059L314.651 161.208L304.785 206.929L282.959 195.185L271.896 232.391L252.233 228.95L225.901 201.317L213.223 225.036L192.955 220.377L167.801 193.498L136.518 140.282L124.148 170.01L104.872 169.86L91.9359 197.012L83.1391 244.524L53.0282 195.101L30.2496 177.49L18.5884 210.439'
                    fill='none'
                    stroke='url(#grad)'
                    strokeWidth='3'
                    strokeLinecap='round'
                    strokeLinejoin='round'
                    initial={{ pathLength: 0, strokeDashoffset: '100%' }}
                    animate={
                      isVisibleSVG
                        ? { pathLength: 1, strokeDashoffset: '0%', transition: { duration: 6 } }
                        : { pathLength: 0, strokeDashoffset: '100%', transition: { duration: 0 } }
                    }
                  />
                  <circle
                    cx='587.566'
                    cy='98.8341'
                    r='5.09466'
                    transform='rotate(-12.4011 587.566 98.8341)'
                    fill='#040D21'
                    stroke='#007BEF'
                    stroke-width='3'
                  />
                  <circle
                    cx='570.822'
                    cy='102.517'
                    r='5.09466'
                    transform='rotate(-12.4011 570.822 102.517)'
                    fill='#040D21'
                    stroke='#007BEF'
                    stroke-width='3'
                  />
                  <circle
                    cx='544.07'
                    cy='69.9113'
                    r='5.09466'
                    transform='rotate(-12.4011 544.07 69.9113)'
                    fill='#040D21'
                    stroke='#007BEF'
                    stroke-width='3'
                  />
                  <circle
                    cx='526.589'
                    cy='82.5329'
                    r='5.09466'
                    transform='rotate(-12.4011 526.589 82.5329)'
                    fill='#040D21'
                    stroke='#007BEF'
                    stroke-width='3'
                  />
                  <circle
                    cx='515.984'
                    cy='123.352'
                    r='5.09466'
                    transform='rotate(-12.4011 515.984 123.352)'
                    fill='#040D21'
                    stroke='#007BEF'
                    stroke-width='3'
                  />
                  <circle
                    cx='491.937'
                    cy='96.9044'
                    r='5.09466'
                    transform='rotate(-12.4011 491.937 96.9044)'
                    fill='#040D21'
                    stroke='#007BEF'
                    stroke-width='3'
                  />
                  <circle
                    cx='478.498'
                    cy='124.842'
                    r='5.09466'
                    transform='rotate(-12.4011 478.498 124.842)'
                    fill='#040D21'
                    stroke='#007BEF'
                    stroke-width='3'
                  />
                  <circle
                    cx='450.769'
                    cy='81.6486'
                    r='5.09466'
                    transform='rotate(-12.4011 450.769 81.6486)'
                    fill='#040D21'
                    stroke='#007BEF'
                    stroke-width='3'
                  />
                  <circle
                    cx='422.744'
                    cy='43.2462'
                    r='5.09466'
                    transform='rotate(-12.4011 422.744 43.2462)'
                    fill='#040D21'
                    stroke='#007BEF'
                    stroke-width='3'
                  />
                  <circle
                    cx='408.457'
                    cy='67.32'
                    r='5.09466'
                    transform='rotate(-12.4011 408.457 67.32)'
                    fill='#040D21'
                    stroke='#007BEF'
                    stroke-width='3'
                  />
                  <circle
                    cx='388.865'
                    cy='64.2003'
                    r='5.09466'
                    transform='rotate(-12.4011 388.865 64.2003)'
                    fill='#040D21'
                    stroke='#007BEF'
                    stroke-width='3'
                  />
                  <circle
                    cx='369.812'
                    cy='75.817'
                    r='5.09466'
                    transform='rotate(-12.4011 369.812 75.817)'
                    fill='#040D21'
                    stroke='#007BEF'
                    stroke-width='3'
                  />
                  <circle
                    cx='362.568'
                    cy='116.573'
                    r='5.09466'
                    transform='rotate(-12.4011 362.568 116.573)'
                    fill='#040D21'
                    stroke='#0264C3'
                    stroke-width='3'
                  />
                  <circle
                    cx='339.988'
                    cy='106.007'
                    r='5.09466'
                    transform='rotate(-12.4011 339.988 106.007)'
                    fill='#040D21'
                    stroke='#0260BC'
                    stroke-width='3'
                  />
                  <circle
                    cx='332.511'
                    cy='154.917'
                    r='5.09466'
                    transform='rotate(-12.4011 332.511 154.917)'
                    fill='#040D21'
                    stroke='#025BB2'
                    stroke-width='3'
                  />
                  <circle
                    cx='313.898'
                    cy='162.386'
                    r='5.09466'
                    transform='rotate(-12.4011 313.898 162.386)'
                    fill='#040D21'
                    stroke='#0257AB'
                    stroke-width='3'
                  />
                  <circle
                    cx='303.857'
                    cy='205.782'
                    r='5.09466'
                    transform='rotate(-12.4011 303.857 205.782)'
                    fill='#040D21'
                    stroke='#0252A1'
                    stroke-width='3'
                  />
                  <circle
                    cx='283.494'
                    cy='196.08'
                    r='5.09466'
                    transform='rotate(-12.4011 283.494 196.08)'
                    fill='#040D21'
                    stroke='#024E9B'
                    stroke-width='3'
                  />
                  <circle
                    cx='271.896'
                    cy='232.391'
                    r='5.09466'
                    transform='rotate(-12.4011 271.896 232.391)'
                    fill='#040D21'
                    stroke='#034992'
                    stroke-width='3'
                  />
                  <circle
                    cx='252.447'
                    cy='229.916'
                    r='5.09466'
                    transform='rotate(-12.4011 252.447 229.916)'
                    fill='#040D21'
                    stroke='#034283'
                    stroke-width='3'
                  />
                  <circle
                    cx='226.043'
                    cy='201.961'
                    r='5.09466'
                    transform='rotate(-12.4011 226.043 201.961)'
                    fill='#040D21'
                    stroke='#034284'
                    stroke-width='3'
                  />
                  <circle
                    cx='212.902'
                    cy='225.107'
                    r='5.09466'
                    transform='rotate(-12.4011 212.902 225.107)'
                    fill='#040D21'
                    stroke='#033E7C'
                    stroke-width='3'
                  />
                  <circle
                    cx='193.67'
                    cy='220.558'
                    r='5.09466'
                    transform='rotate(-12.4011 193.67 220.558)'
                    fill='#040D21'
                    stroke='#033974'
                    stroke-width='3'
                  />
                  <circle
                    cx='168.052'
                    cy='193.105'
                    r='5.09466'
                    transform='rotate(-12.4011 168.052 193.105)'
                    fill='#040D21'
                    stroke='#03366D'
                    stroke-width='3'
                  />
                  <circle
                    cx='135.623'
                    cy='140.816'
                    r='5.09466'
                    transform='rotate(-12.4011 135.623 140.816)'
                    fill='#040D21'
                    stroke='#033164'
                    stroke-width='3'
                  />
                  <circle
                    cx='123.615'
                    cy='169.115'
                    r='5.09466'
                    transform='rotate(-12.4011 123.615 169.115)'
                    fill='#040D21'
                    stroke='#042E5E'
                    stroke-width='3'
                  />
                  <circle
                    cx='103.867'
                    cy='171.431'
                    r='5.09466'
                    transform='rotate(-12.4011 103.867 171.431)'
                    fill='#040D21'
                    stroke='#042955'
                    stroke-width='3'
                  />
                  <circle
                    cx='91.7945'
                    cy='196.368'
                    r='5.09466'
                    transform='rotate(-12.4011 91.7945 196.368)'
                    fill='#040D21'
                    stroke='#04254E'
                    stroke-width='3'
                  />
                  <circle
                    cx='82.4644'
                    cy='242.984'
                    r='5.09466'
                    transform='rotate(-12.4011 82.4644 242.984)'
                    fill='#040D21'
                    stroke='#041E40'
                    stroke-width='3'
                  />
                  <circle
                    cx='53.4605'
                    cy='193.994'
                    r='5.09466'
                    transform='rotate(-12.4011 53.4605 193.994)'
                    fill='#040D21'
                    stroke='#041836'
                    stroke-width='3'
                  />
                </g>
                <defs>
                  <linearGradient
                    id='paint0_linear_141_49832'
                    x1='480.265'
                    y1='102.172'
                    x2='-0.555411'
                    y2='230.854'
                    gradientUnits='userSpaceOnUse'>
                    <stop stop-color='#007BEF' />
                    <stop offset='1' stop-color='#007BEF' stop-opacity='0' />
                  </linearGradient>
                </defs>
              </svg>
            </motion.div>
          </VisibilitySensor>
          <VisibilitySensor partialVisibility onChange={handleVisibilityChangeFive}>
            <motion.div
              initial={{ scale: 0.1, opacity: 0 }} // Initial size and opacity
              animate={{ scale: isVisibleFive ? 1 : 0.1, opacity: isVisibleFive ? 1 : 0 }} // Zoom in if visible, else zoom out
              transition={{ duration: 1 }}
              className='quant__widthContainer--BGFour'>
              <img src={QuantBGCircleOne} alt='BGFour' />
            </motion.div>
          </VisibilitySensor>
          {/* Section Three */}
          {data.quantLandingPage.sectionThreeData.map((one, index) => (
            <VisibilitySensor
              key={one.id}
              partialVisibility
              onChange={(visible) => handleVisibilityChange(index, visible, 'sectionThree')}>
              <section className='quantLanding__sectionOne' key={one.id}>
                <motion.div
                  className='quantLanding__left'
                  initial={{ x: -100, opacity: 0 }}
                  animate={{
                    x: isVisibleSectionThree[index] ? 0 : -100,
                    opacity: isVisibleSectionThree[index] ? 1 : 0,
                  }}
                  transition={{ duration: 1.5, delay: isVisibleSectionThree[index] ? 0 : 0.5 }}>
                  <h1>
                    # <span>{one.header}</span>
                  </h1>
                  {one.listItems.map((item, listIndex) => (
                    <motion.ul
                      key={item.id}
                      initial={{ y: 100, opacity: 0 }}
                      animate={{
                        y: isVisibleSectionThree[index] ? 0 : 100,
                        opacity: isVisibleSectionThree[index] ? 1 : 0,
                      }}
                      transition={{
                        duration: 1,
                        delay: isVisibleSectionThree[index] ? listIndex * 0.2 + 0.5 : 0,
                      }}>
                      <li>
                        <div />
                        <span>{item.listItem}</span>
                      </li>
                    </motion.ul>
                  ))}
                </motion.div>
                <motion.div
                  className='quantLanding__right'
                  initial={{ x: '100vw', opacity: 0 }}
                  animate={{
                    x: isVisibleSectionThree[index] ? 0 : '100vw',
                    opacity: isVisibleSectionThree[index] ? 1 : 0,
                  }}
                  transition={{ duration: 1.5, delay: isVisibleSectionThree[index] ? 0 : 0.5 }}>
                  <img src={one.Img} alt='' />
                </motion.div>
              </section>
            </VisibilitySensor>
          ))}
          <div className='quant__widthContainer--more'>..and more</div>
          <Stats />
          <Footer />
        </div>
      </div>
    </main>
  );
}

export default QuantLandingPage;
