/* eslint-disable max-len */
/* eslint-disable indent */
import { apiClient } from '../apiClient';
import { config } from 'src/lib/config';

export const getSupportDetails = async (params: any) => {
  const apiUrl = config.API_SVC_PATH_IFIRST_SUPPORT + config.SUBSCRIPTION_META_DATA;
  const res = await apiClient({
    method: 'get',
    url: apiUrl,
    params,
  });
  return res;
};

export const getSupportSections = async (params: any) => {
  const apiUrl = config.API_SVC_PATH_IFIRST_SUPPORT + config.API_SUPPORT_SECTION;
  const res = await apiClient({
    method: 'get',
    url: apiUrl,
    params,
  });
  return res;
};
export const getSupportFaq = async (params: any) => {
  const apiUrl = config.API_SVC_PATH_IFIRST_SUPPORT + config.API_SUPPORT_FAQ;
  const res = await apiClient({
    method: 'get',
    url: apiUrl,
    params,
  });
  return res;
};
export const getSupportFaqDetails = async (params: any) => {
  const apiUrl = config.API_SVC_PATH_IFIRST_SUPPORT + config.API_SUPPORT_FAQ_DETAILS;
  const res = await apiClient({
    method: 'get',
    url: apiUrl,
    params,
  });
  return res;
};
export const getSupportVideo = async (params: any) => {
  const apiUrl = config.API_SVC_PATH_IFIRST_SUPPORT + config.API_SUPPORT_SHORT_VIDEO;
  const res = await apiClient({
    method: 'get',
    url: apiUrl,
    params,
  });
  return res;
};

export const postSupportTicket = async (payload: any) => {
  const apiUrl = config.API_SVC_PATH_IFIRST_SUPPORT + config.API_SUPPORT_TICKET;
  const res = await apiClient({
    headers: { 'Content-Type': 'multipart/form-data' },
    method: 'post',
    url: apiUrl,
    data: payload,
  });
  return res;
};
export const getSupportTickets = async (params: any) => {
  const apiUrl = config.API_SVC_PATH_IFIRST_SUPPORT + config.API_SUPPORT_TICKET;
  const res = await apiClient({
    method: 'get',
    url: apiUrl,
    params,
  });
  return res;
};
export const putSupportTickets = async (payload: any) => {
  const apiUrl = config.API_SVC_PATH_IFIRST_SUPPORT + config.API_SUPPORT_TICKET;
  const res = await apiClient({
    method: 'put',
    url: apiUrl,
    data: payload,
  });
  return res;
};

export const searchSupportFaq = async (params) => {
  const apiUrl = config.API_SVC_PATH_IFIRST_SUPPORT + config.API_SUPPORT_FAQ_SEARCH;
  const res = await apiClient({
    method: 'get',
    url: apiUrl,
    params,
  });
  return res;
};
