import React, { useEffect, useRef } from 'react';
import * as d3 from 'd3';

export default function ZScoreBar({ high, low, currentValue, width, height }) {
  const chartRef = useRef(null);

  useEffect(() => {
    const chartNode = d3.select(chartRef.current);

    // Clear previous chart content
    chartNode.selectAll('*').remove();

    // Set up the dimensions and margins
    const margin = { top: 40, right: 10, bottom: 30, left: 10 };
    const innerWidth = width - margin.left - margin.right;
    const innerHeight = height - margin.top - margin.bottom;

    // Create the main chart group
    const chart = chartNode.append('g').attr('transform', `translate(${margin.left}, ${margin.top})`);

    // Create the red segment (0 to 3)
    chart
      .append('rect')
      .attr('x', 0)
      .attr('y', innerHeight / 2 - 5)
      .attr('width', innerWidth / 3)
      .attr('height', 10)
      .style('fill', 'red');

    // Create the grey segment (3 to 6)
    chart
      .append('rect')
      .attr('x', innerWidth / 3)
      .attr('y', innerHeight / 2 - 5)
      .attr('width', innerWidth / 3)
      .attr('height', 10)
      .style('fill', 'grey');

    // Create the green segment (6 to infinity)
    chart
      .append('rect')
      .attr('x', (innerWidth / 3) * 2)
      .attr('y', innerHeight / 2 - 5)
      .attr('width', innerWidth / 3)
      .attr('height', 10)
      .style('fill', 'green');

    // Create the scales
    const linearScale1 = d3
      .scaleLinear()
      .domain([0, 1.8]) // Distress zone
      .range([0, innerWidth / 3]);

    const linearScale2 = d3
      .scaleLinear()
      .domain([1.8, 2.99]) // Grey zone
      .range([innerWidth / 3, (innerWidth / 3) * 2]);

    const logScale = d3
      .scaleLog()
      .domain([2.99, high]) // Safe zone
      .range([(innerWidth / 3) * 2, innerWidth]);

    // Combine scales to find the current value position
    let currentValueX;
    if (currentValue < 1.8) {
      currentValueX = linearScale1(currentValue);
    } else if (currentValue < 2.99) {
      currentValueX = linearScale2(currentValue);
    } else if (currentValue <= high) {
      currentValueX = logScale(currentValue);
    } else {
      currentValueX = innerWidth; // Place marker at the end (representing infinity)
    }

    // Add scale tick values at segment intersections
    const ticks = [
      0, // Start of Distress Zone
      1.8, // End of Distress Zone
      2.99, // End of Grey Zone
      high, // Maximum value
    ];

    const tickPositions = ticks.map((value) => {
      if (value < 1.9) {
        return linearScale1(value);
      } else if (value < 2.99) {
        return linearScale2(value);
      } else {
        return logScale(value);
      }
    });

    chart
      .selectAll('.tick')
      .data(ticks)
      .enter()
      .append('text')
      .attr('x', (d, i) => tickPositions[i])
      .attr('y', innerHeight / 2 + 20)
      .text((d, i) => {
        if (i === 1) return `${d.toFixed(1)}`;
        if (i === 2) return `${d.toFixed(2)}`; // For the third tick
        return i === ticks.length - 1 ? '∞' : d.toFixed(1); // Last tick as '∞'
      })
      .style('text-anchor', 'middle')
      .attr('fill', '#ffffff')
      .style('font-size', '15px');

    const labels = [
      { text: 'Distress Zone', x: innerWidth / 6, y: -5 },
      { text: 'Grey Zone', x: innerWidth / 2, y: -5 },
      { text: 'Safe Zone', x: ((innerWidth / 3) * 5) / 2, y: -5 },
    ];

    labels.forEach((label) => {
      chart
        .append('text')
        .attr('x', label.x)
        .attr('y', label.y)
        .text(label.text)
        .style('text-anchor', 'middle')
        .attr('fill', '#ffffff')
        .style('font-size', '15px')
        .style('font-weight', '600');
    });

    // Create the current value indicator
    chart
      .append('text')
      .attr('x', currentValueX)
      .attr('y', innerHeight / 2 + 30) // Move it a bit down for visibility
      .text(currentValue > high ? '∞' : currentValue?.toFixed(2)) // Display '∞' if currentValue exceeds high
      .style('text-anchor', 'middle')
      .attr('fill', '#ffffff')
      .style('font-size', '15px')
      .style('font-weight', '600');

    // Create the triangle to indicate the current value
    const currentValueY = innerHeight / 2 - 5; // Offset to position the triangle below the point
    const currentValueTrianglePoints = [
      [currentValueX, currentValueY],
      [currentValueX - 5, currentValueY - 10],
      [currentValueX + 5, currentValueY - 10],
    ];

    chart.append('polygon').attr('points', currentValueTrianglePoints).style('fill', '#ffffff');
  }, [high, low, currentValue]);

  return <svg ref={chartRef} width={width} height={height} />;
}
