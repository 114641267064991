import { put, takeLatest } from 'redux-saga/effects';
import { getTvConfig, getTvSymbols } from 'src/repositories/tv/tv';
import {
  fetchTvConfig,
  fetchTvConfigFailure,
  fetchTvConfigSuccess,
  fetchTvSymbol,
  fetchTvSymbolFailure,
  fetchTvSymbolSuccess,
} from './tv.reducer';
function* fetchTvConfigSaga(_action) {
  try {
    const data = yield getTvConfig().then((response) => response);

    yield put(
      fetchTvConfigSuccess({
        supported_resolutions: [...data.data.data?.supported_resolutions],
        exchanges: data.data.data?.exchanges,
        symbols_types: data.data.data?.symbols_types,
      }),
    );
  } catch (error) {
    yield put(fetchTvConfigFailure(error));
  }
}

export function* watchTvConfigSaga() {
  yield takeLatest(fetchTvConfig.type, fetchTvConfigSaga);
}

function* fetchTvSymbolSaga(_action) {
  try {
    const data = yield getTvSymbols().then((response) => response);
    yield put(
      fetchTvSymbolSuccess([
        ...data.data.data.usStocksDetailedSymbols,
        ...data.data.data.fxDetailedSymbols,
        ...data.data.data.cryptoDetailedSymbols,
        ...data.data.data.commoDitySDetailedSymbols,
      ]),
    );
  } catch (error) {
    yield put(fetchTvSymbolFailure(error));
  }
}

export function* watchTvSymbolSaga() {
  yield takeLatest(fetchTvSymbol.type, fetchTvSymbolSaga);
}
