import React, { useState, useRef, useEffect } from 'react';
import { ToggleButton, PrimaryHeader, ModalWrapper, Button } from 'src/components';
import AccountSubsciption from '../subscription/subscription.router';
import { whiteCircle } from 'src/assets/Images';
import { ArrowRightIcon } from '@heroicons/react/20/solid';
import UpdatePassword from '../../../components/Profile/Updatepassword';
import UpdateEmail from '../../../components/Profile/UpdateEmail';
import ProfileForm from '../../../components/Profile/ProfileForm';
import { useDispatch } from 'react-redux';
import { accountSubscription, fetchProfile, profilePicUpdate, profilePicUpdateReset } from '../profile.reducer';
import { setCurrentUserImage } from 'src/store/user/user.reducer';
import { useSelector } from 'react-redux';
import { selectProfile, selectProfilePhoto } from '../profile.selector';
import { Dialog } from 'src/components/Modal/dialogbox';
import Loader from 'src/components/loadingAnimation/loadingAnimation.component';
export default function Profile() {
  const [successModal, setSuccessModal] = useState(false);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchProfile());
    dispatch(accountSubscription());
  }, []);
  const profilePhoto = useSelector(selectProfilePhoto);
  const [navToggle, setNavToggle] = useState(1);
  const [file, setFile] = useState<Blob | null>(null); // State for the selected file
  const [imagePreview, setImagePreview] = useState<string | null>(null); // State for image preview
  const profileData = useSelector(selectProfile);

  const hiddenFileInput = useRef<HTMLInputElement>(null);

  const handleClick = () => {
    if (hiddenFileInput.current) {
      hiddenFileInput.current.click();
    }
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const selectedFile = event.target.files?.[0];
    if (selectedFile) {
      setFile(selectedFile);
      setImagePreview(URL.createObjectURL(selectedFile)); // Set image preview URL
    }
  };
  const handleRemovePhoto = () => {
    setFile(null);
    setImagePreview(null);
    if (hiddenFileInput.current) {
      hiddenFileInput.current.value = ''; // Clear the file input
    }
  };
  useEffect(() => {
    if (profilePhoto?.data) {
      dispatch(
        setCurrentUserImage({ user_avatar: profilePhoto?.data?.link, imageKey: Math.floor(Math.random() * 100) + 1 }),
      );
      setSuccessModal(true);
    }
  }, [profilePhoto]);
  return (
    <>
      <main className='profile navbar-fix'>
        <PrimaryHeader text='Profile' className='color-primary' />
        <section>
          <div className='profile__container'>
            <div className='profile__toggleContainer'>
              <p>Choose theme color:</p>
              <div className='profile__header'>
                <div className='profile__header--toggleButton'>
                  <p>Quant</p>
                  <ToggleButton className='blue' id='toggle_btn_1' />
                </div>
                <div className='profile__header--toggleButton'>
                  <p>iPortfolio</p>
                  <ToggleButton className='grey' id='toggle_btn_2' />
                </div>
                <div className='profile__header--toggleButton'>
                  <p>iProfile</p>
                  <ToggleButton className='purple' id='toggle_btn_3' />
                </div>
              </div>
            </div>

            <hr />
            <div className='profile__body'>
              <div className='profile__sidebar'>
                <h1>Quick Links</h1>
                <div className='profile__Links'>
                  <ul>
                    <li
                      onClick={() => setNavToggle(1)}
                      className={navToggle === 1 ? 'navToggle__active' : 'navToggle__normal'}>
                      <div className='profile__singleLink'>
                        <img src={whiteCircle} alt='' />
                        User Profile
                      </div>
                      <ArrowRightIcon className='size-8' />
                    </li>
                    <li
                      onClick={() => setNavToggle(2)}
                      className={navToggle === 2 ? 'navToggle__active' : 'navToggle__normal'}>
                      <div className='profile__singleLink'>
                        <img src={whiteCircle} alt='' />
                        Accounts & Subsciption
                      </div>
                      <ArrowRightIcon className='size-8' />
                    </li>
                  </ul>
                </div>
              </div>
              {navToggle === 1 ? (
                <div className='profile__formContainer'>
                  {profileData?.isLoading ? (
                    <Loader />
                  ) : (
                    <>
                      <div className='profile__containerTop'>
                        <div className='profile__container--Left'>
                          <h1 className='block'>Public Information</h1>

                          <div className='profile__uploader'>
                            <div className={`h-[10rem]`}>
                              <label htmlFor='Avatar'>
                                <input
                                  type='file'
                                  accept='image/*'
                                  id='Avatar'
                                  name='Avatar'
                                  hidden
                                  ref={hiddenFileInput}
                                  onChange={handleChange}
                                />
                              </label>
                              <div className='fileUpload__container'>
                                <div className='avatar-preview-container'>
                                  {imagePreview ? (
                                    <img
                                      src={imagePreview || undefined}
                                      alt='Avatar Preview'
                                      className='avatar-preview'
                                    />
                                  ) : (
                                    <span>PD</span>
                                  )}
                                </div>
                                <div className={`flex-[8] mx-[2rem] flex flex-col gap-y-[1rem]`}>
                                  <div className='p1'>JPG or PNG. Max size of 700KB</div>
                                  <div className='p__container'>
                                    {file ? (
                                      <>
                                        <div
                                          className='p2 cursor-pointer btn btn-secondary'
                                          onClick={() => {
                                            if (file) dispatch(profilePicUpdate({ files: file }));
                                          }}>
                                          {profilePhoto?.isLoading ? <div className='loader' /> : 'Upload photo'}
                                        </div>
                                        {profilePhoto?.isLoading ? null : (
                                          <div
                                            className='p3 cursor-pointer btn btn-secondary'
                                            onClick={handleRemovePhoto}>
                                            Remove photo
                                          </div>
                                        )}
                                      </>
                                    ) : (
                                      <>
                                        <div className='p3 cursor-pointer btn btn-secondary' onClick={handleClick}>
                                          Select photo
                                        </div>
                                      </>
                                    )}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className='profile__container--Right'>
                          <h1>Private Details</h1>
                          <Dialog>
                            <UpdateEmail />
                          </Dialog>
                          <Dialog>
                            <UpdatePassword />
                          </Dialog>
                        </div>
                      </div>

                      <ProfileForm />
                    </>
                  )}
                </div>
              ) : null}
              {navToggle === 2 ? (
                <div className='profile__sub'>
                  <AccountSubsciption />
                </div>
              ) : null}
            </div>
          </div>
        </section>
      </main>

      <ModalWrapper open={successModal} onClose={() => setSuccessModal(false)}>
        <div className='postSuccessModal'>
          {profilePhoto?.data ? <h2 className='ca-nodata'>Your Profile Photo Has Been Updated!</h2> : null}
          {profilePhoto?.error ? <h2 className='ca-nodata'>Your Profile Photo Updation Failed!</h2> : null}
          <Button
            className='btn-primary btn-rounded'
            label='OK'
            onClick={() => {
              setSuccessModal(false);
              dispatch(profilePicUpdateReset());
              handleRemovePhoto();
            }}
          />
        </div>
      </ModalWrapper>
    </>
  );
}
